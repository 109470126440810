// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_quote: 'http://100.26.10.11/api',
  api_rocket: 'ws://134.209.117.135:3000/websocket',
  /*enviroment prod*/
  /*api_rest: 'https://services.ammia.io/api/admin',
  api_rest_admin: 'https://services.ammia.io/api/core',
  api_user_external: 'http://157.245.246.200/api',
  api_rest_admin_surexs: 'https://services.ammia.io/api/core',
  api_rest_ammia_surexs: 'https://services.ammia.io/api/ammia/v1.0/protected/sso',
  api_rest_ammia_web_surexs: 'https://services.ammia.io/api/ammia/v1.0/protected'*/
  /*enviroment dev */
  api_rest: 'https://qa.services.ammia.io/api/admin',
  api_rest_admin: 'https://qa.services.ammia.io/api/core',
  api_user_external: 'http://157.245.246.200/api',
  api_rest_admin_surexs: 'https://qa.services.ammia.io/api/core',
  api_rest_ammia_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/protected/sso',
  api_rest_ammia_web_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/protected'

  /*enviroment local*/
  /*api_rest: 'https://qa.services.ammia.io/api/admin',
  api_rest_admin: 'http://localhost/api/core',
  api_user_external: 'http://157.245.246.200/api',
  api_rest_admin_surexs: 'http://localhost/api/core',
  api_rest_ammia_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/protected/sso',
  api_rest_ammia_web_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/public'*/
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
