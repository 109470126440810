import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {DatePipe} from '@angular/common';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-graph-subramo',
  templateUrl: './graph-subramo.component.html',
  styleUrls: ['./graph-subramo.component.css']
})
export class GraphSubramoComponent implements OnInit {

  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filtersHistogram: any = [];
  modalRef: BsModalRef;
  subranchlist = [];
  reportProductionList: any = [];

  pieChartOptions: ChartOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const allData = data.datasets[tooltipItem.datasetIndex].data;
          const tooltipLabel = data.labels[tooltipItem.index];
          const tooltipData: any = allData[tooltipItem.index];
          let total: any = 0;
          for (const i in allData) {
            total = allData[i] + total;
          }
          const tooltipPercentage = Math.round((tooltipData / total) * 100);
          return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
        }
      }
    }
  };
  pieChartLabels: Label[] = [];
  pieChartType: ChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];
  pieChartData: SingleDataSet[] = [];
  pieChartColors = [
    {
      backgroundColor: [
        'rgba(68, 136, 166, 1)',
        'rgba(108, 152, 159, 1)',
        'rgba(181, 200, 207, 1)',
        'rgba(50, 97, 115, 1)',
        'rgba(1, 24, 38, 1)',
        'rgba(153, 153, 153, 1)',
        'rgba(91, 105, 142, 1)',
        'rgba(166, 166, 166, 1)',
        'rgba(126, 145, 196, 1)',
        'rgba(230, 230, 230, 1)',
        'rgba(140, 161, 219, 1)',
        'rgba(54, 54, 54, 1)']
    }
  ];

  private _filters: any = {};
  private _typGraphic;
  reportList: any = [];

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe,
              private validateAccess: ValidateAccessService) {

    this.invokeServiceGetSubranches();
  }

  ngOnInit() {


  }

  loadChart() {
    if (this._typGraphic !== undefined && this._typGraphic !== null)
    {
      this.spinner.show('sp');
      this.api.getReportsSubranchGraphic(this._filters, this._typGraphic)
        .then(
          (response: any) => {
            if (response == null) {
              this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
              this.reportList = [];
              this.spinner.hide('sp');
              return;
            }
            this.reportList = response;
            this.creatChart();
            this.spinner.hide('sp');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.reportList = [];
            this.spinner.hide('sp');
          }
        );
    }
  }

  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const dateStart = '2020-01-01';
    const dateEnd = actualYear.toString().concat('-12-31');

    this._filters = {
      idGrupo: [],
      idSubgrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idSalesman: [],
      idAseguradora: [],
      dateStart,
      dateEnd
    };
    this._typGraphic = 1;
    this.loadChart();
  }

  creatChart() {
    // Obtenemos las etiquetas
    this.reportList.forEach(X => {
      if (this.pieChartLabels.length === 0) {
        this.pieChartLabels.push(X.subramo);
      } else {
        if (!this.pieChartLabels.some(Y => Y === X.subramo)) {
          this.pieChartLabels.push(X.subramo);
        }
      }
    });


    this.reportList.forEach(X => {
      this.pieChartData.push(X.total);
    });

  }

  get filters(): any {
    return this._filters;
  }

  chartClicked(template: TemplateRef<any>, e: any): void {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        if (e.active.length > 0) {
          const chart = e.active[0]._chart;
          const activePoints = chart.getElementAtEvent(e.event);

          if (activePoints.length > 0) {
            // get the internal index of slice in pie chart
            const clickedElementIndex = activePoints[0]._index;
            const subranch = chart.data.labels[clickedElementIndex];

            const index = this.subranchlist.findIndex(SUBRANCH => SUBRANCH.description === subranch);

            this.filtersHistogram = {
              idGrupo: [],
              idSubgrupo: [],
              idRamo: [],
              idSubRamo: [this.subranchlist[index].id],
              idEjecutivo: [],
              idSalesman: [],
              idStatus: [],
              idAseguradora: [],
              dateStart: '',
              dateEnd: '',
              page: 1,
              size: 10,
            };

            this.getByPagination(template, 1, 10);
          }
        }
      }
    });
  }

  invokeServiceGetSubranches() {
    const data = {name: 'SUBRANCHES'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.subranchlist = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  /**
   * Función para obtener las pólizas  para reportear del sistema
   */
  getByPagination(template: TemplateRef<any>, page, size) {
    this.spinner.show('sp');
    this.filtersHistogram.page = page;
    this.filtersHistogram.size = size;
    this.api.getReportsProduccion(this.filtersHistogram)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTS CON SU BUSQUEDA');
            this.reportProductionList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.reportProductionList = this.paginationResponse.tList;
          if (template != null) {
            this.openModalRestorePassword(template);
          }
          this.spinner.hide('sp');
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportProductionList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  openModalRestorePassword(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(null, this.pgCurrentPage, this.pgItemsPerPage);
  }

  @Input()
  set filters(value: any) {
    this._filters = value;
    if (this._filters.dateStart === null) {
      const fecha = new Date();
      const actualYear = fecha.getFullYear();
      const dateStart = actualYear.toString().concat('-01-01');
      const dateEnd = actualYear.toString().concat('-12-31');

      this._filters.dateStart = dateStart;
      this._filters.dateEnd = dateEnd;
    }
    this.pieChartData = [];
    this.pieChartLabels = [];
    this.loadChart();

  }

  get typGraphic() {
    return this._typGraphic;
  }

  @Input()
  set typGraphic(value) {
    this._typGraphic = value;
    this.loadChart();
  }
}
