import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {RecoveryPasswordMdlComponent} from '../recovery-password-mdl/recovery-password-mdl.component';
import {ValidateCodeMdlComponent} from '../validate-code-mdl/validate-code-mdl.component';
import {SharedService} from '../shared-service.service';
import * as Forge from 'node-forge';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user: any = {};
  loading = false;
  notify: any;
  bsModalRef: BsModalRef;
  errors: any = [];
  messages: any = [];
  publicKey: string;
  // tslint:disable-next-line:max-line-length surexs
  // tokenDefault = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJjaGFuZ2VQYXNzd29yZEFtbWlhIjp0cnVlLCJpZFNhYXNDbGllbnQiOjEsInNob3J0TmFtZVNhYXNDbGllbnQiOiJzdXJleHMiLCJ1c2VySWQiOjc1MDUsImlhdCI6MTY4NjMyODYzNiwic3ViIjoic3VyZXhzIn0.njband_h0pkm-qva8jothoY1JwEKMNR_h-loDGUjUHa1aDGSR6fUEmn_rSGRf9P1pNPf7C_hbK2ZlZpteINKCg01VykZ3xnJkojRVmzRKPD3cn4OUrmY7bT-NaDNdhMQ88l13L4eAPneRx7bKJPSjN8SysI_1s9UFpLytAMXpByNcC64co8fvKNFzXuECOCrutozJaaYaPIVlojptV1srza9otkXXcCYWUZlQ-_4BI0flyw8JZpdTVALphs_p3MwkpA1LWPSnNu1F5ycgNiDUYGSJmiY-I0-kuLYGK2LPa0kZo-MJUBntGrTtHfgvSXLKvxTDe68ZFIYszjFW0vH6Q';
  // tslint:disable-next-line:max-line-length gfhk
  tokenDefault = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJjaGFuZ2VQYXNzd29yZEFtbWlhIjp0cnVlLCJmaXJzdE5hbWUiOiJHQVJaQSIsImlkU2Fhc0NsaWVudCI6MiwibmFtZSI6IkdFUkFSRE8gUklDQVJETyIsInNlY29uZE5hbWUiOiJGUklTQklFIiwic2hvcnROYW1lU2Fhc0NsaWVudCI6ImdmaGsiLCJzdHJlYW1adWxpcCI6InBlcnNlby1nb21lel9hbW1pYS00ODE3IiwidXNlcklkIjoxLCJpYXQiOjE2OTY0Mzc0NTUsInN1YiI6ImdmaGsifQ.olTnp7pbq6aTbfstiQ72Tfe2DadVohoMUrY6XY3CZJtyE2T8McanEgdgGqhSxBEEZOAt0Ke1vp4yr3Xi7HAGiJw5_1SeIJu-wpvS8LJufXiDWWYfNly2B-YTRZsd-YQ4iu-DT3OHArG11e-GNNRU1Horp9Cw4WHmAAhRJfIrQS9TFjEw7NS3KpHruf-KGhQx9vfEHC-LZxckpYoBEf874-ZqpjnIDFY1x9WiNlcrJ6CPO-X0MIJsIwLkiFN9fWA2NOYgldvM2IzVExj8aU43UKtkS3yWVgM6ikCWtL4afU5hBtHxPp0bxBM3P6UYGKp8CgU0n90XYalH_XKcMUshXw';

  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern(/^[a-z0-9_-]{6,18}$/)]]
    });

  }

  ngOnInit() {
    localStorage.clear();
    this.invokeServiceGetPublicKey();

  }

  authentication() {
    this.api.authentication(this.loginForm.value)
      .then(
        (user) => {
          this.user = user;
          if (user) {
            localStorage.setItem('token', window.btoa(this.loginForm.value.username + ':' + this.loginForm.value.password));
            localStorage.setItem('user-id', this.user.userId);
            localStorage.setItem('user-login-id', this.user.userId);
            localStorage.setItem('customer-id', this.user.customerId);
            localStorage.setItem('roleId', this.user.rol.roleId);
            localStorage.setItem('rpass', this.user.passwordChat);
            localStorage.setItem('bearer', this.user.token);
            this.login(this.loginForm.value.username, this.loginForm.value.password);
            if (this.user.changePassword) {
              this.openModalWithComponentValideCode(user);
            } else {
              this.redirectCorrectHome();
            }
          }
        }, error => {
          this.loading = false;
          this.launchNotify();
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
        }
      );
  }
  redirectCorrectHome() {
    let goToAdmin = false;
    goToAdmin = true;
    this.router.navigate(['/admin/executive']);
    /*this.api.getUserRoles()
      .then(
        (data: any) => {
          let goToAdmin = false;
          this.shared.gUserRoles = data;
          localStorage.setItem('userRoles', JSON.stringify(data));
          data.forEach((item, index) => {
            if (item.roleId === 3 && item.defaultRole) {
              goToAdmin = true;
            }

            if (index === (data.length - 1) && goToAdmin) {
              this.router.navigate(['/admin/home']);
            } else if (index === (data.length - 1) && !goToAdmin) {
              this.router.navigate(['/admin/home']);
            }
          });
        }, error => {
          console.info(error);
        });*/
  }

  private launchNotify() {
    console.info('Notificacion');
    let message = 'El nombre de usuario y la contraseña que ingresaste no coinciden con nuestros registros. ';
    message = message + 'Por favor, revisa e inténtalo de nuevo.';
    this.toastr.warning(message, `Notificación`);
  }

  openModalWithComponentRecovery() {
    const message = 'Para recuperar tu contraseña, por favor, dirígete a tu aplicación Ammia Mobile. ';
    // message = message + 'Por favor, revisa e inténtalo de nuevo.';
    this.toastr.warning(message, `Notificación`);
    /*
    this.bsModalRef = this.modalService.show(RecoveryPasswordMdlComponent, {});
    this.bsModalRef.setClass('modal-sm');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });*/
  }


  openModalWithComponentValideCode(user) {
    const initialState = {user};
    this.bsModalRef = this.modalService.show(ValidateCodeMdlComponent, {initialState});
    this.bsModalRef.setClass('modal-sm');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.redirectCorrectHome();
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }

  encryptWithPublicKey(valueToEncrypt: string, keyPublica: string): string {
    let keyFinal = '-----BEGIN PUBLIC KEY-----  ';
    keyFinal = keyFinal + keyPublica;
    keyFinal = keyFinal + '  -----END PUBLIC KEY-----';

    const rsa = Forge.pki.publicKeyFromPem(keyFinal);
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
  }
  login(email: string, password: string): void {
    this.errors = [];
    this.messages = [];

    const params = {
      email: this.encryptWithPublicKey(email, this.publicKey),
      password: this.encryptWithPublicKey(password, this.publicKey)
    };

    this.api.getLoginAmmia(params).then((data: any) => {
     // console.log(data.token);
      localStorage.setItem('ammia', data.token);
    }, error => {
    });
  }

  invokeServiceGetPublicKey() {
    localStorage.setItem('ammia', this.tokenDefault);
    this.api.getPublicKey().then((data: any) => {
      this.publicKey = data.mswSANXnWqh;
      // console.log(this.publicKey);
    }, error => {
    });
  }

}
