import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {RecoveryComponent} from './recovery/recovery.component';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {HomeComponent} from './home/home.component';
import {AdminInsuredComponent} from './admin-insured/admin-insured.component';
import {HomeExecutiveComponent} from './home-executive/home-executive.component';
import {AdminReportsComponent} from './admin-reports/admin-reports.component';
import {AdminPoliciesComponent} from './admin-policies/admin-policies.component';
import {AdminCatalogComponent} from './admin-catalog/admin-catalog.component';
import {AdminReceiptsComponent} from './admin-receipts/admin-receipts.component';
import {AdminPoliciesReportsComponent} from './admin-policies-reports/admin-policies-reports.component';
import { AdminClientComponent } from './admin-client/admin-client.component';
import {AdminRequestComponent} from './admin-request/admin-request.component';
import {AdminNewClientComponent} from './admin-new-client/admin-new-client.component';
import {AdminInsuranceCompanyComponent} from './admin-insurance-company/admin-insurance-company.component';
import {InsuranceCompanyComponent} from './insurance-company/insurance-company.component';
import {AuthGuard} from './login/services/auth-guard.service';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminUsersDetailComponent} from './admin-users-detail/admin-users-detail.component';
import {AdminRoleComponent} from './admin-role/admin-role.component';
import {AdminRoleDetailComponent} from './admin-role-detail/admin-role-detail.component';
import {AdminSpecialCoverageComponent} from './admin-special-coverage/admin-special-coverage.component';
import {AdminIncidentComponent} from './admin-incident/admin-incident.component';
import {IncidentMdlComponent} from './incident-mdl/incident-mdl.component';
import {AdminReportCoreComponent} from './admin-report-core/admin-report-core.component';
import {AdminReportProvisionComponent} from './admin-report-provision/admin-report-provision.component';
import {AdminReportProduccionComponent} from './admin-report-produccion/admin-report-produccion.component';
import {AdminReportSiniestrosComponent} from './admin-report-siniestros/admin-report-siniestros.component';
import {PolicyLiteComponent} from './policy-lite/policy-lite.component';
import {AdminPaymentOrdersComponent} from './admin-payment-orders/admin-payment-orders.component';
import {AdminMessengerComponent} from './admin-messenger/admin-messenger.component';
import {DamagePolicyComponent} from './damage-policy/damage-policy.component';
import {CoverageCarPolicyFlotillaComponent} from './coverage-car-policy-flotilla/coverage-car-policy-flotilla.component';
import {AdminNewRequestComponent} from './admin-new-request/admin-new-request.component';
import {RequestTempDetailComponent} from './request-temp-detail/request-temp-detail.component';
import {DinamicReportsComponent} from './dinamic-reports/dinamic-reports.component';
import {DinamicReportsNewComponent} from './dinamic-reports-new/dinamic-reports-new.component';
import {AdminPolicyPrincipalComponent} from './admin-policy-principal/admin-policy-principal.component';
import { MassiveLoadPolicyComponent } from './massive-load-policy/massive-load-policy.component';
// import {AdminPlacementQuotationDetailComponent} from './admin-placement-quotation-detail/admin-placement-quotation-detail.component';
import {AdminPlacementQuotationListComponent} from './admin-placement-quotation-list/admin-placement-quotation-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'recoveypassword',
    component: RecoveryComponent
  },
  {
    path: 'admin',
    component: MainPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'reports',
        component: AdminReportsComponent
      },
      {
        path: 'policies',
        component: AdminPolicyPrincipalComponent
      },
      {
        path: 'insured',
        component: AdminInsuredComponent
      },
      {
        path: 'executive',
        component: HomeExecutiveComponent
      },
      {
          path: 'client',
          component: AdminClientComponent
      },
      {
        path: 'catalog',
        component: AdminCatalogComponent
      },
      {
        path: 'receipts',
        component: AdminReceiptsComponent
      },
      {
        path: 'policies_reports',
        component: AdminPoliciesReportsComponent
      },
      {
        path: 'request',
        component: AdminRequestComponent
      },
      {
        path: 'new_client',
        component: AdminNewClientComponent
      },
      {
        path: 'insurance',
        component: AdminInsuranceCompanyComponent
      },
      {
        path: 'insurance_detail',
        component: InsuranceCompanyComponent
      },
      {
        path: 'user',
        component: AdminUsersComponent
      },
      {
        path: 'user_detail',
        component: AdminUsersDetailComponent
      },
      {
        path: 'role',
        component: AdminRoleComponent
      },
      {
        path: 'role_detail',
        component: AdminRoleDetailComponent
      },
      {
        path: 'special_coverage',
        component: AdminSpecialCoverageComponent
      },
      {
        path: 'incidents',
        component: AdminIncidentComponent
      },
      {
        path: 'incident_detail',
        component: IncidentMdlComponent
      },
      {
        path: 'report_core',
        component: AdminReportCoreComponent
      },
      {
        path: 'report_core_provision',
        component: AdminReportProvisionComponent
      },
      {
        path: 'report_core_produccion',
        component: AdminReportProduccionComponent
      },
      {
        path: 'report_core_incident',
        component: AdminReportSiniestrosComponent
      },
      {
        path: 'ammia_lite',
        component: PolicyLiteComponent
      },
      {
        path: 'policies_orders',
        component: AdminPaymentOrdersComponent
      },
      {
        path: 'admin-messenger',
        component: AdminMessengerComponent
      },
      {
        path: 'damage-policy',
        component: DamagePolicyComponent
      },
      {
        path: 'car-flotilla',
        component: CoverageCarPolicyFlotillaComponent
      },
      {
        path: 'new-request',
        component: AdminNewRequestComponent
      },
      {
        path: 'request-detail',
        component: RequestTempDetailComponent
      },
      {
        path: 'dinamic-reports',
        component: DinamicReportsComponent
      },
      {
        path: 'dinamic-reports-new',
        component: DinamicReportsNewComponent
      },
      {
        path: 'dinamic-reports-new/:id',
        data: { isEdit: true },
        component: DinamicReportsNewComponent
      },
      {
        path: 'dinamic-reports-new/ver/:id',
        data: { isView: true },
        component: DinamicReportsNewComponent
      },
      {
        path: 'massive-load-policy',
        component: MassiveLoadPolicyComponent
      },
      {
        path: 'quotation-list',
        component: AdminPlacementQuotationListComponent
      },
      /*{
        path: 'quotation-detail',
        component: AdminPlacementQuotationDetailComponent
      }*/
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
