import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DualListComponent} from 'angular-dual-listbox';
import {CoverageModel} from '../coverage-car-policy/coverage-model-autos';
import {NgWizardComponent, NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, THEME} from 'ng-wizard';
import {Use} from '../coverage-car-policy/use';
import {CarType} from '../coverage-car-policy/carType';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-coverage-car-policy-flotilla',
  templateUrl: './coverage-car-policy-flotilla.component.html',
  styleUrls: ['./coverage-car-policy-flotilla.component.css']
})
export class CoverageCarPolicyFlotillaComponent implements OnInit {

  private _subBranchId: number;
  private _insuranceId: number;
  private _endorsementId: number;
  dataTypesList: any = {};
  lclDataTypeForReportsSelected: any = {};
  listCategories: any = [];
  modalRef: BsModalRef;
  modalRefDeleteCarsSelected: BsModalRef;
  modalRefNewCar: BsModalRef;
  modalRefCoverage: BsModalRef;
  modalOptionsCoverage: BsModalRef;
  coverageList: Array<CoverageModel>;
  coverageListSelected: Array<CoverageModel> = new Array<CoverageModel>();
  format = {
    add: 'ASIGNAR COBERTURA',
    remove: 'QUITAR COBERTURA',
    all: 'SELECCIONAR TODOS',
    none: 'DESELECCIONAR',
    direction: DualListComponent.LTR,
    draggable: true
  };
  key: any;
  display: any;
  categoryToModified: string;
  categoryName: string;
  netPremium = 0.0;
  coverageName: string;
  dataSourcesCoverages: Array<CoverageModel>;
  dataSourceCarType: Array<CarType>;
  dataSourceUse: Array<Use>;
  dataSourceMarca: any;
  dataSourceModelo: any;
  searchUseSelected: string;
  coverage: FormGroup;
  optionSelected: any;
  label: any;
  newValor: any;
  isNewValor = false;
  indexCategory: any;
  private _flag: number;
  clientId: number;
  dataToSendCategory: any = {};
  categoryId: number;
  catClientsPolicy: any = [];
  coverageForm: FormGroup;
  public fileUpload: File;
  userinfo: any = {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    birthday: ''
  };
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  statsend: boolean;
  coveragesCar: any = [];
  dataToSendGeneral: any = {};
  filters: any = {};
  pgBoundaryLinks = true; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 5; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  categoryIdSelected;
  categorySelected: string;
  adaptaciones = false;
  equipoEspeciales = false;
  idAdapataciones: number;
  idEquiposEspeciales: number;
  idCoverageSelected: number;
  policyId: any;
  carToSaveAftervalueAddes: any;
  dataSourcePackege: any[];
  bsBirthdate: Partial<BsDatepickerConfig>;
  /*variables para paginancion */
  pgClientBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgClientTotalItems: number; // Total de registros
  pgClientCurrentPage = 1; // Página actual
  pgClientMaxSize = 5; // Número de links en paginado
  pgClientItemsPerPage: number;
  page = 1;
  size = 10;
  modalSaveEndorsement: BsModalRef;
  endosoId: number;
  dataToSave: number;
  car: any;
  receiptBusiness: any = {};
  chargesPercentage = 0;
  catTaxes: any = [];
  bsLeavingDateBirthDate: Partial<BsDatepickerConfig>;
  catGenders: any = [];
  isEndoso: false;
  carsSelected = [];

  @ViewChildren('wiz') wiz: NgWizardComponent;
  private _isSaved = new EventEmitter<any>();

  optionsTemplate: {};
  isText: boolean;

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.default,
    cycleSteps: false,
    toolbarSettings: {
      showPreviousButton: false
      , showNextButton: false
    },
    anchorSettings: {
      anchorClickable: true,
      enableAllAnchors: true,
      markDoneStep: true,
      markAllPreviousStepsAsDone: true,
      removeDoneStepOnNavigateBack: false,
      enableAnchorOnDoneStep: true
    }
  };

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  constructor(private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private api: ApiService,
              private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private shared: SharedService) {


    this.shared.gPolicyIdCategoryObservable().subscribe(
      gPolicyIdCategory => {
        this._flag = 0;
        this.listCategories = [];
        this.policyId = localStorage.getItem('policy-id');
        if (Number(localStorage.getItem('policy-id')) > 0) {
          this.getPolicy();
        }
      }
    );
    this.newFormGroup();
  }

  newFormGroup() {
    this.coverageForm = this.formBuilder.group({
      coverages: this.formBuilder.array([
      ])
    });
  }


  getPolicy() {
    this.api.getPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.dataToSendGeneral = data;
      this.invokePackage();
      this.invokeCoveragesByInsurance(this.dataToSendGeneral.insuranceId);
      this.getClientsPolicy();
      this.getCategoriesPolicy();
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }


  ngOnInit() {
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date()
    });

    this.bsLeavingDateBirthDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true
    });

    this._isSaved.emit(false);
    this.dataTypesList = [
      {
        name: 'EMPLEADOS',
        description: 'EMPLEADOS'
      },
      {
        name: 'ADMINISTRADORES',
        description: 'ADMINISTRADORES'
      },
    ];

    this.lclDataTypeForReportsSelected = {
      name: 'EMPLEADOS',
      description: 'EMPLEADOS'
    };

    this.key = 'id';
    this.display = 'description';

    this.loadDataTaxes();
    this.invokeServiceGetGenders();
    this.invokeMakesCatalogList();
    this.invokeModelYearCatalogList();
    this.invokeServiceGetVehicleList();
    this.invokeServiceGetUseVehicleList();

  }

  addNewCar(template: TemplateRef<any>, category) {
    this.categoryId = category;
    this.modalRefNewCar =
      this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});

    // this.saveOneByOne(this.newCar(category));
  }

  get coverages(): FormArray {
    return this.coverageForm.get('coverages') as FormArray;
  }

  coveragesSelecetd(coverageForm): FormArray {
    return coverageForm === undefined ? new FormArray([]) : coverageForm.get('coveragesList') as FormArray;
  }

  getIsValid(detail: AbstractControl): boolean {
    return detail.status === 'INVALID';
  }

  openModal(template: TemplateRef<any>, index) {

    this.indexCategory = index;
    this.categoryToModified = this.listCategories[index].name;
    this.invokeCoverageByCategory(this.listCategories[index].categoryId, true);
    this.modalRefCoverage = this.modalService.show(template, {class: 'modal-lg',  keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  invokeCoverageByCategory(categoryId, isStep) {
    this.adaptaciones = false;
    this.equipoEspeciales = false;
    this.coverageListSelected = [];
    this.api.getCoverageCarFlotillaData(categoryId).then(
      (response: any) => {
        this.coverageListSelected = response;

        if (isStep) {
          this.coverageListSelected.map(coverage => {
            if (coverage.description === 'ADAPTACIONES') {
              this.adaptaciones = true;
              this.idAdapataciones = coverage.id;
            }

            if (coverage.description === 'EQUIPO ESPECIAL') {
              this.equipoEspeciales = true;
              this.idEquiposEspeciales = coverage.id;
            }
          });
        }

      }, error => {
        this.toastr.error('Ocurrió un problema al obtener las coberturas seleccionadas', 'Notificación');
      }
    );

  }

  invokeMakesCatalogList() {
    const data = {
      isMotorbike: false
    };
    this.api.getMakeCatalogist(data).then(
      (response: any) => {
        this.dataSourceMarca = response;

      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeModelYearCatalogList() {
    const data = {
      isMotorbike: false
    };
    this.api.getModelYearCatalogist(data).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceModelo = response;
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeServiceGetVehicleList() {
    const data = {name: 'VEHICLE'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.dataSourceCarType = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  invokeServiceGetUseVehicleList() {
    const data = {name: 'USE_VEHICLE'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.dataSourceUse = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
      console.error(error.message);
    });
  }

  openModalCategory(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  openModalSetCoverages(template: TemplateRef<any>, name: string, idCoverage: number) {
    this.coverageName = name;
    this.idCoverageSelected = idCoverage;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  closeModal() {
    this.modalRef.hide();
  }

  closeModalOptionsCoverage() {
    this.modalOptionsCoverage.hide();
  }

  closeModalEliminarAutosSeleccionados() {
    this.modalRefDeleteCarsSelected.hide();
  }

  closeModalNewCar() {
    this.fileUpload = undefined;
    this.modalRefNewCar.hide();
  }

  closeModalCoverage() {
    this.coverageListSelected = [];
    this.modalRefCoverage.hide();
  }

  saveCoveragesFromCategories() {

    if (this.coverageListSelected !== undefined && this.coverageListSelected.length > 0) {
      this.api.postCoverageCarData(this.coverageListSelected, this.listCategories[this.indexCategory].categoryId).then((data: any) => {
        this.coverageListSelected = [];
        this.invokeCoverageByCategory(this.categoryIdSelected, true);
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      }, error => {
        this.catClientsPolicy = [];
      });
    }

    this.modalRefCoverage.hide();

  }

  onDestinationChange(event) {
    console.log(event);

  }

  getClientsPolicy() {
    this.api.getClientsPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.catClientsPolicy = data;
      this.clientId = this.catClientsPolicy[0].clientId;
    }, error => {
      this.catClientsPolicy = [];
    });
  }

  getCategoriesPolicy() {
    this.listCategories = [];
    this.api.getCategoriesPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.listCategories = data;

    }, error => {

    });
  }

  sendCategoryData() {
    this.spinner.show('sp');
    this.dataToSendCategory = {
      categoryId: 0,
      name: this.categoryName,
      typeTax: this._flag,
      clientId: this.clientId
    };
    if (this.dataToSendCategory.name === undefined || this.dataToSendCategory.name === '') {
      this.toastr.info('POR FAVOR INGRESE UN NOMBRE DE CATEGORIA', 'Notificación');
      this.spinner.hide('sp');
      return;
    }

    this.api.postCategoryPolicyData(this.dataToSendCategory, localStorage.getItem('policy-id'))
      .then(
        (response: any) => {
          this.categoryId = response.categoryId;
          this.getCategoriesPolicy();
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
        }, error => {
          this.spinner.show('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
    this.categoryName = '';
    this.closeModal();

  }

  invokeCoveragesByInsurance(insuranceId) {

    this.api.getCoveragesByInsurance(insuranceId).then( // Si todo bien, se guarda
      (response: any) => {
        this.coverageList = response;

        // this.coverageListSelected = this.dataSourcesCoverages.filter(x => x.visible);
        // this.coverageList.map(coverages => this.addOption(coverages));

      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  sendCarData() {
    if (this.error.active === true || this.fileUpload === undefined) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      return;
    }

    const formData = new FormData();
    formData.append('file', this.fileUpload);
    formData.append('car', JSON.stringify(this.newCar(this.categoryId, this.userinfo).value));
    formData.append('endorsement', JSON.stringify(this.endosoId));

    this.api.postCarintoAutoFlotilla(formData)
      .then( // Si todo bien, se guarda
        (response: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.fileUpload = undefined;
          this.modalRefNewCar.hide();
          this.clearUserInfo();
          this.getAutosFlotilla(this.categoryId, 1, 10, '');
        }, error => {
          console.error();
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          this.statsend = false;
        }
      );
  }

  getAutoFlotillaBySearch(search) {
    this.getAutosFlotilla(this.categoryIdSelected, 1, 10, search.currentTarget.value);
  }

  getAutosFlotilla(idCategory, page, size, search) {

    this.spinner.show('sp');
    this.pgCurrentPage = page;

    const data = {
      policyId: Number(localStorage.getItem('policy-id')),
      categoryId: idCategory,
      incisoSerie: search,
      page,
      size
    };

    this.coveragesCar = [];

    this.api.getCoverageCarFlotillaById(data, this.endorsementId)
      .then( // Si todo bien, se guarda
        (response: any) => {
          if (response.autoFlotillas == null ) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.coveragesCar = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.coveragesCar = this.paginationResponse.autoFlotillas;
          this.pgClientTotalItems = this.paginationResponse.totalRows;

          if (this.coveragesCar !== undefined && this.coveragesCar !== null && this.coveragesCar.length > 0) {
            this.createFormCar();
          } else {
            this.newFormGroup();
          }

          if (this.coveragesCar !== undefined && this.coveragesCar.length !== 0 && this.coverageForm.valid) {
            this._isSaved.emit(true);
          } else {
            this._isSaved.emit(false);
          }
          this.spinner.hide('sp');
        }, error => {
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
          this.spinner.hide('sp');
        });
  }

  createFormCar() {

    if (this.coverageForm) {
      this.newFormGroup();
    }

    this.coveragesCar.map(coverages => {
      this.coverages.push(this.addCoverageEssential(coverages));
    });
  }

  addCoverageEssential(coverage): FormGroup {
    return this.formBuilder.group({
      policyId: new FormControl (coverage.policyId, [Validators.required]),
      carId: new FormControl(coverage.carId, [Validators.required]),
      serialNumber: new FormControl(coverage.serialNumber, [Validators.required]),
      numberPolicy: new FormControl(coverage.numberPolicy, [Validators.required]),
      inciso: new FormControl(coverage.inciso, [Validators.required]),
      mark: new FormControl(coverage.mark, [Validators.required]),
      model: new FormControl(coverage.model, [Validators.required]),
      version: new FormControl(coverage.version, [Validators.required]),
      vehicleId: new FormControl(coverage.vehicleId === 0 ? null : coverage.vehicleId),
      useId: new FormControl(coverage.useId === 0 ? null : coverage.useId),
      categoryId: new FormControl(coverage.categoryId, [Validators.required]),
      category: new FormControl(coverage.category, [Validators.required]),
      netPremium: new FormControl(coverage.netPremium, [Validators.required]),
      rights: new FormControl(coverage.rights, [Validators.required]),
      charges: new FormControl(coverage.charges, [Validators.required]),
      iva: new FormControl(coverage.iva, [Validators.required]),
      totalPremium: new FormControl(coverage.totalPremium, [Validators.required]),
      name: new FormControl(coverage.name, [Validators.required]),
      firstName: new FormControl(coverage.firstName, [Validators.required]),
      lastName: new FormControl(coverage.lastName, [Validators.required]),
      email: new FormControl(coverage.email, [Validators.required]),
      gender: new FormControl(coverage.gender === undefined || coverage.gender === null ? 1 : coverage.gender, [Validators.required]),
      birthdate: new FormControl( this.datePipe.transform(coverage.birthdate, 'dd-MM-yyyy'), [Validators.required]),
      coveragesList: this.formBuilder.array(
        coverage.coveragesList.length === 0 ? [] :
          coverage.coveragesList.map(coverageSelected => this.addCoverageSelected(coverageSelected))
      )
    });
  }

  newCar(category, userInfo): FormGroup {
    return this.formBuilder.group({
      policyId: (this.dataToSendGeneral.policyId),
      carId: (0),
      serialNumber: (''),
      numberPolicy: (this.dataToSendGeneral.numberPolicy),
      inciso: (''),
      mark: (''),
      model: (''),
      version: (''),
      vehicleId: (null),
      useId: (null),
      categoryId: (category),
      category: (''),
      netPremium: (0),
      rights: (0),
      charges: (0),
      iva: (0),
      totalPremium: (0),
      name: (userInfo !== undefined && userInfo.name !== undefined ? userInfo.name : ''),
      firstName: (userInfo !== undefined && userInfo.firstName !== undefined ? userInfo.firstName : ''),
      lastName: (userInfo !== undefined && userInfo.lastName !== undefined ? userInfo.lastName : ''),
      email: (userInfo !== undefined && userInfo.email !== undefined ? userInfo.email : ''),
      gender: (1),
      birthdate: (''),
    });
  }

  addCoverageSelected(coverageSelected): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(coverageSelected.id, [Validators.required]),
      description: new FormControl(coverageSelected.description, [Validators.required]),
      value: new FormControl(coverageSelected.value, [Validators.required]),
      visible: new FormControl(coverageSelected.visible, ),
      typeInput: new FormControl(coverageSelected.typeInput, ),
      medition: new FormControl(coverageSelected.medition),
      options: coverageSelected.options === null ? this.formBuilder.array([]) : this.formBuilder.array(
        coverageSelected.options.map(option => option)
      )
    });
  }


  getOptionsAmp(): any[] {
    return ['AMPARADO', 'NO AMPARADO'];
  }


  get subBranchId(): number {
    return this._subBranchId;
  }

  @Input()
  set subBranchId(value: number) {
    this._subBranchId = value;
  }


  typeaheadOnSelectVehicleType(e): void {
    this.searchUseSelected = undefined;
    this.dataSourceUse = new Array<Use>();
    this.dataSourceUse = e._useList;
  }

  typeaheadOnSelectCoverage(template: TemplateRef<any>, e, coverage, categoriesCarForm) {
    this.carToSaveAftervalueAddes = categoriesCarForm;
    this.coverage = coverage;

    if (e.label.split('::')[1] === '-1') {
      this.label = e.label.split('::')[0] === undefined ? '' : e.label.split('::')[0];
      this.coverage.controls.value.setValue('');
      this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, backdrop: false});
    } else {
      if (this.coverage.controls.description.value === 'CADE') {
        const currentYear = new Date().getFullYear();
        const model = categoriesCarForm.controls.model.value;
        if ((currentYear - model) > 15) {
          this.toastr.warning('Los años máximos permitidos para CADE son 15', 'Notificación');
        } else {
          this.coverage.controls.medition.setValue(e.value);
          this.coverage.controls.value.setValue(e.label.split('::')[1]);
        }
      } else {
        this.coverage.controls.medition.setValue(e.value);
        this.coverage.controls.value.setValue(e.label.split('::')[1]);
      }
    }
  }

  onFocusSelectCoverage(template: TemplateRef<any>, e, coverage, categoriesCarForm) {
    if (coverage.controls.options.value.length !== undefined && coverage.controls.options.value.length !== 0) {
      this.carToSaveAftervalueAddes = categoriesCarForm;
      this.coverage = coverage;
      this.optionSelected = e;
      this.modalOptionsCoverage = this.modalService.show(
        template, {class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, backdrop: false});
    }
  }

  addNewValue(optionSelected?) {
    this.onChangeMedition(optionSelected.meditionType);

    if (optionSelected === 1) {
      const option = document.getElementById('valueCoverage') as HTMLInputElement | null;
      this.coverage.controls.medition.setValue(this.newValor);
      this.coverage.controls.value.setValue(this.label + '' + option.value);
      // this.saveOneByOne(this.carToSaveAftervalueAddes);
      this.newValor = undefined;
      this.label = '';
      this.isNewValor = false;
      this.modalOptionsCoverage.hide();
    } else if (optionSelected.label.startsWith('::')) {
      if (optionSelected.label.split('::')[1] !== '-1') {
        this.coverage.controls.medition.setValue(optionSelected.value);
        this.coverage.controls.value.setValue(optionSelected.label.split('::')[1]);
        this.optionSelected = undefined;
        // this.saveOneByOne(this.carToSaveAftervalueAddes);
        this.newValor = undefined;
        this.label = '';
        this.modalOptionsCoverage.hide();
      } else {
        this.label = '';
        this.isNewValor = true;
      }
    } else if (optionSelected.label.split('::')[1] === '-1') {
      this.label = optionSelected.label.split('::')[0] + '-';
      this.isNewValor = true;
    } else if (optionSelected.label.includes('::')) {
      this.coverage.controls.medition.setValue(optionSelected.value);
      this.coverage.controls.value.setValue(optionSelected.label.replace('::', '-'));
      this.optionSelected = undefined;
      // this.saveOneByOne(this.carToSaveAftervalueAddes);
      this.newValor = undefined;
      this.label = '';
      this.modalOptionsCoverage.hide();
    } else  {
      this.newValor = undefined;
      this.label = '';
      this.isNewValor = false;
      this.modalOptionsCoverage.hide();
    }
  }

  groupingHelper(item) {
    return item.label.split('::')[0];
  }

  @Input()
  set flag(value: number) {
    this._flag = value;
  }

  get flag(): number {
    return this._flag;
  }

  categoryChanged(args: StepChangedArgs) {
    console.log(args.step);
    this.categoryIdSelected = this.listCategories[args.step.index].categoryId;
    this.categorySelected = this.listCategories[args.step.index].name;
    this.pgClientCurrentPage = 1;
    this.getAutosFlotilla(this.categoryIdSelected, this.pgClientCurrentPage, this.pgItemsPerPage, '');
    this.invokeCoverageByCategory(this.categoryIdSelected, true);
    this.carsSelected = [];
  }

  saveOneByOne(car, endosoId?) {
    if (endosoId === undefined) {
      this.endosoId = 0;
    } else {
      this.endosoId = endosoId;
    }
    if (this.coverageForm.valid) {
      this._isSaved.emit(true);
    } else {
      this._isSaved.emit(false);
    }

    this.api.postCar(car.value, this.endosoId)
      .then( // Si todo bien, se guarda
        (response: any) => {
          if (this.modalSaveEndorsement !== undefined) {
            this.modalSaveEndorsement.hide();
          }
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          console.error();
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
  }

  pageChanged(event: PageChangedEvent): void {
    this.pgClientCurrentPage = event.page;
    this.getAutosFlotilla(this.categoryIdSelected, this.pgClientCurrentPage, this.pgItemsPerPage, '');
  }

  changeColorPagination() {
  }


  addNetPremium() {
    const data = {
      coverageId: this.idCoverageSelected,
      categoryId: this.categoryIdSelected,
      netPremium: this.netPremium
    };

    this.api.postCoverageNetPremium(data).then(
      (response: any) => {
        this.getAutosFlotilla(this.categoryIdSelected, this.pgClientCurrentPage, this.pgItemsPerPage, '');
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
  }

  removeCar(car, i: number) {

    this.api.deleteCar(car, this.endosoId).then(
      (response: any) => {
        // this.coverages.removeAt(i);
        this.getAutosFlotilla(this.categoryIdSelected, this.pgClientCurrentPage, this.pgItemsPerPage, '');
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
  }

  removeAllCar() {
    this.api.deleteAllCar(localStorage.getItem('policy-id'), this.categoryIdSelected).then(
      (response: any) => {
        this.getAutosFlotilla(this.categoryIdSelected, this.pgClientCurrentPage, this.pgItemsPerPage, '');
        this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      });
  }

  downloadLayout() {
    this.spinner.show('sp');
    this.api.getLayoutFileAutoFlotilla(20, this.categoryIdSelected)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_AUTOS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }
  invokeDownloadReportCar(policy) {
    this.spinner.show('sp');

    this.api.getReportsCar(policy)
      .then(
        (response: any) => {
          console.log(response);
          if (response == null) {
            this.toastr.info('No se encontraron registros para descargar');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', 'reporte_autos.xls');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.spinner.hide('sp');
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  copyText(coverage: any) {
    let textToCopy = '';

    if (!coverage.label.startsWith('::')) {
      textToCopy = (coverage.label.split('::')[0]).concat('-').concat(coverage.label.split('::')[1] === '-1' ? 'Ingrese valor' : coverage.value);
    } else {
      if (coverage.value === '-1') {
        textToCopy = 'Ingrese valor';
      } else {
        textToCopy = coverage.value;
      }
    }

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (textToCopy));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  @Output()
  get isSaved(): EventEmitter<any> {
    return this._isSaved;
  }

  set isSaved(value: EventEmitter<any>) {
    this._isSaved = value;
  }

  onChangeMedition(e) {
    console.log(e);
    switch (e) {
      case 'PORCENTAJES': // 'PORCENTAJES' :
        this.isText = false;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' %',
          thousands: '',
          nullable: true
        };
        break;

      case 'UMAM': // 'UMAM' :
        this.isText = false;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' UMAM',
          thousands: '',
          nullable: true
        };
        break;

      case 'PESOS MEXICANOS': // 'PESOS MEXICANOS' :
        this.isText = false;
        this.optionsTemplate = {};
        break;

      case 'USD': // 'USD' :
        this.isText = false;
        this.optionsTemplate = {
          suffix: ' USD'
        };
        break;

      case 'TEXTO': // 'TEXTO' :
        this.isText = true;
        this.optionsTemplate = {
          allowNegative: true,
          allowZero: true,
          decimal: '',
          precision: 0,
          prefix: '',
          suffix: ' UMAM',
          thousands: '',
          nullable: true
        };
        break;

      default:
        break;
    }
  }

  invokePackage() {
    const data = {
      idInsurance: this.dataToSendGeneral.insuranceId,
      idSubranch: this._subBranchId
    };
    this.api.getPacakge(data).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourcePackege = response;
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  typeaheadOnSelectPackage(e) {

    if (e !== undefined && e.idPackage !== null && e.idPackage !== undefined) {
      const data = {
        idPackage: e.idPackage
      };

      this.invokeCoverageFromPackage(data, false);
    }
  }

  invokeCoverageFromPackage(data, isEdit) {

    this.api.getCoverageFromPacakge(data).then(
      (response: any) => {
        this.coverageListSelected =  new Array<CoverageModel>();
        // this.dataSourcesCoveragesMandatory = new Array<CoverageModel>();

        this.coverageListSelected = response;

      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  getGenderType(): any[] {
    const gender = [
      {id: 2, name: 'HOMBRE'}, {id: 1, name: 'MUJER'}
    ];
    return gender;
  }

  functAsociateUserToCar() {
    this.spinner.show('sp');
    this.api.asociateUserBToCar(this.dataToSendGeneral.policyId)
      .then(
        (data: any) => {
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, () => {
          this.spinner.hide('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  openModalSaveEndorsement(template: TemplateRef<any>, option:number, carDetail?) {
    this.dataToSave = option;
    this.car = carDetail;
    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      if (this.dataToSendGeneral.typeAdministrationPolicy !== 1 || this.dataToSendGeneral.typeAdministrationPolicy !== '1') {
        this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, animated: true});
      }
    } else {
      this.saveNewCarOrChanges({saveData: true, endorsementId: 0});
    }

  }

  saveNewCarOrChanges(endorsement?) {
    if (endorsement || endorsement.saveData) {
      if (this.dataToSave === 1) {
        this.endosoId = endorsement.endorsementId === undefined ? 0 : endorsement.endorsementId;
        this.sendCarData();
        this.closeModalNewCar();
      } else if (this.dataToSave === 2) {
        this.endosoId = endorsement.endorsementId === undefined ? 0 : endorsement.endorsementId;
        this.saveOneByOne(this.car);
      } else if (this.dataToSave === 3) {
        this.endosoId = endorsement.endorsementId === undefined ? 0 : endorsement.endorsementId;
        this.removeCar(this.carsSelected, 1);
        this.closeModalEliminarAutosSeleccionados();
      }
    }
  }

  sumTotalPremium() {
    if (this.receiptBusiness.taxId > 0) {
      this.reCalTax();
    }
    this.calCommissionAmount();

    if (this.chargesPercentage === 0 && this.receiptBusiness.paymentCharges > 0) {
      this.calChargesPercentage();
    }
    // tslint:disable-next-line:max-line-length
    this.receiptBusiness.totalPremium  = this.receiptBusiness.netPremium
      + this.receiptBusiness.rights
      + this.receiptBusiness.paymentCharges
      + this.receiptBusiness.iva;
  }

  calCommissionAmount() {
    const commissionAmount = (this.receiptBusiness.netPremium
      * this.receiptBusiness.commission) / 100;

    this.receiptBusiness.commissionAmount = commissionAmount.toFixed(4);
  }

  reCalTax() {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(this.receiptBusiness.taxId)) {
        this.receiptBusiness.iva =
          ((this.receiptBusiness.netPremium
            + this.receiptBusiness.rights
            + this.receiptBusiness.paymentCharges) * tax.taxValue) / 100;
      }
    }
  }

  calChargesPercentage() {
    const chargesPercentageTmp = (this.receiptBusiness.paymentCharges * 100)
      / this.receiptBusiness.netPremium;

    this.chargesPercentage = chargesPercentageTmp;
  }

  calChargesAmount() {
    const chargesAmount =  (this.receiptBusiness.netPremium * this.chargesPercentage) / 100;
    this.receiptBusiness.paymentCharges = chargesAmount;
  }

  loadDataTaxes() {
    this.api.getTaxes().then((data: any) => {
      this.catTaxes = data;
    }, error => {
    });
  }
  calTax(i) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {

        this.receiptBusiness.iva =
          ((this.receiptBusiness.netPremium
              + this.receiptBusiness.rights + this.receiptBusiness.paymentCharges)
            * tax.taxValue) / 100;

        this.sumTotalPremium();
      }
    }
  }

  calCommissionPercentage() {
    const commssion = (this.receiptBusiness.commissionAmount * 100)
      / this.receiptBusiness.netPremium;
    this.receiptBusiness.commission = commssion.toFixed(2);
  }

  invokeServiceGetGenders() {
    console.log('invokeServiceGetGenders');
    const data = {name: 'GENDERS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.catGenders = data;
        console.log('data');
        console.log(data);
      }
    }, error => {

    });
  }
  get endorsementId(): number {
    return this._endorsementId;
  }
  @Input()
  set endorsementId(value: number) {
    this._endorsementId = value;
  }

  clearUserInfo() {
    this.userinfo = {
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      birthday: ''
    };
  }

  onSelectCar(car, event) {
    if (event.target.checked === true) {
      this.carsSelected.push(car.value);
    } else {
      const index = this.carsSelected.findIndex(carSelected => carSelected.carId === car.value.carId);

      if (index !== -1) {
        this.carsSelected.splice(index, 1);
      }
    }

  }

  isSelected(idCar): boolean{
    return this.carsSelected.some(car => car.carId === idCar);
  }

  openModalDeleteACarsSelected(template: TemplateRef<any>) {

    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      this.modalRefDeleteCarsSelected = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
    } else {
      this.removeCar(this.carsSelected, 0);
      this.carsSelected = [];
    }

  }

  sumTotalPremiumBajaCar(car) {
    if (car.taxId > 0) {
      this.reCalTaxCar(car);
    }
    this.calCommissionAmountCar(car);

    if (car.chargesPercentage === 0 && car.paymentCharges > 0) {
      this.calChargesPercentageCar(car);
    }
    // tslint:disable-next-line:max-line-length
    car.totalPremium  = car.netPremium
      + car.rights
      + car.paymentCharges
      + car.iva;
  }

  calCommissionAmountCar(car) {
    const commissionAmount = (car.netPremium
      * car.commission) / 100;

    car.commissionAmount = commissionAmount.toFixed(4);
  }

  reCalTaxCar(car) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(car.taxId)) {
        car.iva =
          ((car.netPremium
            + car.rights
            + car.paymentCharges) * tax.taxValue) / 100;
      }
    }
  }

  calChargesPercentageCar(car) {
    const chargesPercentageTmp = (car.paymentCharges * 100)
      / car.netPremium;

    car.chargesPercentage = chargesPercentageTmp;
  }

  calChargesAmountCar(car) {
    const chargesAmount =  (car.netPremium * this.chargesPercentage) / 100;
    car.paymentCharges = chargesAmount;
  }

  calTaxCar(i, car) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {

        car.iva =
          ((car.netPremium
              + car.rights + car.paymentCharges)
            * tax.taxValue) / 100;

        this.sumTotalPremiumBajaCar(car);
      }
    }
  }

  calCommissionPercentageCar(car) {
    const commssion = (car.commissionAmount * 100)
      / car.netPremium;
    car.commission = commssion.toFixed(2);
  }
}
