import {Component, Input, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CategoryCoverageModel, CoverageModel} from '../admin-policies/coverage-model';

@Component({
  selector: 'app-basic-coverage',
  templateUrl: './basic-coverage.component.html',
  styleUrls: ['./basic-coverage.component.css']
})
export class BasicCoverageComponent implements OnInit {

  categoryForm: FormGroup;
  clientId: number;
  catClientsPolicy: any = [];
  error = {active: false, msg: ''};
  private _dataToSendGeneral: any = {};
  private _receiptData: any = {};
  private _endorsementId: number;
  public fileUpload: File;
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  dataToSendCategory: any = {};
  category = 'CATEGORIA';
  categoryId: number;
  categoryList: any = [];
  bCoverage: number;
  coverageList: Array<CoverageModel>;
  saTypeSelected: any = {};
  deTypeSelected: any = {};
  receiptList: any = [];
  coverageSa: string;
  coverageDe: string;
  typeList: any = [];
  private _subBranchId: number;
  private _flag: number;
  type_provisions: boolean;
  /*MODAL*/
  modalRef: BsModalRef;
  selectedConceptType: number;
  selectedConceptTypeHospital: number;
  selectedConceptTypeCovered: number;
  selectedConceptTypeLife: number;
  selectedConceptTypeCoaseguro: number;
  selectedConceptTypeExpenses: number;
  coverageValue: CoverageModel;
  tariffList: any = [];
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  coveragesAmmia: any = [];
  coveragesAmmiaDetail: any = [];
  modalSaveEndorsement: BsModalRef;
  typeOperation: number;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService) {

    this.categoryForm = this.fb.group({
      catalogs: ['groups', [Validators.required]],
      category: ['category', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]]
    });

    this.shared.gPolicyIdCategoryObservable().subscribe(
        gPolicyIdCategory => {
          if (this._subBranchId !== undefined ) {
            this.bCoverage = -1;
            // console.log(this._subBranchId);
            this._flag = 0;
            this.type_provisions = false;
            if (this._subBranchId === 2 || this._subBranchId === 4
              || this._subBranchId === 5 || this._subBranchId === 7) {
              this._flag = 1;
              this.type_provisions = false;
            } else if (this._subBranchId === 10) {
              this.flag = 2;
            }
            this.categoryList = [];
            // console.log('bcoverage ngoninit:' + gPolicyIdCategory);
            this.getClientsPolicy(localStorage.getItem('policy-id'));
            if (this.shared.fPolicyDataReadonly) {
              this.getCategoriesPolicy();
            }
          }
        }
      );
  }

  ngOnInit() {
  }
  /*ngOnChanges() {
    this.bCoverage = -1;
    this.categoryList = [];
    console.log('ngOnChangue Componente hijo:' + localStorage.getItem('policy-id'));
    this.getClientsPolicy(localStorage.getItem('policy-id'));
    if (this.shared.fPolicyDataReadonly) {
      this.getCategoriesPolicy();
    }
  }*/
  /*--+---------------------------------------------------------------------------------+
   |----------------------------| C A T E G O R I A |----------------------------|
   +---------------------------------------------------------------------------------+--*/


  getClientsPolicy(id) {
    this.api.getClientsPolicy(id, this.endorsementId).then((data: any) => {
      this.catClientsPolicy = data;
      this.clientId = this.catClientsPolicy[0].clientId;
      // console.log('componente hijo: ' + this.clientId);
    }, error => {
      this.catClientsPolicy = [];
    });
  }

  onChangeInputFile(e) {
    // console.log(e);
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  sendCategoryData() {
    this.spinner.show('sp');
    /*this.dataToSendCategory = {
      categoryId: 0,
      name: this.category,
      typeTax: this._flag,
      clientId: this.clientId,
      averagePremium: this.type_provisions
    };
    if (this.dataToSendCategory.name === undefined || this.dataToSendCategory.name === '') {
      this.toastr.info('POR FAVOR INGRESE UN NOMBRE DE CATEGORIA', 'Notificación');
      this.spinner.hide('sp');
      return;
    }
    if (this.dataToSendCategory.clientId === undefined) {
      this.toastr.info('POR FAVOR SELECCIONE UN SUBGRUPO', 'Notificación');
      this.spinner.hide('sp');
      return;
    }
    if (this._flag === 1) {
      if (this.error.active === true || this.fileUpload === undefined) {
        this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
        this.spinner.hide('sp');
        return;
      }
    }*/
    this.api.postCategoryPolicyData(this.dataToSendCategory, localStorage.getItem('policy-id'))
      .then(
        (response: any) => {
          this.categoryId = response.categoryId;
          this.category = undefined;
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          if (this._flag === 1) {
            const formData = new FormData();
            formData.append('file', this.fileUpload);
            this.api.postLayoutTariff(formData, this.categoryId)
              .subscribe(
                res => {
                  // console.log(res);
                  this.fileUpload = undefined;
                  (document.getElementById('fileCategory') as HTMLInputElement).value = null;
                  this.spinner.hide('sp');
                  this.getCategoriesPolicy();
                }, error => {
                  this.toastr.warning(error.error);
                  this.getCategoriesPolicy();
                  // console.error();
                  this.spinner.hide('sp');
                }
              );
          } else {
            this.spinner.hide('sp');
            this.getCategoriesPolicy();
          }
        }, error => {
          this.spinner.show('hide');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  getCategoriesPolicy() {
    this.categoryList = [];
    this.api.getCategoriesPolicy(localStorage.getItem('policy-id'), this.endorsementId).then((data: any) => {
      this.categoryList = data;
      if (this.categoryList !== undefined && this.categoryList !== null && this.categoryList.length > 0) {
        this.type_provisions = this.categoryList[0].averagePremium;
      } else {
        this.type_provisions = false;
      }
      if (this._subBranchId === 23 || this._subBranchId === 24 || this._subBranchId === 19) {
        this.api.getCoveragesAmmiaHousing(this.categoryList[0].categoryId).then((dataCoverage: any) => {
          this.coveragesAmmia = dataCoverage;
          this.spinner.hide('sp');
          if (this._subBranchId === 23 || this._subBranchId === 19) {
            this.api.getCoveragesAmmiaCellPhone(localStorage.getItem('policy-id')).then((dataCoverage1: any) => {
              this.coveragesAmmiaDetail = dataCoverage1;
            }, error => {
              this.spinner.hide('sp');
            });
          }
        }, error => {
          this.spinner.hide('sp');
        });
      }
      // console.log('resultado getcategoriespolicy: componente hijo');
      // this.loadReceiptPolicyCategory(this.categoryList[0].categoryId);
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  deleteCategory(category) {
    this.spinner.show('sp');
    this.api.deleteCategoryPolicy(category.categoryId).subscribe(response => {
      this.getCategoriesPolicy();
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
      this.spinner.hide('sp');
    });
  }

  loadTax(id) {
    // console.log(id);
  }
  showTariff(c, template: TemplateRef<any>) {
    // console.log(c);
    this.categoryId = c.categoryId;
    this.invokeServiceGetTariff(this.categoryId);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  invokeServiceGetTariff(categoryId) {
    this.api.getTariffByCategory(categoryId).then((data: any) => {
      this.tariffList = data;
    }, error => {
    });
  }
  showCoverage(c) {
    // console.log(c);
    this.bCoverage = c.coverage;
    this.categoryId = c.categoryId;
    if (this.bCoverage > 0) {
      this.api.getCoverageCategory(this.categoryId, this.endorsementId).then((data: any) => {
        this.coverageList = data;
      }, error => {
        // console.error(error.status);
        // console.error(error.statusText);
        // console.error(error.message);
      });
    } else {
      switch (this._subBranchId) {
        case 5: {
          this.getCoverages(5); // ok
          break;
        }
        case 3: {
          this.getCoverages(3); // ok
          break;
        }
        case 10: {
          this.getCoverages(10); // ok
          break;
        }
        case 6: {
          this.getCoverages(6); // ok
          break;
        }
        case 7: {
          this.getCoverages(7); // ok
          break;
        }
        case 4: {
          this.getCoverages(4); // ok
          break;
        }
        case 21: {
          this.getCoverages(21); // ok
          break;
        }
        case 9: {
          this.getCoverages(21); // ok
          break;
        }
        case 20: {
          this.getCoverages(21); // ok
          break;
        }
        case 2: {
          this.getCoverages(2); // ok
          break;
        }
        case 12:
          this.getCoverages(12); // ok
          break;
        case 8:
          this.getCoverages(8); // ok
          break;
        case 13:
          this.getCoverages(13); // ok
          break;
        default:
          this.getCoverages(this._subBranchId);
          break;
      }
    }
  }

  saveCoverage() {
    this.spinner.show('sp');
    // validacione de medidas
    /*for (const item of this.coverageList) {
      // console.log(item);
      if (item.value === '') {
        this.toastr.warning('DEBE DE INGRESAR LOS VALORES DE TODAS LAS COBERTURAS', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        return;
      }
      if (item.description === 'SUMA ASEGURADA' || item.description === 'DEDUCIBLE' || item.description === 'TOPE DE COASEGURO'
        || item.description === 'LIMITE ANUAL') {
        if (item.medition === '') {
          this.toastr.warning('DEBE DE SELECCIONAR TODAS LAS OPCIONES DE LAS COBERTURAS', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          return;
        }
      }
    }*/
    this.api.postCoverageData(this.coverageList, this.categoryId)
      .then(
        (response: any) => {
          this.toastr.success('Su registro se ha agregado correctamente.', 'Notificación');
          this.bCoverage = undefined;
          this.getCategoriesPolicy();
          this.spinner.hide('sp');
          this.saTypeSelected = 0;
          this.deTypeSelected = 0;
        }, error => {
          this.spinner.hide('sp');
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  edithCoverage() {
    this.bCoverage = 0;
    // console.log(this.categoryId);
  }
  edit_category(row) {
    this.dataToSendCategory = row;
    document.getElementById('edit_category' + row.categoryId).style.display = 'none';
    document.getElementById('save_category' + row.categoryId).style.display = 'block';
    const name = document.getElementById('name' + row.categoryId);
    name.innerHTML = '<input type=\'text\' id=\'name_text' + row.categoryId + '\' value=\'' + row.name + '\'>';
  }

  save_category(id) {
    this.spinner.show('sp');
    this.dataToSendCategory = {
      categoryId: id,
      name: (document.getElementById('name_text' + id) as HTMLInputElement).value,
      typeTax: this.dataToSendCategory.typeTax
    };
    document.getElementById('edit_category' + id).style.display = 'block';
    document.getElementById('save_category' + id).style.display = 'none';
    this.api.putCategoryPolicyData(this.dataToSendCategory)
      .then(
        (response: any) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.getCategoriesPolicy();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.warning(error.error);
          // console.error();
          this.spinner.hide('sp');
        }
      );
  }

  getCoverages(id) {
    this.spinner.show('sp');
    this.api.getCoverageSubbranch(id).then((data: any) => {
      this.coverageList = data;
      for (const item of this.coverageList) {
        if (item.description === 'COASEGURO' || item.description === 'COPAGO'
          || item.description === 'URGENCIAS DENTALES' || item.description === 'DIAGNOTICO Y PREVENSION'
          || item.description === 'RESTAURAMIENTO BASICO' || item.description === 'PROCEDIMIENTOS QUIRURGICOS MENORES'
          || item.description === 'ENDODONCIAS' || item.description === 'REMOCION DE TERCEROS MOLARES'
          || item.description === 'PERIODONCIA') {
          item.medition = '%';
        }
        if (item.description === 'SUMA ASEGURADA TOTAL' || item.description === 'GASTOS FUNERARIOS'
          || item.description === 'MUERTE ACCIDENTAL' || item.description === 'PERDIDAS ORGANICAS'
          || item.description === 'INDEMNIZACION POR HOSPITALIZACION POR ENFERMEDAD'
          || item.description === 'INDEMNIZACION POR HOSPITALIZACION POR ACCIDENTE'
          || item.description === 'INDEMNIZACION POR CANCER' || item.description === 'ENFERMEDADES GRAVES'
          || item.description === 'REEMBOLSO DE GASTOS MEDICOS POR ACCIDENTE' || item.description === 'GASTOS FUNERARIOS'
          || item.description === 'INVALIDEZ') {
          if (id !== 10 && id !== 6) {
            item.medition = 'PESOS MEXICANOS';
          } else {
            if (item.description === 'GASTOS FUNERARIOS' || item.description === 'SUMA ASEGURADA TOTAL') {
              item.medition = 'PESOS MEXICANOS';
            }
          }
        }
      }
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
      // console.error(error.status);
    });
  }

  loadReceiptPolicyCategory(categoryId) {
    this.receiptList = [];
    // console.log('petición desde coberturas');
    this.api.getReceiptPolicyCategory(localStorage.getItem('policy-id'), categoryId).then((data: any) => {
      this.receiptList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  changeSAToConsult(coverage) {
    this.coverageSa = this.saTypeSelected;
    for (let item of this.coverageList) {
      if (coverage.id == item.id) {
        for (let l of this.typeList) {
          item.value = item.value.replace(l.name, '');
        }
        item.value = item.value.replace(' ', '');
        item.value = item.value + ' ' + this.saTypeSelected;
        break;
      }
    }
  }
  changeDEToConsult(coverage) {
    this.coverageDe = this.deTypeSelected;
    for (let item of this.coverageList) {
      if (coverage.id == item.id) {
        for (let l of this.typeList) {
          item.value = item.value.replace(l.name, '');
        }
        item.value = item.value.replace(' ', '');
        item.value = item.value + ' ' + this.coverageDe;
        break;
      }
    }
  }

  /**
   * Función para limpiar los filtros
   */
  downloadCoverages() {
    const policy = {
      idPolicy: Number(localStorage.getItem('policy-id'))
    };
    this.api.downloadCoverages(policy).then(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'Coverages.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }, (error) => {
      });
  }

  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (id === 1) {
              downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS.xlsx');
            } else {
              downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  get subBranchId(): number {
    return this._subBranchId;
  }
  get flag(): number {
    return this._flag;
  }
  @Input()
  set subBranchId(value: number) {
    this._subBranchId = value;
  }
  // tslint:disable-next-line:adjacent-overload-signatures
  @Input()
  set flag(value: number) {
    this._flag = value;
  }

  showTypeConcept(template: TemplateRef<any>, coverageValue) {
    // console.log(template);
    this.selectedConceptType = -1;
    this.coverageValue = coverageValue;
    if (this._subBranchId === 10 || this.subBranchId === 6) {
      if (this.coverageList[0].value === 'MESES' && coverageValue.description === 'SUMA ASEGURADA') {
        this.toastr.warning('YA NO PUEDE CAMBIAR EL TIPO DE SUMA ASEGURADA', 'NOTIFICACIÓN');
        return;
      }
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-md', keyboard: false});
  }
  selectedType() {
    let concept;
    switch (this.selectedConceptType) {
      case 1:
        concept = 'SMGMV';
        break;
      case 2:
        concept = 'UMAM';
        break;
      case 3:
        concept = 'USD';
        break;
      case 4:
        concept = 'PESOS MEXICANOS';
        break;
      case 5:
        concept = 'MESES';
        break;
    }
    this.coverageValue.medition = concept;
    this.selectedConceptType = -1;
    this.modalRef.hide();
  }
  selectedTypeHospital() {
    let concept;
    switch (this.selectedConceptTypeHospital) {
      case 1:
        concept = 'SIN RESTRICCIÓN';
        break;
      case 2:
        concept = 'RESTRICCIÓN HOSPITALES A';
        break;
      case 3:
        concept = 'RESTRICCIÓN HOSPITALES A Y B';
        break;
    }
    this.coverageValue.value = concept;
    this.selectedConceptTypeHospital = -1;
    this.modalRef.hide();
  }

  selectedTypeCovered() {
    let concept;
    switch (this.selectedConceptTypeCovered) {
      case 1:
        concept = 'AMPARADO';
        break;
      case 2:
        concept = 'NO AMPARADO';
        break;
    }
    this.coverageValue.value = concept;
    this.selectedConceptTypeCovered = -1;
    this.modalRef.hide();
  }

  selectedTypeLife() {
    let concept;
    switch (this.selectedConceptTypeLife) {
      case 1:
        concept = 'FIJA';
        break;
      case 2:
        concept = 'MESES';
        break;
    }
    this.coverageValue.value = concept;
    this.selectedConceptTypeLife = -1;
    if (this._subBranchId === 10 || this.subBranchId === 6) {
      this.validateMovement(this.coverageValue);
    }
    (document.getElementById('SUMA ASEGURADA') as HTMLButtonElement).disabled = false;
    this.modalRef.hide();
  }

  selectedTypeCoaseguro() {
    let concept;
    switch (this.selectedConceptTypeCoaseguro) {
      case 1:
        concept = '0';
        break;
      case 2:
        concept = '5';
        break;
      case 3:
        concept = '10';
        break;
      case 4:
        concept = '15';
        break;
      case 5:
        concept = '20';
        break;
      case 6:
        concept = '25';
        break;
      case 7:
        concept = '30';
        break;
    }
    this.coverageValue.value = concept;
    this.selectedConceptTypeCoaseguro = -1;
    this.modalRef.hide();
  }

  selectedTypeExpense() {
    let concept;
    switch (this.selectedConceptTypeExpenses) {
      case 1:
        concept = 'TITULAR';
        break;
      case 2:
        concept = 'FAMILIAR';
        break;
      case 3:
        concept = 'ASCENDENTE';
        break;
    }
    this.coverageValue.value = concept;
    this.selectedConceptTypeExpenses = -1;
    this.modalRef.hide();
  }

  validateMovement(coverageValue) {
    // console.log(coverageValue);
    if (coverageValue.value === 'MESES') {
      this.coverageList[1].medition = 'MESES';
    }
  }
  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    // console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  invokeServiceSaveTariff() {
    this.spinner.show('sp');
    if (this.fileUploadXlsx === undefined) {
      this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const formData = new FormData();
    formData.append('file', this.fileUploadXlsx);
    this.api.postLayoutTariff(formData, this.categoryId)
      .subscribe(
        res => {
          // console.log(res);
          this.fileUploadXlsx = undefined;
          (document.getElementById('fileTariff') as HTMLInputElement).value = null;
          this.invokeServiceGetTariff(this.categoryId);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.warning(error.error);
          // console.error();
          this.spinner.hide('sp');
        }
      );
  }

  openModalSaveEndorsement(template: TemplateRef<any>, typeOperation: number) {
    this.typeOperation = typeOperation;
    if (typeOperation === 1) {
      this.dataToSendCategory = {
        categoryId: 0,
        name: this.category,
        typeTax: this._flag,
        clientId: this.clientId,
        averagePremium: this.type_provisions
      };
      if (this.dataToSendCategory.name === undefined || this.dataToSendCategory.name === '') {
        this.toastr.info('POR FAVOR INGRESE UN NOMBRE DE CATEGORIA', 'Notificación');
        this.spinner.hide('sp');
        return;
      }
      if (this.dataToSendCategory.clientId === undefined) {
        this.toastr.info('POR FAVOR SELECCIONE UN SUBGRUPO', 'Notificación');
        this.spinner.hide('sp');
        return;
      }
      if (this._flag === 1) {
        if (this.error.active === true || this.fileUpload === undefined) {
          this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
          this.spinner.hide('sp');
          return;
        }
      }
    } else if (typeOperation === 2) {

      for (const item of this.coverageList) {
        // console.log(item);
        if (item.value === '') {
          this.toastr.warning('DEBE DE INGRESAR LOS VALORES DE TODAS LAS COBERTURAS', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          return;
        }
        if (item.description === 'SUMA ASEGURADA' || item.description === 'DEDUCIBLE' || item.description === 'TOPE DE COASEGURO'
          || item.description === 'LIMITE ANUAL') {
          if (item.medition === '') {
            this.toastr.warning('DEBE DE SELECCIONAR TODAS LAS OPCIONES DE LAS COBERTURAS', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
        }
      }
    }

    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {

      if (this.dataToSendGeneral.typeAdministrationPolicy === 1 || this.dataToSendGeneral.typeAdministrationPolicy === '1') {
        this.saveCoverages({saveData: true});
      } else {

        this.modalSaveEndorsement = this.modalService.show(template, {
          class: 'modal-lg',
          keyboard: false,
          animated: true
        });
      }
    } else {
      this.saveCoverages({saveData: true});
    }
  }
  @Input()
  set dataToSendGeneral(value: any) {
    this._dataToSendGeneral = value;
  }
  @Input()
  set receiptData(value: any) {
    this._receiptData = value;
  }
  get dataToSendGeneral(): any {
    return this._dataToSendGeneral;
  }

  get receiptData(): any {
    return this._receiptData;
  }
  saveCoverages(save) {
    if (save.saveData) {
      if (this.typeOperation === 1) {
        this.sendCategoryData();
      } else {
        this.saveCoverage();
      }
    }
  }

  closeModalEndorsement() {
    this.modalSaveEndorsement.hide();
    this.saveCoverages({saveData: true});
  }

  get endorsementId(): number {
    return this._endorsementId;
  }
  @Input()
  set endorsementId(value: number) {
    this._endorsementId = value;
  }


}
