import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {document} from 'ngx-bootstrap/utils';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {DatePipe, formatNumber} from '@angular/common';
import {regex} from '../../common/regex';
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import {AddressFisico, GroupFisico, SubgroupFisico, DocumentationListFisico} from '../admin-new-client/client-physical.model';
import {PolicyLoad, PolicyBusiness, ReceiptBusiness} from '../massive-load-policy/policy-massive.model';
import {Address, Group, Subgroup, DocumentationList, Contact} from '../admin-new-client/client-model';
import { idLocale } from 'ngx-bootstrap/chronos';
import { info } from 'console';
declare var $: any;



interface Policy {
  tipoPersona: string;
  numero: string;
  inicioVigencia: string;
  terminoVigencia: string;
  detalleId: number;
  grupo: string;
  idGrupo: number;
  subGrupo: string;
  idSubGrupo: number;
  aseguradora: string;
  idAseguradora: number;
  ramo: string;
  idRamo: number;
  claveAseguradora: string;
  idClaveAseguradora: number;
  subRamo: string;
  idSubRamo: number;
  renovacion: string;
  polizaAnterior: string;
  vendedor: string;
  idVendedor: number;
  coordinador: string;
  idCoordinador: number;
  gerenteSiniestro: string;
  idGerenteSiniestro: number;
  ejecutivoSurexs: string;
  idEjecutivoSurexs: number;
  ejecutivoSiniestro: string;
  idEjecutivoSiniestro: number;
  moneda: string;
  idMoneda: number;
  tipoPago: string;
  idTipoPago: number;
  tipoCobranza: string;
  idTipoCobranza: number;
  oficina: string;
  idOficina: number;
  primaNeta: number;
  derechos: number;
  porcRecargo: number;
  porcComision: number;
  iva: number;
  primaTotal: number;
  valRamo: boolean;
  valSubRamo: boolean;
  valGrupo: boolean;
  valSubGrupo: boolean;
  valRfcCliente: boolean;

}
@Component({
  selector: 'app-massive-load-policy',
  templateUrl: './massive-load-policy.component.html',
  styleUrls: ['./massive-load-policy.component.css']
})
export class MassiveLoadPolicyComponent implements OnInit {

  @ViewChild('gestionTabs', {static: false}) staticTabs1: TabsetComponent;
  @ViewChild('sectionTabs', {static: false}) staticTabs2: TabsetComponent;

  convertToJson!: any;
  convertToJsonMoral!: string;
  convertToJsonFisico!: string;
  infoPolicy: any = [];
  infoPolicyMoral: any = [];
  infoPolicyFisico: any = [];
  infoPolicyMoralTemp: any = [];
  infoPolicyFisicoTemp: any = [];

  infoPolicyValidate: any = [];
  polizasMaestras: any = [];
  esValido = false;
  presenta = false;
  tipoCliente = 0;
  detalleId = 0;
  modalRef: BsModalRef;
  modalRefNewClient: BsModalRef;
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  public fileUploadZip: File;

  public fileUpload: File;
  public idCarga: any;
  public showModal: boolean = false;
  editingPolicy: Policy | null = null;
  editingPolicyM: Policy | null = null;
  archivosZip: string[] = [];
  habilitaZip = false;

  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  bsExpirationDate: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  catInsuranceCompany: any = [];

  catBranch: any = [];

  catAgentKeys: any = [];
  catSubBranch: any = [];
  salesmanList: any = [];
  catCoordinatorList: any = [];
  catManagerIncidentList: any = [];
  executiveSurexList: any = [];
  executiveIncidentList: any = [];
  currencyList: any = [];
  catPaymentTypes: any = [];
  catTypePayments: any = [];
  officeList: any = [];
  esValidoProcesar = false;
  esValidaInformacion = true;
  catGroups: any = [];
  catClients: any = [];

  filesZip: any = [];

  indexAdd: number;
  isAdd: boolean = true;


 groupFisico: GroupFisico;
  // groupFisico: Array<GroupFisico>;

  subgroupFisico: SubgroupFisico;
  constructor( private api: ApiService,
               private router: Router,
               private toastr: ToastrService,
               private shared: SharedService,
               private datePipe: DatePipe,
               private spinner: NgxSpinnerService,
               private modalService: BsModalService) {

  }

  ngOnInit() {
    const obFisicos = localStorage.getItem('fisicos');
    if (obFisicos) {
      this.infoPolicyFisico = JSON.parse(obFisicos);
      this.presenta = true;
      this.isAdd = false;
    }

    // const obMorales = localStorage.getItem('morales');
    // if (obMorales) {
    //   this.infoPolicyMoral = JSON.parse(obMorales);
    //   this.presenta = true;
    //   this.isAdd = false;
    // }

    const obArchivosZip = localStorage.getItem('archivosZip');
    if (obArchivosZip && obArchivosZip != 'null') {
      this.archivosZip = JSON.parse(obArchivosZip);
    }

    const obFilesZip = localStorage.getItem('filesZip');
    if (obFilesZip) {
      this.filesZip = JSON.parse(obFilesZip);
    }

    const idCarga = localStorage.getItem('idCarga');
    if (idCarga) {
      this.idCarga = JSON.parse(idCarga);
    }

    if (obFisicos  && idCarga) {
      this.infoPolicyValidate = [];
      for (let i = 0; i < this.infoPolicyFisico.length; i++) {
        this.infoPolicyFisico[i].cargaId = this.idCarga.idCarga;
      }

      for (let i = 0; i < this.infoPolicyMoral.length; i++) {
        this.infoPolicyMoral[i].cargaId = this.idCarga.idCarga;
      }
      for (const dato of this.infoPolicyFisico) {
        this.infoPolicyValidate.push(dato);
      }

      for (const dato of this.infoPolicyMoral) {
        this.infoPolicyValidate.push(dato);
      }
      if (this.infoPolicyValidate.length === 0) {
        this.toastr.warning('CARGUE EL LAYOUT DE CARGA MASIVA DE PÓLIZAS, PARA PODER VALIDAR LA INFORMACIÓN.', 'NOTIFICACIÓN');
        return;
      }

      console.log('VALIDANDO: ', this.infoPolicyValidate);
      this.policyMasterFiles(this.infoPolicyValidate, 2);

      console.log('entre a carga');
      this.cargaDataFromClient();
    }

    // @ts-ignore
    $('#smartwizard').smartWizard({
      selected: 0, // Initial selected step, 0 = first step
      enableAllSteps: true,
      theme: 'default', // theme for the wizard, related css need to include for other than default theme
      justified: true, // Nav menu justification. true/false
      autoAdjustHeight: true, // Automatically adjust content height
      cycleSteps: false, // Allows to cycle the navigation of steps
      backButtonSupport: true, // Enable the back button support
      enableURLhash: false, // Enable selection of the step based on url hash,
      enableAnchorOnDoneStep: true,
      transition: {
        animation: 'none', // Effect on navigation, none/fade/slide-horizontal/slide-vertical/slide-swing
        speed: '400', // Transion animation speed
        easing: '' // Transition animation easing. Not supported without a jQuery easing plugin
      },
      toolbarSettings: {
        toolbarPosition: 'bottom', // none, top, bottom, both
        toolbarButtonPosition: 'right', // left, right, center
        showNextButton: false, // show/hide a Next button
        showPreviousButton: false, // show/hide a Previous button
        toolbarExtraButtons: [] // Extra buttons to show on toolbar, array of jQuery input/buttons elements
      },
      anchorSettings: {
        anchorClickable: true, // Enable/Disable anchor navigation
        enableAllAnchors: true, // Activates all anchors clickable all times
        markDoneStep: true, // Add done state on navigation
        markAllPreviousStepsAsDone: true, // When a step selected by url hash, all previous steps are marked done
        removeDoneStepOnNavigateBack: true, // While navigate back done step after active step will be cleared
        enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
      },
      keyboardSettings: {
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        keyLeft: [37], // Left key code
        keyRight: [39] // Right key code
      },
      lang: { // Language variables for button
        next: 'SIGUIENTE',
        previous: 'ANTERIOR'
      },
      disabledSteps: [], // Array Steps disabled
      errorSteps: [], // Highlight step with errors
      hiddenSteps: [] // Hidden steps
    });
    this.getGroups();
    this.getInsuranceCompanies();
    this.getBranches();
    this.getAgentKeys(0);
    this.getSubBranch(0);
    this.getSalesman();
    this.getExecutives(1);
    this.getExecutives(2);
    this.getExecutives(3);
    this.getManagerIncident();
    this.getCurrency();
    this.getTypePayments();
    this.getTypePaymentsPolicy();
    this.getOffices();

    let clientNew: any;
    clientNew = localStorage.getItem('clientNew');
    if (clientNew != '' ) {
      this.cargaTres();
      this.esValido = true;
      this.presenta = true;
    }
  }

  nuevaCarga(): void {
    localStorage.removeItem('fisicos');
    localStorage.removeItem('morales');
    localStorage.removeItem('archivosZip');
    localStorage.removeItem('filesZip');
    localStorage.removeItem('idCarga');
    this.presenta = false;
    this.isAdd = true;
    this.esValidaInformacion = true;

    this.infoPolicy = [];
  }

  cargaUno() {
   let lista: any;
   lista =  [
     {
       NUMERO: 'GMM141414',
       INICIO_VIGENCIA: '2023-02-20',
       TERMINO_VIGENCIA: '2024-02-20',
       RFC_CLIENTE: 'QAD120117AT0',
       SUB_GRUPO: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
       CATEGORIA: 'EJECUTIVO',
       ASEGURADORA: 'ALLIANZ MEXICO S.A.',
       CLAVE_ASEGURADORA: '5406-GMM-VIDA',
       RAMO: 'BENEFICIOS',
       SUB_RAMO: 'GMM GRUPO',
       RENOVACION: 'SI',
       VENDEDOR: 'CARLOS DAGNINO FRISBIE',
       COORDINADOR: 'VIVIANA HERNANDEZ MERINO',
       GERENTE_SINIESTROS: 'ENRIQUE GUTIERREZ ESLAVA',
       EJECUTIVO_SUREXS: 'KENIA ESTRADA SALGADO',
       EJECUTIVO_SINIESTRO: 'GIAN CARLO REYNOSO MELO',
       MONEDA: 'MX',
       TIPO_PAGO: 'ANUAL',
       TIPO_COBRANZA: 'POR POLIZA',
       EDAD_MAXIMA_HIJOS: '24 AÑOS Y 11 MESES',
       OFICINA: 'CDMX',
       ANEXO_URLS: 'https://hospitalesangeles.com/directorio-medico,https://www.hespanol.com/contact',
       ANEXO_ARCHIVOS: 'MATERNIDAD.PDF',
       PRIMA_NETA: 650000,
       DERECHOS: 5000,
       PORC_RECARGO: 5,
       PORC_COMISION: 5,
       IVA: 16
  },
    {
      NUMERO: 'GMM141415',
        INICIO_VIGENCIA: '2023-02-20',
      TERMINO_VIGENCIA: '2024-02-20',
      RFC_CLIENTE: 'QAD120117AT1',
      SUB_GRUPO: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
      CATEGORIA: 'EJECUTIVO',
      ASEGURADORA: 'ALLIANZ MEXICO S.A.',
      CLAVE_ASEGURADORA: '5406-GMM-VIDA',
      RAMO: 'BENEFICIOS',
      SUB_RAMO: 'GMM GRUPO',
      RENOVACION: 'SI',
      VENDEDOR: 'CARLOS DAGNINO FRISBIE',
      COORDINADOR: 'VIVIANA HERNANDEZ MERINO',
      GERENTE_SINIESTROS: 'ENRIQUE GUTIERREZ ESLAVA',
      EJECUTIVO_SUREXS: 'KENIA ESTRADA SALGADO',
      EJECUTIVO_SINIESTRO: 'GIAN CARLO REYNOSO MELO',
      MONEDA: 'MX',
      TIPO_PAGO: 'ANUAL',
      TIPO_COBRANZA: 'POR POLIZA',
      EDAD_MAXIMA_HIJOS: '25 AÑOS Y 11 MESES',
      OFICINA: 'CDMX',
      ANEXO_URLS: 'https://hospitalesangeles.com/directorio-medico,https://www.hespanol.com/contact',
      ANEXO_ARCHIVOS: 'MATERNIDAD.PDF',
      PRIMA_NETA: 650000,
      DERECHOS: 5000,
      PORC_RECARGO: 5,
      PORC_COMISION: 5,
      IVA: 16
      },
    {
      NUMERO: 'GMM141417',
        INICIO_VIGENCIA: '2023-02-20',
      TERMINO_VIGENCIA: '2024-02-20',
      RFC_CLIENTE: 'QAD120117AT3',
      SUB_GRUPO: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
      CATEGORIA: 'GERENTE',
      ASEGURADORA: 'A.N.A. COMPAÑÍA DE SEGUROS S.A. DE C.V.',
      CLAVE_ASEGURADORA: '00000-NO DEFINIDO',
      RAMO: 'AUTOS',
      SUB_RAMO: 'AUTO FLOTILLA',
      VENDEDOR: 'CARLOS DAGNINO FRISBIE',
      COORDINADOR: 'JOSE LUIS TREJO NIETO',
      GERENTE_SINIESTROS: 'ENRIQUE GUTIERREZ ESLAVA',
      EJECUTIVO_SUREXS: 'KENIA ESTRADA SALGADO',
      EJECUTIVO_SINIESTRO: 'GIAN CARLO REYNOSO MELO',
      MONEDA: 'MX',
      TIPO_PAGO: 'ANUAL',
      TIPO_COBRANZA: 'POR POLIZA',
      OFICINA: 'CDMX',
      ANEXO_URLS: 'https://hospitalesangeles.com/directorio-medico',
      PRIMA_NETA: 500000,
      DERECHOS: 5000,
      PORC_RECARGO: 6,
      PORC_COMISION: 5,
      IVA: 16
    }
  ]
    ;
   this.infoPolicy = lista;
   console.log('rolllist: ', this.infoPolicy);
  }


  cargaTres() {
    let lista: any;
    lista =  [
      {
        NUMERO: 'GMM141414',
        INICIO_VIGENCIA: '2023-02-20',
        TERMINO_VIGENCIA: '2024-02-20',
        RFC_CLIENTE: 'EEE121212EE0',
        SUB_GRUPO: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
        CATEGORIA: 'EJECUTIVO',
        ASEGURADORA: 'ALLIANZ MEXICO S.A.',
        CLAVE_ASEGURADORA: '5406-GMM-VIDA',
        RAMO: 'BENEFICIOS',
        SUB_RAMO: 'GMM GRUPO',
        RENOVACION: 'SI',
        VENDEDOR: 'CARLOS DAGNINO FRISBIE',
        COORDINADOR: 'VIVIANA HERNANDEZ MERINO',
        GERENTE_SINIESTROS: 'ENRIQUE GUTIERREZ ESLAVA',
        EJECUTIVO_SUREXS: 'KENIA ESTRADA SALGADO',
        EJECUTIVO_SINIESTRO: 'GIAN CARLO REYNOSO MELO',
        MONEDA: 'MX',
        TIPO_PAGO: 'ANUAL',
        TIPO_COBRANZA: 'POR POLIZA',
        EDAD_MAXIMA_HIJOS: '24 AÑOS Y 11 MESES',
        OFICINA: 'CDMX',
        ANEXO_URLS: 'https://hospitalesangeles.com/directorio-medico,https://www.hespanol.com/contact',
        ANEXO_ARCHIVOS: 'MATERNIDAD.PDF',
        PRIMA_NETA: 650000,
        DERECHOS: 5000,
        PORC_RECARGO: 5,
        PORC_COMISION: 5,
        IVA: 16
      },
      {
        NUMERO: 'GMM141415',
        INICIO_VIGENCIA: '2023-02-20',
        TERMINO_VIGENCIA: '2024-02-20',
        RFC_CLIENTE: 'EEE121212EE0',
        SUB_GRUPO: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
        CATEGORIA: 'EJECUTIVO',
        ASEGURADORA: 'ALLIANZ MEXICO S.A.',
        CLAVE_ASEGURADORA: '5406-GMM-VIDA',
        RAMO: 'BENEFICIOS',
        SUB_RAMO: 'GMM GRUPO',
        RENOVACION: 'SI',
        VENDEDOR: 'CARLOS DAGNINO FRISBIE',
        COORDINADOR: 'VIVIANA HERNANDEZ MERINO',
        GERENTE_SINIESTROS: 'ENRIQUE GUTIERREZ ESLAVA',
        EJECUTIVO_SUREXS: 'KENIA ESTRADA SALGADO',
        EJECUTIVO_SINIESTRO: 'GIAN CARLO REYNOSO MELO',
        MONEDA: 'MX',
        TIPO_PAGO: 'ANUAL',
        TIPO_COBRANZA: 'POR POLIZA',
        EDAD_MAXIMA_HIJOS: '25 AÑOS Y 11 MESES',
        OFICINA: 'CDMX',
        ANEXO_URLS: 'https://hospitalesangeles.com/directorio-medico,https://www.hespanol.com/contact',
        ANEXO_ARCHIVOS: 'MATERNIDAD.PDF',
        PRIMA_NETA: 650000,
        DERECHOS: 5000,
        PORC_RECARGO: 5,
        PORC_COMISION: 5,
        IVA: 16
      },
      {
        NUMERO: 'GMM141417',
        INICIO_VIGENCIA: '2023-02-20',
        TERMINO_VIGENCIA: '2024-02-20',
        RFC_CLIENTE: 'EEE121212EE0',
        SUB_GRUPO: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
        CATEGORIA: 'GERENTE',
        ASEGURADORA: 'A.N.A. COMPAÑÍA DE SEGUROS S.A. DE C.V.',
        CLAVE_ASEGURADORA: '00000-NO DEFINIDO',
        RAMO: 'AUTOS',
        SUB_RAMO: 'AUTO FLOTILLA',
        VENDEDOR: 'CARLOS DAGNINO FRISBIE',
        COORDINADOR: 'JOSE LUIS TREJO NIETO',
        GERENTE_SINIESTROS: 'ENRIQUE GUTIERREZ ESLAVA',
        EJECUTIVO_SUREXS: 'KENIA ESTRADA SALGADO',
        EJECUTIVO_SINIESTRO: 'GIAN CARLO REYNOSO MELO',
        MONEDA: 'MX',
        TIPO_PAGO: 'ANUAL',
        TIPO_COBRANZA: 'POR POLIZA',
        OFICINA: 'CDMX',
        ANEXO_URLS: 'https://hospitalesangeles.com/directorio-medico',
        PRIMA_NETA: 500000,
        DERECHOS: 5000,
        PORC_RECARGO: 6,
        PORC_COMISION: 5,
        IVA: 16
      }
    ]
    ;
    this.infoPolicy = lista;
    console.log('rolllist: ', this.infoPolicy);
  }
  fileUploadXls(event: any): void {
// Obtener la fecha actual
    const today = new Date();
    const e: any = event;
// Convertir a cadena ISO y extraer la fecha en formato aaaa-mm-dd
    const formattedDate = today.toISOString().slice(0, 10);

    console.log('FECHA: ' , formattedDate); // Salida: "2023-05-19";
// Obtener la fecha y hora actual
    const now = new Date();
    console.log('FECHA-HORA: ', now);
// Extraer la hora en formato hh:mm:ss
    const formattedTime = now.toLocaleTimeString();

    console.log('HORA: ' , formattedTime); // Salida: "13:45:30"

    this.presenta = false;
    console.log(event.target.files);
    let selectedFile = event.target.files[0];
    // this.onFileSelected(event);
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);

    if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // tslint:disable-next-line:no-shadowed-variable
      fileReader.onload = (event) => {
        console.log('event: ', event);
        // @ts-ignore
        const binaryData = event.target.result;
        const workBook = XLSX.read(binaryData, {type: 'binary'});

        // Obtener los datos de la hoja de cálculo en forma de matriz JSON
        const sheetName = workBook.SheetNames[0];
        const worksheet = workBook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        if (this.validateExcelStructured(sheetName, worksheet, jsonData)) {
          // workBook.SheetNames.forEach(sheet => {
            // console.log(sheet);
            const data = XLSX.utils.sheet_to_json(workBook.Sheets['Hoja1']);
            console.log('data: ', data);
            this.convertToJson = JSON.stringify(data, undefined, 4);
          // });
          console.log('workBook: ', workBook);
          console.log('convertToJson: ', this.convertToJson);
          const tmp = JSON.parse(this.convertToJson);
          this.infoPolicy = tmp;
          this.infoPolicy.forEach(inf => {
            const arr = inf.NOMBRE_ARCHIVO_POLIZA.split('.'); 
            const name = arr[0];
            const ext = arr[1];
            inf.NOMBRE_ARCHIVO_POLIZA = name + '.' + ext.toLowerCase();
          });
          console.log('CONVERTIR A INFOPOLICY', this.infoPolicy);

          if (this.validaExcel(this.infoPolicy)) {

            this.convertToObjectToJson(formattedDate, formattedTime);

            this.policyMasterFiles(this.infoPolicy, 1);

            this.convertToJson = JSON.stringify(this.infoPolicy, undefined, 4);

            console.log('EL OBJETO QUE MANDO AL API: ', this.convertToJson);

            this.spinner.show('sp');
            this.api.loadMassivePolicy(JSON.parse(this.convertToJson))
              .subscribe(
                response => {
                  this.idCarga = response;
                  localStorage.setItem('idCarga', JSON.stringify(this.idCarga));
                  console.log('IDCARGA: ', this.idCarga.idCarga);

                  this.cargaTemporal(this.idCarga.idCarga);
                  e.target.value = '';
                  this.isAdd = false;

                }, error => {
                  this.toastr.warning(error.error.toUpperCase());
                }
              );

            this.presenta = true;

            selectedFile = null;
          } else {
            selectedFile = null;
            e.target.value = '';

          }
        } else {
          this.toastr.error('EL ARCHIVO SELECCIONADO, NO CONTIENE UNA ESTRUCTURA VALIDA PARA LA CARGA MASIVA DE PÓLIZAS', 'NOTIFICACIÓN');
          selectedFile = null;
          e.target.value = '';
          return;
        }
      };

    } else {
      this.toastr.error('EL ARCHIVO SELECCIONADO, NO ES UN ARCHIVO DE EXCEL VÁLIDO, SELECCIONE EL ARCHIVO CORRECTO', 'NOTIFICACIÓN');
      selectedFile = null;
      e.target.value = '';
      return;
      }

  }

  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
  }

  changeColorPagination() { }

  openModalAddClient(template: TemplateRef<any>, tipo: number, index: number) {
    this.indexAdd = index;
    this.onChangeTipo(tipo);
    localStorage.setItem('isViewFromPolicie', 'true');
    // tslint:disable-next-line:max-line-length
    localStorage.setItem('guardado', 'false');
    this.modalRefNewClient = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  closeModalNewClient() {
    localStorage.setItem('clientNew', 'SI');
    const isGuardado = localStorage.getItem('guardado');
    if (isGuardado === 'true') {
      switch (this.tipoCliente) {
        case 1 :
          const groupMoral = JSON.parse(localStorage.getItem('groupMoral'));
          const subgroupMoral = JSON.parse(localStorage.getItem('subgroupMoral'));
          this.infoPolicyFisico[this.indexAdd].grupo = groupMoral.name;
          this.infoPolicyFisico[this.indexAdd].subGrupo = subgroupMoral.general_data.name;
          this.cargaDataFromClient();
          // this.esValido = true;
          // this.presenta = true;
          this.modalRefNewClient.hide();
          return;
        case 3 :
          const groupFisico = JSON.parse(localStorage.getItem('groupFisico'));
          const subgroupFisico = JSON.parse(localStorage.getItem('subgroupFisico'));
          this.infoPolicyFisico[this.indexAdd].grupo = groupFisico.name;
          this.infoPolicyFisico[this.indexAdd].subGrupo = subgroupFisico.general_data.name + ' ' + subgroupFisico.general_data.firstName + ' ' + subgroupFisico.general_data.lastName;
          this.cargaDataFromClient();
          // this.esValido = true;
          // this.presenta = true;
          this.modalRefNewClient.hide();
          return;

      }
    } else {
        this.modalRefNewClient.hide();
    }
  }

  onChangeTipo(event: any) {
    localStorage.setItem('clientNew', 'SI');
//    localStorage.setItem('esClienteFisico', 'SI');
    this.tipoCliente = event;
    console.log('tipo cliente', this.tipoCliente);
    switch (this.tipoCliente) {
      case 1 :
        localStorage.setItem('selectTypeContact', this.tipoCliente.toString());
        localStorage.setItem('esClienteFisico', 'NO');
        return;
      case 2 :
        localStorage.setItem('selectTypeContact', this.tipoCliente.toString());
        return;
      case 3 :
        localStorage.setItem('selectTypeContact', this.tipoCliente.toString());
        localStorage.setItem('esClienteFisico', 'SI');
        return;

    }
  }

  downloadLayout() {
    this.toastr.info('DESCARGANDO LAYOUT PARA CARGA MASIVA DE PÓLIZAS', 'NOTIFICACIÓN');
    this.downloadLayoutPolicy(26);
  }


  downloadLayoutPolicy(id) {
    this.spinner.show('sp');
    this.api.getLayout()
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (id === 26) {
              downloadLink.setAttribute('download',  'LAYOUT_CARGA_MASIVA_POLIZAS.xlsx');
            }

            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          console.log(error);
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.fileUploadXlsx = null;
    } else {
      this.SendPolicy();
    }
  }

  SendPolicy() {
    if (this.fileUploadXlsx === undefined ) {
      this.toastr.warning('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACIÓN');
    } else {
      const formData = new FormData();
      formData.append('file', this.fileUpload);
      const formDataXlsx = new FormData();
      formDataXlsx.append('fileXlsx', this.fileUploadXlsx);

      console.log(formDataXlsx);
    }
  }

  readZip(e: any, event: any) {
    this.archivosZip = [];
    this.filesZip = [];

    JSZip.loadAsync(e)
      .then(zip => {
        console.log(zip);
        zip.forEach((relativePath, file) => {
          console.log('nombre del archivo: ', relativePath);
          this.archivosZip.push(relativePath);
        });
        if (!this.validaZip(event)) {
          this.spinner.hide('sp');
          return;
        }
        Object.keys(zip.files).forEach((filename) => {
          zip.files[filename].async('base64').then((fileData) => {
            this.filesZip[filename] = fileData;
            const data = [];
            let infoTmp;
            let index = 0;
            let isFisico = false;
            for (const info of this.infoPolicyFisico) {
              if (!isFisico) {
                if (info.polizaMaestra.toUpperCase() === filename.toUpperCase()) {
                  info.archivoPolizaMaestra = fileData;
                  infoTmp = info;
                  data.push(info);
                  isFisico = true;
                } else {
                  index++;
                }
              }
            }
            let isMoral = false;
            if (!isFisico) {
              index = 0;
              for (const info of this.infoPolicyMoral) {
                if (!isMoral) {
                  if (info.polizaMaestra.toUpperCase() === filename.toUpperCase()) {
                    info.archivoPolizaMaestra = fileData;
                    infoTmp = info;
                    data.push(info);
                    isMoral = true;
                  } else {
                    index++;
                  }
                }
              }
            }
            localStorage.setItem('archivosZip', JSON.stringify(this.archivosZip));
            localStorage.setItem('filesZip', JSON.stringify(this.filesZip));
            this.api.updateMassivePolicy(data)
              .subscribe(
                (response: any) => {
                  // infoTmp = response;
                  console.log('IDCARGA DEL UPDATE en validacion de archivos: ', this.idCarga.idCarga);
                  // this.infoPolicyFisico = tmp.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
                  // this.infoPolicyMoral = tmp.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');
                  localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
                  localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
                  if (isFisico) {
                    this.infoPolicyFisico[index].pathPoliza = response[0].pathPoliza;
                  } else if (isMoral) {
                    this.infoPolicyMoral[index].pathPoliza = response[0].pathPoliza;
                  }
                  this.spinner.hide('sp');
                }, error => {
                  this.toastr.warning(error.error.toUpperCase());
                  this.spinner.hide('sp');
                }
              );

            });
          });
        for (const numero of this.archivosZip) {
          console.log('ARREGLO ZIP: ', numero);
        }
        this.spinner.hide('sp');
        console.log('tamaño del arreglo zip: ', this.archivosZip.length);
        console.log('tamaño del arreglo xls: ', this.polizasMaestras.length);
        }, error => {
        this.spinner.hide('sp');
          this.toastr.warning(error.error.toUpperCase());

        }
      );
  }

  onFileSelected(file) {
    console.log('file selected');
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileContent = reader.result as string;
    console.log('file content');
      console.log(fileContent);
    };

    // reader.readAsText(file);
  }
  fileUpZip(event: any) {
    const selectedFile = event.target.files[0];
    const filePath = event.target.value;
    this.readZip(selectedFile, event);

  }

  onChangeInputFileZip(e: any): void{
    this.spinner.show('sp');
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.spinner.hide('sp');
    } else {
      if (this.infoPolicy.length === 0 && this.infoPolicyFisico.length === 0 && this.infoPolicyMoral.length === 0) {
        this.archivosZip = null;
        const file = e.target.files[0];
        e.target.value = '';
          this.toastr.info('CARGUE PRIMERO EL LAYOUT DE LA CARGA MASIVA DE PÓLIZAS', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
      } else {
        this.fileUpZip(e);
      }
    }
  }

  validaZip(e: any) {

    for (const fzip of this.archivosZip) {
      if (!this.validarCamposCommandInjection(fzip.toUpperCase())) {
        this.toastr.error('EL ARCHIVO .ZIP, CONTIENE ARCHIVOS QUE PUEDEN AFECTAR LA INTEGRIDAD DE LA BASE DE DATOS, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
        this.archivosZip = null;
        const file = e.target.files[0];
        e.target.value = '';
        return false;
      }
    }

    console.log(this.polizasMaestras);
    console.log(this.archivosZip);
    if (this.polizasMaestras.length !== this.archivosZip.length) {
      // tslint:disable-next-line:max-line-length
      this.toastr.error('EL NÚMERO DE ARCHIVOS CONTENIDOS EN EL .ZIP, NO CORRESPONDEN AL NÚMERO CONTENIDOS EN LA PLANTILLA, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
      this.archivosZip = null;
      const file = e.target.files[0];
      e.target.value = '';
      return false;
    }

    let contador = 0;
    for (const fxlsx of this.polizasMaestras) {
       for (const fzip of this.archivosZip) {
         if (fxlsx.toUpperCase() === fzip.toUpperCase() ) {
           contador++;
         }
       }
    }
    if (this.polizasMaestras.length !== contador) {
       // tslint:disable-next-line:max-line-length
       this.toastr.error('LOS ARCHIVOS CONTENIDOS EN EL .ZIP, NO CORRESPONDEN A LOS DESCRITOS EN LA PLANTILLA, VERIFIQUE SUS ARCHIVOS', 'NOTIFICACIÓN');
      this.archivosZip = null;
      const file = e.target.files[0];
      e.target.value = '';
       return false;
     }

    this.toastr.success('EL ARCHIVO .ZIP SE CARGO CORRECTAMENTE', 'NOTIFICACIÓN');
    const file = e.target.files[0];
    e.target.value = '';
    return true;
  }

  validaExcel(excel: any) {

    if (excel.length === 0) {
      this.toastr.error('LA PLANTILLA NO CONTIENE INFORMACIÓN', 'NOTIFICACIÓN');
      return false;
    }

    for (const columna of excel) {
      if (columna.TIPO_PERSONA === '' || columna.TIPO_PERSONA === undefined) {
        this.toastr.error('LA COLUMNA TIPO PERSONA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.TIPO_PERSONA !== 'FISICA' && columna.TIPO_PERSONA !== 'MORAL') {
        this.toastr.error('LA COLUMNA TIPO PERSONA, SOLO ACEPTA 2 VALORES: FISICA O MORAL', 'NOTIFICACIÓN');
        return false;
        break;
      }
    }
    const numeroPoliza: any[] = [];
    for (const columna of excel) {
      if (columna.NUMERO === '' || columna.NUMERO === undefined) {
        this.toastr.error('LA COLUMNA NÚMERO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (!this.validarCamposSqlInjection(columna.NUMERO)) {
        this.toastr.error('LA COLUMNA NÚMERO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.NUMERO)) {
        this.toastr.error('LA COLUMNA NÚMERO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.NUMERO)) {
        this.toastr.error('LA COLUMNA NÚMERO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      numeroPoliza.push(columna.NUMERO);
   }

    if (this.encontrarDuplicados(numeroPoliza)) {
      this.toastr.error('LA COLUMNA NÚMERO, NO DEBE TENER INFORMACIÓN DUPLICADA', 'NOTIFICACIÓN');
      return false;
    }

    for (const columna of excel) {
      if (columna.INICIO_VIGENCIA === '' || columna.INICIO_VIGENCIA === undefined) {
        this.toastr.error('LA COLUMNA INICIO_VIGENCIA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (!this.validarFormatoFecha(columna.INICIO_VIGENCIA)) {
        this.toastr.error('LA COLUMNA INICIO_VIGENCIA, DEBE TENER UN FORMATO VÁLIDO DE FECHA: "AAAA-MM-DD"', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
        if (!this.validarFecha(columna.INICIO_VIGENCIA)) {
          this.toastr.error('LA COLUMNA INICIO_VIGENCIA, DEBE TENER FECHAS VÁLIDAS', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }

      if (!this.validarCamposSqlInjection(columna.INICIO_VIGENCIA)) {
        this.toastr.error('LA COLUMNA INICIO_VIGENCIA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.INICIO_VIGENCIA)) {
        this.toastr.error('LA COLUMNA INICIO_VIGENCIA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }


    }

    for (const columna of excel) {
      if (columna.TERMINO_VIGENCIA === '' || columna.TERMINO_VIGENCIA === undefined) {
        this.toastr.error('LA COLUMNA TERMINO_VIGENCIA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (!this.validarFormatoFecha(columna.TERMINO_VIGENCIA)) {
        this.toastr.error('LA COLUMNA TERMINO_VIGENCIA, DEBE TENER UN FORMATO VÁLIDO DE FECHA: "AAAA-MM-DD"', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
        if (!this.validarFecha(columna.TERMINO_VIGENCIA)) {
          this.toastr.error('LA COLUMNA TERMINO_VIGENCIA, DEBE TENER FECHAS VÁLIDAS', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }

      if (!this.validarCamposSqlInjection(columna.TERMINO_VIGENCIA)) {
        this.toastr.error('LA COLUMNA TERMINO_VIGENCIA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.TERMINO_VIGENCIA)) {
        this.toastr.error('LA COLUMNA TERMINO_VIGENCIA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

    }

    for (const columna of excel) {
      if (this.convertirAFecha(columna.INICIO_VIGENCIA) > this.convertirAFecha(columna.TERMINO_VIGENCIA)) {
        this.toastr.error('LA FECHA DE INICIO_VIGENCIA NO PUEDE SER MAYOR AL DE LA FECHA TERMINO_VIGENCIA', 'NOTIFICACIÓN');
        return false;
        break;
      }
    }

    const listaRfc: any[] = [];


    for (const columna of excel) {
      // if (columna.RFC_CLIENTE === '' || columna.RFC_CLIENTE === undefined) {
      //   this.toastr.error('LA COLUMNA RFC CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      //   return false;
      //   break;
      // }

      // if (!this.validarRFC(columna.RFC_CLIENTE, columna.TIPO_PERSONA)) {
      //   const cliente =  columna.TIPO_PERSONA === 'FISICA' ? 'FISICO' : columna.TIPO_PERSONA;
      //   this.toastr.error('EL RFC DEL CLIENTE ' + cliente +  ', NO TIENE EL FORMATO CORRECTO, VERIFÍQUELO', 'NOTIFICACIÓN');
      //   return false;
      //   break;
      // }

      listaRfc.push(columna.RFC_CLIENTE);
    }

    // if (this.encontrarDuplicados(listaRfc)) {
    //   this.toastr.error('LA COLUMNA RFC_CLIENTE, NO DEBE TENER INFORMACIÓN DUPLICADA', 'NOTIFICACIÓN');
    //   return false;
    // }


    for (const columna of excel) {
      if (columna.GRUPO === '' || columna.GRUPO === undefined) {
        this.toastr.error('LA COLUMNA GRUPO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.SUB_GRUPO === '' || columna.SUB_GRUPO === undefined) {
        this.toastr.error('LA COLUMNA SUB_GRUPO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.ASEGURADORA === '' || columna.ASEGURADORA === undefined) {
        this.toastr.error('LA COLUMNA ASEGURADORA, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.CLAVE_ASEGURADORA === '' || columna.CLAVE_ASEGURADORA === undefined) {
        this.toastr.error('LA COLUMNA CLAVE_ASEGURADORA, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.RAMO === '' || columna.RAMO === undefined) {
        this.toastr.error('LA COLUMNA RAMO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.SUB_RAMO === '' || columna.SUB_RAMO === undefined) {
        this.toastr.error('LA COLUMNA SUB_RAMO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.GRUPO)) {
        this.toastr.error('LA COLUMNA GRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.SUB_GRUPO)) {
        this.toastr.error('LA COLUMNA SUB_GRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.ASEGURADORA)) {
        this.toastr.error('LA COLUMNA ASEGURADORA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.CLAVE_ASEGURADORA)) {
        this.toastr.error('LA COLUMNA CLAVE_ASEGURADORA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.RAMO)) {
        this.toastr.error('LA COLUMNA RAMO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.SUB_RAMO)) {
        this.toastr.error('LA COLUMNA SUB_RAMO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.GRUPO)) {
        this.toastr.error('LA COLUMNA GRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.SUB_GRUPO)) {
        this.toastr.error('LA COLUMNA SUB_GRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.ASEGURADORA)) {
        this.toastr.error('LA COLUMNA ASEGURADORA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.CLAVE_ASEGURADORA)) {
        this.toastr.error('LA COLUMNA CLAVE_ASEGURADORA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.RAMO)) {
        this.toastr.error('LA COLUMNA RAMO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.SUB_RAMO)) {
        this.toastr.error('LA COLUMNA SUB_RAMO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (!this.validarCaracteresEspeciales(columna.GRUPO)) {
        this.toastr.error('LA COLUMNA GRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.SUB_GRUPO)) {
        this.toastr.error('LA COLUMNA SUB_GRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.ASEGURADORA)) {
        this.toastr.error('LA COLUMNA ASEGURADORA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.CLAVE_ASEGURADORA)) {
        this.toastr.error('LA COLUMNA CLAVE_ASEGURADORA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.RAMO)) {
        this.toastr.error('LA COLUMNA RAMO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.SUB_RAMO)) {
        this.toastr.error('LA COLUMNA SUB_RAMO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

    }

    for (const columna of excel) {
      if (columna.RENOVACION !== '' && columna.RENOVACION  !== undefined) {
        if (columna.RENOVACION !== 'SI') {
          this.toastr.error('LA COLUMNA RENOVACIÓN, SOLO ACEPTA EL VALOR  "SI" O VALOR VACIO', 'NOTIFICACIÓN');
          return false;
          break;
        } else {
          if (columna.POLIZA_ANTERIOR === '' || columna.POLIZA_ANTERIOR === undefined ) {
            this.toastr.error('SI ES RENOVACIÓN, LA COLUMNA PÓLIZA_ANTERIOR DEBE TENER VALOR', 'NOTIFICACIÓN');
            return false;
            break;
          }
        }
      }
      if (!this.validarCamposSqlInjection(columna.POLIZA_ANTERIOR)) {
        this.toastr.error('LA COLUMNA PÓLIZA_ANTERIOR, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.POLIZA_ANTERIOR)) {
        this.toastr.error('LA COLUMNA PÓLIZA_ANTERIOR, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.POLIZA_ANTERIOR)) {
        this.toastr.error('LA COLUMNA PÓLIZA_ANTERIOR, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

    }

    for (const columna of excel) {
      if (columna.POLIZA_ANTERIOR !== '' && columna.POLIZA_ANTERIOR  !== undefined) {
        if (columna.RENOVACION === '' || columna.RENOVACION === undefined) {
          this.toastr.error('SI TIENE PÓLIZA ANTERIOR, ENTONCES LA COLUMNA RENOVACIÓN, DEBE TENER EL VALOR  "SI"', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }
    }

    for (const columna of excel) {
      if (columna.VENDEDOR === '' || columna.VENDEDOR === undefined) {
        this.toastr.error('LA COLUMNA VENDEDOR, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.COORDINADOR === '' || columna.COORDINADOR === undefined) {
        this.toastr.error('LA COLUMNA COORDINADOR, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.GERENTE_SINIESTROS === '' || columna.GERENTE_SINIESTROS === undefined) {
        this.toastr.error('LA COLUMNA GERENTE_SINIESTROS, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.EJECUTIVO_SUREXS === '' || columna.EJECUTIVO_SUREXS === undefined) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SUREXS, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.EJECUTIVO_SINIESTRO === '' || columna.EJECUTIVO_SINIESTRO === undefined) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SINIESTRO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.MONEDA === '' || columna.MONEDA === undefined) {
        this.toastr.error('LA COLUMNA MONEDA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.TIPO_PAGO === '' || columna.TIPO_PAGO === undefined) {
        this.toastr.error('LA COLUMNA TIPO_PAGO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.TIPO_COBRANZA === '' || columna.TIPO_COBRANZA === undefined) {
        this.toastr.error('LA COLUMNA TIPO_COBRANZA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.OFICINA === '' || columna.OFICINA === undefined) {
        this.toastr.error('LA COLUMNA OFICINA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.VENDEDOR)) {
        this.toastr.error('LA COLUMNA VENDEDOR, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.VENDEDOR)) {
        this.toastr.error('LA COLUMNA VENDEDOR, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.COORDINADOR)) {
        this.toastr.error('LA COLUMNA COORDINADOR, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.COORDINADOR)) {
        this.toastr.error('LA COLUMNA COORDINADOR, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.GERENTE_SINIESTROS)) {
        this.toastr.error('LA COLUMNA GERENTE_SINIESTROS, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.GERENTE_SINIESTROS)) {
        this.toastr.error('LA COLUMNA GERENTE_SINIESTROS, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.EJECUTIVO_SUREXS)) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SUREXS, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.EJECUTIVO_SUREXS)) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SUREXS, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.EJECUTIVO_SINIESTRO)) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SINIESTRO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.EJECUTIVO_SINIESTRO)) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SINIESTRO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.MONEDA)) {
        this.toastr.error('LA COLUMNA MONEDA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.MONEDA)) {
        this.toastr.error('LA COLUMNA MONEDA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.TIPO_PAGO)) {
        this.toastr.error('LA COLUMNA TIPO_PAGO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.TIPO_PAGO)) {
        this.toastr.error('LA COLUMNA TIPO_PAGO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.TIPO_COBRANZA)) {
        this.toastr.error('LA COLUMNA TIPO_COBRANZA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.TIPO_COBRANZA)) {
        this.toastr.error('LA COLUMNA TIPO_COBRANZA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposSqlInjection(columna.OFICINA)) {
        this.toastr.error('LA COLUMNA OFICINA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCamposCommandInjection(columna.OFICINA)) {
        this.toastr.error('LA COLUMNA OFICINA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.VENDEDOR)) {
        this.toastr.error('LA COLUMNA VENDEDOR, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.COORDINADOR)) {
        this.toastr.error('LA COLUMNA PÓLIZA_ANTERIOR, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.GERENTE_SINIESTROS)) {
        this.toastr.error('LA COLUMNA GERENTE_SINIESTROS, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.EJECUTIVO_SUREXS)) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SUREXS, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.EJECUTIVO_SINIESTRO)) {
        this.toastr.error('LA COLUMNA EJECUTIVO_SINIESTRO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.MONEDA)) {
        this.toastr.error('LA COLUMNA MONEDA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.TIPO_PAGO)) {
        this.toastr.error('LA COLUMNA TIPO_PAGO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.TIPO_COBRANZA)) {
        this.toastr.error('LA COLUMNA TIPO_COBRANZA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarCaracteresEspeciales(columna.OFICINA)) {
        this.toastr.error('LA COLUMNA OFICINA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
        return false;
        break;
      }
    }

    const polizaMaestra: any[] = [];
    for (const columna of excel) {
      if (columna.NOMBRE_ARCHIVO_POLIZA === '' || columna.NOMBRE_ARCHIVO_POLIZA === undefined) {
        this.toastr.error('LA COLUMNA NOMBRE_ARCHIVO_POLIZA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (!this.validarNombreArchivoPDF(columna.NOMBRE_ARCHIVO_POLIZA)) {
        this.toastr.error('EN LA COLUMNOMBRE_ARCHIVO_POLIZA, ESCRIBA UN NOMBRE DE ARCHIVO CORRECTO, CON EXTENSION .PDF', 'NOTIFICACIÓN');
        return false;
        break;
      }

      polizaMaestra.push(columna.NOMBRE_ARCHIVO_POLIZA);
    }

    if (this.encontrarDuplicados(polizaMaestra)) {
      this.toastr.error('LA COLUMNA NOMBRE_ARCHIVO_POLIZA, NO DEBE TENER INFORMACIÓN DUPLICADA', 'NOTIFICACIÓN');
      return false;
    }

    for (const columna of excel) {
      if (columna.PRIMA_NETA === '' || columna.PRIMA_NETA === undefined) {
        this.toastr.error('LA COLUMNA PRIMA_NETA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.DERECHOS === '' || columna.DERECHOS === undefined) {
        this.toastr.error('LA COLUMNA DERECHOS, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.PORC_RECARGO === '' || columna.PORC_RECARGO === undefined) {
        this.toastr.error('LA COLUMNA PORC_RECARGO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.PORC_COMISION === '' || columna.PORC_COMISION === undefined) {
        this.toastr.error('LA COLUMNA PORC_COMISION, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }
      if (columna.IVA === '' || columna.IVA === undefined) {
        this.toastr.error('LA COLUMNA IVA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

      if (columna.PRIMA_TOTAL === '' || columna.PRIMA_TOTAL === undefined) {
        this.toastr.error('LA COLUMNA PRIMA_TOTAL, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
        return false;
        break;
      }

    }

    for (const columna of excel) {
      if (!this.validarValorNumerico(columna.PRIMA_NETA)) {
        this.toastr.error('LA COLUMNA PRIMA_NETA, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
        if (columna.PRIMA_NETA === 0) {
          this.toastr.error('LA COLUMNA PRIMA_NETA, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }

      if (!this.validarValorNumerico(columna.DERECHOS)) {
        this.toastr.error('LA COLUMNA DERECHOS, DESOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
       /* if (columna.DERECHOS === 0) {
          this.toastr.error('LA COLUMNA DERECHOS, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
          return false;
          break;
        } */
      }

      if (!this.validarValorNumerico(columna.PORC_RECARGO)) {
        this.toastr.error('LA COLUMNA PORC_RECARGO, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
        if (columna.PORC_RECARGO < 0 || columna.PORC_RECARGO > 100) {
          this.toastr.error('LA COLUMNA PORC_RECARGO, DEBE SER MAYOR O IGUAL A CERO Y MENOR O IGUAL A 100', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }
      if (!this.validarValorNumerico(columna.PORC_COMISION)) {
        this.toastr.error('LA COLUMNA PORC_COMISION, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
        if (columna.PORC_COMISION < 1 || columna.PORC_COMISION > 100) {
          this.toastr.error('LA COLUMNA PORC_COMISION, DEBE SER MAYOR A CERO Y MENOR O IGUAL A 100', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }
      if (!this.validarValorNumerico(columna.IVA)) {
        this.toastr.error('LA COLUMNA IVA, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
       /* if (columna.IVA === 0) {
          this.toastr.error('LA COLUMNA IVA, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
          return false;
          break;
        } */
      }

      if (!this.validarValorNumerico(columna.PRIMA_TOTAL)) {
        this.toastr.error('LA COLUMNA PRIMA_TOTAL, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
        return false;
        break;
      } else {
        if (columna.PRIMA_TOTAL === 0) {
          this.toastr.error('LA COLUMNA PRIMA_TOTAL, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
          return false;
          break;
        }
      }

    }

    return true;
  }

  validaExcelEdicion(excel: any) {

    if (excel.idSubGrupo === '' || excel.idSubGrupo === undefined || excel.idSubGrupo === null) {
      this.toastr.error('EL CAMPO SUBGRUPO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;
    }

    if (excel.numero === '' || excel.numero === undefined) {
      this.toastr.error('EL CAMPO NÚMERO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }
    if (!this.validarCamposSqlInjection(excel.numero)) {
      this.toastr.error('EL CAMPO NÚMERO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.numero)) {
      this.toastr.error('EL CAMPO NÚMERO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCaracteresEspeciales(excel.numero)) {
      this.toastr.error('EL CAMPO NÚMERO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }


    if (excel.inicioVigencia === '' || excel.inicioVigencia === undefined) {
      this.toastr.error('EL CAMPO INICIO VIGENCIA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }
    if (!this.validarFormatoFecha(excel.inicioVigencia)) {
      this.toastr.error('EL CAMPO INICIO VIGENCIA, DEBE TENER UN FORMATO VÁLIDO DE FECHA: "AAAA-MM-DD"', 'NOTIFICACIÓN');
      return false;

    } else {
      if (!this.validarFecha(excel.inicioVigencia)) {
        this.toastr.error('EL CAMPO INICIO VIGENCIA, DEBE TENER FECHAS VÁLIDAS', 'NOTIFICACIÓN');
        return false;

      }
    }

    if (!this.validarCamposSqlInjection(excel.inicioVigencia)) {
      this.toastr.error('EL CAMPO INICIO VIGENCIA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.inicioVigencia)) {
      this.toastr.error('EL CAMPO INICIO VIGENCIA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }


    if (excel.terminoVigencia === '' || excel.terminoVigencia === undefined) {
      this.toastr.error('EL CAMPO TERMINO VIGENCIA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }
    if (!this.validarFormatoFecha(excel.terminoVigencia)) {
      this.toastr.error('EL CAMPO TERMINO VIGENCIA, DEBE TENER UN FORMATO VÁLIDO DE FECHA: "AAAA-MM-DD"', 'NOTIFICACIÓN');
      return false;

    } else {
      if (!this.validarFecha(excel.terminoVigencia)) {
        this.toastr.error('EL CAMPO TERMINO VIGENCIA, DEBE TENER FECHAS VÁLIDAS', 'NOTIFICACIÓN');
        return false;

      }
    }

    if (!this.validarCamposSqlInjection(excel.terminoVigencia)) {
      this.toastr.error('EL CAMPO TERMINO VIGENCIA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.terminoVigencia)) {
      this.toastr.error('EL CAMPO TERMINO VIGENCIA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (this.convertirAFecha(excel.inicioVigencia) > this.convertirAFecha(excel.terminoVigencia)) {
      this.toastr.error('LA FECHA DE INICIO_VIGENCIA NO PUEDE SER MAYOR AL DE LA FECHA TERMINO_VIGENCIA', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.rfcCliente === '' || excel.rfcCliente === undefined) {
      this.toastr.error('EL CAMPO RFC CLIENTE, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;
    }


    // if (!this.validarRFC(excel.rfcCliente, excel.tipoPersona)) {
    //   this.toastr.error('EL RFC DEL CLIENTE, NO TIENE EL FORMATO CORRECTO, VERIFIQUELO', 'NOTIFICACIÓN');
    //   return false;

    // }


    if (excel.grupo === '' || excel.grupo === undefined) {
      this.toastr.error('EL CAMPO GRUPO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.subGrupo === '' || excel.subGrupo === undefined) {
      this.toastr.error('EL CAMPO SUBGRUPO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    console.log(excel);
    if (excel.aseguradora === '' || excel.aseguradora === undefined) {
      this.toastr.error('EL CAMPO ASEGURADORA, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idAseguradora === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA ASEGURADORA', 'NOTIFICACIÓN');
      return false;

    }
    if (excel.claveAseguradora === '' || excel.claveAseguradora === undefined) {
      this.toastr.error('EL CAMPO CLAVE ASEGURADORA, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idClaveAseguradora === -1) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA CLAVE DE ASEGURADORA', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.ramo === '' || excel.ramo === undefined) {
      this.toastr.error('EL CAMPO RAMO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idRamo === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA RAMO', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.subRamo === '' || excel.subRamo === undefined) {
      this.toastr.error('EL CAMPO SUB RAMO, DEBE DE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idSubRamo === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA SUBRAMO', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.grupo)) {
      this.toastr.error('EL CAMPO GRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.subGrupo)) {
      this.toastr.error('EL CAMPO SUBGRUPO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.aseguradora)) {
      this.toastr.error('EL CAMPO ASEGURADORA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.claveAseguradora)) {
      this.toastr.error('EL CAMPO CLAVE ASEGURADORA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.ramo)) {
      this.toastr.error('EL CAMPO RAMO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.subRamo)) {
      this.toastr.error('EL CAMPO SUB RAMO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.grupo)) {
      this.toastr.error('EL CAMPO GRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.subGrupo)) {
      this.toastr.error('EL CAMPO SUBGRUPO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.aseguradora)) {
      this.toastr.error('EL CAMPO ASEGURADORA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.claveAseguradora)) {
      this.toastr.error('EL CAMPO CLAVE ASEGURADORA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.ramo)) {
      this.toastr.error('EL CAMPO RAMO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.subRamo)) {
      this.toastr.error('EL CAMPO SUB RAMO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCaracteresEspeciales(excel.grupo)) {
      this.toastr.error('EL CAMPO GRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }

    if (!this.validarCaracteresEspeciales(excel.subGrupo)) {
      this.toastr.error('EL CAMPO SUBGRUPO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }

    if (!this.validarCaracteresEspeciales(excel.aseguradora)) {
      this.toastr.error('EL CAMPO ASEGURADORA, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }

    if (!this.validarCaracteresEspeciales(excel.claveAseguradora)) {
      this.toastr.error('EL CAMPO CLAVE ASEGURADORA CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }

    if (!this.validarCaracteresEspeciales(excel.ramo)) {
      this.toastr.error('EL CAMPO RAMO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }

    if (!this.validarCaracteresEspeciales(excel.subRamo)) {
      this.toastr.error('EL CAMPO SUBRAMO, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }


  if (excel.renovacion === null || excel.renovacion === '  ') {
    excel.renovacion = '';
  }
    if (excel.renovacion !== '' && excel.renovacion  !== undefined) {
      if (excel.renovacion !== 'SI') {
        this.toastr.error('EL CAMPO RENOVACIÓN, SOLO ACEPTA EL VALOR  "SI" O VALOR VACIO', 'NOTIFICACIÓN');
        return false;

      } else {
        if (excel.polizaAnterior === '' || excel.polizaAnterior === undefined ) {
          this.toastr.error('SI ES RENOVACIÓN, EL CAMPO PÓLIZA ANTERIOR DEBE TENER VALOR', 'NOTIFICACIÓN');
          return false;

        }
      }
    }
    if (!this.validarCamposSqlInjection(excel.polizaAnterior)) {
      this.toastr.error('EL CAMPO PÓLIZA ANTERIOR, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.polizaAnterior)) {
      this.toastr.error('EL CAMPO PÓLIZA ANTERIOR, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCaracteresEspeciales(excel.polizaAnterior)) {
      this.toastr.error('EL CAMPO PÓLIZA ANTERIOR, CONTIENE CARACTERES NO PERMITIDOS', 'NOTIFICACIÓN');
      return false;
    }

    if (excel.polizaAnterior === null) {
      excel.polizaAnterior = '';
    }

    if (excel.polizaAnterior !== '' && excel.polizaAnterior  !== undefined) {
      if (excel.renovacion === '' || excel.renovacion === undefined) {
        this.toastr.error('SI TIENE PÓLIZA ANTERIOR, ENTONCES EL CAMPO RENOVACIÓN, DEBE TENER EL VALOR  "SI"', 'NOTIFICACIÓN');
        return false;

      }
    }

    if (excel.vendedor === '' || excel.vendedor === undefined) {
      this.toastr.error('EL CAMPO VENDEDOR, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idVendedor === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA VENDEDOR', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.coordinador === '' || excel.coordinador === undefined) {
      this.toastr.error('EL CAMPO COORDINADOR, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idCoordinador === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA COORDINADOR', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.gerenteSiniestro === '' || excel.gerenteSiniestro === undefined) {
      this.toastr.error('EL CAMPO GERENTE SINIESTRO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idGerenteSiniestro === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA GERENTE DE SINIESTRO', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.ejecutivoSurexs === '' || excel.ejecutivoSurexs === undefined) {
      this.toastr.error('EL CAMPO EJECUTIVO SUREXS, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idEjecutivoSurexs === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA EJECUTIVO SUREXS', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.ejecutivoSiniestro === '' || excel.ejecutivoSiniestro === undefined) {
      this.toastr.error('EL CAMPO EJECUTIVO SINIESTRO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idEjecutivoSiniestro === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA EJECUTIVO SINIESTRO', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.moneda === '' || excel.moneda === undefined) {
      this.toastr.error('EL CAMPO MONEDA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idMoneda === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA EL TIPO DE MONEDA', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.tipoPago === '' || excel.tipoPago === undefined) {
      this.toastr.error('EL CAMPO TIPO DE PAGO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idTipoPago === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA EL TIPO DE PAGO', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.tipoCobranza === '' || excel.tipoCobranza === undefined) {
      this.toastr.error('EL CAMPO TIPO DE COBRANZA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idTipoCobranza === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA EL TIPO DE COBRANZA', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.oficina === '' || excel.oficina === undefined) {
      this.toastr.error('EL CAMPO OFICINA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.idOficina === 0) {
      this.toastr.error('SELECCIONE UN VALOR VÁLIDO PARA OFICINA', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.vendedor)) {
      this.toastr.error('EL CAMPO VENDEDOR, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.vendedor)) {
      this.toastr.error('EL CAMPO VENDEDOR, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.coordinador)) {
      this.toastr.error('EL CAMPO COORDINADOR, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.coordinador)) {
      this.toastr.error('EL CAMPO COORDINADOR, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.gerenteSiniestro)) {
      this.toastr.error('EL CAMPO GERENTE SINIESTRO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.gerenteSiniestro)) {
      this.toastr.error('EL CAMPO GERENTE SINIESTRO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.ejecutivoSurexs)) {
      this.toastr.error('EL CAMPO EJECUTIVO SUREXS, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.ejecutivoSurexs)) {
      this.toastr.error('EL CAMPO EJECUTIVO SUREXS, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.ejecutivoSiniestro)) {
      this.toastr.error('EL CAMPO EJECUTIVO SINIESTRO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.ejecutivoSiniestro)) {
      this.toastr.error('EL CAMPO EJECUTIVO SINIESTRO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.moneda)) {
      this.toastr.error('EL CAMPO MONEDA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.moneda)) {
      this.toastr.error('EL CAMPO MONEDA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.tipoPago)) {
      this.toastr.error('EL CAMPO TIPO DE PAGO, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.tipoPago)) {
      this.toastr.error('EL CAMPO TIPO DE PAGO, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.tipoCobranza)) {
      this.toastr.error('EL CAMPO TIPO DE COBRANZA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.tipoCobranza)) {
      this.toastr.error('EL CAMPO TIPO DE COBRANZA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposSqlInjection(excel.oficina)) {
      this.toastr.error('EL CAMPO OFICINA, CONTIENE VALORES QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarCamposCommandInjection(excel.oficina)) {
      this.toastr.error('EL CAMPO OFICINA, CONTIENE COMANDOS QUE AFECTARAN LA INTEGRIDAD DE BASE DE DATOS', 'NOTIFICACIÓN');
      return false;

    }


    if (excel.polizaMaestra === '' || excel.polizaMaestra === undefined) {
      this.toastr.error('EL CAMPO PÓLIZA MAESTRA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (!this.validarNombreArchivoPDF(excel.polizaMaestra)) {
      this.toastr.error('EN EL CAMPO PÓLIZA MAESTRA, ESCRIBA UN NOMBRE DE ARCHIVO CORRECTO, CON EXTENSION .PDF', 'NOTIFICACIÓN');
      return false;

    }


    if (excel.primaNeta === '' || excel.primaNeta === undefined) {
      this.toastr.error('EL CAMPO PRIMA NETA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.derechos === '' || excel.derechos === undefined) {
      this.toastr.error('EL CAMPO DERECHOS, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.porcRecargo === '' || excel.porcRecargo === undefined) {
      this.toastr.error('EL CAMPO % RECARGO, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }
    if (excel.porcComision === '' || excel.porcComision === undefined) {
      this.toastr.error('EL CAMPO % COMISION, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }
    if (excel.iva === '' || excel.iva === undefined) {
      this.toastr.error('EL CAMPO IVA, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }

    if (excel.primaTotal === '' || excel.primaTotal === undefined) {
      this.toastr.error('EL CAMPO PRIMA TOTAL, DEBE TENER INFORMACIÓN', 'NOTIFICACIÓN');
      return false;

    }


    if (!this.validarValorNumerico(excel.primaNeta)) {
      this.toastr.error('EL CAMPO PRIMA NETA, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
      return false;

    } else {
      if (excel.primaNeta === 0) {
        this.toastr.error('EL CAMPO PRIMA NETA, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
        return false;

      }
    }

    if (!this.validarValorNumerico(excel.derechos)) {
      this.toastr.error('EL CAMPO DERECHOS, DESOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
      return false;

    } else {
     /* if (excel.derechos === 0) {
        this.toastr.error('EL CAMPO DERECHOS, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
        return false;

      } */
    }

    if (!this.validarValorNumerico(excel.porcRecargo)) {
      this.toastr.error('EL CAMPO % RECARGO, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
      return false;

    } else {
      if (excel.porcRecargo < 0 || excel.porcRecargo > 100) {
        this.toastr.error('EL CAMPO % RECARGO, DEBE SER MAYOR O IGUAL A CERO Y MENOR O IGUAL A 100', 'NOTIFICACIÓN');
        return false;

      }
    }
    if (!this.validarValorNumerico(excel.porcComision)) {
      this.toastr.error('EL CAMPO % COMISION, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
      return false;

    } else {
      if (excel.porcComision < 1 || excel.porcComision > 100) {
        this.toastr.error('EL CAMPO % COMISION, DEBE SER MAYOR A CERO Y MENOR O IGUAL A 100', 'NOTIFICACIÓN');
        return false;

      }
    }
    if (!this.validarValorNumerico(excel.iva)) {
      this.toastr.error('EL CAMPO IVA, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
      return false;

    } else {
    /*  if (excel.iva === 0) {
        this.toastr.error('EL CAMPO IVA, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
        return false;

      } */
    }

    if (!this.validarValorNumerico(excel.primaTotal)) {
      this.toastr.error('EL CAMPO PRIMA TOTAL, SOLO ACEPTA VALORES NUMÉRICOS', 'NOTIFICACIÓN');
      return false;
    } else {
      if (excel.primaTotal === 0) {
        this.toastr.error('EL CAMPO PRIMA TOTAL, DEBE SER MAYOR A CERO', 'NOTIFICACIÓN');
        return false;
      }
    }

    return true;

  }
   validarRFC(rfc: string, tipo: string): boolean {
      let re: any;
      if (tipo === 'FISICA') {
        re = /^[A-Z&Ñ]{4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/;
    } else {
       re = /^[A-Z&Ñ]{3}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/;
    }
    // const re = /^[A-ZÑ&]{3,4}\d{6}[A-V1-9][0-9A-HJ-NP-TV-Z]{2}$/;
      return re.test(rfc);
  }

   validarNombreArchivoPDF(nombreArchivo: string): boolean {
    const re = /^[a-zA-Z0-9_\-]+\.(pdf|PDF)$/;
    return re.test(nombreArchivo);
  }

   encontrarDuplicados(arr: any[]): boolean {
    const duplicados = [];
    const valoresUnicos = new Set();

    for (const valor of arr) {
       if (valoresUnicos.has(valor)) {
         duplicados.push(valor);
       } else {
         valoresUnicos.add(valor);
       }
     }
    if (duplicados.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  validarValorNumerico(valor: any): boolean {
    const regex = /^[0-9]+(\.[0-9]+)?$/;

    if (regex.test(valor)) {
      const numberValue = parseFloat(valor);

      console.log("El número es:", numberValue);
      return true;
    } else {
      console.log("La cadena de texto no es un número válido.");
      return false;
    }

  //  return typeof valor === 'number';
  }

  validarFormatoFecha(fecha: string): boolean {
    const re = /^\d{4}-\d{2}-\d{2}$/;
    return re.test(fecha);
  }

  validarFecha(fecha: string): boolean {
    const partes = fecha.split('-');
    const year = parseInt(partes[0]);
    const month = parseInt(partes[1]);
    const day = parseInt(partes[2]);


    const fechaObjeto = new Date(year, month - 1, day);
    return fechaObjeto.getFullYear() === year && fechaObjeto.getMonth() + 1 === month && fechaObjeto.getDate() === day;
  }

   convertirAFecha(fechaStr: string): Date {
    const partes = fechaStr.split('-');
    const year = parseInt(partes[0]);
    const month = parseInt(partes[1]);
    const day = parseInt(partes[2]);

    const fecha = new Date(year, month - 1, day);
    return fecha;
  }

  convertToObjectToJson(formattedDate: any, formattedTime: any) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.infoPolicy.length; i++) {
      this.infoPolicy[i].fecha = formattedDate + ' ' + formattedTime;
      this.infoPolicy[i].idUsuario = localStorage.getItem('user-login-id');
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.infoPolicy.length; i++) {
      this.infoPolicy[i].tipoPersona = this.infoPolicy[i].TIPO_PERSONA;
      this.infoPolicy[i].numero = this.infoPolicy[i].NUMERO;
      this.infoPolicy[i].inicioVigencia = this.infoPolicy[i].INICIO_VIGENCIA;
      this.infoPolicy[i].terminoVigencia = this.infoPolicy[i].TERMINO_VIGENCIA;
      this.infoPolicy[i].rfcCliente = this.infoPolicy[i].RFC_CLIENTE;
      this.infoPolicy[i].grupo = this.infoPolicy[i].GRUPO;
      this.infoPolicy[i].subGrupo = this.infoPolicy[i].SUB_GRUPO;
      this.infoPolicy[i].aseguradora = this.infoPolicy[i].ASEGURADORA;
      // tslint:disable-next-line:max-line-length
      this.infoPolicy[i].claveAseguradora = this.infoPolicy[i].CLAVE_ASEGURADORA;
      this.infoPolicy[i].ramo = this.infoPolicy[i].RAMO;
      this.infoPolicy[i].subRamo = this.infoPolicy[i].SUB_RAMO;
      this.infoPolicy[i].renovacion = this.infoPolicy[i].RENOVACION;
      this.infoPolicy[i].esRenovacion = (this.infoPolicy[i].RENOVACION === 'SI') ? true: false;
      this.infoPolicy[i].polizaAnterior = this.infoPolicy[i].POLIZA_ANTERIOR;
      this.infoPolicy[i].vendedor = this.infoPolicy[i].VENDEDOR;
      this.infoPolicy[i].coordinador = this.infoPolicy[i].COORDINADOR;
      // tslint:disable-next-line:max-line-length
      this.infoPolicy[i].gerenteSiniestro = this.infoPolicy[i].GERENTE_SINIESTROS;
      this.infoPolicy[i].ejecutivoSurexs = this.infoPolicy[i].EJECUTIVO_SUREXS;
      // tslint:disable-next-line:max-line-length
      this.infoPolicy[i].ejecutivoSiniestro = this.infoPolicy[i].EJECUTIVO_SINIESTRO;
      this.infoPolicy[i].moneda = this.infoPolicy[i].MONEDA;
      this.infoPolicy[i].tipoPago = this.infoPolicy[i].TIPO_PAGO;
      this.infoPolicy[i].tipoCobranza = this.infoPolicy[i].TIPO_COBRANZA;
      this.infoPolicy[i].oficina = this.infoPolicy[i].OFICINA;
      this.infoPolicy[i].polizaMaestra = this.infoPolicy[i].NOMBRE_ARCHIVO_POLIZA;
      this.infoPolicy[i].primaNeta = this.infoPolicy[i].PRIMA_NETA;
      this.infoPolicy[i].derechos = this.infoPolicy[i].DERECHOS;
      this.infoPolicy[i].porcRecargo = this.infoPolicy[i].PORC_RECARGO;
      this.infoPolicy[i].porcComision = this.infoPolicy[i].PORC_COMISION;
      this.infoPolicy[i].iva = this.infoPolicy[i].IVA;
      this.infoPolicy[i].primaTotal = this.infoPolicy[i].PRIMA_TOTAL;

      delete this.infoPolicy[i].TIPO_PERSONA;
      delete this.infoPolicy[i].NUMERO;
      delete this.infoPolicy[i].INICIO_VIGENCIA;
      delete this.infoPolicy[i].TERMINO_VIGENCIA;
      delete this.infoPolicy[i].RFC_CLIENTE;
      delete this.infoPolicy[i].GRUPO;
      delete this.infoPolicy[i].SUB_GRUPO;
      delete this.infoPolicy[i].ASEGURADORA;
      delete this.infoPolicy[i].CLAVE_ASEGURADORA;
      delete this.infoPolicy[i].RAMO;
      delete this.infoPolicy[i].SUB_RAMO;
      delete this.infoPolicy[i].RENOVACION;
      delete this.infoPolicy[i].POLIZA_ANTERIOR;
      delete this.infoPolicy[i].VENDEDOR;
      delete this.infoPolicy[i].COORDINADOR;
      delete this.infoPolicy[i].GERENTE_SINIESTROS;
      delete this.infoPolicy[i].EJECUTIVO_SUREXS;
      delete this.infoPolicy[i].EJECUTIVO_SINIESTRO;
      delete this.infoPolicy[i].MONEDA;
      delete this.infoPolicy[i].TIPO_PAGO;
      delete this.infoPolicy[i].TIPO_COBRANZA;
      delete this.infoPolicy[i].OFICINA;
      delete this.infoPolicy[i].NOMBRE_ARCHIVO_POLIZA;
      delete this.infoPolicy[i].PRIMA_NETA;
      delete this.infoPolicy[i].DERECHOS;
      delete this.infoPolicy[i].PORC_RECARGO;
      delete this.infoPolicy[i].PORC_COMISION;
      delete this.infoPolicy[i].IVA;
      delete this.infoPolicy[i].PRIMA_TOTAL;
    }


  }


  cargaTemporal(idCarga: any) {

    this.api.getMassivePolicyTemp(idCarga)
      .then(
        (response: any) => {

          console.log('CARGA INFO TEMPORAL: ', response);

          console.log(response);
          this.api.validateLoadMassivePolicy(response)
            .subscribe(
              datos => {
              let data: any;
              data = datos;


              for (let i = 0; i < data.length; i++) {
                data[i].cargaId = idCarga;
              }
               console.log('datos obtenidos finales de validacion con el ID DE CARGA: ', data);
              // this.infoPolicyFisico = data.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
              this.infoPolicyFisico = data;
              // this.infoPolicyMoral = data.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');
              localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
              localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
              this.spinner.hide('sp');
              this.toastr.success('SU INFORMACIÓN HA SIDO CARGADA CORRECTAMENTE.', 'NOTIFICACIÓN');
             }, error => {

                this.toastr.warning(error.error.toUpperCase());
                console.error(error);
                this.spinner.hide('sp');
              }
            );
        }, error => {
          this.toastr.warning(error.error.toUpperCase());
          console.error(error);
          this.spinner.hide('sp');
        }
      );

  }


  validarCamposSqlInjection(texto: string): boolean {
    console.log('ENTRO A VALIDAR SQL INJECTION', texto);
    const patronSQL = /SELECT|INSERT|UPDATE|DELETE|DROP|UNION|FROM|1=1|EXEC/i;
    if (patronSQL.test(texto)) {
       return false;
    }
    return true;
  }

  validarCamposCommandInjection(texto: string): boolean {
    console.log('ENTRO A VALIDAR COMANDOS INJECTION', texto);
    if (texto !== 'CASH FLOW') {
      const patronSQL = /.BIN|.RUN|.SH|TOUCH|LS|CHMOD|SH|MV/i;
      if (patronSQL.test(texto)) {
        return false;
      }
    }
    return true;
  }

  editPolicyF(template: TemplateRef<any>, policy: Policy) {
      this.editingPolicy = {...policy};
      console.log(this.editingPolicy);
      this.getAgentKeys(this.editingPolicy.idAseguradora);
      // if (!this.editingPolicy.valRamo) {
        // this.editingPolicy.idSubRamo = null;
      // } else {
        this.getSubBranch(this.editingPolicy.idRamo);
      // }

    const data = {name: 'GROUPS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      const tmp = data.map(ob => ({ groupId: ob.id, name: ob.description }));
      let vacio =
        {
          groupId: 0,
          name: 'ELIJA UN VALOR VÁLIDO',
          parent: 0
        };
      tmp.push(vacio);
      this.catGroups = tmp;
      if (this.editingPolicy.idGrupo === null) {
        this.editingPolicy.idGrupo = 0;
      }
      if (this.editingPolicy.idSubGrupo === null) {
        this.editingPolicy.idSubGrupo = 0;
      }
      if (!this.editingPolicy.valGrupo) {
        this.editingPolicy.idSubGrupo = 0;
        const dataSub = [];
        let vacio =
          {
            clientId: 0,
            rfc: '',
            name: 'ELIJA UN VALOR VÁLIDO',
            clientType: 2,
            type: 'MORAL',
            groupId: 0,
            status: false,
            comment: null,
            typeSocietyId: 0
          };
        dataSub.unshift(vacio);
        this.catClients = dataSub;
      } else {
        this.getSubGroups(this.editingPolicy.idGrupo);
      }
      this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    }, error => {

    });
  }


  savePolicyF(tipoCliente: any) {
    if (this.editingPolicy && this.validaExcelEdicion(this.editingPolicy)) {
        if (this.catAgentKeys.length === 1) {
          this.editingPolicy.claveAseguradora = this.catAgentKeys[0].description;
        }

        if (tipoCliente === 1) {
          const index = this.infoPolicyFisico.findIndex((fisico) => fisico.detalleId === this.editingPolicy.detalleId);
          this.infoPolicyFisico[index] = {...this.editingPolicy};
          this.catInsuranceCompany.index = -1;
          this.editingPolicy = null;
        } else {
          const indexM = this.infoPolicyMoral.findIndex((moral) => moral.detalleId === this.editingPolicy.detalleId);
          this.infoPolicyMoral[indexM] = {...this.editingPolicy};
          this.catInsuranceCompany.index = -1;
          this.editingPolicy = null;
        }
        localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
        localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
        console.log(this.infoPolicyFisico);
        this.modalRef.hide();
    }
  }

  editPolicyM(policy: Policy) {
    this.editingPolicyM = { ...policy };
  }


  savePolicyM() {
    if (this.editingPolicyM) {
      const index = this.infoPolicyMoral.findIndex((moral) => moral.detalleId === this.editingPolicyM.detalleId);
      this.infoPolicyMoral[index] = { ...this.editingPolicyM };
      this.editingPolicyM = null;
      localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
      localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
    }
  }

  cancelarEdicion() {
    this.editingPolicy = null;
    this.modalRef.hide();
  }

  validateInfo() {
    this.infoPolicyValidate = [];
    for (let i = 0; i < this.infoPolicyFisico.length; i++) {
      this.infoPolicyFisico[i].cargaId = this.idCarga.idCarga;
    }

    for (let i = 0; i < this.infoPolicyMoral.length; i++) {
      this.infoPolicyMoral[i].cargaId = this.idCarga.idCarga;
    }
    for (const dato of this.infoPolicyFisico) {
      this.infoPolicyValidate.push(dato);
    }

    for (const dato of this.infoPolicyMoral) {
      this.infoPolicyValidate.push(dato);
    }
    if (this.infoPolicyValidate.length === 0) {
      this.toastr.warning('CARGUE EL LAYOUT DE CARGA MASIVA DE PÓLIZAS, PARA PODER VALIDAR LA INFORMACIÓN.', 'NOTIFICACIÓN');
      return;
    }


    console.log('VALIDANDO: ', this.infoPolicyValidate);
    this.policyMasterFiles(this.infoPolicyValidate, 2);

    if (this.polizasMaestras.length > 0) {
      if (this.archivosZip != null) {
        if (this.archivosZip.length === 0) {
          this.toastr.warning('CARGUE EL ARCHIVO .ZIP, CON LOS ARCHIVOS DE LAS PÓLIZAS MAESTRAS, DESCRITAS EN EL LAYOUT.', 'NOTIFICACIÓN');
          return;
        }
      } else {
        this.toastr.warning('CARGUE EL ARCHIVO .ZIP, CON LOS ARCHIVOS DE LOS ANEXOS, DESCRITOS EN EL LAYOUT.', 'NOTIFICACIÓN');
        return;
      }
    }

    this.spinner.show('sp');
    console.log(this.infoPolicyValidate);
    this.api.validateLoadMassivePolicy(this.infoPolicyValidate)
      .subscribe(
        datos => {
          let data: any;
          data = datos;
          console.log('datos finales obtenidos de la validacion, que ENVIO AL UPDATE: ', data);

          // this.api.updateMassivePolicy(data)
          //   .subscribe(
          //     response => {
          //       console.log('IDCARGA DEL UPDATE en validacion de informacion: ', this.idCarga.idCarga);
          //       this.cargaTemporalUpdate(this.idCarga.idCarga);
          //       this.infoPolicyValidate = [];
          //       this.spinner.hide('sp');
          //     }, error => {
          //       this.toastr.warning(error.error.toUpperCase());
          //       this.spinner.hide('sp');
          //     }
          //   );

          // this.infoPolicyFisico = data.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
          this.infoPolicyFisico = data;
          // this.infoPolicyMoral = data.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');
          localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
          localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
          this.procesarInfo(data);
          if (!this.esValidoProcesar) {
            this.toastr.success('SU INFORMACIÓN HA SIDO ACTUALIZADA CORRECTAMENTE.', 'NOTIFICACIÓN');
          } else {
            this.toastr.success('INFORMACIÓN VÁLIDA PARA PROCESAR.', 'NOTIFICACIÓN');
          }
          if (this.modalRef) {
            this.modalRef.hide();
          }
          this.spinner.hide('sp');

        }, error => {
          console.log(error);
          this.toastr.warning(error.error.toUpperCase());
          this.spinner.hide('sp');
        }
      );

  }

  cargaTemporalUpdate(idCarga: any) {

    console.log(idCarga);
    this.api.getMassivePolicyTemp(idCarga)
      .then(
        (response: any) => {

          console.log(response);
          console.log(this.infoPolicyValidate);
          this.api.validateLoadMassivePolicy(response)
            .subscribe(
              datos => {
                let data: any;
                data = datos;
                this.infoPolicyFisico = [];
                this.infoPolicyMoral = [];
                console.log('datos obtenidos en la validacion en validacion de informacion: ', data);
                // this.infoPolicyFisico = data.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
                this.infoPolicyFisico = data;
                // this.infoPolicyMoral = data.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');
                localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
                localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
                this.procesarInfo(data);
                if (!this.esValidoProcesar) {
                  this.toastr.success('SU INFORMACIÓN HA SIDO ACTUALIZADA CORRECTAMENTE.', 'NOTIFICACIÓN');
                } else {
                  this.toastr.success('INFORMACIÓN VÁLIDA PARA PROCESAR.', 'NOTIFICACIÓN');
                }
                if (this.modalRef) {
                  this.modalRef.hide();
                }
              }, error => {
                this.toastr.warning(error.error.toUpperCase());
                this.spinner.hide('sp');
              }
            );
        }, error => {
          console.error(error);
          this.spinner.hide('sp');
        }
      );
  }

  closeModal() {
    this.modalRef.hide();
  }


  openDelete(template: TemplateRef<any>, r) {
    this.detalleId = 0;
    this.detalleId = r.detalleId;
    // this.reportName = r.name;
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
  }

  deleteRow() {
    this.spinner.show('sp');
    this.api.deleteRowLoadMassive(this.detalleId)
      .then(
        (response: any) => {

          const indexF = this.infoPolicyFisico.findIndex((fisico) => fisico.detalleId === this.detalleId);
          if (indexF >= 0) {
            this.infoPolicyFisico.splice(indexF, 1);
          }
          const indexM = this.infoPolicyMoral.findIndex((moral) => moral.detalleId === this.detalleId);
          if (indexM >= 0) {
            this.infoPolicyMoral.splice(indexM, 1);
          }

          this.toastr.success('LA FILA SE HA ELIMINADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          this.toastr.error('OCURRIÓ UN PROBLEMA AL PROECESAR SU PETICIÓN, CONTACTE AL ADMINISTRADOR.', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
        }
      );

  }

  cargaTemporalDeleted(idCarga: any) {

    this.api.getMassivePolicyTemp(idCarga)
      .then(
        (response: any) => {
          this.infoPolicyFisico = [];
          this.infoPolicyMoral = [];
          // this.infoPolicyFisico = response.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
          this.infoPolicyFisico = response;
          // this.infoPolicyMoral = response.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');
          localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
          localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));
          this.toastr.success('LA FILA SE HA ELIMINADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.modalRef.hide();

        }, error => {
          this.toastr.warning(error.error);
        }
      );
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      let vacio =
        {
          insuranceId: 0,
          rfc: null,
          name: 'ELIJA UN VALOR VÁLIDO',
          emergencyPhoneNumber: null,
          imagePath: null,
          cardColor: null,
          shortName: null,
          subBranches: null,
          webPage: null,
          userWeb: null,
          passwordWeb: null,
          rights: null,
          amountRight: null,
          totalMedicalCare: null,
          subranchId: 0
        };
      data.push(vacio);
      this.catInsuranceCompany = data;
    }, error => {
    });
  }
  changeAseguradora(e: any) {
    const valor = this.catInsuranceCompany.find(a =>a.insuranceId === e);
    if (valor) {
      this.editingPolicy.idAseguradora = e;
      this.editingPolicy.aseguradora = valor.name;
      this.api.getAgentKeysByInsuranceCompany(e).then((data: any) => {
        const vacio = {
          agentKeyId: -1,
          agentKey: '-1',
          description: 'ELIJA UN VALOR VÁLIDO',
          insuranceCompanyId: -1
        };
        data.push(vacio);
        this.catAgentKeys = data;
        if (this.catAgentKeys.length === 1) {
          this.editingPolicy.claveAseguradora = data[0].description;
        }
      }, error => {
      });
    } else {
      this.editingPolicy.idAseguradora = 0;
      this.editingPolicy.aseguradora = '';
    }
  }

  getBranches() {
    this.api.getBranchesGeneral().then((data: any) => {
      const vacio = {
            branchId: 0,
            branchType: 'ELIJA UN VALOR VÁLIDO',
          };
      data.push(vacio);
      this.catBranch = data;
    }, error => {
    });
  }

  changeRamo(e: any) {
    const valor = this.catBranch.find(a =>a.branchId === e);
    if (valor) {
      this.editingPolicy.idRamo = e;
      this.editingPolicy.ramo = valor.branchType;
      this.getSubBranch(e);
    } else {
      this.editingPolicy.ramo = '';
    }
  }

  getAgentKeys(insuranceId?) {

    this.api.getAgentKeysByInsuranceCompany(insuranceId).then((data: any) => {
      const vacio = {
        agentKeyId: -1,
        agentKey: '-1',
        description: 'ELIJA UN VALOR VÁLIDO',
        insuranceCompanyId: -1
      };
      data.push(vacio);
      this.catAgentKeys = data;
    }, error => {
    });

  }

  changeClave(e: any) {
    const valor = this.catAgentKeys.find(a =>a.agentKeyId === e);
    if (valor) {
      this.editingPolicy.idClaveAseguradora = e;
      this.editingPolicy.claveAseguradora = valor.description;
    } else {
      this.editingPolicy.claveAseguradora = '';
    }
  }

getSubBranch(branchId?) {
  this.catSubBranch = [];
  const vacio = {
    branchId: 0,
    subBranch: 'ELIJA UN VALOR VÁLIDO',
    subBranchId: 0
  };
  if (branchId === 0)  {
      this.catSubBranch.push(vacio);
    }
  this.api.getSubBranches(branchId).then((data: any) => {
    if (data !== null) {
      data.push(vacio);
    }
    this.catSubBranch = data;
    if (this.editingPolicy && !this.editingPolicy.idSubRamo) {
      this.editingPolicy.idSubRamo = 0;
    }
  }, error => {
  });
}

  changeSubRamo(e: any) {
    const valor = this.catSubBranch.find(a =>a.subBranchId === e);
    if (valor) {
      this.editingPolicy.idSubRamo = e;
      this.editingPolicy.subRamo = valor.subBranch;
    } else {
      this.editingPolicy.subRamo = '';
    }
  }

  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      const vacio = {
        userId: 0,
        name: 'ELIJA UN VALOR VÁLIDO',
        directoryId: 0
      };
      data.push(vacio);
      this.salesmanList = data;
    }, error => {
    });
  }

  changeVendedor(e: any) {
    const valor = this.salesmanList.find(a =>a.userId === e);
    if (valor) {
      this.editingPolicy.idVendedor = e;
      this.editingPolicy.vendedor = valor.name;
    } else {
      this.editingPolicy.vendedor = '';
    }
  }

  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      const vacio = {
        name: 'ELIJA UN VALOR VÁLIDO',
        directoryId: 0
      };
      switch (id) {
        case 1:
          data.push(vacio);
          this.catCoordinatorList = data;
          break;
        case 2:
          data.push(vacio);
          this.executiveSurexList = data;
          break;
        case 3:
          data.push(vacio);
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
    });
  }

  changeCoordinador(e: any) {
    const valor = this.catCoordinatorList.find(a => a.directoryId === e);
    if (valor) {
      this.editingPolicy.idCoordinador = e;
      this.editingPolicy.coordinador = valor.name;
    } else {
      this.editingPolicy.coordinador = '';
    }
  }

  getManagerIncident() {
    this.api.getManagerIncident().then((data: any) => {
      if (data == null) {
        this.catManagerIncidentList = [];
      } else {
        const vacio = {
          userId: 0,
          name: 'ELIJA UN VALOR VÁLIDO',
          directoryId: 0
        };
        data.push(vacio);
        this.catManagerIncidentList = data;
      }
    }, error => {
    });
  }

  changeGerenteSiniestro(e: any) {
    const valor = this.catManagerIncidentList.find(a =>a.userId === e);
    if (valor) {
      this.editingPolicy.idGerenteSiniestro = e;
      this.editingPolicy.gerenteSiniestro = valor.name;
    } else {
      this.editingPolicy.gerenteSiniestro = '';
    }
  }

  changeEjecutivoSurexs(e: any) {
    const valor = this.executiveSurexList.find(a => a.directoryId === e);
    if (valor) {
      this.editingPolicy.idEjecutivoSurexs = e;
      this.editingPolicy.ejecutivoSurexs = valor.name;
    } else {
      this.editingPolicy.ejecutivoSurexs = '';
    }
  }

  changeEjecutivoSiniestro(e: any) {
    const valor = this.executiveIncidentList.find(a => a.directoryId === e);
    if (valor) {
      this.editingPolicy.idEjecutivoSiniestro = e;
      this.editingPolicy.ejecutivoSiniestro = valor.name;
    } else {
      this.editingPolicy.ejecutivoSiniestro = '';
    }
  }

  getCurrency() {
    this.currencyList = [
      {currencyId: 0, name: 'ELIJA UN VALOR VÁLIDO'}, {currencyId: 1, name: 'MX'}, {currencyId: 2, name: 'USD'}
    ];
  }

  changeMoneda(e: any) {
    const valor = this.currencyList.find(a => a.currencyId === e);
    if (valor) {
      this.editingPolicy.idMoneda = e;
      this.editingPolicy.moneda = valor.name;
    } else {
      this.editingPolicy.moneda = '';
    }
  }

  getTypePayments() {
    this.api.getTypePayments().then((data: any) => {
      console.log(data);
      const vacio = {
        paymentTypeId: 0,
        paymentType: 'ELIJA UN VALOR VÁLIDO',
      };
      data.push(vacio);
      this.catPaymentTypes = data;
    }, error => {
    });
  }

  changeTipoPago(e: any) {
    const valor = this.catPaymentTypes.find(a => a.paymentTypeId === e);
    if (valor) {
      this.editingPolicy.idTipoPago = e;
      this.editingPolicy.tipoPago = valor.paymentType;
    } else {
      this.editingPolicy.tipoPago = '';
    }
  }

  getTypePaymentsPolicy() {
    this.api.getTypePaymentsPolicy().then((data: any) => {
      const vacio = {
        typePaymentPolicy: 0,
        description: 'ELIJA UN VALOR VÁLIDO',
      };
      data.push(vacio);
      this.catTypePayments = data;
    }, error => {
    });
  }

  changeTipoCobranza(e: any) {
    const valor = this.catTypePayments.find(a => a.typePaymentPolicy === e);
    if (valor) {
      this.editingPolicy.idTipoCobranza = e;
      this.editingPolicy.tipoCobranza = valor.description;
    } else {
      this.editingPolicy.tipoCobranza = '';
    }
  }


  getOffices() {
    this.api.getOffices().then((data: any) => {
      const vacio = {
        officeId: 0,
        office: 'ELIJA UN VALOR VÁLIDO',
      };
      data.push(vacio);
      this.officeList = data;
    }, error => {
    });
  }

  changeOficina(e: any) {
    const valor = this.officeList.find(a => a.officeId === e);
    if (valor) {
      this.editingPolicy.idOficina = e;
      this.editingPolicy.oficina = valor.office;
    } else {
      this.editingPolicy.oficina = '';
    }
  }

  validateExcelStructured(sheetName: any, worksheet: any, jsonData: any ): boolean {

      const expectedColumns = {
        'TIPO_PERSONA': 'string',
        'NUMERO': 'string',
        'INICIO_VIGENCIA': 'string',
        'TERMINO_VIGENCIA': 'string',
        // 'RFC_CLIENTE': 'string',
        'GRUPO': 'string',
        'SUB_GRUPO': 'string',
        'ASEGURADORA': 'string',
        'CLAVE_ASEGURADORA': 'string',
        'RAMO': 'string',
        'SUB_RAMO': 'string',
        'RENOVACION': 'string',
        'POLIZA_ANTERIOR': 'string',
        'VENDEDOR': 'string',
        'COORDINADOR': 'string',
        'GERENTE_SINIESTROS': 'string',
        'EJECUTIVO_SUREXS': 'string',
        'EJECUTIVO_SINIESTRO': 'string',
        'MONEDA': 'string',
        'TIPO_PAGO': 'string',
        'TIPO_COBRANZA': 'string',
        'OFICINA': 'string',
        'NOMBRE_ARCHIVO_POLIZA': 'string',
        'PRIMA_NETA': 'number',
        'DERECHOS': 'number',
        'PORC_RECARGO': 'number',
        'PORC_COMISION': 'number',
        'IVA': 'number',
        'PRIMA_TOTAL': 'number'
      };

    // Validar la estructura de la tabla

    if (jsonData[0].RENOVACION === undefined) {
      jsonData[0].RENOVACION = '';
    }

    if (jsonData[0].POLIZA_ANTERIOR === undefined) {
      jsonData[0].POLIZA_ANTERIOR = '';
    }

    const columns = Object.keys(jsonData[0]);
    if (columns.length !== Object.keys(expectedColumns).length) {
      console.log('El número de columnas en la tabla es incorrecto.');
      return false;
    }

    for (const column of columns) {
      //if (!expectedColumns.hasOwnProperty(column) || typeof jsonData[0][column] !== expectedColumns[column]) {
      if (!expectedColumns.hasOwnProperty(column)) {
        console.log(`El nombre de la columna "${column}" o su tipo son incorrectos.`);
        return false;
      }
    }

    console.log('La estructura de la tabla es correcta.');
    return true;
  }
  policyMasterFiles(e: any, seccion: number) {
    this.polizasMaestras = [];

    if (seccion === 1) {
      // this.infoPolicyFisicoTemp = e.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
      this.infoPolicyFisicoTemp = e;
      this.infoPolicyMoralTemp = e.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');

      for (const numero of this.infoPolicyFisicoTemp) {
        if (numero.polizaMaestra !== '' && numero.polizaMaestra !== undefined) {
          this.polizasMaestras.push(numero.polizaMaestra);
        }
      }

      // for (const numero of this.infoPolicyMoralTemp) {
      //   if (numero.polizaMaestra !== '' && numero.polizaMaestra !== undefined) {
      //     this.polizasMaestras.push(numero.polizaMaestra);
      //   }
      // }

      for (const numero of this.polizasMaestras) {
        console.log('POLIZAS MAESTRAS SECCION 1: ', numero);
      }

    } else {
      // PARA EL BOTON VALIDAR INFORMACION

      for (const numero of e) {
        if (numero.polizaMaestra !== '' && numero.polizaMaestra !== undefined) {
          this.polizasMaestras.push(numero.polizaMaestra);
        }
      }

      for (const numero of this.polizasMaestras) {
        console.log('PÓLIZAS MAESTRAS SECCION 2: ', numero);
      }

  }
}

  procesarInfo(e: any) {

      const validaNumero = [];
      const validaRfc = [];
      const validaGrupo = [];
      const validaSubGrupo = [];
      const validaCveAseguradora = [];
      const validaAseguradora = [];
      const validaRamo = [];
      const validaSubRamo = [];
      const validaRenovacion = [];
      const validaVendedor = [];
      const validaCoordinador = [];
      const validaGerenteSiniestro = [];
      const validaEjecutivoSurexs = [];
      const validaEjecutivoSiniestro = [];
      const validaMoneda = [];
      const validaTipoPago = [];
      const validaTipoCobranza = [];
      const validaOficina = [];

      validaNumero.push(e.filter(d => d.valNumero === true).map(d => d.valNumero));
      validaRfc.push(e.filter(d => d.valRfcCliente === true).map(d => d.valRfcCliente));
      validaGrupo.push(e.filter(d => d.valGrupo === true).map(d => d.valGrupo));
      validaSubGrupo.push(e.filter(d => d.valSubGrupo === true).map(d => d.valSubGrupo));
      validaAseguradora.push(e.filter(d => d.valAseguradora === true).map(d => d.valAseguradora));
      validaCveAseguradora.push(e.filter(d => d.valClaveAseguradora === true).map(d => d.valClaveAseguradora));
      validaRamo.push(e.filter(d => d.valRamo === true).map(d => d.valRamo));
      validaSubRamo.push(e.filter(d => d.valSubRamo === true).map(d => d.valSubRamo));
      validaVendedor.push(e.filter(d => d.valVendedor === true).map(d => d.valVendedor));
      validaCoordinador.push(e.filter(d => d.valCoordinador === true).map(d => d.valCoordinador));
      validaGerenteSiniestro.push(e.filter(d => d.valGerenteSiniestro === true).map(d => d.valGerenteSiniestro));
      validaEjecutivoSurexs.push(e.filter(d => d.valEjecutivoSurexs === true).map(d => d.valEjecutivoSurexs));
      validaEjecutivoSiniestro.push(e.filter(d => d.valEjecutivoSiniestro === true).map(d => d.valEjecutivoSiniestro));
      validaMoneda.push(e.filter(d => d.valMoneda === true).map(d => d.valMoneda));
      validaTipoPago.push(e.filter(d => d.valTipoPago === true).map(d => d.valTipoPago));
      validaTipoCobranza.push(e.filter(d => d.valTipoCobranza === true).map(d => d.valTipoCobranza));
      validaOficina.push(e.filter(d => d.valOficina === true).map(d => d.valOficina));

      const procesar = e.length;

      if (validaNumero[0].length === procesar && validaGrupo[0].length === procesar &&
        validaSubGrupo[0].length === procesar && validaAseguradora[0].length === procesar && validaCveAseguradora[0].length === procesar &&
        validaRamo[0].length === procesar && validaSubRamo[0].length === procesar && validaVendedor[0].length === procesar &&
        validaCoordinador[0].length === procesar && validaGerenteSiniestro[0].length === procesar && validaEjecutivoSurexs[0].length === procesar &&
        validaEjecutivoSiniestro[0].length === procesar && validaMoneda[0].length === procesar && validaTipoPago[0].length === procesar &&
        validaTipoCobranza[0].length === procesar && validaOficina[0].length === procesar) {
        this.esValidaInformacion = false;
        this.esValidoProcesar = true;
        this.spinner.hide('sp');
        this.toastr.success('VALIDACIÓN CORRECTA, PUEDE PROCESAR LA INFORMACIÓN DE PÓLIZAS', 'NOTIFICACIÓN');
        console.log('VALIDACIÓN CORRECTA, PUEDE PROCESAR LA INFORMACIÓN DE PÓLIZAS');
      } else {
        this.esValidaInformacion = true;
        this.esValidoProcesar = false;
        this.toastr.warning('FALTAN DATOS POR CORREGIR Y VALIDAR EN PÓLIZAS', 'NOTIFICACIÓN');
        console.log('FALTAN DATOS POR CORREGIR Y VALIDAR EN PÓLIZAS');
        this.spinner.hide('sp');
      }

  }

  procesaInformacion() {
    let lista: any;

    /*
    lista =  [
      {
        fecha: null,
        idUsuario: null,
        detalleId: 450,
        cargaId: null,
        nombre: 'JUAN',
        apellidoPaterno: 'CORREA',
        apellidoMaterno: 'SUAREZ',
        rfc: 'PEJH020212365',
        valRfc: true,
        grupo: '(S.C.A.) SOCIDAD EN COMANDITA POR ACCIONES',
        valGrupo: true,
        idGrupo: 5,
        telefono: '966325847',
        email: 'perez.hernandez.juan@gmail.com',
        paisCliente: 'MÉXICO',
        valPaisCliente: true,
        idPaisCliente: 1,
        cpCliente: '66200',
        valCpCliente: true,
        estadoCliente: 'NUEVO LEÓN',
        valEstadoCliente: true,
        idEstadoCliente: 19,
        municipioCliente: 'SAN PEDRO GARZA GARCÍA',
        valMunicipioCliente: true,
        idMunicipioCliente: '019',
        coloniaCliente: 'SAN PEDRO GARZA GARCIA CENTRO',
        valColoniaCliente: true,
        idColoniaCliente: '0857',
        calleCliente: 'ENCINOS',
        numExtCliente: '123',
        numIntCliente: '3',
        paisFiscal: 'MÉXICO',
        valPaisFiscal: true,
        idPaisFiscal: 1,
        cpFiscal: '66200',
        valCpFiscal: true,
        estadoFiscal: 'NUEVO LEÓN',
        valEstadoFiscal: true,
        idEstadoFiscal: 19,
        municipioFiscal: 'SAN PEDRO GARZA GARCÍA',
        valMunicipioFiscal: true,
        idMunicipioFiscal: '019',
        coloniaFiscal: 'LOS ENCINOS',
        valColoniaFiscal: true,
        idColoniaFiscal: '0004',
        calleFiscal: 'ENCINOS',
        numExtFiscal: '123',
        numIntFiscal: '3',
        actaNacimiento: 'ACTA NACIMIENTO1.PDF',
        comprobanteDomFiscal:'' ,
        identificacionOficial:'' ,
        rfcDocumento:'' ,
        acusePrescripcion:''
      },
      {
        fecha: null,
        idUsuario: null,
        detalleId: 451,
        cargaId: null,
        nombre: 'SEBASTIAN',
        apellidoPaterno: 'LOPEZ',
        apellidoMaterno: 'RAMOS',
        rfc: 'QAD120117AT7',
        valRfc: true,
        grupo: '(S.N.C.) SOCIEDAD EN NOMBRE COLECTIVO',
        valGrupo: true,
        idGrupo: 2,
        telefono: '966325847',
        email: 'sebaslora@yahoo.com.mx',
        paisCliente: 'MÉXICO',
        valPaisCliente: true,
        idPaisCliente: 1,
        cpCliente: '86750',
        valCpCliente: true,
        estadoCliente: 'TABASCO',
        valEstadoCliente: true,
        idEstadoCliente: 27,
        municipioCliente: 'CENTLA',
        valMunicipioCliente: true,
        idMunicipioCliente: '003',
        coloniaCliente: 'FRONTERA CENTRO',
        valColoniaCliente: true,
        idColoniaCliente: '0754',
        calleCliente: 'PINO SUAREZ',
        numExtCliente: '123',
        numIntCliente: '3',
        paisFiscal: 'MÉXICO',
        valPaisFiscal: true,
        idPaisFiscal: 1,
        cpFiscal: '86000',
        valCpFiscal: true,
        estadoFiscal: 'TABASCO',
        valEstadoFiscal: true,
        idEstadoFiscal: 27,
        municipioFiscal: 'CENTRO',
        valMunicipioFiscal: true,
        idMunicipioFiscal: '004',
        coloniaFiscal: 'VILLAHERMOSA CENTRO',
        valColoniaFiscal: true,
        idColoniaFiscal: '0002',
        calleFiscal: 'QUINTIN',
        numExtFiscal: '123',
        numIntFiscal: '3',
        actaNacimiento: 'ACTA NACIMIENTO4.PDF',
        comprobanteDomFiscal:'' ,
        identificacionOficial:'' ,
        rfcDocumento: '',
        acusePrescripcion: 'ACUSE2.PDF'
      }
    ];
*/

    lista = [
      {
        fecha: null,
        idUsuario: null,
        detalleId: 1294,
        cargaId: null,
        tipoPersona: 'FISICA',
        numero: 'GMM141420',
        valNumero: true,
        inicioVigencia: '2023-02-20',
        terminoVigencia: '2024-02-20',
        rfcCliente: 'ZW15WROZSODO9',
        valRfcCliente: true,
        grupo: 'GRUPO AMMIA',
        valGrupo: true,
        idGrupo: 1,
        subGrupo: 'SANDOVAL RADA ACEVES',
        valSubGrupo: true,
        idSubGrupo: 506,
        aseguradora: 'ALLIANZ MEXICO S.A.',
        idAseguradora: 8,
        valAseguradora: true,
        claveAseguradora: '5406-GMM-VIDA',
        valClaveAseguradora: true,
        idClaveAseguradora: 7,
        ramo: 'BENEFICIOS',
        idRamo: 1,
        valRamo: true,
        subRamo: 'CASH FLOW',
        idSubRamo: 35,
        valSubRamo: true,
        renovacion: '  ',
        esRenovacion: false,
        polizaAnterior: '',
        valRenovacion: false,
        vendedor: 'CARLO DAGNINO FRISBIE',
        idVendedor: 946,
        valVendedor: true,
        coordinador: 'EDUARDO ZAMORA HURTADO',
        idCoordinador: 25,
        valCoordinador: true,
        gerenteSiniestro: 'ENRIQUE GUTIERREZ ESLAVA',
        idGerenteSiniestro: 8680,
        valGerenteSiniestro: true,
        ejecutivoSurexs: 'MOISES MORALES RIVERA',
        idEjecutivoSurexs: 80,
        valEjecutivoSurexs: true,
        ejecutivoSiniestro: 'GIAN CARLO REYNOSO MELO',
        idEjecutivoSiniestro: 24,
        valEjecutivoSiniestro: true,
        moneda: 'MX',
        valMoneda: true,
        idMoneda: 1,
        tipoPago: 'MENSUAL',
        valTipoPago: true,
        idTipoPago: 5,
        tipoCobranza: 'POR CATEGORÍA',
        valTipoCobranza: true,
        idTipoCobranza: 3,
        oficina: 'CDMX',
        valOficina: true,
        idOficina: 1,
        polizaMaestra: '1234567.PDF',
        primaNeta: 650000,
        derechos: 5000,
        porcRecargo: 5,
        porcComision: 5,
        iva: 16
      },
      {
        fecha: null,
        idUsuario: null,
        detalleId: 1295,
        cargaId: null,
        tipoPersona: 'MORAL',
        numero: 'GMMC-2999',
        valNumero: true,
        inicioVigencia: '2023-02-21',
        terminoVigencia: '2024-02-21',
        rfcCliente: 'MOM8512079W9',
        valRfcCliente: true,
        grupo: 'BXP',
        valGrupo: true,
        idGrupo: 40,
        subGrupo: 'SUREXS AGENTE DE SEGUROS Y DE FIANZAS, S.A. DE C.V.',
        valSubGrupo: true,
        idSubGrupo: 123,
        aseguradora: 'ALLIANZ MEXICO S.A.',
        idAseguradora: 8,
        valAseguradora: true,
        claveAseguradora: '5406-GMM-VIDA',
        valClaveAseguradora: true,
        idClaveAseguradora: 7,
        ramo: 'BENEFICIOS',
        idRamo: 1,
        valRamo: true,
        subRamo: 'VISION',
        idSubRamo: 4,
        valSubRamo: true,
        renovacion: '  ',
        esRenovacion: false,
        polizaAnterior: '',
        valRenovacion: false,
        vendedor: 'CARLO DAGNINO FRISBIE',
        idVendedor: 946,
        valVendedor: true,
        coordinador: 'VIVIANA HERNANDEZ MERINO',
        idCoordinador: 11,
        valCoordinador: true,
        gerenteSiniestro: 'ENRIQUE GUTIERREZ ESLAVA',
        idGerenteSiniestro: 8680,
        valGerenteSiniestro: true,
        ejecutivoSurexs: 'MOISES MORALES RIVERA',
        idEjecutivoSurexs: 80,
        valEjecutivoSurexs: true,
        ejecutivoSiniestro: 'GIAN CARLO REYNOSO MELO',
        idEjecutivoSiniestro: 24,
        valEjecutivoSiniestro: true,
        moneda: 'MX',
        valMoneda: true,
        idMoneda: 1,
        tipoPago: 'SEMESTRAL',
        valTipoPago: true,
        idTipoPago: 3,
        tipoCobranza: 'POR PÓLIZA',
        valTipoCobranza: true,
        idTipoCobranza: 1,
        oficina: 'CDMX',
        valOficina: true,
        idOficina: 1,
        polizaMaestra: 'AA.pdf',
        primaNeta: 650000,
        derechos: 1000,
        porcRecargo: 7,
        porcComision: 7,
        iva: 32
      }
    ];


    console.log('DATOS VALIDADOS', lista);

    let listaPolizas: PolicyLoad[] = [];

    listaPolizas = lista.map(p => {
      let i = 0;
      const poliza = new PolicyLoad();
      const generales = new PolicyBusiness();
      const recibos = new ReceiptBusiness();
      poliza.policyBusiness.number = p.numero;
      poliza.policyBusiness.startDate = p.inicioVigencia;
      poliza.policyBusiness.expirationDate = p.terminoVigencia;
      poliza.policyBusiness.groupId = p.idGrupo;
      poliza.policyBusiness.branchId = p.idRamo;
      poliza.policyBusiness.subBranchId = p.idSubRamo;

      poliza.policyBusiness.renew = p.esRenovacion;
      poliza.policyBusiness.insuranceId = p.idAseguradora;
      poliza.policyBusiness.clients.push(p.idSubGrupo);
      poliza.policyBusiness.currencyId = p.idMoneda;
      poliza.policyBusiness.userId = p.idUsuario;
      poliza.policyBusiness.policyNumberOld = p.polizaAnterior;

      poliza.policyBusiness.paymentTypeId = p.idTipoPago;
      poliza.policyBusiness.directoryId = p.idVendedor;
      poliza.policyBusiness.coordinators.push(p.idCoordinador);
      poliza.policyBusiness.executiveSurexs.push(p.idEjecutivoSurexs);
      poliza.policyBusiness.executiveIncidents.push(p.idEjecutivoSiniestro);
      poliza.policyBusiness.agentKeyId = p.idClaveAseguradora;

      poliza.policyBusiness.typePaymentPolicy = p.idTipoCobranza;
      poliza.policyBusiness.managerIncident = p.idGerenteSiniestro;
      poliza.policyBusiness.officeId = p.idOficina;

      poliza.receiptBusiness.netPremium = p.primaNeta;
      poliza.receiptBusiness.rights = p.derechos;
      poliza.receiptBusiness.paymentCharges = p.porcRecargo;
      poliza.receiptBusiness.commission = p.porcComision;
      poliza.receiptBusiness.iva = p.iva;

      return poliza;

    });

    console.log('POLIZA FINAL: ', listaPolizas);

  }

  getGroups() {
    const data = {name: 'GROUPS'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      const tmp = data.map(ob => ({ groupId: ob.id, name: ob.description }));
      let vacio =
        {
          groupId: 0,
          name: 'ELIJA UN VALOR VÁLIDO',
          parent: 0
        };
      tmp.unshift(vacio);
      this.catGroups = tmp;
      console.log(this.editPolicyF);
      console.log(this.catGroups);
    }, error => {

    });
    // this.api.getGroups().then((data: any) => {
    //   let vacio =
    //     {
    //       groupId: 0,
    //       name: 'ELIJA UN VALOR VÁLIDO',
    //       parent: 0
    //     };
    //   data.push(vacio);
    //   console.log(data);
    //   this.catGroups = data;
    // }, error => {
    // });
  }

  changeGrupo(e: any) {
    const valor = this.catGroups.find(a => a.groupId === e);
    if (valor) {
      this.editingPolicy.idGrupo = e;
      this.editingPolicy.grupo = valor.name;
      this.catClients = [];
      this.api.getSubGroups(e).then((data: any) => {
        let vacio =
          {
            clientId: 0,
            rfc: '',
            name: 'ELIJA UN VALOR VÁLIDO',
            clientType: 2,
            type: 'MORAL',
            groupId: 0,
            status: false,
            comment: null,
            typeSocietyId: 0
          };
        data.unshift(vacio);

        this.catClients = data;

      }, error => {
      });
    } else {
      this.editingPolicy.grupo = '';
    }


  }

  getSubGroups(id) {
    console.log('get subgrupos');
    this.catClients = [];
    this.api.getSubGroups(id).then((data: any) => {
      let vacio =
        {
          clientId: 0,
          rfc: '',
          name: 'ELIJA UN VALOR VÁLIDO',
          clientType: 2,
          type: 'MORAL',
          groupId: 0,
          status: false,
          comment: null,
          typeSocietyId: 0
        };
      data.unshift(vacio);
      this.catClients = data;
      console.log(this.catClients);
    }, error => {
    });

  }

  changeSubGrupo(e: any) {
    const valor = this.catClients.find(a => a.clientId === e);
    if (valor) {
      this.editingPolicy.idSubGrupo = e;
      this.editingPolicy.subGrupo = valor.name;
    } else {
      this.editingPolicy.subGrupo = '';
    }
  }

cargaDataFromClient() {
  this.infoPolicyValidate = [];
  for (let i = 0; i < this.infoPolicyFisico.length; i++) {
    this.infoPolicyFisico[i].cargaId = this.idCarga.idCarga;
  }

  for (let i = 0; i < this.infoPolicyMoral.length; i++) {
    this.infoPolicyMoral[i].cargaId = this.idCarga.idCarga;
  }
  for (const dato of this.infoPolicyFisico) {
    this.infoPolicyValidate.push(dato);

  }

  for (const dato of this.infoPolicyMoral) {
    this.infoPolicyValidate.push(dato);
  }

  this.api.validateLoadMassivePolicy(this.infoPolicyValidate)
    .subscribe(
      datos => {
        let data: any;
        data = datos;
        this.infoPolicyFisico = [];
        this.infoPolicyMoral = [];
        console.log('datos obtenidos en la validacion de informacion que regresa de carga masiva de clientes: ', data);
        // this.infoPolicyFisico = data.filter(tipoPersona => tipoPersona.tipoPersona === 'FISICA');
        this.infoPolicyFisico = data;
        // this.infoPolicyMoral = data.filter(tipoPersona => tipoPersona.tipoPersona === 'MORAL');
        localStorage.setItem('fisicos', JSON.stringify(this.infoPolicyFisico));
        localStorage.setItem('morales', JSON.stringify(this.infoPolicyMoral));

        // this.toastr.success('SU INFORMACIÓN HA SIDO ACTUALIZADA CORRECTAMENTE.', 'NOTIFICACIÓN');
      }, error => {
        this.toastr.warning(error.error);
      }
    );
}

  procesaInfo() {

    this.infoPolicyValidate = [];
    for (let i = 0; i < this.infoPolicyFisico.length; i++) {
      this.infoPolicyFisico[i].cargaId = this.idCarga.idCarga;
    }

    for (let i = 0; i < this.infoPolicyMoral.length; i++) {
      this.infoPolicyMoral[i].cargaId = this.idCarga.idCarga;
    }
    for (const dato of this.infoPolicyFisico) {
      this.infoPolicyValidate.push(dato);

    }

    for (const dato of this.infoPolicyMoral) {
      this.infoPolicyValidate.push(dato);
    }

    let listaPolizas: PolicyLoad[] = [];

    listaPolizas = this.infoPolicyValidate.map(p => {
      let i = 0;
      const poliza = new PolicyLoad();
      poliza.policyBusiness.nombrePolizaMaestra = p.polizaMaestra;
      poliza.policyBusiness.archivoPolizaMaestra = p.archivoPolizaMaestra;
      poliza.policyBusiness.policyPath = p.pathPoliza;

      poliza.policyBusiness.number = p.numero;
      poliza.policyBusiness.startDate = p.inicioVigencia;
      poliza.policyBusiness.expirationDate = p.terminoVigencia;
      poliza.policyBusiness.groupId = p.idGrupo;
      poliza.policyBusiness.branchId = p.idRamo;
      poliza.policyBusiness.subBranchId = p.idSubRamo;

      poliza.policyBusiness.renew = p.esRenovacion;
      poliza.policyBusiness.insuranceId = p.idAseguradora;
      poliza.policyBusiness.clients.push(p.idSubGrupo);
      poliza.policyBusiness.currencyId = p.idMoneda;
      poliza.policyBusiness.userId = p.idVendedor;
      poliza.policyBusiness.policyNumberOld = p.polizaAnterior;

      poliza.policyBusiness.paymentTypeId = p.idTipoPago;
      // poliza.policyBusiness.directoryId = p.idVendedor;
      poliza.policyBusiness.coordinators.push(p.idCoordinador);
      poliza.policyBusiness.executiveSurexs.push(p.idEjecutivoSurexs);
      poliza.policyBusiness.executiveIncidents.push(p.idEjecutivoSiniestro);
      poliza.policyBusiness.agentKeyId = p.idClaveAseguradora;

      poliza.policyBusiness.typePaymentPolicy = p.idTipoCobranza;
      poliza.policyBusiness.managerIncident = p.idGerenteSiniestro;
      poliza.policyBusiness.officeId = p.idOficina;

      poliza.receiptBusiness.netPremium = p.primaNeta;
      poliza.receiptBusiness.rights = p.derechos;
      poliza.receiptBusiness.paymentCharges = p.porcRecargo;
      poliza.receiptBusiness.commission = p.porcComision;
      poliza.receiptBusiness.iva = p.iva;

      return poliza;

    });

    console.log('POLIZA FINAL: ', listaPolizas);
    this.saveInformacionPolicy(listaPolizas);

  }

  saveInformacionPolicy(e: any) {
    this.spinner.show('sp');
       this.api.processingInfoPolicy(e)
        .subscribe(
          response => {
            this.esValidoProcesar = false;
            this.toastr.success('INFORMACIÓN REGISTRADA SATISFACTORIAMENTE.', 'NOTIFICACIÓN');
            this.nuevaCarga();
            this.spinner.hide('sp');
          }, error => {
            this.toastr.warning(error.error.toUpperCase());
            this.spinner.hide('sp');
          }
        );
    }

  validarCaracteresEspeciales(inputString: string): boolean {

    const regex = /[@?'"=]/;
    if (regex.test(inputString)) {
      return false;
    }
    return true;
  }
}
