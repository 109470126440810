import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {ApiService} from '../api.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe} from '@angular/common';
import {document} from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-endorsement-modal',
  templateUrl: './endorsement-modal.component.html',
  styleUrls: ['./endorsement-modal.component.css']
})
export class EndorsementModalComponent implements OnInit {

  endorsementForm: FormGroup;
  typeEndorsementList: any = [];
  public fileUploadPDF: File;
  errorPDF = {active: false, msg: ''};
  fileTypesPDF: string[] = ['application/pdf', 'application/x-pdf'];
  endorsementId: number;
  endorsementList: any = [];
  mostrarAnexo = true;
  isNewEndoso = false;
  receiptTotal: any = {};
  policyAmount: any = {};
  receiptList: any = [];
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  statusList: any = [];
  paymentList: any = [];
  private _modalSaveEndorsement: BsModalRef;
  catPaymentTypes: any = [];
  catTypePayments: any = [];
  catTaxes: any = [];
  chargesPercentage = 0;
  bReceiptC: number;
  private _dataToSendGeneral: any = {};
  private _dateReceiptForCloseEndorsement: any = {};
  bsLeavingDate: Partial<BsDatepickerConfig>;
  @Output() saveDataPolicy: EventEmitter<object> = new EventEmitter<object>();
  typeEndorsement: 0;
  patternString: string;
  patternStringPN: string;
  constructor(private toastr: ToastrService,
              private datePipe: DatePipe,
              private shared: SharedService,
              private api: ApiService) {
    this.endorsementForm = new FormGroup({
      endorsementId: new FormControl(0, [
        Validators.required
      ]),
      endorsement: new FormControl(null, [
        Validators.required
      ]),
      endorsementTypeId: new FormControl(null, [
        Validators.required
      ]),
      endorsementDescription: new FormControl(null, [
        Validators.required
      ]),
      endorsementFilePath: new FormControl(null, [
        Validators.required
      ]),
      endorsementFileName: new FormControl(null, [
          Validators.required
      ]),
      policyId: new FormControl(this.dataToSendGeneral.policyId, [
        Validators.required
      ]),
      cardId: new FormControl(0, [
        Validators.required
      ]),
      startDate: new FormControl(null, [
        Validators.required
      ]),
      receiptBusiness: new FormGroup({
        receiptId: new FormControl(0, [
          Validators.required
        ]),
        commission: new FormControl(0, [
          Validators.required
        ]),
        commissionAmount: new FormControl(0, [
          Validators.required
        ]),
        netPremium: new FormControl(0, [
          Validators.required
        ]),
        rights: new FormControl(0, [
          Validators.required
        ]),
        paymentCharges: new FormControl(0, [
          Validators.required
        ]),
        iva: new FormControl(0, [
          Validators.required
        ]),
        totalPremium: new FormControl(0, [
          Validators.required
        ]),
        paymentDate: new FormControl('', ),
        paymentMonth: new FormControl(0, [
          Validators.required
        ]),
        policyId: new FormControl(this.dataToSendGeneral.policyId, [
          Validators.required
        ]),
        receiptStatusId: new FormControl(1, [
          Validators.required
        ]),
        paymentType: new FormControl(this.dataToSendGeneral.paymentTypeId, [
          Validators.required
        ]),
        paymentFormId: new FormControl(0, [
          Validators.required
        ]),
        taxId: new FormControl(0, [
          Validators.required
        ]),
        numberReceipt: new FormControl(0, [
          Validators.required
        ])
      })
    });

  }

  ngOnInit() {

    this.endorsementForm.disable();
    this.invokeServiceGetTypeEndorsement();
    this.getStatusReceipts();
    this.getPaymentForms();
    this.loadDataTaxes();
    this.getTypePayments();
    this.bsLeavingDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      adaptivePosition: true,
    });

  }
  invokeServiceGetTypeEndorsement() {
    this.typeEndorsementList = [
      {
        endorsementTypeId: 1,
        endorsement: 'Tipo A'
      },
      {
        endorsementTypeId: 2,
        endorsement: 'Tipo D'
      },
      {
        endorsementTypeId: 3,
        endorsement: 'Tipo B'
      }
    ];
  }
  onChangeInputFilePDF(e) {

    this.errorPDF = {active: false, msg: ''};
    this.fileUploadPDF = e.target.files[0];
    console.log(this.fileUploadPDF);
    if (0 > this.fileTypesPDF.indexOf(this.fileUploadPDF.type)) {
      this.errorPDF = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.fileUploadPDF = null;
      return;
    }
    this.getBase64(this.fileUploadPDF).then(
      res => {
        this.endorsementForm.controls.endorsementFilePath.setValue(res.split(',')[1]);
      }
    );

    this.endorsementForm.controls.endorsementFileName.setValue(this.fileUploadPDF.name);
  }

  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  invokeEndorsements(policyId) {
    const obj = {
      policyId
    };
    if (this._dataToSendGeneral.policySimply) {
      this.isNewEndoso = true;
      this.endorsementForm.enable();
    } else {
        this.api.getEndorsementsByPolicy(obj).then((data: any) => {
          this.endorsementList = data;
          // tslint:disable-next-line:max-line-length
          if (this.endorsementList !== undefined && this.endorsementList[this.endorsementList.length - 1].endorsementId === 0) { // Si no hay endoso
            this.isNewEndoso = true;
            this.endorsementForm.enable();
          } else {
            this.invokeEndorsementById(this.endorsementList[this.endorsementList.length - 1].endorsementId);
          }
        }, error => {
        });
    }
  }

  invokeEndorsementById(endorsementId) {
    const obj = {
      endorsementId,
      categoryId: 0
    };
    this.api.getEndorsementsReceiptsByEndorsement(obj).then((data: any) => {
      const endorsement = data.endorsement;
      this.receiptList = data.receiptList;

      this.endorsementForm.get('endorsementId').setValue( endorsement.endorsementId);
      this.endorsementForm.get('endorsement').setValue( endorsement.endorsement);
      this.endorsementForm.get('endorsementTypeId').setValue( endorsement.endorsementTypeId);
      this.endorsementForm.get('endorsementDescription').setValue( endorsement.endorsementDescription);
      this.endorsementForm.get('endorsementFileName').setValue( endorsement.endorsementFileName);

    }, error => {

    });
  }

  saveEndorsements() {
    const endorsementDTO = {
      saveData: false,
      endorsementId: this.endorsementForm.get('endorsementId').value
    };
    this.endorsementForm.controls.startDate.setValue(this.datePipe.transform(this.endorsementForm.controls.startDate.value, 'yyyy-MM-dd'));

    this.api.saveEndorsements(this.endorsementForm.value).then((data: any) => {
      endorsementDTO.saveData = true;
      endorsementDTO.endorsementId = data.endorsementId;

      this.saveDataPolicy.emit(endorsementDTO);
      this._modalSaveEndorsement.hide();
    }, error => {
      this.saveDataPolicy.emit(endorsementDTO);
    });
  }

  saveSameEndorsements() {
    const endorsementDTO = {
      saveData: true,
      endorsementId: this.endorsementForm.get('endorsementId').value
    };
    this.saveDataPolicy.emit(endorsementDTO);
    this._modalSaveEndorsement.hide();
  }

  clearForm() {
    this.isNewEndoso = true;
    this.mostrarAnexo = false;
    this.endorsementForm.reset();
    this.endorsementForm.enable();
    this.setValuesToNewForm();
    this.receiptList = [];
    this.getTypePayments();
  }

  setValuesToNewForm() {
    this.endorsementForm.controls.cardId.setValue(0);
    this.endorsementForm.controls.policyId.setValue(this.dataToSendGeneral.policyId);
    this.endorsementForm.controls.endorsementId.setValue(0);
    this.endorsementForm.get('receiptBusiness.receiptId').setValue(0);
    this.endorsementForm.get('receiptBusiness.commission').setValue(0);
    this.endorsementForm.get('receiptBusiness.netPremium').setValue(0);
    this.endorsementForm.get('receiptBusiness.commissionAmount').setValue(0);
    this.endorsementForm.get('receiptBusiness.rights').setValue(0);
    this.endorsementForm.get('receiptBusiness.paymentCharges').setValue(0);
    this.endorsementForm.get('receiptBusiness.iva').setValue(0);
    this.endorsementForm.get('receiptBusiness.totalPremium').setValue(0);
    this.endorsementForm.get('receiptBusiness.paymentDate').setValue('');
    this.endorsementForm.get('receiptBusiness.paymentMonth').setValue(0);
    this.endorsementForm.get('receiptBusiness.policyId').setValue(this.dataToSendGeneral.policyId);
    this.endorsementForm.get('receiptBusiness.receiptStatusId').setValue(1);
    this.endorsementForm.get('receiptBusiness.paymentType').setValue(this.dataToSendGeneral.paymentTypeId);
    this.endorsementForm.get('receiptBusiness.paymentFormId').setValue(1);
    this.endorsementForm.get('receiptBusiness.taxId').setValue(1);
    this.endorsementForm.get('receiptBusiness.numberReceipt').setValue(0);
  }

  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  getTypePayments() {
    this.api.getTypePayments().then((data: any) => {
      this.catPaymentTypes = data;
    }, error => {
    });
  }
  closeModalEndorsement() {
    const endorsementDTO = {
      saveData: true,
      endorsementId: this.endorsementForm.get('endorsementId').value
    };
    this.saveDataPolicy.emit(endorsementDTO);
    this._modalSaveEndorsement.hide();
  }
  sumTotalPremium() {
    if (this.endorsementForm.value.receiptBusiness.taxId > 0) {
      this.reCalTax();
    }
    this.calCommissionAmount();

    if (this.chargesPercentage === 0 && this.endorsementForm.value.receiptBusiness.paymentCharges > 0) {
      this.calChargesPercentage();
    }
    // tslint:disable-next-line:max-line-length
    this.endorsementForm.get('receiptBusiness.totalPremium').setValue(this.endorsementForm.value.receiptBusiness.netPremium
      + this.endorsementForm.value.receiptBusiness.rights
      + this.endorsementForm.value.receiptBusiness.paymentCharges
      + this.endorsementForm.value.receiptBusiness.iva);
  }
  loadDataTaxes() {
    this.api.getTaxes().then((data: any) => {
      this.catTaxes = data;
    }, error => {
    });
  }
  calTax(i) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {

        this.endorsementForm.get('receiptBusiness.iva').setValue(
          ((this.endorsementForm.value.receiptBusiness.netPremium
          + this.endorsementForm.value.receiptBusiness.rights + this.endorsementForm.value.receiptBusiness.paymentCharges)
          * tax.taxValue) / 100
        );

        this.sumTotalPremium();
      }
    }
  }
  reCalTax() {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(this.endorsementForm.value.receiptBusiness.taxId)) {
        this.endorsementForm.get('receiptBusiness.iva').setValue(
          ((this.endorsementForm.value.receiptBusiness.netPremium
          + this.endorsementForm.value.receiptBusiness.rights
          + this.endorsementForm.value.receiptBusiness.paymentCharges) * tax.taxValue) / 100
        );
      }
    }
  }
  calCommissionAmount() {
    const commissionAmount = (this.endorsementForm.value.receiptBusiness.netPremium
      * this.endorsementForm.value.receiptBusiness.commission) / 100;

    this.endorsementForm.get('receiptBusiness.commissionAmount').setValue(commissionAmount.toFixed(4));
  }
  calChargesPercentage() {
    const chargesPercentageTmp = (this.endorsementForm.value.receiptBusiness.paymentCharges * 100)
      / this.endorsementForm.value.receiptBusiness.netPremium;

    this.chargesPercentage = chargesPercentageTmp;
  }
  calChargesAmount() {
    const chargesAmount =  (this.endorsementForm.value.receiptBusiness.netPremium * this.chargesPercentage) / 100;
    this.endorsementForm.get('receiptBusiness.paymentCharges').setValue(chargesAmount);
  }
  calCommissionPercentage() {
    const commssion = (this.endorsementForm.value.receiptBusiness.commissionAmount * 100)
      / this.endorsementForm.value.receiptBusiness.netPremium;
    this.endorsementForm.get('receiptBusiness.commission').setValue(commssion.toFixed(2));
  }

  formValid(): boolean {
    return this.endorsementForm.invalid;
  }
  @Input()
  set modalSaveEndorsement(value: BsModalRef) {
    this._modalSaveEndorsement = value;
  }
  @Input()
  set dataToSendGeneral(value: any) {
    this._dataToSendGeneral = value;
    this.endorsementForm.controls.policyId.setValue(this.dataToSendGeneral.policyId);
    this.endorsementForm.get('receiptBusiness.policyId').setValue(this.dataToSendGeneral.policyId);
    this.endorsementForm.get('receiptBusiness.paymentType').setValue(this.dataToSendGeneral.paymentTypeId);
    this.invokeEndorsements(value.policyId);
  }

  get dataToSendGeneral(): any {
    return this._dataToSendGeneral;
  }

  validateEndorsement() {
    const endor = {
      policyId: this.dataToSendGeneral.policyId,
      endorsement:  this.endorsementForm.value.endorsement
    };
    this.api.getValidateEndorsementByPolicy(endor).then((data: any) => {
      if (data.endorsementId > 0) {
        this.toastr.warning('EL NUMERO DE ENDOSO YA FUE REGISTRADO EN ESTA PÓLIZA, POR FAVOR INGRESE UN NUEVO NUMERO', 'NOTIFICACIÓN');
        this.endorsementForm.get('endorsement').setValue('');
      }
    }, error => {
    });
  }

  invokeFunctionSelectTypeEndorsement() {
    this.typeEndorsement = this.endorsementForm.get('endorsementTypeId').value;
    if (this.endorsementForm.get('endorsementTypeId').value === 1) {
      this.patternString = '^[0-9]+([.][0-9]{1,4})?$';
    } else {
      this.patternString = '^[-][0-9]+([.][0-9]{1,4})?$';
      this.patternStringPN = '^[-]?[0-9]+([.][0-9]{1,4})?$';
    }
  }


  get dateReceiptForCloseEndorsement(): any {
    return this._dateReceiptForCloseEndorsement;
  }

  @Input()
  set dateReceiptForCloseEndorsement(value: any) {
    this._dateReceiptForCloseEndorsement = value;
    this.endorsementForm.controls.endorsementDescription.setValue('Endoso de cierre');
    this.typeEndorsement = this.dateReceiptForCloseEndorsement.endorsementTypeId;
    this.endorsementForm.controls.endorsementTypeId.setValue(this.dateReceiptForCloseEndorsement.endorsementTypeId);
    this.endorsementForm.get('receiptBusiness.netPremium').setValue(this.dateReceiptForCloseEndorsement.netPremium);
    this.endorsementForm.get('receiptBusiness.rights').setValue(this.dateReceiptForCloseEndorsement.rights);
    this.endorsementForm.get('receiptBusiness.paymentCharges').setValue(this.dateReceiptForCloseEndorsement.paymentCharges);
    this.endorsementForm.get('receiptBusiness.iva').setValue(this.dateReceiptForCloseEndorsement.iva);
    this.endorsementForm.get('receiptBusiness.totalPremium').setValue(this.dateReceiptForCloseEndorsement.totalPremium);

  }

  invokeServiceForDownloadEndorsementFile() {
    const endorsement = {
      endorsementId:  this.endorsementForm.get('endorsementId').value
    };
    this.api.getEndorsementFile(endorsement)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', this.endorsementForm.get('endorsement').value);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          // console.error(error);
        }
      );
  }
}
