import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {DatePipe} from '@angular/common';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-graph-cobranza',
  templateUrl: './graph-cobranza.component.html',
  styleUrls: ['./graph-cobranza.component.css']
})
export class GraphCobranzaComponent implements OnInit {

  reportCobranzaList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filtersHistogram: any = [];

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {xAxes: [{}], yAxes: [{}]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItems, data) => {
          return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' %';
        }
      }
    },

  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [];
  barChartColors: Color[] = [
  ];

  private _filters: any = {};
  private _typGraphicCobranza;
  reportProductionList: any = [];
  modalRef: BsModalRef;

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe,
              private validateAccess: ValidateAccessService) {
  }

  ngOnInit() {


  }

  loadChart() {
    this.spinner.show('sp');
    this.reportProductionList = [];
    this.api.getReportsCobranzaGraphic(this._filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.reportProductionList = [];
            this.spinner.hide('sp');
            return;
          }
          this.reportProductionList = response;
          this.creatChart();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportProductionList = [];
          this.spinner.hide('sp');
        }
      );
  }

  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    const fecha = new Date();
    const actualYear = fecha.getFullYear();
    const monthYear = fecha.getMonth();
    const dateStart = new Date(actualYear, monthYear, 1).toString();
    const dateEnd = new Date(actualYear, monthYear + 1, 0);

    this._filters = {
      idGrupo: [],
      idSubgrupo: [],
      idRamo: [],
      idSubRamo: [],
      idEjecutivo: [],
      idSalesman: [],
      idAseguradora: [],
      typeGraphic: this.filters.typGraphicCobranza,
      dateStart,
      dateEnd
    };

    this.loadChart();
  }

  creatChart() {
    const pendiente = {
      data: [],
      label: 'Pendiente',
      stack: 'a',
      borderColor: [],
      borderWidth: {
        top: 0,
        right: 3,
        bottom: 3,
        left: 3
      },
      // surexs
      // backgroundColor: 'rgb(234,228,59)',
      // hoverBackgroundColor: 'rgba(234,228,59,0.6)'
      // ghfk
      backgroundColor: 'rgb(59, 187, 172)',
      hoverBackgroundColor: 'rgba(59, 187, 172,0.6)'
    };
    const cancelado = {
      data: [],
      label: 'Cancelado',
      stack: 'a',
      borderColor: [],
      borderWidth: {
        top: 0,
        right: 3,
        bottom: 0,
        left: 3
      },
      backgroundColor: 'rgb(194,29,9)',
      hoverBackgroundColor: 'rgba(194,29,9,0.6)'
    };
    const pagado = {
      data: [],
      label: 'pagado',
      stack: 'a',
      borderColor: [],
      borderWidth: {
        top: 3,
        right: 3,
        bottom: 0,
        left: 3
      },
      // surexs
      // backgroundColor: 'rgb(95,183,2)',
      // hoverBackgroundColor: 'rgba(95,183,2,0.6)'
      // ghfk
      backgroundColor: 'rgb(169, 216, 98)',
      hoverBackgroundColor: 'rgb(119,168,50)'
    };
    const isNewDataSet = false;
    let P = false;
    let C = false;
    let Pg = false;
    let label = '';
    const monthAccurate = new Date().getFullYear() + ' ' + this.getLabelMonth((new Date().getMonth()) + 1);
    let bordercolor = [];

    // Obtenemos las etiquetas agrupadoras
    this.reportProductionList.forEach(X => {

      if (this.barChartLabels.length === 0) {
        this.barChartLabels.push(X.year.toString().concat(' ').concat(X.month));
      } else {
        if (!this.barChartLabels.some(Y => Y === X.year.toString().concat(' ').concat(X.month))) {
          this.barChartLabels.push(X.year.toString().concat(' ').concat(X.month));
        }
      }

      if (label === '') {
        if (X.status === 'PENDIENTE') {
          pendiente.data.push(X.total);
          P = true;
        } else if (X.status === 'CANCELADO') {
          cancelado.data.push(X.total);
          C = true;
        } else if (X.status === 'PAGADO') {
          pagado.data.push(X.total);
          Pg = true;
        }
        label = (X.year.toString().concat(' ').concat(X.month));

      } else if (X.year.toString().concat(' ').concat(X.month) === label) {
        if (X.status === 'PENDIENTE') {
          pendiente.data.push(X.total);
          P = true;
        } else if (X.status === 'CANCELADO') {
          cancelado.data.push(X.total);
          C = true;
        } else if (X.status === 'PAGADO') {
          pagado.data.push(X.total);
          Pg = true;
        }
        label = (X.year.toString().concat(' ').concat(X.month));
      } else {
        if (P === false) {
          pendiente.data.push(0);
        }
        if (C === false) {
          cancelado.data.push(0);
        }
        if (Pg === false) {
          pagado.data.push(0);
        }

        P = false;
        C = false;
        Pg = false;

        if (X.status === 'PENDIENTE') {
          pendiente.data.push(X.total);
          P = true;
        } else if (X.status === 'CANCELADO') {
          cancelado.data.push(X.total);
          C = true;
        } else if (X.status === 'PAGADO') {
          pagado.data.push(X.total);
          Pg = true;
        }

        label = (X.year.toString().concat(' ').concat(X.month));
      }

    });
    this.barChartLabels.forEach(labels => {
      if (labels === monthAccurate) {
        bordercolor.push('rgb(5,5,5)');
      } else {
        bordercolor.push('rgba(33,81,238,0)');
      }
    });

    pendiente.borderColor = bordercolor;
    cancelado.borderColor = bordercolor;
    pagado.borderColor = bordercolor;

    this.barChartData.push(pendiente);
    this.barChartData.push(cancelado);
    this.barChartData.push(pagado);

  }


  get filters(): any {
    return this._filters;
  }

  chartClicked(template: TemplateRef<any>, e: any): void {
    this.validateAccess.validateAccessCore(2).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        if (e.active.length > 0) {
          const chart = e.active[0]._chart;
          const activePoints = chart.getElementAtEvent(e.event);

          if (activePoints.length > 0) {

            const clickedElementIndex = activePoints[0]._index;
            const label = chart.data.labels[clickedElementIndex];
            const labelSplited = label.split(' ');
            const year = labelSplited[0];
            const month = (this.getNumberMonth(labelSplited[1]));

            this.filtersHistogram = this.filters;

            /*this.filtersHistogram = {
              idGrupo: [],
              idSubgrupo: [],
              idRamo: [],
              idSubRamo: [],
              idEjecutivo: [],
              idSalesman: [],
              idStatus: [],
              idAseguradora: [],
              dateStart: this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd'),
              dateEnd: this.datePipe.transform(new Date(year, month + 1, 0), 'yyyy-MM-dd'),
              page: 1,
              size: 10,
            };*/

            this.filtersHistogram.dateStart = this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd');
            this.filtersHistogram.dateEnd = this.datePipe.transform(new Date(year, month + 1, 0), 'yyyy-MM-dd');
            this.filtersHistogram.page = 1;
            this.filtersHistogram.size = 10;
            this.getByPagination(template, 1, 10);

          }
        }
      }
    });
  }

  getNumberMonth(month: string): number {
    switch (month) {
      case 'ENE':
        return 1;
        break;
      case 'FEB':
        return 2;
        break;
      case 'MAR':
        return 3;
        break;
      case 'ABR':
        return 4;
        break;
      case 'MAY':
        return 5;
        break;
      case 'JUN':
        return 6;
        break;
      case 'JUL':
        return 7;
        break;
      case 'AGO':
        return 8;
        break;
      case 'SEP':
        return 9;
        break;
      case 'OCT':
        return 10;
        break;
      case 'NOV':
        return 11;
        break;
      case 'DIC':
        return 12;
        break;
    }
  }

  getLabelMonth(month: number): String {
    switch (month) {
      case 1:
        return 'ENE';
        break;
      case 2:
        return 'FEB';
        break;
      case 3:
        return 'MAR';
        break;
      case 4:
        return 'ABR';
        break;
      case 5:
        return 'MAY';
        break;
      case 6:
        return 'JUN';
        break;
      case 7:
        return 'JUL';
        break;
      case 8:
        return 'AGO';
        break;
      case 9:
        return 'SEP';
        break;
      case 10:
        return 'JUL';
        break;
      case 11:
        return 'NOV';
        break;
      case 12:
        return 'DIC';
        break;
    }
  }

  /**
   * Función para obtener las pólizas  para reportear del sistema
   */
  getByPagination(template: TemplateRef<any>, page, size) {
    this.spinner.show('sp');
    this.filtersHistogram.page = page;
    this.filtersHistogram.size = size;
    this.api.getReportsProduccionByCobranza(this.filtersHistogram)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTS CON SU BUSQUEDA');
            this.reportCobranzaList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.reportCobranzaList = this.paginationResponse.tList;
          if (template != null) {
            this.openModal(template);
          }
          this.spinner.hide('sp');
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportCobranzaList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  openModal(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }

  @Input()
  set filters(value: any) {
    this._filters = value;
    if (this._filters.dateStart === null) {
      const fecha = new Date();
      const actualYear = fecha.getFullYear();
      const monthYear = fecha.getMonth();
      const dateStart = this.datePipe.transform(new Date(actualYear, monthYear, 1), 'yyyy-MM-dd');
      const dateEnd = this.datePipe.transform(new Date(actualYear, monthYear + 1, 0), 'yyyy-MM-dd');

      this._filters.dateStart = dateStart;
      this._filters.dateEnd = dateEnd;
    }
    this.barChartData = [];
    this.barChartLabels = [];
    this.loadChart();
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(null, this.pgCurrentPage, this.pgItemsPerPage);
  }
  changeColorPagination() {
  }

  invokeServiceForGetReportsCobranzaCSV() {
    this.spinner.show('sp');
    this.api.getReportsCobranzaCSV(this.filtersHistogram)
      .then(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', 'cobranza.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.reportCobranzaList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

}
