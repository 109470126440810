import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {document} from 'ngx-bootstrap/utils';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {regex} from '../../common/regex';
import {DualListComponent} from 'angular-dual-listbox';
import {TabsetComponent} from 'ngx-bootstrap/tabs';
import {ITreeOptions} from '@circlon/angular-tree-component';
import {DatePipe, formatNumber} from '@angular/common';
import {FileUploadControl, FileUploadValidators} from '@iplab/ngx-file-upload';
import {Documents} from '../upload-file/documents';

declare var $: any;
@Component({
  selector: 'app-damage-policy',
  templateUrl: './damage-policy.component.html',
  styleUrls: ['./damage-policy.component.css']
})
export class DamagePolicyComponent implements OnInit {
  // variables generales de módulo
  // @ViewChild('gestionTabs', {static: false}) staticTabs: TabsetComponent;
  // variables para datos generales
  dataToSendGeneral: any = {};
  policyForm: FormGroup;
  emmitHousingForm: FormGroup;
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  bsExpirationDate: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  startDateTmp: string;
  isRenewPolicy: boolean;
  endDateTmp: any;
  dataExpired: any = [];
  policyIdExpired: number;
  policyId: number;
  modalDates: BsModalRef;
  modalSaveEndorsement: BsModalRef;
  catClients: any = [];
  catGroups: any = [];
  catTypeAdmin: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  dropdownSettingsCoordinators = {};
  clientId: number;
  clientHousingId: number;
  catClientsPolicy: any = [];
  policyRenew: any = {};
  currencyList: any = [];
  salesmanList: any = [];
  executiveSurexList: any = [];
  SurexsSelectedItems: any = [];
  dropdownSettingsSurexs = {};
  executiveIncidentList: any = [];
  incidentsSelectedItems: any = [];
  dropdownSettingsIncidents = {};
  executiveSurexs: any = [];
  catAgentKeys: any = [];
  catInsuranceCompany: any = [];
  catBranch: any = [];
  catSubBranch: any = [];
  modalRefRenew: BsModalRef;
  catCoordinatorList: any = [];
  CoordinatorsSelectedItems: any = [];
  catManagerIncidentList: any = [];
  catPaymentTypes: any = [];
  catTypePayments: any = [];
  typeSubBranch: number;
  isLoadPolicy: boolean;
  bGeneral: boolean;
  bAttachment: boolean;
  bReceipt: boolean;
  attachmentCatalog: any = [];
  lclSectionSelected: number;
  policyAttachmentList: any = [];
  bReceiptC: number;
  receiptList: any = [];
  paymentTypeOld: number;
  typePaymentOld: number;
  public fileUploadPolicy: File;
  fileTypesPolicy: string[] = ['application/pdf'];
  // variables para ubicaciones
  filters: any = {};
  locationList: any = [];
  // -Varibales para paginado->
  pgTableBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgTableMaxSize = 3; // Número de links en paginado
  pgTableItemsPerPage = 8; // Número de registros por página
  pgTableTotalItems: number; // Total de registros
  pgTableCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  searchAddress: string;
  addressList: any = [];
  addressListPag: any = [];
  addressId: number;
  dataAddressFiscal: any = {};
  typesAddress: any = [];
  countryListFiscal: any = [];
  countryList: any = [];
  countryListOther: any = [];
  municipalityListFiscal: any = [];
  municipalityList: any = [];
  municipalityListOther: any = [];
  zipCodeListFiscal: any = [];
  zipCodeList: any = [];
  zipCodeListOther: any = [];
  stateListFiscal: any = [];
  stateList: any = [];
  stateListOther: any = [];
  neighborhoodListFiscal: any = [];
  neighborhoodList: any = [];
  neighborhoodListOther: any = [];
  streetsListFiscal: any = [];
  streetsList: any = [];
  streetsListOther: any = [];
  selectStateFiscalyTemp: any = {};
  selectMunicipalityFiscalTemp: any = {};
  selectZipCodeFiscalTemp: any = {};
  selectNeighborhoodFiscalTemp: any = {};
  selectStreetFiscalTemp: any = {};
  _FISCAL = 'FISCAL';
  streetFiscalNew: any = {};
  isNewStreetFiscal = false;
  addressForm: FormGroup;
  countryFiscal: string;
  modalRef: BsModalRef;
  typeWallCeiling: any = [];
  catSecurityMeasure: any = [];
  securityMeasureSelectedItems: any = [];
  dropdownSettingsSecurityMeasure: any = {};
  catFireProtection: any = [];
  fireProtectionSelectedItems: any = [];
  dropdownSettingsFireProtection: any = {};
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  subActivity: string;
  subActivityList: any = [];
  modalLocation: BsModalRef;
  operationLocation: number;
  base64File2: string;
  dataDuplicateLocation: any = {};
  documentUpload: any = {};
  formMdl: FormGroup;
  public fileUploadControlMdl = new FileUploadControl({listVisible: false, discardInvalid: true, accept: ['.pdf']},
    [FileUploadValidators.accept(['.pdf']), FileUploadValidators.fileSize(25428800)]);
  multiple: true;
  dataAddressAmmia: any = {};
  isSameDocument: boolean;
  // variables contactos
  myFormContact: FormGroup;
  contactList: any = [];
  searchContact: string;
  contactId: number;
  dataContact: any = {};
  contactTypeList: any = {};
  contactTypeSelected: any = {};
  emailContactOld: string;
  dataToSendReceiptLocation: any = {};
  chargesPercentageDamage: number;
  // -Varibales para paginado de contactos->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 5; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponseContact: any = {};
  list;
  list2;
  catLocations: any = [];
  locationsSelectedItems: any = [];
  dropdownSettingsLocations = {};
  nameLocation: string;
  dataContactTable: any = {};
  // variables para secciones
  coveragesAmmia: any = [];
  sections: any = [];
  sectionList: Array<any>;
  sectionListSelected: Array<any>;

  dataSelection: any = {};
  @ViewChild('gestionTabs', {static: false}) staticTabs1: TabsetComponent;
  @ViewChild('sectionTabs', {static: false}) staticTabs2: TabsetComponent;
  nodes: any = [];
  optionsSection: ITreeOptions = {
    useCheckbox: true,
    isExpandedField: 'expanded',
    nodeHeight: 50
  };
  locationId: number;
  dataSectionSave: any = {};
  luc: boolean;
  // VARIABLES PARA COBRANZA
  categoryList: any = [];
  categoryName: string;
  categoryId: number;
  dataToSendReceipt: any = {};
  catTaxes: any = [];
  isSplitRights: boolean;
  dataReceiptEdith: any = {};
  receiptData: any = {};
  statusList: any = [];
  paymentList: any = [];
  chargesPercentage = 0;
  receiptForm: FormGroup;
  myFormReceipt: FormGroup;
  receiptTotal: any = {};
  netPremiumTmp: number;
  dataToSendCancelPolicy: any = {};
  dataToSendIssuePolicy: any = {};
  validationPolicy: any = {};
  bsPaymentDeadline: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  policyAmount: any = {};
  // variables operaciones globales
  typeOperationRemove: number;
  typeId: number;
  misCrystals: boolean;
  misTheft: boolean;
  misMoney: boolean;
  misAdvertisements: boolean;
  misRc: boolean;
  misItems: boolean;
  misAssault: boolean;
  firstLocationId: number;
  // IMPORTACIONES MASIVAS
  massImportList: any = [];
  consequentialLoss: any = [];
  containedFireList: any = [];
  buildingFireList: any = [];
  boilerList: any = [];
  machinery: any = [];
  electronicEquipmentList: any = [];
  contractorEquipment: any = [];
  glassList: any = [];
  theftList: any = [];
  moneyList: any = [];
  advertisementsList: any = [];
  rcList: any = [];
  officeList: any = [];
  dataEmmitHousing: any = {};
  catGenderList: any = [];
  // variables para coberturas obra civil
  categorySpecialCoverageList: any = [];
  specialCoverageList: Array<any>;
  specialCoverageListSelected: Array<any>;
  dataBasicCoverage: any = {};
  endorsements: any = [];
  endorsementListSelected: any = [];
  tmpEndorsements: any = [];
  dataCoverageAdditional: any = [];
  dataDetailCivilWork: any = {};
  civilWorkForm: FormGroup;
  key: any;
  display: any;
  format = {
    add: 'ASIGNAR ENDOSOS',
    remove: 'QUITAR ENDOSO',
    all: 'SELECCIONAR TODOS',
    none: 'DESELECCIONAR',
    direction: DualListComponent.LTR,
    draggable: true };
  endorsementList: any = [];
  endorsementId: number;
  operationSystem: number;
  endorsementDataCivilWork: any = {};
  editData: boolean;
  constructor( private api: ApiService,
               private router: Router,
               private toastr: ToastrService,
               private shared: SharedService,
               private datePipe: DatePipe,
               public fb: FormBuilder,
               private spinner: NgxSpinnerService,
               private modalService: BsModalService,
               private formBuilder: FormBuilder) {
    this.policyForm  = this.fb.group({
      numberPolicy: [''],
      startDate: ['', [Validators.required]],
      expirationDate: ['', [Validators.required]],
      group: ['', [Validators.required]],
      client: ['', [Validators.required]],
      insurance: ['', [Validators.required]],
      keyAgent: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      typeAdmin: ['', [Validators.required]],
      subBranch: ['', [Validators.required]],
      salesman: ['', [Validators.required]],
      coordinator: ['', [Validators.required]],
      executive: ['', [Validators.required]],
      incident: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      payment: ['', [Validators.required]],
      collection: ['', [Validators.required]],
      renewPolicy: ['', [Validators.required]],
      managerIncident: ['', [Validators.required]],
      max_age_limit: [''],
      office: [''],
      startDate1: [''],
      expirationDate1: [''],
    });
    this.addressForm = new FormGroup({
      nameLocation: new FormControl(null, [
        Validators.required
      ]),
      numIntFiscal: new FormControl(null, [
        Validators.maxLength(40),
        Validators.required
      ]),
      numExtFiscal: new FormControl(null, [
        Validators.maxLength(40),
        Validators.required
      ]),
      countryFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      stateFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      municipalityCodeFiscal: new FormControl({value: null, disabled: true}, [
        Validators.required
      ]),
      zipCodeFiscal: new FormControl(null, [
        Validators.required
      ]),
      neighborhoodFiscal: new FormControl(null, [
        Validators.required
      ]),
      streetFiscal: new FormControl(null, [
        Validators.required, Validators.maxLength(100)
      ]),
      agregatCalle: new FormControl(),
      typeWall: new FormControl(null, [
        Validators.required
      ]),
      activity: new FormControl(null, [
        Validators.required
      ]),
      basement: new FormControl(null, [
        Validators.required
      ]),
      floor: new FormControl(null, [
        Validators.required
      ]),
      numberLocation: new FormControl(null, [
        Validators.required
      ]),
      subGroup: new FormControl(null, [
        Validators.required
      ]),
      securityMeasure: new FormControl(null, [
        Validators.required
      ]),
      fireProtection: new FormControl(null, [
        Validators.required
      ]),
      sameDocument: new FormControl()
    });
    this.myFormContact = this.fb.group({
      nameContact: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$'), Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.pattern(regex.correoElectronicolarge), Validators.maxLength(60)]],
      phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        Validators.maxLength(10)]],
      namePosition: ['', [Validators.required, Validators.maxLength(100)]],
      locations: ['']
    });
    this.receiptForm = this.fb.group({
      payment: new FormControl({ value: '', disabled : true} , Validators.required),
      netPremium: ['', [Validators.required]],
      rights: ['', [Validators.required]],
      charges: new FormControl({ value: '', disabled : true} , Validators.required),
      commission: ['', [Validators.required]],
      commissionAmount: ['', [Validators.required]],
      iva: ['', [Validators.required]],
      ivaAmount: new FormControl({ value: '', disabled : true} , Validators.required),
      totalPremium: new FormControl({ value: '', disabled : true} , Validators.required),
      chargesPercentage: new FormControl({ value: '', disabled : true} , Validators.required)
    });
    this.myFormReceipt = this.fb.group({
      numberReceipt: [''],
      paymentDeadline: [''],
      paymentDate: [''],
      netPremium: [''],
      rights: [''],
      paymentCharges: [''],
      iva: [''],
      totalPremium: [''],
      receiptStatus: [''],
      paymentForm: [''],
      settlementNumber: ['']
    });
    this.formMdl = this.formBuilder.group({
      filesMdl: new FormControl()
    });
    this.emmitHousingForm = this.fb.group({
      policy: ['', [Validators.required, Validators.maxLength(100)]],
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$'), Validators.maxLength(100)]],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$'), Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+$'), Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.pattern(regex.correoElectronicolarge), Validators.maxLength(60)]],
      birthDate: ['', [Validators.required]],
      gender: ['', [Validators.required]]
    });
    this.civilWorkForm = this.fb.group(
      {
        namecw: new FormControl(null, [
          Validators.required
        ]),
        descriptioncw: new FormControl(null, [
          Validators.required
        ]),
        countrycw: new FormControl({value: null, disabled: true}, [
          Validators.required
        ]),
        statecw: new FormControl({value: null, disabled: true}, [
          Validators.required
        ]),
        municipalityCodecw: new FormControl({value: null, disabled: true}, [
          Validators.required
        ]),
        zipCodecw: new FormControl(null, [
          Validators.required
        ]),
        neighborhoodcw: new FormControl(null, [
          Validators.required
        ]),
        streetcw: new FormControl(null, [
          Validators.required, Validators.maxLength(100)
        ]),
        agregatCallecw: new FormControl(),
        numIntcw: new FormControl(null, [
          Validators.maxLength(40),
          Validators.required
        ]),
        numExtcw: new FormControl(null, [
          Validators.maxLength(40),
          Validators.required
        ])
      }
    );
    this.editData = true;
    this.shared.gPolicyIdDamageObservable().subscribe(
      gPolicyIdDamage => {
        const policy = parseInt(localStorage.getItem('policy-id'), 10);
        // // console.log('policyId');
        // // console.log(policy);
        // // console.log(gPolicyId);
        if (gPolicyIdDamage > 0) {
          localStorage.setItem('policy-id', String(gPolicyIdDamage));
          this.shared.fPolicyDataReadonly = true;
        } else if (gPolicyIdDamage < 0) {
          if (policy > 0) {
            this.shared.fPolicyDataReadonly = true;
          } else {
            this.shared.fPolicyDataReadonly = false;
          }
        } else {
          localStorage.setItem('policy-id', String(gPolicyIdDamage));
          this.shared.fPolicyDataReadonly = false;
        }
        if (this.shared.fPolicyDataReadonly) {
          // // console.log('edición de documento');
          this.bGeneral = true;
          this.bAttachment = true;
          this.bReceipt = true;
          this.loadCatalogGenerals();
          this.policyAttachmentList = [];
          this.bReceiptC = 1;
          this.receiptList = [];
          setTimeout(() => {
            this.getTypesAdminPolicy();
            this.getPolicy();
            // @ts-ignore
            $('#smartwizard').smartWizard('goToStep', 0);
          }, 1000);
        } else {
          this.policyId = 0;
          this.bGeneral = true;
          this.bAttachment = false;
          this.bReceipt = false;
          this.loadCatalogGenerals();
          this.getIdOT();
          this.clientsSelectedItems = [];
          setTimeout(() => {
            this.isLoadPolicy = true;
            // se cargan ejecutivos default
            this.CoordinatorsSelectedItems = [ {name: 'JOSE LUIS TREJO NIETO', directoryId: 42 } ];
            this.chanceStatusCoordinators();
            this.SurexsSelectedItems = [{name: 'MARCO ANTONIO RAMOS SANCHEZ', directoryId: 65}];
            this.changeStatusSurexs();
            this.incidentsSelectedItems = [{name: 'LEOBARDO VICTOR FLORES ARENAS', directoryId: 41}];
            this.changeStatusIncidents();
          }, 1000);
        }
        this.lclSectionSelected = 0;
      }
    );
  }

  ngOnInit() {

    this.clientHousingId = 0;
    this.operationLocation = 1;
    // @ts-ignore
    $('#smartwizard').smartWizard({
      selected: 0, // Initial selected step, 0 = first step
      enableAllSteps: true,
      theme: 'default', // theme for the wizard, related css need to include for other than default theme
      justified: true, // Nav menu justification. true/false
      autoAdjustHeight: true, // Automatically adjust content height
      cycleSteps: false, // Allows to cycle the navigation of steps
      backButtonSupport: true, // Enable the back button support
      enableURLhash: false, // Enable selection of the step based on url hash,
      enableAnchorOnDoneStep: true,
      transition: {
        animation: 'none', // Effect on navigation, none/fade/slide-horizontal/slide-vertical/slide-swing
        speed: '400', // Transion animation speed
        easing: '' // Transition animation easing. Not supported without a jQuery easing plugin
      },
      toolbarSettings: {
        toolbarPosition: 'bottom', // none, top, bottom, both
        toolbarButtonPosition: 'right', // left, right, center
        showNextButton: false, // show/hide a Next button
        showPreviousButton: false, // show/hide a Previous button
        toolbarExtraButtons: [] // Extra buttons to show on toolbar, array of jQuery input/buttons elements
      },
      anchorSettings: {
        anchorClickable: true, // Enable/Disable anchor navigation
        enableAllAnchors: true, // Activates all anchors clickable all times
        markDoneStep: true, // Add done state on navigation
        markAllPreviousStepsAsDone: true, // When a step selected by url hash, all previous steps are marked done
        removeDoneStepOnNavigateBack: true, // While navigate back done step after active step will be cleared
        enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
      },
      keyboardSettings: {
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        keyLeft: [37], // Left key code
        keyRight: [39] // Right key code
      },
      lang: { // Language variables for button
        next: 'SIGUIENTE',
        previous: 'ANTERIOR'
      },
      disabledSteps: [], // Array Steps disabled
      errorSteps: [], // Highlight step with errors
      hiddenSteps: [] // Hidden steps
    });

    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.bsExpirationDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });

    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurexs = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsIncidents = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCoordinators = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON COORDINADORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSecurityMeasure = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON MEDIDAS DE SEGURIDAD',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsFireProtection = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PROTECCIÓN CONTRA INCENDIOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsLocations = {
      singleSelection: false,
      idField: 'locationId',
      textField: 'location',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON UBICACIONES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.bsPaymentDeadline = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
  }

  /* -----------------------------------------------------------------------------------------------------
    ------------------------F U N C I O N E S G E N E R A L E S-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  loadDateSmartWizardMass(id) { }
  loadDateSmartWizard(id) {
    // // console.log('pestaña: ' + id);
    switch (id) {
      case 0:
        console.info('general ');
        break;
      case 1:
        // console.log('ubicaciones');
        if (this.dataToSendGeneral.subBranchId === 1 || this.dataToSendGeneral.subBranchId === 15) {
          this.loadDataTaxes();
          this.initFiltersLocation();
          this.loadFiltersTable();
          this.operationLocation = 1;
        } else if (this.dataToSendGeneral.subBranchId === 18) {
          this.invokeFunctionInitialValuesDetail();
          this.invokeServiceGetCountries();
          this.invokeServiceGetStatesByCountry();
          this.loadInitialValuesData();
          if (this.shared.fPolicyDataReadonly) {
            this.invokeServiceGetDetailCivilWork();
          }
          this.invokeServiceGetBasicCoverageCivilWork();
        }
        break;
      case 2:
        // console.log('anexos');
        break;
      case 3:
        // console.log('cobranza');
        this.categoryList = [];
        this.receiptList = [];
        this.getCategoriesPolicy();
        this.loadDataTaxes();
        this.getPaymentForms();
        this.getStatusReceipts();
        this.loadDataToSendReceipt();
        break;
      case 4:
        break;
      case 5:
        break;
    }
  }
  nextOperation(id) {
    switch (id) {
      case 2:
        setTimeout(() => {
          // @ts-ignore
          $('#smartwizard').smartWizard('goToStep', 2);
          this.loadDateSmartWizard(2);
        }, 1000);
        break;
      case 3:
        setTimeout(() => {
          // @ts-ignore
          $('#smartwizard').smartWizard('goToStep', 3);
          this.loadDateSmartWizard(3);
        }, 1000);
        break;
      case 4:
        setTimeout(() => {
          // @ts-ignore
          $('#smartwizard').smartWizard('goToStep', 4);
          this.loadDateSmartWizard(4);
        }, 1000);
        break;

      case 5:
        setTimeout(() => {
          // @ts-ignore
          $('#smartwizard').smartWizard('goToStep', 2);
        }, 1000);
        break;
    }
  }
  newDocument() {
    this.shared.fPolicyDataReadonly = false;
    this.shared.gPolicyIdDamage.next(0);
    localStorage.setItem('policy-id', String(0));
    setTimeout(() => {
      // @ts-ignore
      $('#smartwizard').smartWizard('goToStep', 0);
    }, 1000);
  }
  confirmDelete() {
    switch (this.typeOperationRemove) {
      case 1: // location
        this.deleteLocationDamage();
        break;
      case 2: // contact
        this.deleteContactDamage();
        break;
      case 3: // section
        this.deleteSectionDamage();
        break;
      case 4: // civil work
        this.deleteSectionDamage();
        break;
    }
  }
  openDelete(template: TemplateRef<any>, type: number, id: number) {
    this.typeOperationRemove = type;
    this.typeId = id;
    if (this.dataToSendGeneral.subBranchId === 18) {
      this.locationId = this.dataDetailCivilWork.locationId;
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
  }
  onChangeInputFile(e) {
    // console.log('carga de archivo');
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
    this.dataAddressFiscal.nameFile = this.fileUpload.name;
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataAddressFiscal.pathFile = btoa(e.target.result);
    // (this.dataAddressFiscal.pathFile);
  }
  showDashboardDamage(id: number) {
    this.operationLocation = id;
    switch (id) {
      case 1: // home locations
        this.initFiltersLocation();
        this.loadFiltersTable();
        break;
      case 2: // home contacts
        this.getLocationsContacts();
        this.initDataFilterContact();
        this.loadFiltersTableContact();
        break;
      case 3: // home sections
        // this.invokeServiceGetSections(15);
        this.locationId = 0;
        this.nameLocation = '';
        this.nodes = [];
        this.initSectionData();
        this.getLocationsContacts();
        break;
      case 4: // home mass import
        this.consequentialLoss = [];
        this.containedFireList = [];
        this.buildingFireList = [];
        this.boilerList = [];
        this.machinery = [];
        this.electronicEquipmentList = [];
        this.contractorEquipment = [];
        this.glassList = [];
        this.theftList = [];
        this.moneyList = [];
        this.advertisementsList = [];
        this.rcList = [];
        this.massImportList = [];
        this.invokeServiceGetCountries();
        this.invokeServiceGetStatesByCountry();
        this.getTypesHomeWallCeiling();
        this.loadInitialValuesData();
        this.invokeServiceGetClientsPolicy(localStorage.getItem('policy-id'));
        this.invokeServiceGetSecurityMeasure();
        this.invokeServiceGetFireProtection();
        break;
    }
  }
  /* -----------------------------------------------------------------------------------------------------
    ------------------------D A T O S G E N E R A L E S-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  loadCatalogGenerals() {
    this.dataToSendGeneral = {
      policyId: 0,
      number: 'OT00001',
      startDate: undefined,
      expirationDate: undefined,
      groupId: undefined,
      insuranceId: undefined,
      branchId: 0,
      subBranchId: 0,
      renew: false,
      status: 2,
      clients: [],
      currencyId: 1,
      userId: 0,
      paymentTypeId: 1,
      coordinators: [],
      executiveSurexs: [],
      executiveIncidents: [],
      policyIdOld: 0,
      agentKeyId: 0,
      typePaymentPolicy: 0,
      managerIncident: 0,
      maxAgeLimit: 25,
      startDatePeriod: '',
      endDatePeriod: '',
      medicalCare: false,
      typeAdministrationPolicy: 2
    };
    // this.getTypePayments();
    this.getSalesman();
    this.getCurrency();
    this.getGroups();
    this.getInsuranceCompanies();
    this.getBranches();
    this.getTypePayments();
    this.getTypePaymentsPolicy();
    this.getExecutives(1);
    this.getExecutives(2);
    this.getExecutives(3);
    this.getManagerIncident();
    this.getOffices();
    this.getTypesAdminPolicy();
    this.attachmentCatalog = [
      {attachmentId: 1, description: 'ARCHIVO'},
      {attachmentId: 8, description: 'DIRECTORIO MEDICO'}]; // , {attachmentId: 8, description: 'DIRECTORIO MEDICO'}
    this.SurexsSelectedItems = [];
    this.incidentsSelectedItems = [];
    this.CoordinatorsSelectedItems = [];
    this.clientsSelectedItems = [];
  }
  getIdOT() {
    this.api.getId().then((data: any) => {
      this.dataToSendGeneral.number = data.otId;
    }, error => {
    });
  }
  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      this.salesmanList = data;
      this.dataToSendGeneral.userId = this.salesmanList[0].userId;
    }, error => {
    });
  }
  getCurrency() {
    this.currencyList = [
      {currencyId: 1, name: 'MX'}, {currencyId: 2, name: 'USD'}
    ];
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.catGroups = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        // this.dataToSendGeneral.groupId = this.catGroups[0].groupId;
      }
      // this.getSubGroups(this.dataToSendGeneral.groupId);
    }, error => {
    });
  }
  getOffices() {
    this.api.getOffices().then((data: any) => {
      this.officeList = data;
      this.dataToSendGeneral.officeId = this.officeList[0].officeId;
    }, error => {
    });
  }
  getExecutivesByPolicy(id) {
    this.api.getExecutivesByPolicy(localStorage.getItem('policy-id'), id).then((data: any) => {
      switch (id) {
        case 1:
          this.CoordinatorsSelectedItems = data;
          this.chanceStatusCoordinators();
          break;
        case 2:
          this.SurexsSelectedItems = data;
          this.changeStatusSurexs();
          break;
        case 3:
          this.incidentsSelectedItems = data;
          this.changeStatusIncidents();
          break;
      }
    }, error => {
    });
  }
  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
          this.catCoordinatorList = data;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
    });
  }
  getPolicy() {
    this.api.getPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.dataToSendGeneral = data;
      // ('getpolicy', this.dataToSendGeneral);
      this.isRenewPolicy = this.dataToSendGeneral.renew;
      this.paymentTypeOld = this.dataToSendGeneral.paymentTypeId;
      this.typePaymentOld = this.dataToSendGeneral.typePaymentPolicy;
      this.policyId = this.dataToSendGeneral.policyId;
      this.getSubGroups(this.dataToSendGeneral.groupId);
      this.getSubBranches(this.dataToSendGeneral.branchId);
      this.getAgentKeys(this.dataToSendGeneral.insuranceId);
      this.getExecutivesByPolicy(1);
      this.getExecutivesByPolicy(2);
      this.getExecutivesByPolicy(3);
      setTimeout(() => {
        this.isLoadPolicy = true;
      }, 1000);
      if (this.dataToSendGeneral.branchId === 2) {// si es poliza de daños
        // // console.log('enro if ');
      }
      if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
        this.invokeServiceGetEndorsementByPolicy(this.dataToSendGeneral.policyId);
      }
    }, error => {
    });
    this.api.getClientsPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.clientsSelectedItems = data;
      this.clientHousingId = data[0].clientId;
      this.changeSubGroups();
    }, error => {
    });
  }
  public openModalAddExpiration(startDate: Date[], template: TemplateRef<any>) {
    // // console.log('función de fechas');
    this.selectedConceptTypeValidity = 1;
    this.yearsValidity = 0;
    if (!this.shared.fPolicyDataReadonly && this.policyId === 0) {
      if (startDate !== null) {
        this.startDateTmp = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'es-ES');
        this.modalDates = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
      }
    }
  }
  validateNumberYears() {
    if (this.yearsValidity < 0) {
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
    if (this.yearsValidity === 0) {
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER 0, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
  }
  public selectedTypeValidity() {
    switch (this.selectedConceptTypeValidity) {
      case 1:
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + 1, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 2:
        if (this.yearsValidity === 0 || this.yearsValidity === undefined) {
          this.toastr.warning('DEBE DE INGRESAR EL NUMERO DE AÑOS DE VIGENCIA DE LA PÓLIZA', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 0) {
          this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
          return;
        }
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + this.yearsValidity, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES');
        break;
      case 3:
        this.dataToSendGeneral.expirationDate = '';
        break;
    }
    if (this.dataToSendGeneral.subBranchId === 18) {
      this.dataToSendGeneral.endDatePeriod = this.datePipe.transform(this.dataToSendGeneral.expirationDate, 'yyyy-MM-dd', 'es-ES');
    }
    this.modalDates.hide();
  }
  getSubGroups(id) {
    this.api.getSubGroups(id).then((data: any) => {
      this.catClients = data;
      if (this.dataToSendGeneral.policyId === 0) {
        this.clientsSelectedItems = [];
        this.dataToSendGeneral.clients = [];
      }
    }, error => {
    });
  }

  getTypesAdminPolicy() {
    this.catTypeAdmin = [
      {
        typeAdminId: 1,
        name: 'AUTO ADMINISTRADA'
      },
      {
        typeAdminId: 2,
        name: 'A DETALLE'
      }

    ];
  }

  invokeServiceGetEndorsementByPolicy(policy) {
    const obj = {
      policyId: policy
    };
    this.api.getEndorsementsByPolicy(obj).then((data: any) => {
      this.endorsementList = data;
      this.endorsementId = this.endorsementList[this.endorsementList.length -  1].endorsementId;
      this.editData = this.endorsementList[this.endorsementList.length - 1].edit;
      setTimeout(() => {
        this.shared.gPolicyIdEndorsement.next(this.endorsementId);
      }, 1000);
    }, error => {
    });
  }
  invokeServiceLoadEndorsement() {
    console.log(this.endorsementId);
    if (this.endorsementId > 0) {
      this.endorsementList.forEach( item => {
        if (item.endorsementId === this.endorsementId) {
          this.editData = item.edit;
        }
      });
      this.shared.gPolicyIdEndorsement.next(this.endorsementId);
      $('#smartwizard').smartWizard('goToStep', 3);
    }
  }
  changeSubGroups() {
    const subGroup = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroup.push(item.clientId);
        });
        this.dataToSendGeneral.clients = subGroup;
        this.clientHousingId = subGroup[0];
      } catch (e) {
      }
    }, 1000);
  }
  getAgentKeys(insuranceId) {
    this.api.getAgentKeysByInsuranceCompany(insuranceId).then((data: any) => {
      this.catAgentKeys = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        this.dataToSendGeneral.agentKeyId = this.catAgentKeys[0].agentKeyId;
      }
    }, error => {
    });
  }
  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data;
      if (this.dataToSendGeneral.policyId <= 0) {
        // this.dataToSendGeneral.insuranceId = this.catInsuranceCompany[0].insuranceId;
      }
      // this.getAgentKeys(this.dataToSendGeneral.insuranceId);
    }, error => {
    });
  }
  getSubBranches(id) {
    this.api.getSubBranches(id).then((data: any) => {
      this.catSubBranch = data;
      if (this.dataToSendGeneral.policyId <= 0) {
       // this.dataToSendGeneral.subBranchId = this.catSubBranch[0].subBranchId;
      }
    }, error => {
    });
  }
  getBranches() {
    this.catBranch = [{branchId: 2, branchType: 'DAÑOS'}];
    if (this.dataToSendGeneral.policyId <= 0) {
      this.dataToSendGeneral.branchId = this.catBranch[0].branchId;
    }
    this.getSubBranches(this.dataToSendGeneral.branchId);
  }
  onChangeRenewPolicy(event, template: TemplateRef<any>) {
    this.isRenewPolicy = event;
    this.dataToSendGeneral.renew = event;
    this.policyIdExpired = 0;
    if (!this.shared.fPolicyDataReadonly) {
      if (this.isRenewPolicy) {
        if (this.dataToSendGeneral.startDate === undefined || this.dataToSendGeneral.groupId === 0
          || this.dataToSendGeneral.clients.length === 0 || this.dataToSendGeneral.subBranchId === 0) {
          this.toastr.warning('DEBE DE CAPTURAR LOS DATOS PREVIOS PARA PODER SELECCIONAR LA PÓLIZA A RENOVAR', 'NOTIFICACIÓN');
          this.dataToSendGeneral.renew = false;
          this.isRenewPolicy = false;
          this.policyForm.controls.renewPolicy.setValue(false);
          return;
        }
        const dateStart = Date.parse(this.dataToSendGeneral.startDate);
        const data = {
          groupId: this.dataToSendGeneral.groupId,
          clients: this.dataToSendGeneral.clients,
          subBranchId: this.dataToSendGeneral.subBranchId,
          startDate: this.datePipe.transform(dateStart, 'yyyy-MM-dd', 'es-ES')
        };
        this.getPoliciesExpired(data);
        this.modalRefRenew = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
      }
    }
  }
  getPoliciesExpired(dataRenew) {
    this.api.postExpiredPolicies(dataRenew).then((data: any) => {
      if (data == null) {
        this.dataExpired = [];
      } else {
        this.dataExpired = data;
      }
    }, error => {
    });
  }
  closeModalRenew() {
    this.modalRefRenew.hide();
    this.policyForm.controls.renewPolicy.setValue(false);
  }
  selectPolicy(policyExpiredId) {
    this.dataToSendGeneral.policyIdOld = policyExpiredId;
    this.dataExpired.forEach(item => {
      if (item.policyId === policyExpiredId) {
        this.dataToSendGeneral.policyNumberOld = item.policyNumber;
        return;
      }
    });
  }
  saveRelationRenew() {
    if (this.dataToSendGeneral.policyIdOld === 0) {
      this.toastr.warning('DEBE DE SELECCIONAR UNA PÓLIZA', 'NOTIFICACIÓN');
      return;
    } else {
      this.modalRefRenew.hide();
    }
  }
  chanceStatusCoordinators() {
    setTimeout(() => {
      try {
        const coordinators = [];
        this.CoordinatorsSelectedItems.forEach(item => {
          coordinators.push(item.directoryId);
        });
        this.dataToSendGeneral.coordinators = coordinators;
      } catch (e) {
      }
    }, 500);
  }
  changeStatusSurexs() {
    setTimeout(() => {
      try {
        const executive = [];
        this.SurexsSelectedItems.forEach(item => {
          executive.push(item.directoryId);
        });
        // // console.log(executive);
        this.dataToSendGeneral.executiveSurexs = executive;
      } catch (e) {
      }
    }, 500);
  }
  changeStatusIncidents() {
    setTimeout(() => {
      try {
        const executive1 = [];
        this.incidentsSelectedItems.forEach(item => {
          executive1.push(item.directoryId);
        });
        this.dataToSendGeneral.executiveIncidents = executive1;
      } catch (e) {
      }
    }, 500);
  }
  getManagerIncident() {
    this.api.getManagerIncident().then((data: any) => {
      if (data == null) {
        this.catManagerIncidentList = [];
      } else {
        this.catManagerIncidentList = data;
        this.dataToSendGeneral.managerIncident = this.catManagerIncidentList[0].userId;
      }
    }, error => {
    });
  }
  getTypePayments() {
    this.api.getTypePayments().then((data: any) => {
      this.catPaymentTypes = data;
      this.dataToSendGeneral.paymentTypeId = this.catPaymentTypes[0].paymentTypeId;
    }, error => {
    });
  }
  getTypePaymentsPolicy() {
    this.api.getTypePaymentsPolicyDamages().then((data: any) => {
      this.catTypePayments = data;
      if (this.dataToSendGeneral.typePaymentPolicy === 0) {
        this.dataToSendGeneral.typePaymentPolicy = this.catTypePayments[0].typePaymentPolicy;
      }
    }, error => {
    });
  }
  confirmInfoPolicy(template: TemplateRef<any>) {
    if (this.dataToSendGeneral.groupId === 1) {
      this.toastr.warning('NO SE PUEDEN GENERAR PÓLIZAS CON EL CLIENTE DE AMMIA, POR FAVOR CAMBIE EL CLIENTE', 'NOTIFICACIÓN');
      return;
    }
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  sendGeneralData() {
    // console.log(this.dataToSendGeneral);
    this.spinner.show('sp');
    this.policyId = 1;
    this.dataToSendGeneral.startDate = this.datePipe.transform(this.dataToSendGeneral.startDate, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.expirationDate = this.datePipe.transform(this.dataToSendGeneral.expirationDate, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.startDatePeriod = this.datePipe.transform(this.dataToSendGeneral.startDatePeriod, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.endDatePeriod = this.datePipe.transform(this.dataToSendGeneral.endDatePeriod, 'yyyy-MM-dd', 'es-ES');
    setTimeout(() => {
      try {
        // // console.log(this.dataToSendGeneral);
        this.api.postPolicyData(this.dataToSendGeneral)
          .then(
            (response: any) => {
              this.modalRef.hide();
              this.shared.fPolicyDataReadonly = true;
              this.policyId = response.policyId;
              localStorage.setItem('policy-id', String(this.policyId));
              this.typeSubBranch = parseInt(this.dataToSendGeneral.subBranchId, 10);
              this.dataToSendGeneral.policyId = this.policyId;
              this.getPolicy();
              setTimeout(() => {
                if (this.dataToSendGeneral.branchId === 2) {
                  if (this.dataToSendGeneral.subBranchId === 18) {
                    this.invokeServiceGetCountries();
                    this.invokeServiceGetStatesByCountry();
                    this.loadInitialValuesData();
                    this.invokeFunctionInitialValuesDetail();
                    $('#smartwizard').smartWizard('goToStep', 1);
                  } else {
                    this.loadDataTaxes();
                    this.initFiltersLocation();
                    this.locationList = [];
                    // @ts-ignore
                    $('#smartwizard').smartWizard('goToStep', 1);
                  }
                } else {
                  // this.shared.gPolicyIdCategory.next(Number(localStorage.getItem('policy-id')));
                  // @ts-ignore
                  // $('#smartwizard').smartWizard('goToStep', 1);
                }

              }, 1000);
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.spinner.hide('sp');
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } catch (e) {
      }
    }, 500);
  }
  sendGeneralDataUpdate() {
    // console.log(this.dataToSendGeneral);
    this.spinner.show('sp');
    const dateStart = Date.parse(this.dataToSendGeneral.startDate);
    const dateExpiration = Date.parse(this.dataToSendGeneral.expirationDate);
    this.dataToSendGeneral.startDate = this.datePipe.transform(dateStart, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.expirationDate = this.datePipe.transform(dateExpiration, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.startDatePeriod = this.datePipe.transform(this.dataToSendGeneral.startDatePeriod, 'yyyy-MM-dd', 'es-ES');
    this.dataToSendGeneral.endDatePeriod = this.datePipe.transform(this.dataToSendGeneral.endDatePeriod, 'yyyy-MM-dd', 'es-ES');
    const clients = [];
    setTimeout(() => {
      try {
        this.api.putPolicyData(this.dataToSendGeneral)
          .then(
            (response: any) => {
              this.typeSubBranch = parseInt(this.dataToSendGeneral.subBranchId, 10);
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              if (this.paymentTypeOld !== this.dataToSendGeneral.paymentTypeId
                || this.typePaymentOld !== this.dataToSendGeneral.typePaymentPolicy) {
                // se cambio el tipo de pago o cobranza se tiene que borrar la cobranza para que se genere de nuevo
                const obj = {
                  policyId: this.dataToSendGeneral.policyId
                };
                this.loadDataToSendReceipt();
                this.invokeServiceGetConfigurationRightInsurance();
                this.api.postChangePaymentPolicy(obj)
                  .then(
                    (response1: any) => {
                      // this.loadDataToSendReceipt();
                      this.chargesPercentage = 0;
                      this.bReceiptC = 1;
                      this.receiptList = [];
                      this.spinner.hide('sp');
                    }, error => {
                      this.spinner.hide('sp');
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  );
              } else {
                this.spinner.hide('sp');
              }
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } catch (e) {
      }
    }, 500);
  }
  invokeFunctionChangeSubBranch(subBranchId) {
    // console.log(subBranchId);
    if (subBranchId === 18) {
      this.dataToSendGeneral.endDatePeriod = this.datePipe.transform(this.dataToSendGeneral.expirationDate, 'yyyy-MM-dd', 'es-ES');
    }
  }
  invokeFunctionValidateStartPeriod(startDate: Date[]) {
    // console.log(startDate);
    if (startDate !== null) {
      if (this.dataToSendGeneral.startDate === undefined) {
        this.toastr.warning('PRIMERO SELECCIONE LA VIGENCIA DE LA PÓLIZA', 'NOTIFICACIÓN');
        setTimeout(() => {
          this.dataToSendGeneral.startDatePeriod = undefined;
        }, 500);
        return;
      }
      this.dataToSendGeneral.startDatePeriod = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'es-ES');
      if (this.dataToSendGeneral.startDatePeriod > this.datePipe.transform(this.dataToSendGeneral.startDate, 'yyyy-MM-dd', 'es-ES')) {
        this.toastr.warning('LA FECHA DE INICIO DE PERIODO NO PUEDE SER MAYOR A LA FECHA INICIO DE LA PÓLIZA', 'NOTIFICACION');
        setTimeout(() => {
          this.dataToSendGeneral.startDatePeriod = undefined;
        }, 500);
      }
    }
  }
  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyFile(this.dataToSendGeneral.policyId)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', this.dataToSendGeneral.number + '.pdf');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          // console.error(error);
        }
      );
  }
  onChangeInputFilePolicy(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadPolicy = e.target.files[0];
    if (0 > this.fileTypesPolicy.indexOf(this.fileUploadPolicy.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
    const formData = new FormData();
    formData.append('file', this.fileUploadPolicy);
    this.api.postFilePolicy(formData, localStorage.getItem('policy-id'))
      .subscribe(
        response => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.fileUploadPolicy = undefined;
        }, error => {
          this.toastr.warning(error.error);
          // console.error();
        }
      );
  }
  /* -----------------------------------------------------------------------------------------------------
    ------------------------U B I C A C I O N E S-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  initFiltersLocation() {
    this.filters = {
      search: '',
      page: 1,
      size: 10,
      policyId: this.dataToSendGeneral.policyId,
      endorsementId: 0
    };
  }
  clearFiltersTableLocations() {
    this.initFiltersLocation();
    this.loadFiltersTable();
  }
  loadDataToSendReceiptByLocation() {
    this.dataToSendReceiptLocation = {
      receiptId: 0,
      commission: 0,
      commissionAmount: 0,
      netPremium: Number(0),
      rights: 0,
      paymentCharges: 0,
      iva: 0,
      totalPremium: Number(0),
      paymentDate: '',
      paymentMonth: 0,
      policyId: Number(localStorage.getItem('policy-id')),
      receiptStatusId: 1,
      paymentFormId: 0,
      paymentType: this.dataToSendGeneral.paymentTypeId,
      categoryId: 0,
      taxId: 0
    };
    if (this.dataToSendGeneral.typePaymentPolicy === 3) {
      this.invokeServiceGetConfigurationRightInsurance();
    }
  }
  loadFiltersTable() {
    this.invokeServiceGetLocationsByPagination(1, 8);
  }
  invokeServiceGetLocationsByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.filters.endorsementId = this.endorsementId;
    this.api.getLocationsByPolicy(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.pgTableTotalItems = 0;
            this.locationList = [];
            if (this.filters.search !== '') {
              this.toastr.info('NO SE ENCONTRARON RESULTADOS CON EL FILTRO SELECCIONADO', 'NOTIFICACIÓN');
            }
          } else {
            this.paginationResponse = response;
            this.pgTableTotalItems = this.paginationResponse.total ;
            this.locationList = this.paginationResponse.locationPolicyList ;
          }
          this.spinner.hide('sp');
        }, error => {
          this.pgTableTotalItems = 0;
          this.locationList = [];
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  pageTableChanged(event: PageChangedEvent): void {
    this.pgTableCurrentPage = event.page;
    // console.log(this.pgTableCurrentPage);
    this.invokeServiceGetLocationsByPagination(this.pgTableCurrentPage, this.pgTableItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() {
  }
  closeModal() {
    this.modalRef.hide();
  }
  openDetailAddress(template: TemplateRef<any>, id) {
    this.addressForm.reset();
    if (this.dataToSendGeneral.policyId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR DIRECCIONES SIN ANTES AGREGAR LA PÓLIZA', 'NOTIFICATION');
      return;
    }
    this.loadDataToSendReceiptByLocation();
    this.invokeServiceGetCountries();
    this.invokeServiceGetStatesByCountry();
    this.getTypesHomeWallCeiling();
    this.loadInitialValuesData();
    this.invokeServiceGetClientsPolicy(localStorage.getItem('policy-id'));
    this.invokeServiceGetSecurityMeasure();
    this.invokeServiceGetFireProtection();
    this.addressId = id;
    this.isSameDocument = false;
    if (this.addressId > 0) {
      this.base64File2 = '';
      let streetTmp = '';
      let neighborhoodCodeTmp = '';
      this.spinner.show('sp');
      const endorsementObj = {
        locationId: this.addressId,
        endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
      };
      console.log(endorsementObj);
      this.api.getLocationDamageById(endorsementObj).then((data: any) => {
        this.dataAddressFiscal = data;
        this.isNewStreetFiscal = true;
        streetTmp = this.dataAddressFiscal.street;
        neighborhoodCodeTmp = this.dataAddressFiscal.neighborhoodCode;
        // this.addressForm.get('addressTypeFiscal').patchValue(data.addressType);
        this.addressForm.get('countryFiscal').patchValue(data.country);
        this.selectStateMunicipalityByZipCode(this._FISCAL);
        // load multiSelect
        setTimeout(() => {
          const securityMeasure = [];
          this.dataAddressFiscal.securityMeasureList.forEach(item => {
            this.catSecurityMeasure.forEach(item2 => {
              if (item === item2.id) {
                securityMeasure.push(item2);
                return;
              }
            });
          });
          this.securityMeasureSelectedItems = securityMeasure;
        }, 1000);
        setTimeout(() => {
          const fireProtection = [];
          this.dataAddressFiscal.fireProtectionList.forEach(item => {
            this.catFireProtection.forEach(item2 => {
              if (item === item2.id) {
                fireProtection.push(item2);
                return;
              }
            });
          });
          this.fireProtectionSelectedItems = fireProtection;
        }, 1000);
        setTimeout(() => {
          const subActivity = [];
          this.dataAddressFiscal.subActivity.forEach(item => {
            subActivity.push(item);
          });
          this.subActivityList = subActivity;
        }, 1000);
        // console.log(this.dataAddressFiscal);
        setTimeout(() => {
          this.dataAddressFiscal.street = streetTmp;
          this.dataAddressFiscal.neighborhoodCode = neighborhoodCodeTmp;
        }, 1000);
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    } else {
      this.dataAddressFiscal.country = 'MÉXICO';
      this.invokeServiceGetNumberLocationAutomatic(this.dataToSendGeneral.policyId);
    }
    this.modalLocation = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  openDetailAddressAmmia(template: TemplateRef<any>, id) {
      this.spinner.show('sp');
      const damagePolicy = {
        locationId: id,
        type: 2,
        endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
      };
      this.api.getAddressHousing(damagePolicy).then((data: any) => {
        this.dataAddressAmmia = data;
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
      this.modalLocation = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  getCountrySelectedFiscal(event) {
    this.dataAddressFiscal.country = event.target.value;
  }
  invokeServiceGetCountries() {
    this.api.getCountries().then((data: any) => {
      if (data != null) {
        this.countryList = data;
        this.countryListFiscal = data;
        this.countryListOther = data;
        this.dataAddressFiscal.country = this.countryListFiscal[0].cValue;
      }
    }, error => {
    });
  }
  selectStateFiscaly(event) {
    if (event !== undefined) {
      this.selectStateFiscalyTemp = event;
      this.dataAddressFiscal.stateCode = event.cKey;
      this.clearMunicipalityFiscal();
      this.invokeServiceGetMunicipalyByStateName(event, this._FISCAL);
    }
  }
  clearMunicipalityFiscal() {
    this.dataAddressFiscal.municipalityCode = null;
    this.dataAddressFiscal.zipCode = null;
    this.clearZipCodeFiscalList();
    this.dataAddressFiscal.neighborhoodCode = null;
    this.clearNeighborhoodFiscalList();
    this.dataAddressFiscal.street = null;
    this.clearStreetFiscalList();
  }
  clearMunicipalityFiscalList() {
    this.municipalityListFiscal = [];
  }
  clearMunicipalityList() {
    this.municipalityList = [];
  }
  clearZipCodeFiscalList() {
    this.zipCodeListFiscal = [];
  }
  clearNeighborhoodFiscalList() {
    this.neighborhoodListFiscal = [];
  }
  clearStreetFiscalList() {
    this.streetsListFiscal = [];
  }
  clearNeighborhoodList() {
    this.neighborhoodList = [];
  }
  clearStateFiscal() {
    this.dataAddressFiscal.stateCode = null;
    this.clearMunicipalityFiscal();
    this.clearMunicipalityFiscalList();

  }
  invokeServiceGetMunicipalyByStateName(data1, typeAddress) {
    this.api.getMunicipalyByStateName(data1).then((data: any) => {
      if (data != null) {
        switch (typeAddress) {
          case this._FISCAL: {
            this.municipalityListFiscal = data;
            this.invokeTownByZipCode(this.dataAddressFiscal.zipCode, typeAddress);
            break;
          }
        }
      }
    }, error => {
    });

  }
  selectMunicipalityFiscal(event) {
    // this.zipCodeListFiscal=[];
    if (event !== undefined) {
      this.selectMunicipalityFiscalTemp = event;
      this.dataAddressFiscal.municipalityCode = event.cKey;
      this.invokeServiceGetZipCodesByMunicipalyCode(event, this._FISCAL);
      this.clearZipCodeFiscal();
    }
  }
  invokeServiceGetZipCodesByMunicipalyCode(data1, typeAddress) {
    this.api.getZipCodesByMunicipalyCode(data1).then((data: any) => {
      if (data != null) {
        this.zipCodeListFiscal = data;
      }
    }, error => {
    });
  }
  clearZipCodeFiscal() {
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.neighborhoodCode = null;
    this.dataAddressFiscal.zipCode = null;
    this.clearNeighborhoodFiscalList();
    this.clearStreetFiscalList();
  }
  selectZipCodeFiscal(event) {
    if (event !== undefined) {
      this.selectZipCodeFiscalTemp = event;
      this.dataAddressFiscal.zipCode = event.cValue;
      this.invokeServiceGetColoniesByZipCode(event, this._FISCAL);
      this.clearNeighborhoodFiscal();
    }
  }
  invokeServiceGetColoniesByZipCode(data1, typeAddress) {
    this.api.getColoniesByZipCode(data1).then((data: any) => {
      if (data != null) {
        this.neighborhoodListFiscal = data;
      }
    }, error => {
    });
  }
  clearNeighborhoodFiscal() {
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.neighborhoodCode = null;
    this.clearStreetFiscalList();
  }
  selectNeighborhoodFiscal(event) {
    if (event !== undefined) {
      this.selectNeighborhoodFiscalTemp = {};
      this.dataAddressFiscal.neighborhoodCode = event.cKey;
      this.clearStreetFiscalAndList();
      this.invokeServiceGetStreetsByColonie(event, this._FISCAL);
      this.streetFiscalNew.neighborhoodCode = event.cKey;
    }
  }
  selectNeighborhoodFiscalM(event) {
    if (event !== undefined) {
      this.selectNeighborhoodFiscalTemp = {};
      this.dataAddressFiscal.neighborhoodCode = event.cKey;
      this.streetFiscalNew.neighborhoodCode = event.cKey;
    }
  }
  clearStreetFiscalAndList() {
    this.dataAddressFiscal.street = null;
    this.clearStreetFiscalList();

  }
  invokeServiceGetStreetsByColonie(data1, typeAddress) {
    this.api.getStreetsByColonie(data1).then((data: any) => {
      if (data != null) {
        this.streetsListFiscal = data;
      }
    }, error => {
    });
  }
  loadInitialValuesData() {
    this.dataAddressFiscal = {
      locationId: 0,
      location: '',
      country: null,
      zipCode: null,
      stateCode: null,
      municipalityCode: null,
      neighborhoodCode: null,
      street: null,
      numExt: null,
      numInt: 'S/N',
      activity: '',
      subActivity: [],
      constructionTypeId: 0,
      basement: 0,
      floor: 0,
      securityMeasureList: [],
      fireProtectionList: [],
      locationNumber: 0,
      clientId: 0,
      pathFile: '',
      nameFile: '',
      policyId: this.dataToSendGeneral.policyId,
      streetNew : false,
      receipt: null,
      sameFile: false
    };
    this.streetFiscalNew = {
      coloniesStreetsId: 0
      , neighborhoodCode: null
      , nameStreet: null
      , status: false

    };
    this.isNewStreetFiscal = false;
    this.fireProtectionSelectedItems = [];
    this.securityMeasureSelectedItems = [];
    this.subActivityList = [];
    this.fileUpload = null;
  }
  selectStreetFiscal(event) {
    if (event !== undefined) {
      this.selectStreetFiscalTemp = event;
      this.dataAddressFiscal.street = event.cValue;
    }
  }
  clearStreetFiscal() {
    this.dataAddressFiscal.street = null;

  }
  onChangeStreet() {}
  onChangeFiscal(event) {
    this.isNewStreetFiscal = event;
    this.dataAddressFiscal.street = null;
    this.dataAddressFiscal.streetNew = event;
  }
  onChangeFiscalDocument(event) {
    this.dataAddressFiscal.sameFile = event;
  }
  onChangeNumInt() {}
  onChangeNumExt() {}
  invokeServiceGetStatesByCountry() {
    this.api.getStatesByCountry().then((data: any) => {
      if (data != null) {
        this.stateListFiscal = data;
      }
    }, error => {
    });
  }


  saveAddress() {
    if (this.subActivityList.length === 0) {
      this.toastr.warning('POR FAVOR INGRESE AL MENOS UN SUBGIRO', 'NOTIFICACION');
      return;
    }
    if (this.dataAddressFiscal.pathFile === '' && !this.dataAddressFiscal.sameFile) {
      this.toastr.warning('POR FAVOR INGRESE EL ARCHIVO SOLICITADO', 'NOTIFICACION');
      return;
    }
    this.dataAddressFiscal.subActivity = this.subActivityList;
    this.dataAddressFiscal.receipt = this.dataToSendReceiptLocation;
    this.spinner.show('sp');
    this.api.postLocationDamages(this.dataAddressFiscal)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response.locationId > 0) {
            this.modalLocation.hide();
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            this.invokeServiceGetLocationsByPagination(1, 8);
            if (this.dataAddressFiscal.streetNew) {
              const dataJson = {
                coloniesStreetsId: 0,
                neighborhoodCode: this.dataAddressFiscal.neighborhoodCode,
                nameStreet: this.dataAddressFiscal.street,
                status: true
              };
              this.api.postStreet(dataJson)
                .then(
                  (response1: any) => {
                    // console.log(response1);
                  }, error => {
                  }
                );
            }
          } else {
            this.toastr.info('lA UBICACIÓN YA EXISTE', 'NOTIFICACIÓN');
          }
        }, error => {
          this.toastr.error('OCURRIO UN PROBLEMA AL PROCESAR SU PETICIÓN', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
        }
      );
  }
  updateAddress() {
    // console.log(this.dataAddressFiscal);
    this.spinner.show('sp');
    this.api.updateLocationDamages(this.dataAddressFiscal)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response.locationId > 0) {
            this.modalLocation.hide();
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            this.invokeServiceGetLocationsByPagination(1, 8);
            if (this.dataAddressFiscal.streetNew) {
              const dataJson = {
                coloniesStreetsId: 0,
                neighborhoodCode: this.dataAddressFiscal.neighborhoodCode,
                nameStreet: this.dataAddressFiscal.street,
                status: true
              };
              this.api.postStreet(dataJson)
                .then(
                  (response1: any) => {
                    // (response1);
                  }, error => {
                  }
                );
            }
          } else {
            this.toastr.info('LA UBICACIÓN YA EXISTE', 'NOTIFICACIÓN');
          }
        }, error => {
          this.toastr.error('OCURRIO UN PROBLEMA AL PROCESAR SU PETICIÓN', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
        }
      );
  }
  getTypesHomeWallCeiling() {
    this.api.getCatalogDamagesWall().then((data: any) => {
      this.typeWallCeiling = data;
    }, error => {
    });
  }
  getTypesSelectedFiscal(event) {
    this.dataAddressFiscal.constructionTypeId = event.target.value;
  }
  selectStateMunicipalityByZipCode(typeAddress: string) {
    let zipCode;
    if (typeAddress === this._FISCAL) {
      zipCode = this.dataAddressFiscal.zipCode;
    }
    this.api.getCheckZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (result) {
          this.invokeStateByZipCode(zipCode, typeAddress);
          let value;
          if (typeAddress === this._FISCAL) {
            value =  {
              cKey: this.dataAddressFiscal.zipCode,
              cValue: this.dataAddressFiscal.zipCode
            };
          }
          this.selectZipCodeFiscal(value);
        } else {
          this.toastr.warning('El código postal no existe, por favor intenta con otro', 'Notificación');
          this.clearStateFiscal();
        }
      }
    }, error => {
    });

  }
  invokeStateByZipCode(zipCode, typeAddress) {
    this.api.getStatesByZipeCode(zipCode).then((result: any) => {
      if (result != null) {
        if (this._FISCAL === typeAddress) {
          this.dataAddressFiscal.stateCode = result.cKey;
          // this.dataAddressFiscal.state = result.cValue;
          // console.log('obtener municipio');
          // console.log(result);
          this.invokeServiceGetMunicipalyByStateName(result, this._FISCAL);
        }
      } else {
        if (this._FISCAL === typeAddress) {
          this.dataAddressFiscal.stateCode = null;
          this.dataAddressFiscal.state = null;
        }
      }
    }, error => {
    });
  }
  invokeTownByZipCode(zipCode, typeAddress) {
    this.api.getTownByPostcode(zipCode).then((result: any) => {
      if (result != null) {
        if (typeAddress === this._FISCAL) {
          this.dataAddressFiscal.municipalityCode = result.cKey;
        }
      } else {
        if (typeAddress === this._FISCAL) {
          this.dataAddressFiscal.municipalityCode = null;
        }
      }
    }, error => {
    });
  }
  invokeServiceGetClientsPolicy(id) {
    this.api.getClientsPolicy(id).then((data: any) => {
      this.catClientsPolicy = data;
    }, error => {
      this.catClientsPolicy = [];
    });
  }
  changeSecurityMeasure() {
    setTimeout(() => {
      try {
        const measure = [];
        this.securityMeasureSelectedItems.forEach(item => {
          measure.push(item.id);
        });
        // // console.log(executive1);
        this.dataAddressFiscal.securityMeasureList = measure;
      } catch (e) {
      }
    }, 500);
  }
  invokeServiceGetSecurityMeasure() {
    this.api.getCatalogDamagesSecurity().then((data: any) => {
      this.catSecurityMeasure = data;
    }, error => {
    });
  }
  changeFireProtection() {
    setTimeout(() => {
      try {
        const fire = [];
        this.fireProtectionSelectedItems.forEach(item => {
          fire.push(item.id);
        });
        // // console.log(executive1);
        this.dataAddressFiscal.fireProtectionList = fire;
      } catch (e) {
      }
    }, 500);
  }
  invokeServiceGetFireProtection() {
    this.api.getCatalogDamagesFire().then((data: any) => {
      this.catFireProtection = data;
    }, error => {
    });
  }
  openAddSubActivity(template: TemplateRef<any>, id: number) {
    this.modalRef = this.modalService.show(template, {class: 'modal-md', keyboard: false, backdrop: 'static'});
  }
  addSubActivity() {
    if (this.subActivity === undefined) {
      this.toastr.warning('DEBE DE INGRESAR EL SUBGIRO', 'NOTIFICACION');
      return;
    }
    this.subActivityList.push(this.subActivity);
  }
  deleteSubActivity(activity: string) {
    const newArray = this.subActivityList.filter(item => {
      return item !== activity;
    });
    this.subActivityList = newArray;
  }
  openDuplicateLocation(template: TemplateRef<any>, location) {
    // console.log(location);
    this.dataDuplicateLocation = {
      policyId: this.dataToSendGeneral.policyId,
      locationId: location.locationId
    };
    this.nameLocation = location.location;
    this.modalRef = this.modalService.show(template, {class: 'modal-md', keyboard: false, backdrop: 'static'});
  }
  InvokeServiceDuplicateLocation() {
    this.spinner.show('sp');
    this.api.postDuplicateLocation(this.dataDuplicateLocation)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.invokeServiceGetLocationsByPagination(1, 8);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  deleteLocationDamage() {
    this.api.deleteLocationDamageById(this.typeId).subscribe( response => {
      this.typeId = 0;
      this.typeOperationRemove = 0;
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      this.invokeServiceGetLocationsByPagination(1, 8);
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  showDocumentViewer(item, template: TemplateRef<any>) {
    // console.log('visor');
    this.base64File2 = '';
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    this.spinner.show('sp');
    setTimeout(() => {
      this.base64File2 = item;
      this.spinner.hide('sp');
      // console.log(item);
    }, 1000);
  }
  sumTotalPremiumDamage() {
    if (this.dataToSendReceiptLocation.taxId > 0) {
      this.reCalTax();
    }
    this.calCommissionAmountDamage();
    // tslint:disable-next-line:max-line-length
    this.dataToSendReceiptLocation.totalPremium = Number(this.dataToSendReceiptLocation.netPremium) + this.dataToSendReceiptLocation.rights + this.dataToSendReceiptLocation.paymentCharges + this.dataToSendReceiptLocation.iva;
  }
  reCalTaxDamage() {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(this.dataToSendReceiptLocation.taxId)) {
        // ('entra a recalculo');
        // tslint:disable-next-line:max-line-length
        this.dataToSendReceiptLocation.iva = ((Number(this.dataToSendReceiptLocation.netPremium) + this.dataToSendReceiptLocation.rights + this.dataToSendReceiptLocation.paymentCharges) * tax.taxValue) / 100;
      }
    }
  }
  calTaxDamage(i) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {
        // tslint:disable-next-line:max-line-length
        this.dataToSendReceiptLocation.iva = ((Number(this.dataToSendReceiptLocation.netPremium) + this.dataToSendReceiptLocation.rights + this.dataToSendReceiptLocation.paymentCharges) * tax.taxValue) / 100;
        // this.dataToSendReceiptLocation.iva = iva.toFixed(4);
        this.sumTotalPremiumDamage();
      }
    }
  }
  calCommissionPercentageDamage() {
    const commssion = (this.dataToSendReceiptLocation.commissionAmount * 100) / Number(this.dataToSendReceiptLocation.netPremium);
    this.dataToSendReceiptLocation.commission = commssion.toFixed(2);
  }
  calCommissionAmountDamage() {
    const commissionAmount = (Number(this.dataToSendReceiptLocation.netPremium) * this.dataToSendReceiptLocation.commission) / 100;
    this.dataToSendReceiptLocation.commissionAmount = commissionAmount.toFixed(4);
  }
  calChargesPercentageDamage() {
    const chargesPercentageTmp = (this.dataToSendReceiptLocation.paymentCharges * 100) / Number(this.dataToSendReceiptLocation.netPremium);
    this.chargesPercentageDamage = chargesPercentageTmp;
  }
  calChargesAmountDamage() {
    const chargesAmount =  (Number(this.dataToSendReceiptLocation.netPremium) * this.chargesPercentageDamage) / 100;
    this.dataToSendReceiptLocation.paymentCharges = chargesAmount;
  }
  uploadedFilesMdl() {
    // console.log('entra : ');
    // console.log( this.fileUploadControlMdl);
    if (this.fileUploadControlMdl.invalid) {
      this.fileUploadControlMdl.getError().map(ERROR => {
        if (ERROR.fileSize !== undefined) {
          ERROR.fileSize.map(FILE => {
            this.toastr.warning( 'Se quita ' + FILE.file.name + '. El tamaño del archivo debe ser menor a 25 MB', 'Notificación');
            this.fileUploadControlMdl.removeFile(FILE.file);
          });
        } else if (ERROR.fileTypes !== undefined) {
          ERROR.fileTypes.map(FILE => {
            this.toastr.warning( 'Se quita ' + FILE.file.name + '.  Formato no válido, solo PDF, JPEG, JPG y PNG', 'Notificación');
            this.fileUploadControlMdl.removeFile(FILE.file);
          });
        }
      });
    }
  }
  invokeServiceUploadFilesMultiples() {
    this.spinner.show('sp');
    // console.log('total de archivos');
    const objDocument = [];
    let cont = 1;
    this.fileUploadControlMdl.value.forEach(FILE => {
      const documents = new Documents();
      this.getBase64(FILE).then(
        res => {
          const documentTmp = {
            orderFile: cont,
            name: FILE.name,
            b64: res.split(',')[1]
          };
          objDocument.push(documentTmp);
        }
      );
      cont = cont + 1;
    });
    // console.log(objDocument);
    setTimeout(() => {
    if (objDocument.length === 1) {
      this.modalRef.hide();
      this.dataAddressFiscal.nameFile = objDocument[0].name;
      this.dataAddressFiscal.pathFile = objDocument[0].b64;
      this.spinner.hide('sp');
      return;
    }
    this.api.postMergeDocument(objDocument)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          // tslint:disable-next-line:max-line-length
          this.dataAddressFiscal.nameFile = this.dataAddressFiscal.location === '' ? 'certificado.pdf' : this.dataAddressFiscal.location + '.pdf';
          this.dataAddressFiscal.pathFile = response.b64;
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
    }, 3000);
  }
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }
  openUploadFilesMultiples(template: TemplateRef<any>) {
    this.formMdl.reset();
    this.fileUploadControlMdl.clear();
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }

  invokeServiceGetNumberLocationAutomatic(policyId) {
    this.api.getNumberLocationAutomatic(policyId).then((data: any) => {
      if (data != null) {
        this.dataAddressFiscal.locationNumber = data.number;
      }
    }, error => {
    });
  }
  /* -----------------------------------------------------------------------------------------------------
    ------------------------C O N T A C T O S-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  initDataContact() {
    this.dataContact = {
      contactId: 0,
      name: '',
      email: '',
      phone: '',
      position: '',
      locations: [],
      policyId: this.dataToSendGeneral.policyId
    };
    if (this.dataToSendGeneral.subBranchId === 1) {
      this.dataContact.locations.push(this.clientHousingId);
      this.locationsSelectedItems.push(this.clientHousingId);
    }
    this.locationsSelectedItems = [];
  }
  initDataFilterContact() {
    this.dataContactTable = {
      searchContact: '',
      policyId: this.dataToSendGeneral.policyId,
      page: 1,
      size: 10
    };
  }
  getContactsByPolicyId(page: number, size: number) {
    this.contactList = [];
    this.spinner.show('sp');
    this.dataContactTable.page = page;
    this.dataContactTable.size = size;
    this.api.getContactDamagePagination(this.dataContactTable)
      .then(
        (response: any) => {
          if (response == null && this.dataContactTable.searchContact !== '') {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.contactList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          if (response == null && this.dataContactTable.searchContact === '') {
            this.contactList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.contactList = this.paginationResponse.contactPolicyList ;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // // // console.logerror(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.contactList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  clearFiltersTableContact() {
    this.initDataFilterContact();
    this.getContactsByPolicyId(1, 10);
  }
  loadFiltersTableContact() {
    this.getContactsByPolicyId(1, 10);
  }
  openDetailContact(template: TemplateRef<any>, contactId) {
    this.initDataContact();
    this.myFormContact.reset();
    this.contactId = contactId;
    if (this.dataToSendGeneral.policyId === 0) {
      this.toastr.warning('NO SE PUEDEN AGREGAR CONTANTOS SIN ANTES AGREGAR LA ASEGURADORA', 'NOTIFICATION');
      return;
    }
    if (this.contactId > 0) {
        this.api.getContactsDamageById(this.contactId).then((data: any) => {
          this.dataContact = data;
          this.dataContact.policyId = this.dataToSendGeneral.policyId;
          this.emailContactOld = data.email;
          // console.log('termina ', this.dataContact);
          setTimeout(() => {
            const locations = [];
            this.dataContact.locations.forEach(item => {
              this.catLocations.forEach(item2 => {
                    if (item === item2.locationId) {
                      locations.push(item2);
                      return;
                    }
              });
            });
            this.locationsSelectedItems = locations;
          }, 1000);
          // console.log(this.locationsSelectedItems);
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');
        });
    }
    // console.log(this.dataContact);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  public changeClientToConsult(event: any) {
    if (event !== undefined) {
      this.dataContact.contactTypeId = event.contactTypeId;
      // console.log(this.dataContact);
    }
  }
  saveContact() {
    // console.log(this.dataContact);
    this.spinner.show('sp');
    this.api.postContactDamage(this.dataContact)
      .then(
        (response: any) => {
          if (response.contactId === 0) {
            this.spinner.hide('sp');
            this.toastr.warning('EL CORREO YA EXISTE, POR FAVOR DE CAMBIAR', 'NOTIFICACIÓN');
            return;
          }
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getContactsByPolicyId(1, 10);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateContact() {
    this.spinner.show('sp');
    this.api.putContactDamage(this.dataContact)
      .then(
        (response: any) => {
          if (response.contactId === 0) {
            this.spinner.hide('sp');
            this.toastr.warning('EL CORREO YA EXISTE, POR FAVOR DE CAMBIAR', 'NOTIFICACIÓN');
            return;
          }
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.getContactsByPolicyId(1, 10);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('OCURRIO UN PROBLEMA AL PROCESAR SU PETICIÓN', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
        }
      );
  }
  get f() {
    return this.myFormContact.controls;
  }
  itemsPerPageChange(size) {
    if (this.pgItemsPerPage === size) { return; }
    this.pgItemsPerPage = size;
    this.pgBoundaryLinks = (this.pgTotalItems / this.pgItemsPerPage) >= 5;
  }
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getContactsByPolicyId(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
    // console.info(startItem, '-', endItem);
  }
  getLocationsContacts() {
    this.spinner.show('sp');
    const endorsementPolicy = {
      policyId: this.dataToSendGeneral.policyId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getLocationsDamageByPolicyId(endorsementPolicy).then((data: any) => {
      this.catLocations = data;
      this.spinner.hide('sp');
      if (this.operationLocation === 3 && this.catLocations.length > 0) {
          this.firstLocationId = this.catLocations[0].locationId;
          this.invokeServiceGetSections(this.catLocations[0].locationId);
      }
    }, error => {
      this.catLocations = [];
      this.spinner.hide('sp');
    });
  }
  changeLocations() {
    const locations = [];
    setTimeout(() => {
      try {
        this.locationsSelectedItems.forEach(item => {
          locations.push(item.locationId);
        });
        this.dataContact.locations = locations;
      } catch (e) {
      }
    }, 1000);
  }
  deleteContactDamage() {
    this.api.deleteContactDamageById(this.typeId).subscribe( response => {
      this.typeId = 0;
      this.typeOperationRemove = 0;
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      this.getContactsByPolicyId(1, 10);
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  /* -----------------------------------------------------------------------------------------------------
    ------------------------S E C C I O N E S----------------------------------------------------------------
    ----------------------------------------------------------------------------------------------------- */

  initSectionData() {
    this.sectionList = [];
    this.sections = [];
  }
  getChildren(node: any) {
    // console.log(node);
    const newNodes = [
      {
        name: 'child1'
      }, {
        name: 'child2'
      }
    ];
  }
  invokeServiceGetSections(id: number) {
    this.coveragesAmmia = [];
    this.nodes = [];
    this.spinner.show('sp');
    if (this.dataToSendGeneral.groupId !== 1) {
      const endorsementPolicy = {
        locationId: id,
        subBranchId: this.dataToSendGeneral.subBranchId,
        endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
      };
      this.api.getSectionByGroups(endorsementPolicy).then((data: any) => {
        this.nodes = data;
        this.locationId = id;
        this.catLocations.forEach(item => {
          if (item.locationId === this.locationId) {
            this.nameLocation = item.location;
            item.locationNumber = 1;
            // return;
          } else {
            item.locationNumber = 0;
          }
        });
        // poner banderas para validaciones de miscelaneos y rc
        // console.log('validaciones luc');
        if (this.firstLocationId === id) {
          this.nodes[2].children.forEach(item => {
            switch (item.sectionId) {
              case 130:
                this.misTheft = item.applySection;
                break;
              case 131:
                this.misCrystals = item.applySection;
                break;
              case 132:
                this.misMoney = item.applySection;
                break;
              case 133:
                this.misAdvertisements = item.applySection;
                break;
              case 326:
                this.misTheft = item.applySection;
                break;
              case 325:
                this.misCrystals = item.applySection;
                break;
              case 327:
                this.misMoney = item.applySection;
                break;
              case 329:
                this.misAssault = item.applySection;
                break;
              case 330:
                this.misItems = item.applySection;
                break;
            }
          });
          this.misRc = this.nodes[3].children[0].applySection;
        }
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
      this.key = 'sectionId';
      this.display = 'name';
      this.sectionListSelected = [];
    } else if (this.dataToSendGeneral.groupId ===  1) {
      // console.log('ammia mobile');
      this.api.getCoveragesAmmiaHousing(id).then((data: any) => {
        this.coveragesAmmia = data;
        this.locationId = id;
        this.catLocations.forEach(item => {
          if (item.locationId === this.locationId) {
            this.nameLocation = item.location;
            item.locationNumber = 1;
            // return;
          } else {
            item.locationNumber = 0;
          }
        });
        this.spinner.hide('sp');
      }, error => {
        this.spinner.hide('sp');
      });
    }
  }
  openSection(template: TemplateRef<any>, id: number) {
    // console.log(this.locationId, ': ', this.firstLocationId);
    if (this.firstLocationId !== this.locationId && (id === 130 || id === 326) && !this.misTheft) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[2].children[1].applySection = false;
      return;
    } else if (this.firstLocationId !== this.locationId && (id === 131 || id === 325) && !this.misCrystals) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[2].children[0].applySection = false;
      return;
    } else if (this.firstLocationId !== this.locationId && (id === 132 || id === 327) && !this.misMoney) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[2].children[2].applySection = false;
      return;
    } else if ( this.firstLocationId !== this.locationId && id === 133 && !this.misAdvertisements) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[2].children[3].applySection = false;
      return;
    } else if (this.firstLocationId !== this.locationId && (id === 134 || id === 328) && !this.misRc) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[3].children[0].applySection = false;
      return;
    } else if (this.firstLocationId !== this.locationId && (id === 329) && !this.misAssault) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[3].children[0].applySection = false;
      return;
    } else if (this.firstLocationId !== this.locationId && (id === 330) && !this.misItems) {
      this.toastr.warning('TIENE QUE AGREGAR LA SECCIÓN EN LA PRIMERA UBICACIÓN, PARA CONTINUAR', 'NOTIFICACION');
      this.nodes[3].children[0].applySection = false;
      return;
    }

    this.luc = false;
    this.dataSectionSave = {
      locationId: this.locationId,
      sectionId: id,
      json: ''
    };
    // // console.log(id);
    this.spinner.show('sp');
    const damageObj = {
      policyId: this.dataToSendGeneral.policyId,
      sectionId: id,
      locationId: this.locationId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getSectionById(damageObj).then((data: any) => {
      this.dataSelection = data;
      // // console.log('load');
      // tslint:disable-next-line:max-line-length
      if (this.dataSelection.id === 130 || this.dataSelection.id === 131 || this.dataSelection.id === 132 || this.dataSelection.id === 133
        || this.dataSelection.id === 326 || this.dataSelection.id === 325 || this.dataSelection.id === 327 || this.dataSelection.id === 329
        || this.dataSelection.id === 330) {
          if (this.dataSelection.coverageList[0].descriptionList[0].valueList[0].value === 'LUC') {
            this.luc = true;
          } else if (this.firstLocationId !== this.locationId) {
            // tslint:disable-next-line:no-unused-expression
            this.dataSelection.coverageList[0].descriptionList[0].valueList[0].value = 'POR UBICACIÓN';
            this.dataSelection.coverageList[0].descriptionList[0].valueList[0].disabledField = true;
          }
      }
      if (this.dataSelection.id === 134 || this.dataSelection.id === 328) {
        if (this.firstLocationId !== this.locationId) {
          this.dataSelection.coverage5List[0].descriptionCoverageList[0].valueList[0].disabledField = true;
          // tslint:disable-next-line:max-line-length
          if (this.dataSelection.coverage5List[0].descriptionCoverageList[0].valueList[0].value !== 'LUC') {
            // tslint:disable-next-line:no-unused-expression
            this.dataSelection.coverage5List[0].descriptionCoverageList[0].valueList[0].value = 'POR UBICACIÓN';
          }
        }
      }
      this.spinner.hide('sp');
      this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    }, error => {
      this.spinner.hide('sp');
    });

  }
  changeSelectionOption2(valor, coverage, description, value, typeVisible) {
    // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      this.dataSelection.coverageList[coverage].descriptionList[description].valueList[value].medition = valor.medition;
      // asignar la misma unidad a todos las cobertura que aplica la suma asegurada
      if (this.dataSelection.id === 127 || this.dataSelection.id === 126
        || this.dataSelection.id === 322 || this.dataSelection.id === 323) {
        if (coverage === 0 && value === 0 && valor.description === 'MONTO') {
          // console.log('primera suma asegurada de cobertura basica');
          // EC
          this.dataSelection.coverageList[1].descriptionList[description].valueList[value].medition = valor.medition;
          // TR
          this.dataSelection.coverageList[2].descriptionList[description].valueList[value].medition = valor.medition;
          // FHM
          this.dataSelection.coverageList[3].descriptionList[description].valueList[value].medition = valor.medition;
          // TEV
          this.dataSelection.coverageList[4].descriptionList[description].valueList[value].medition = valor.medition;
          // RE
          this.dataSelection.coverageList[5].descriptionList[description].valueList[value].medition = valor.medition ;
        }
        if (valor.description === 'MONTO O PORCENTAJE' && valor.medition === 'NO APLICA') {
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[(value + 1)].value = 'NO APLICA';
        }
      }
      if (this.dataSelection.id === 128 || this.dataSelection.id === 125 || this.dataSelection.id === 131
      || this.dataSelection.id === 132 || this.dataSelection.id === 133 || this.dataSelection.id === 130
      || this.dataSelection.id === 320 || this.dataSelection.id === 324 || this.dataSelection.id === 325
        || this.dataSelection.id === 327 || this.dataSelection.id === 329 || this.dataSelection.id === 326
        || this.dataSelection.id === 330) {
        if (valor.description === 'MONTO O PORCENTAJE' && valor.medition === 'NO APLICA') {
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[(value + 1)].value = 'NO APLICA';
        }
      }
    } else {
      this.dataSelection.coverageList[coverage].descriptionList[description].valueList[value].value = valor.value;
      // asignar el mismo tipo a todos las cobertura que aplica la suma asegurada
      if (this.dataSelection.id === 127 || this.dataSelection.id === 126
        || this.dataSelection.id === 322 || this.dataSelection.id === 323) {
        if (coverage === 0 && value === 1 && valor.description === 'TIPO') {
          // console.log('primera suma asegurada de cobertura basica');
          // EC
          this.dataSelection.coverageList[1].descriptionList[description].valueList[value].value = valor.value;
          // TR
          this.dataSelection.coverageList[2].descriptionList[description].valueList[value].value = valor.value;
          // FHM
          this.dataSelection.coverageList[3].descriptionList[description].valueList[value].value = valor.value;
          // TEV
          this.dataSelection.coverageList[4].descriptionList[description].valueList[value].value = valor.value;
          // RE
          this.dataSelection.coverageList[5].descriptionList[description].valueList[value].value = valor.value;
        } else if (coverage === 0 && value === 1 && valor.description === 'SOBRE QUE APLICA') {
          // console.log('primera suma asegurada de cobertura basica');
          // EC
          this.dataSelection.coverageList[1].descriptionList[description].valueList[value].value = valor.value;
          // TR
          this.dataSelection.coverageList[2].descriptionList[description].valueList[value].value = valor.value;
          // FHM
          this.dataSelection.coverageList[3].descriptionList[description].valueList[value].value = valor.value;
          // TEV
          this.dataSelection.coverageList[4].descriptionList[description].valueList[value].value = valor.value;
          // RE
          this.dataSelection.coverageList[5].descriptionList[description].valueList[value].value = valor.value;
        }
      }
    }
    // console.log('entra: ', valor, coverage, description, value, typeVisible);
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MAXIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MINIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
    }
  }
  formatCurrency2(valor, coverage, description, value) {
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    valor.value = valor.value.replace(/,/g, '');
    const r10 = valor.value * .10;
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    this.dataSelection.coverageList[coverage].descriptionList[description].valueList[value].value = val;
    // seteo de sumas aseguradas
    if (this.dataSelection.id === 127 || this.dataSelection.id === 126
      || this.dataSelection.id === 322 || this.dataSelection.id === 323) {
      if (coverage === 0 && value === 0 && valor.description === 'MONTO') {
        // // console.log('primera suma asegurada de cobertura basica');
        // EC
        this.dataSelection.coverageList[1].descriptionList[description].valueList[value].value = val;
        // TR
        this.dataSelection.coverageList[2].descriptionList[description].valueList[value].value = val;
        // FHM
        this.dataSelection.coverageList[3].descriptionList[description].valueList[value].value = val;
        // TEV
        this.dataSelection.coverageList[4].descriptionList[description].valueList[value].value = val;
        // RE
        const val10 = formatNumber(Number(r10), 'en-US', '1.2-2');
        this.dataSelection.coverageList[5].descriptionList[description].valueList[value].value = val10 ;
        // CB
        this.dataSelection.coverageList[0].descriptionList[description].valueList[value].medition = moneyTmp;
        // EC
        this.dataSelection.coverageList[1].descriptionList[description].valueList[value].medition = moneyTmp;
        // TR
        this.dataSelection.coverageList[2].descriptionList[description].valueList[value].medition = moneyTmp;
        // FHM
        this.dataSelection.coverageList[3].descriptionList[description].valueList[value].medition = moneyTmp;
        // TEV
        this.dataSelection.coverageList[4].descriptionList[description].valueList[value].medition = moneyTmp;
        // RE
        this.dataSelection.coverageList[5].descriptionList[description].valueList[value].medition = moneyTmp;
      }
    }
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.coverageList[coverage].descriptionList[description].valueList[value].medition = moneyTmp;
    }
  }

  validateData2(valor, coverage, description, va, event) {

    const next = va + 1;
    setTimeout(() => {
      const nextkey = document.getElementById('na014') as HTMLInputElement | null;
      nextkey.focus();

      // console.log(nextkey.disabled = true);
    }, 1000);

  }
  changeSelectionOption3(valor, subCoverage, coverage, description, value, typeVisible) {
    // // console.log(valor,  subCoverage, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[value].medition = valor.medition;
    } else {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[value].value = valor.value;
    }
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MINIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // tslint:disable-next-line:max-line-length
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].medition = '';
          // document.getElementById('va_l3' + subCoverage + coverage + description + va).style.pointerEvents = 'none';
        } else {
          // document.getElementById('va_l3' + subCoverage + coverage + description + va).style.pointerEvents = 'auto';
          // tslint:disable-next-line:max-line-length
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MAXIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l3' + subCoverage + coverage + description + va).style.pointerEvents = 'none';
          // tslint:disable-next-line:max-line-length
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // tslint:disable-next-line:max-line-length
          this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
          // document.getElementById('va_l3' + subCoverage + coverage + description + va).style.pointerEvents = 'auto';
        }
        break;
    }
    if (this.dataSelection.id === 124 || this.dataSelection.id === 321) {
      if (subCoverage === 2 &&  coverage === 0 && value === 0 && valor.description === 'MONTO') {
        // console.log('primera suma asegurada de cobertura basica');
        // FHM
        this.dataSelection.subCoverageList[2].coverageList[1].descriptionList[0].valueList[0].medition = valor.medition;
        // TEV
        this.dataSelection.subCoverageList[2].coverageList[2].descriptionList[0].valueList[0].medition = valor.medition;
      } else if (subCoverage === 3 &&  coverage === 0 && value === 0 && valor.description === 'MONTO') {
        // FHM
        this.dataSelection.subCoverageList[3].coverageList[1].descriptionList[0].valueList[0].medition = valor.medition;
        // TEV
        this.dataSelection.subCoverageList[3].coverageList[2].descriptionList[0].valueList[0].medition = valor.medition;
      }
      if (subCoverage === 2 &&  coverage === 0 && value === 0 && valor.description === 'MESES') {
        // console.log('primera suma asegurada de cobertura basica');
        // FHM
        this.dataSelection.subCoverageList[2].coverageList[1].descriptionList[1].valueList[0].medition = valor.medition;
        // TEV
        this.dataSelection.subCoverageList[2].coverageList[2].descriptionList[1].valueList[0].medition = valor.medition;
      } else if (subCoverage === 3 &&  coverage === 0 && value === 0 && valor.description === 'MESES') {
        // FHM
        this.dataSelection.subCoverageList[3].coverageList[1].descriptionList[1].valueList[0].medition = valor.medition;
        // TEV
        this.dataSelection.subCoverageList[3].coverageList[2].descriptionList[1].valueList[0].medition = valor.medition;
      }
    }
  }
  formatCurrency3(valor, subCoverage, coverage, description, value) {
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    valor.value = valor.value.replace(/,/g, '');
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    // console.log(val);
    this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[value].value = val;
    // asignar la misma unidad a todos las cobertura que aplica la suma asegurada
    if (this.dataSelection.id === 124 || this.dataSelection.id === 321) {
      if (subCoverage === 2 &&  coverage === 0 && value === 0 && valor.description === 'MONTO') {
        // console.log('primera suma asegurada de cobertura basica');
        // FHM
        this.dataSelection.subCoverageList[2].coverageList[1].descriptionList[0].valueList[0].value = valor.value;
        this.dataSelection.subCoverageList[2].coverageList[1].descriptionList[0].valueList[0].medition = moneyTmp;
        // TEV
        this.dataSelection.subCoverageList[2].coverageList[2].descriptionList[0].valueList[0].value = valor.value;
        this.dataSelection.subCoverageList[2].coverageList[2].descriptionList[0].valueList[0].medition = moneyTmp;
      } else if (subCoverage === 3 &&  coverage === 0 && value === 0 && valor.description === 'MONTO') {
        // FHM
        this.dataSelection.subCoverageList[3].coverageList[1].descriptionList[0].valueList[0].value = valor.value;
        this.dataSelection.subCoverageList[3].coverageList[1].descriptionList[0].valueList[0].medition = moneyTmp;
        // TEV
        this.dataSelection.subCoverageList[3].coverageList[2].descriptionList[0].valueList[0].value = valor.value;
        this.dataSelection.subCoverageList[3].coverageList[2].descriptionList[0].valueList[0].medition = moneyTmp;
      }
      if (subCoverage === 2 &&  coverage === 0 && value === 0 && valor.description === 'MESES') {
        // console.log('primera suma asegurada de cobertura basica');
        // FHM
        this.dataSelection.subCoverageList[2].coverageList[1].descriptionList[1].valueList[0].value = valor.value;
        // TEV
        this.dataSelection.subCoverageList[2].coverageList[2].descriptionList[1].valueList[0].value = valor.value;
      } else if (subCoverage === 3 &&  coverage === 0 && value === 0 && valor.description === 'MESES') {
        // FHM
        this.dataSelection.subCoverageList[3].coverageList[1].descriptionList[1].valueList[0].value = valor.value;
        // TEV
        this.dataSelection.subCoverageList[3].coverageList[2].descriptionList[1].valueList[0].value = valor.value;
      }
    }
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.subCoverageList[subCoverage].coverageList[coverage].descriptionList[description].valueList[value].medition = moneyTmp;
    }
  }
  formatCurrency3a(valor, subCoverage, coverage, description, value) {
    // asignar la misma unidad a todos las cobertura que aplica la suma asegurada
    if (this.dataSelection.id === 124 || this.dataSelection.id === 321) {
      if (subCoverage === 2 &&  coverage === 0 && value === 0 && valor.description === 'MESES') {
        // console.log('primera suma asegurada de cobertura basica');
        // FHM
        this.dataSelection.subCoverageList[2].coverageList[1].descriptionList[1].valueList[0].value = valor.value;
        // TEV
        this.dataSelection.subCoverageList[2].coverageList[2].descriptionList[1].valueList[0].value = valor.value;
      } else if (subCoverage === 3 &&  coverage === 0 && value === 0 && valor.description === 'MESES') {
        // FHM
        this.dataSelection.subCoverageList[3].coverageList[1].descriptionList[1].valueList[0].value = valor.value;
        // TEV
        this.dataSelection.subCoverageList[3].coverageList[2].descriptionList[1].valueList[0].value = valor.value;
      }
    }
  }
  changeSelectionOption4(valor, coverage, description, value, typeVisible) {
    valor.value = valor.value.replace(/,/g, '');
    // // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[value].medition = valor.medition;
    } else {
      this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[value].value = valor.value;
    }
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MINIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l4' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l4' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MAXIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l4' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l4' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
    }
  }
  formatCurrency4(valor, coverage, description, value) {
    valor.value = valor.value.replace(/,/g, '');
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    // console.log(val);
    this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[value].value = val;
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.subCoverageList[0].coverageList[coverage].descriptionList[description].valueList[value].medition = moneyTmp;
    }
  }
  changeSelectionOption5(valor, sc, coverage, description, value, typeVisible) {
    // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[value].medition = valor.medition;
    } else {
      this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[value].value = valor.value;
    }
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MINIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l5' + sc + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l5' + sc + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MAXIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l5' + sc + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l5' + sc + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
    }
  }
  formatCurrency5(valor, sc, coverage, description, value) {
    valor.value = valor.value.replace(/,/g, '');
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    // console.log(val);
    this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[value].value = val;
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.subCoverageList5[sc].coverageList[coverage].descriptionList[description].valueList[value].medition = moneyTmp;
    }
  }
  changeSelectionOption5a(valor, coverage, description, value, typeVisible) {
    // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[value].medition = valor.medition;
    } else {
      this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[value].value = valor.value;
    }
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MINIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].medition = '';
          // document.getElementById('va_l5a' + coverage + description + va).style.pointerEvents = 'none';
        } else {
          // document.getElementById('va_l5a' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MAXIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l5a' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].value = '';
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l5a' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
    }
  }
  formatCurrency5a(valor, coverage, description, value) {
    valor.value = valor.value.replace(/,/g, '');
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    // // console.log(val);
    this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[value].value = val;
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.coverageLevels2[coverage].descriptionList[description].valueList[value].medition = moneyTmp;
    }
  }
  changeSelectionOption6(valor, coverage, description, value, typeVisible) {
    // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value].medition = valor.medition;
    } else {
      this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value].value = valor.value;
    }
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MAXIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l6' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].disabledField = true;
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].value = '';
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l6' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MINIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va_l6' + coverage + description + va).style.pointerEvents = 'none';
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].disabledField = true;
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].value = '';
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va_l6' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[va].disabledField = false;
        }
        break;

      case 'TIPO':
        const v = this.dataSelection.coverage5List[0].descriptionCoverageList[0].valueList[1].value;
        const m = this.dataSelection.coverage5List[0].descriptionCoverageList[0].valueList[1].medition;
        if (valor.value === 'BASICA' || valor.value === 'MONTO DE SUMA ASEGURADA TOTAL') {
            this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value + 1].value = v;
            this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value + 1].medition = m;
        } else if (valor.value === 'SUBLIMITE' || valor.value === 'SUBLIMITE POR UNIDAD') {
            this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value + 1].value = v;
            this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value + 1].medition = m;
        }
        break;
    }
  }
  formatCurrency6(valor, coverage, description, value) {
    valor.value = valor.value.replace(/,/g, '');
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    // // console.log(val);
    this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value].value = val;
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.dataSelection.coverage5List[coverage].descriptionCoverageList[description].valueList[value].medition = moneyTmp;
    }
  }
  saveSectionLevel2() {
    console.log('validaciones 2');
    this.dataSectionSave.json = JSON.stringify(this.dataSelection);
    // console.log(this.dataSelection);
    if (this.dataSectionSave.sectionId === 130 || this.dataSectionSave.sectionId === 131
        || this.dataSectionSave.sectionId === 132 || this.dataSectionSave.sectionId === 133) {
      if (this.dataSelection.coverageList[0].descriptionList[0].valueList[0].value === 'LUC') {
        // this.dataSectionSave.json =  this.dataSectionSave.json.replace(/false/g, 'true');
        // console.log(this.dataSectionSave.json);
      }
    }
    // proceso de validación de datos de nivel 2
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.dataSelection.coverageList.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
        for (let d = 0; d < this.dataSelection.coverageList[c].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
          // tslint:disable-next-line:prefer-for-of
          for (let va = 0; va < this.dataSelection.coverageList[c].descriptionList[d].valueList.length; va ++) { // se recorre valores
            // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
            if (va === 0) {
              if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
                if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                  if (this.dataToSendGeneral.subBranchId === 18
                    || this.dataToSendGeneral.subBranchId === 15
                    || this.dataToSendGeneral.subBranchId === 1) {
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return;
                  }
                  break; // se salta la validación
                }
              } else {
                if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                  if (this.dataToSendGeneral.subBranchId === 18
                    || this.dataToSendGeneral.subBranchId === 15
                    || this.dataToSendGeneral.subBranchId === 1) {
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return;
                  }
                  break; // se salta la validación
                }
              }
            } else {
              if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
                if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                  // console.log(c, d, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              } else {
                if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'MINIMO'
                    || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'MAXIMO'
                  || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'DESCRIPCIÓN') {
                    if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === 'NO APLICA'
                        || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                      // console.log('ya no valida el monto y unidad');
                      va++;
                    }
                } else if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                  // console.log(c, d, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              }
            }

          }
        }
    }
    if (this.dataToSendGeneral.subBranchId === 18 ) {
      this.invokeServiceSaveCoverageAdditionalCivilWork(this.dataSectionSave);
    } else {
      this.invokeServiceSaveSectionDamageByLocation(this.dataSectionSave);
    }
  }
  saveSectionLevel3() {
    this.dataSectionSave.json = JSON.stringify(this.dataSelection);
    // console.log(this.dataSectionSave);
    // proceso de validación de datos de nivel 3
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.dataSelection.subCoverageList.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
      for (let d = 0; d < this.dataSelection.subCoverageList[c].coverageList.length; d ++) { // se recorre caracteristicas de coberturas
        // tslint:disable-next-line:prefer-for-of
        for (let de = 0; de < this.dataSelection.subCoverageList[c].coverageList[d].descriptionList.length; de ++) {
          // tslint:disable-next-line:prefer-for-of max-line-length
          for (let va = 0; va < this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList.length; va ++) { // se recorre valores
            // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
            // console.log(this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va]);
            if (de === 0 && va === 0) {
              if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].typeVisible === 1) {
                if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                  de = this.dataSelection.subCoverageList[c].coverageList[d].descriptionList.length;
                  break; // se salta la validación
                }
              } else {
                if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                  de = this.dataSelection.subCoverageList[c].coverageList[d].descriptionList.length;
                  break; // se salta la validación
                }
              }
            } else {
              if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].typeVisible === 1) {
                if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                  // console.log(c, d, de, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              } else {
                if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].description === 'MINIMO'
                  || this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].description === 'MAXIMO') {
                  if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === 'NO APLICA') {
                    // console.log('ya no valida el monto y unidad');
                    va++;
                  }
                } else if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                  // console.log(c, d, de, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              }
            }
          }
        }
      }
    }
    this.invokeServiceSaveSectionDamageByLocation(this.dataSectionSave);
  }
  saveSectionLevel4() {
    this.dataSectionSave.json = JSON.stringify(this.dataSelection);
    // console.log(this.dataSectionSave);
    for (let c = 0; c < this.dataSelection.subCoverageList.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
      for (let co = 0; co < this.dataSelection.subCoverageList[c].coverageList.length; co ++) {
        // tslint:disable-next-line:prefer-for-of max-line-length
        for (let d = 0; d < this.dataSelection.subCoverageList[c].coverageList[co].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
          // tslint:disable-next-line:prefer-for-of max-line-length
          for (let va = 0; va < this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList.length; va ++) { // se recorre valores
            // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
            if (va === 0) {
              if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  break; // se salta la validación
                }
              } else {
                if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  break; // se salta la validación
                }
              }
            } else {
              if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  // console.log(c, co, d, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              } else {
                if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].description === 'MINIMO'
                  || this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].description === 'MAXIMO') {
                  if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === 'NO APLICA') {
                    // console.log('ya no valida el monto y unidad');
                    va++;
                  }
                } else if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  // console.log(c, co, d, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              }
            }
          }
        }
      }
    }
    this.invokeServiceSaveSectionDamageByLocation(this.dataSectionSave);
  }
  saveSectionLevel5() {
    this.dataSectionSave.json = JSON.stringify(this.dataSelection);
    // console.log(this.dataSectionSave);
    // proceso de validación de datos de nivel 5
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.dataSelection.coverageLevels2.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
      for (let d = 0; d < this.dataSelection.coverageLevels2[c].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
        // tslint:disable-next-line:prefer-for-of
        for (let va = 0; va < this.dataSelection.coverageLevels2[c].descriptionList[d].valueList.length; va ++) { // se recorre valores
          // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
          if (va === 0) {
            if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].typeVisible === 1) {
              if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                break; // se salta la validación
              }
            } else {
              if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                break; // se salta la validación
              }
            }
          } else {
            if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].typeVisible === 1) {
              if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageLevels2[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            } else {
              if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].description === 'MINIMO'
                || this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].description === 'MAXIMO') {
                if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === 'NO APLICA') {
                  // console.log('ya no valida el monto y unidad');
                  va++;
                }
              } else if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageLevels2[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            }
          }

        }
      }
    }
    for (let c = 0; c < this.dataSelection.subCoverageList5.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
      for (let co = 0; co < this.dataSelection.subCoverageList5[c].coverageList.length; co ++) {
        // tslint:disable-next-line:prefer-for-of max-line-length
        for (let d = 0; d < this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
          // tslint:disable-next-line:prefer-for-of max-line-length
          for (let va = 0; va < this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList.length; va ++) { // se recorre valores
            // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
            if (va === 0) {
              if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  break; // se salta la validación
                }
              } else {
                if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  break; // se salta la validación
                }
              }
            } else {
              if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  // console.log(c, co, d, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList5[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              } else {
                if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].description === 'MINIMO'
                  || this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].description === 'MAXIMO') {
                  if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === 'NO APLICA') {
                    // console.log('ya no valida el monto y unidad');
                    va++;
                  }
                } else if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                  // console.log(c, co, d, va);
                  // tslint:disable-next-line:max-line-length
                  this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList5[c].name + ', PARA PODER GUARDAR', 'Notificación');
                  return; // se salta la validación
                }
              }
            }
          }
        }
      }
    }
    this.invokeServiceSaveSectionDamageByLocation(this.dataSectionSave);
  }
  saveSectionLevel6() {
    this.dataSectionSave.json = JSON.stringify(this.dataSelection);
    // console.log(this.dataSectionSave);
    // validaciones para nivel 6
    for (let c = 0; c < this.dataSelection.coverage5List.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of max-line-length
      for (let d = 0; d < this.dataSelection.coverage5List[c].descriptionCoverageList.length; d ++) { // se recorre caracteristicas de coberturas
        // tslint:disable-next-line:prefer-for-of max-line-length
        for (let va = 0; va < this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList.length; va ++) { // se recorre valores
          // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
          if (va === 0) {
            if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].typeVisible === 1) {
              if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                break; // se salta la validación
              }
            } else {
              if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                break; // se salta la validación
              }
            }
          } else {
            if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].typeVisible === 1) {
              if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverage5List[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            } else {
              if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].description === 'MINIMO'
                || this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].description === 'MAXIMO') {
                if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === 'NO APLICA') {
                  // console.log('ya no valida el monto y unidad');
                  va++;
                }
              } else if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverage5List[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            }
          }

        }
      }
    }
    this.invokeServiceSaveSectionDamageByLocation(this.dataSectionSave);
  }
  onManagementTabSelect(c) {
    // console.log(c);
  }
  onManagementTabSelectSection(c) {
    // this.spinner.show('sp');
    // console.log(c.id);
    let id = 0;
    switch (c.id) {
      case '3s':
        id = 126;
        break;
    }
   /* this.api.getSectionById(id).then((data: any) => {
      this.dataSelection = data;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });*/
  }
  onActivateNode(event: any) {
    // console.log(event);
    // this.selectedNode = event.node.data;
    // this.showEdit = true;
    // Do stuff with selected node
  }
  showSection(event: any) {
    // console.log(event);
  }
  invokeServiceSaveSectionDamageByLocation(dataSection: any) {
    this.spinner.show('sp');
    this.api.postSectionDamageByLocation(dataSection)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.invokeServiceGetSections(this.locationId);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  deleteSectionDamage() {
    this.spinner.show('sp');
    this.api.deleteSectionLocationDamageById(this.locationId, this.typeId).subscribe( response => {
      this.typeId = 0;
      this.typeOperationRemove = 0;
      this.modalRef.hide();
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      if (this.dataToSendGeneral.subBranchId === 18) {
        this.invokeServiceGetCoveragesAdditionalCivilWork();
      } else {
        this.invokeServiceGetSections(this.locationId);
      }
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
      this.spinner.hide('sp');
    });
  }
  closeSection() {
    this.modalRef.hide();
    this.invokeServiceGetSections(this.locationId);
  }
  getResultLocationHousing(e) {
    this.invokeServiceGetLocationsByPagination(1, 8);
  }
  getResultImport(e) {
    // console.log(e);
    this.massImportList = [];
    this.consequentialLoss = [];
    this.containedFireList = [];
    this.buildingFireList = [];
    this.boilerList = [];
    this.machinery = [];
    this.electronicEquipmentList = [];
    this.contractorEquipment = [];
    this.glassList = [];
    this.theftList = [];
    this.moneyList = [];
    this.advertisementsList = [];
    this.rcList = [];
    switch (e.typeImport) {
      case 7:
        this.massImportList = e.list;
        break;
      case 8:
        this.consequentialLoss = e.list;
        break;
      case 9:
        this.containedFireList = e.list;
        break;
      case 10:
        this.buildingFireList = e.list;
        break;
      case 11:
        this.boilerList = e.list;
        break;
      case 12:
        this.machinery = e.list;
        break;
      case 13:
        this.electronicEquipmentList = e.list;
        break;
      case 14:
        this.contractorEquipment = e.list;
        break;
      case 15:
        this.glassList = e.list;
        break;
      case 16:
        this.theftList = e.list;
        break;
      case 17:
        this.moneyList = e.list;
        break;
      case 18:
        this.advertisementsList = e.list;
        break;
      case 19:
        this.rcList = e.list;
        break;
    }

  }
  openDetailAddressMassive(template: TemplateRef<any>, m) {
    // this.loadDataToSendReceiptByLocation();
    this.invokeServiceGetCountries();
    this.invokeServiceGetStatesByCountry();
    this.getTypesHomeWallCeiling();
    this.streetFiscalNew = {
      coloniesStreetsId: 0
      , neighborhoodCode: null
      , nameStreet: null
      , status: false

    };
    this.isNewStreetFiscal = false;
    this.fireProtectionSelectedItems = [];
    this.securityMeasureSelectedItems = [];
    this.subActivityList = [];
    this.fileUpload = null;
    this.invokeServiceGetClientsPolicy(localStorage.getItem('policy-id'));
    this.invokeServiceGetSecurityMeasure();
    this.invokeServiceGetFireProtection();
    this.addressId = 1;
    if (this.addressId > 0) {
        this.base64File2 = '';
        let streetTmp = '';
        let neighborhoodCodeTmp = '';
        this.spinner.show('sp');
        this.dataAddressFiscal = m;
        this.isNewStreetFiscal = true;
        streetTmp = this.dataAddressFiscal.street;
        neighborhoodCodeTmp = this.dataAddressFiscal.neighborhoodCode;
        // this.addressForm.get('addressTypeFiscal').patchValue(data.addressType);
        this.addressForm.get('countryFiscal').patchValue(m.country);
        this.selectStateMunicipalityByZipCode(this._FISCAL);
        // load multiSelect
        setTimeout(() => {
          const securityMeasure = [];
          if (this.dataAddressFiscal.securityMeasureList !== null) {
            this.dataAddressFiscal.securityMeasureList.forEach(item => {
              this.catSecurityMeasure.forEach(item2 => {
                if (item === item2.id) {
                  securityMeasure.push(item2);
                  return;
                }
              });
            });
          }
          this.securityMeasureSelectedItems = securityMeasure;
        }, 1000);
        setTimeout(() => {
          const fireProtection = [];
          if (this.dataAddressFiscal.fireProtectionList !== null) {
            this.dataAddressFiscal.fireProtectionList.forEach(item => {
              this.catFireProtection.forEach(item2 => {
                if (item === item2.id) {
                  fireProtection.push(item2);
                  return;
                }
              });
            });
          }
          this.fireProtectionSelectedItems = fireProtection;
        }, 1000);
        setTimeout(() => {
          const subActivity = [];
          if (this.dataAddressFiscal.subActivityList !== null) {
            this.dataAddressFiscal.subActivityList.forEach(item => {
              subActivity.push(item);
            });
          }
          this.subActivityList = subActivity;
        }, 1000);
        setTimeout(() => {
          this.dataAddressFiscal.street = streetTmp;
          this.dataAddressFiscal.neighborhoodCode = neighborhoodCodeTmp === '0' ? null : neighborhoodCodeTmp;
        }, 1000);
        this.spinner.hide('sp');
    }
    this.modalLocation = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
    // console.log(this.dataAddressFiscal);
  }
  invokeFunctionLoadDataLocation() {
    if (this.dataToSendGeneral.typePaymentPolicy === 3) {
      if (this.dataAddressFiscal.netPremium === 0 || this.dataAddressFiscal.commission === 0
          || this.dataAddressFiscal.totalPremium === 0) {
        this.toastr.warning('POR FAVOR COMPLETE LA COBRANZA', 'NOTIFICACION');
        return;
      }
    }
    // setear valores
    this.stateListFiscal.forEach(item => {
      if (item.cKey === this.dataAddressFiscal.stateCode) {
        this.dataAddressFiscal.state = item.cValue;
        return;
      }
    });
    this.municipalityListFiscal.forEach(item => {
      if (item.cKey === this.dataAddressFiscal.municipalityCode) {
        this.dataAddressFiscal.municipality = item.cValue;
        return;
      }
    });
    this.neighborhoodListFiscal.forEach(item => {
      if (item.cKey === this.dataAddressFiscal.neighborhoodCode) {
        this.dataAddressFiscal.neighborhood = item.cValue;
        return;
      }
    });
    let s = '';
    if (this.dataAddressFiscal.subActivityList !== null) {
      this.dataAddressFiscal.subActivityList.forEach(item => {
        s = s + item + ',';
      });
    }
    this.dataAddressFiscal.subActivity = s;
    this.typeWallCeiling.forEach(item => {
      if (item.id === this.dataAddressFiscal.constructionTypeId) {
        this.dataAddressFiscal.constructionType = item.name;
        return;
      }
    });
    let sm = '';
    this.catSecurityMeasure.forEach(item => {
      this.dataAddressFiscal.securityMeasureList.forEach(i => {
        if (i === item.id) {
          sm = sm + item.name + ',';
        }
      });
    });
    this.dataAddressFiscal.securityMeasure = sm;
    let fp = '';
    this.catFireProtection.forEach(item => {
      this.dataAddressFiscal.fireProtectionList.forEach(f => {
        if (f === item.id) {
          fp = fp + item.name + ',';
        }
      });
    });
    this.dataAddressFiscal.fireProtection = fp;
    this.catClientsPolicy.forEach(item => {
      if (item.clientId === this.dataAddressFiscal.clientId) {
        this.dataAddressFiscal.client = item.name;
        return;
      }
    });

    this.massImportList.forEach(item => {
      if (item.locationId === this.dataAddressFiscal.locationId) {
        item = this.dataAddressFiscal;
        return;
      }
    });

    if (this.dataAddressFiscal.clientId === 0 || this.subActivityList.length === 0) {
      this.toastr.warning('POR FAVOR INGRESE TODOS LOS DATOS', 'NOTIFICACIÓN');
      return;
    }

    this.dataAddressFiscal.processMovement = true;
    this.modalLocation.hide();
    // console.log(this.dataAddressFiscal);
  }
  invokeServiceSaveLocationsDamages() {
    this.spinner.show('sp');
    if (this.massImportList.length === 0) {
      this.toastr.info('TIENE QUE CARGAR EL LAYOUT DE UBICACIONES', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    let process: boolean;
    process = false;
    this.massImportList.some(item => {
      if (!item.processMovement) {
        this.toastr.info('TIENE QUE COMPLETAR LA INFORMACIÓN DE TODAS LAS UBICACIONES ANTES DE GUARDAR', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        process = true;
        return process;
      }
    });
    if (!process) {
      // console.log('continua');
      const data = {
        process: 1,
        objectImport: JSON.stringify(this.massImportList)
      };
      this.api.postLocationsDamages(data)
        .then(
          (response: any) => {
            this.spinner.hide('sp');
            this.massImportList = [];
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    }
  }
  invokeServicePostImportMassiveSectionDamage(typeImport: number) {
    this.spinner.show('sp');
    let data: any;
    switch (typeImport) {
      case 8:
        data = {
          process: 8,
          objectImport: JSON.stringify(this.consequentialLoss)
        };
        break;
      case 9:
        data = {
          process: 9,
          objectImport: JSON.stringify(this.containedFireList)
        };
        break;
      case 10:
        data = {
          process: 10,
          objectImport: JSON.stringify(this.buildingFireList)
        };
        break;
      case 11:
        data = {
          process: 11,
          objectImport: JSON.stringify(this.boilerList)
        };
        break;
      case 12:
        data = {
          process: 12,
          objectImport: JSON.stringify(this.machinery)
        };
        break;
      case 13:
        data = {
          process: 13,
          objectImport: JSON.stringify(this.electronicEquipmentList)
        };
        break;
      case 14:
        data = {
          process: 14,
          objectImport: JSON.stringify(this.contractorEquipment)
        };
        break;
      case 15:
        data = {
          process: 15,
          objectImport: JSON.stringify(this.glassList)
        };
        break;
      case 16:
        data = {
          process: 16,
          objectImport: JSON.stringify(this.theftList)
        };
        break;
      case 17:
        data = {
          process: 17,
          objectImport: JSON.stringify(this.moneyList)
        };
        break;
      case 18:
        data = {
          process: 18,
          objectImport: JSON.stringify(this.advertisementsList)
        };
        break;
      case 19:
        data = {
          process: 19,
          objectImport: JSON.stringify(this.rcList)
        };
        break;
    }
    if (data.objectImport === '[]') {
      this.toastr.warning('NO HAY DATOS QUE GUARDAR, CARGUE LAYOUT', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    this.api.postLocationsDamages(data)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          this.consequentialLoss = [];
          this.containedFireList = [];
          this.buildingFireList = [];
          this.boilerList = [];
          this.machinery = [];
          this.electronicEquipmentList = [];
          this.contractorEquipment = [];
          this.glassList = [];
          this.theftList = [];
          this.moneyList = [];
          this.advertisementsList = [];
          this.rcList = [];
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  /*--+---------------------------------------------------------------------------------+
|----------------------------| C O B R A N Z A |----------------------------|
+---------------------------------------------------------------------------------+--*/

  showReceipt(c) {
    // console.log(c);
    this.bReceiptC = c.receipt;
    switch (this.dataToSendGeneral.typePaymentPolicy) {
      case 1:
        this.categoryName = c.number;
        this.categoryId = 0;
        break;
      case 2:
        this.categoryName = c.client;
        this.categoryId = c.clientId;
        break;
      case 3:
        this.categoryName = c.client + '-' + c.category;
        this.categoryId = c.categoryId;
        break;
    }
    this.dataToSendReceipt.categoryId = this.categoryId;
    if (this.bReceiptC > 0) {
      this.loadReceiptPolicyCategory(this.categoryId);
    } else {
      this.receiptList = [];
      if (this.dataToSendGeneral.subBranchId === 10
        || this.dataToSendGeneral.subBranchId === 6
        || this.dataToSendGeneral.subBranchId === 13) {
        this.dataToSendReceipt.taxId = 3;
      }
    }
    this.invokeServiceGetConfigurationRightInsurance();
  }
  generateReceipt() {
    this.dataToSendReceipt.categoryId = this.categoryId;
    if (this.dataToSendGeneral.typePaymentPolicy > 1 && this.dataToSendReceipt.categoryId === 0 && this.categoryId === 0) {
      this.toastr.warning('POR FAVOR SELECCIONE DE NUEVO LA UBICACIÓN A LA CUAL SE AGREGARA COBRANZA', 'NOTIFICACIÓN');
      return;
    }
    if (this.dataToSendReceipt.netPremium === 0) {
      this.toastr.info('LA PRIMA NETA DEBE DE SER MAYOR A CERO PARA LA GENERACIÓN DE RECIBOS.', 'NOTIFICACIÓN');
      return;
    }
    this.dataToSendReceipt.categoryId = this.categoryId;
    // console.log(this.dataToSendReceipt);
    this.spinner.show('sp');
    this.api.postReceiptDataDamages(this.dataToSendReceipt)
      .then(
        (response: any) => {
          this.receiptList = response;
          this.getCategoriesPolicy();
          this.loadDataToSendReceipt();
          this.spinner.hide('sp');
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  loadDataToSendReceipt() {
    this.dataToSendReceipt = {
      receiptId: 0,
      commission: 0,
      commissionAmount: 0,
      netPremium: 0,
      rights: 0,
      paymentCharges: 0,
      iva: 0,
      totalPremium: 0,
      paymentDate: '',
      paymentMonth: 0,
      policyId: localStorage.getItem('policy-id'),
      receiptStatusId: 1,
      paymentFormId: 0,
      paymentType: this.dataToSendGeneral.paymentTypeId,
      categoryId: 0,
      taxId: 0
    };
  }
  sumTotalPremium() {
    if (this.dataToSendReceipt.taxId > 0) {
      this.reCalTax();
    }
    this.calCommissionAmount();
    // tslint:disable-next-line:max-line-length
    this.dataToSendReceipt.totalPremium = this.dataToSendReceipt.netPremium + this.dataToSendReceipt.rights + parseFloat(this.dataToSendReceipt.paymentCharges) + parseFloat(this.dataToSendReceipt.iva);
    this.dataToSendReceipt.totalPremium = this.invokeFunctionFormatValue(this.dataToSendReceipt.totalPremium, 2);
  }
  loadDataTaxes() {
    this.api.getTaxes().then((data: any) => {
      this.catTaxes = data;
      if (this.dataToSendGeneral.subBranchId === 10 ||
        this.dataToSendGeneral.subBranchId === 6) {
        if (this.catTaxes !== undefined) {
          this.catTaxes.forEach(item => {
            if (item.value === 0) {
              this.dataToSendReceipt.taxId = item.taxId;
            }
          });
        }
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  calTax(i) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {
        console.log('iva');
        // tslint:disable-next-line:max-line-length
        this.dataToSendReceipt.iva = ((this.dataToSendReceipt.netPremium + this.dataToSendReceipt.rights + parseFloat(this.dataToSendReceipt.paymentCharges)) * tax.taxValue) / 100;
        this.dataToSendReceipt.iva = this.invokeFunctionFormatValue(this.dataToSendReceipt.iva, 2);
        this.sumTotalPremium();
      }
    }
  }
  reCalTax() {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(this.dataToSendReceipt.taxId)) {
        // ('entra a recalculo');
        // tslint:disable-next-line:max-line-length
        this.dataToSendReceipt.iva = ((this.dataToSendReceipt.netPremium + this.dataToSendReceipt.rights + parseFloat(this.dataToSendReceipt.paymentCharges)) * tax.taxValue) / 100;
        this.dataToSendReceipt.iva = this.invokeFunctionFormatValue(this.dataToSendReceipt.iva, 2);
      }
    }
  }
  renewReceipt() {
    // // console.log(this.categoryId);
    this.spinner.show('sp');
    this.bReceiptC = 0;
    this.receiptList = [];
    this.api.deleteReceiptsPolicyCategory(this.categoryId, localStorage.getItem('policy-id')).subscribe(response => {
      this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
      this.getCategoriesPolicy();
      this.invokeServiceGetConfigurationRightInsurance();
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }
  receiptModal(r) {
    localStorage.setItem('receipt-id', r.receiptId);
    // // console.log(localStorage.getItem('receipt-id'));
  }
  loadReceiptPolicyCategory(categoryId) {
    this.receiptList = [];
    this.isSplitRights = false;
    const damagePolicyReceipt = {
      policyId: Number(localStorage.getItem('policy-id')),
      categoryId: this.categoryId === undefined ? 0 : this.categoryId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getReceiptPolicyCategoryDamages(damagePolicyReceipt).then((data: any) => {
      this.receiptList = data.receiptList;
      this.receiptTotal = data.policyAmount;
      this.policyAmount = data.policyAmount;
      this.netPremiumTmp = 500000;
      // // console.log(data);
      if (this.receiptList.length > 1) {
        if (this.receiptList[0].rights > 0 && this.receiptList[1].rights <= 0) {
          this.isSplitRights = true;
        }
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      console.error('desde policy: ', error.message);
    });
  }
  openMdlAttachment(template: TemplateRef<any>, receipt) {
    // // console.log(receipt);
    this.getPaymentForms();
    this.getStatusReceipts();
    this.editReceiptMdl(receipt);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  updateReceipt() {
    this.spinner.show('sp');
    if (this.receiptData.paymentDate === 'Invalid Date') {
      this.spinner.hide('sp');
      this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
      return;
    }
    const paymentDate = Date.parse(this.receiptData.paymentDate);
    this.receiptData.paymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
    const deadLinePayment = Date.parse(this.receiptData.paymentDeadline);
    this.receiptData.paymentDeadline = this.datePipe.transform(deadLinePayment, 'yyyy-MM-dd');
    // // console.log(this.receiptData);
    this.api.putReceiptData(this.receiptData)
      .then(
        (response: any) => {
          this.loadReceiptPolicyCategory(this.categoryId);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
          this.modalRef.hide();
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  editReceiptMdl(r) {
    this.spinner.show('sp');
    this.api.getReceiptById(r.receiptId)
      .then(
        (response: any) => {
          this.receiptData = {
            receiptId: response[0].receiptId,
            commission: response[0].commission,
            commissionAmount: response[0].commissionAmount,
            netPremium: response[0].netPremium,
            rights: response[0].rights,
            paymentCharges: response[0].paymentCharges,
            iva: response[0].iva,
            totalPremium: response[0].totalPremium,
            paymentDate: this.datePipe.transform(response[0].paymentDate, 'yyyy/MM/dd'),
            paymentMonth: 0,
            policyId: Number(localStorage.getItem('policy-id')),
            receiptStatusId: response[0].receiptStatusId,
            paymentFormId: response[0].paymentFormId,
            taxId: response[0].taxId,
            settlementNumber: response[0].settlementNumber,
            numberReceipt: response[0].numberReceipt,
            paymentDeadline: response[0].paymentDeadline
          };
          // // console.log(this.receiptData);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }
  invokeFunctionFormatValue(valor, decimal): number {
    // si no es un número devuelve el valor, o lo convierte a número con N decimales
    valor = parseFloat(valor);
    return isNaN(valor) ? valor : Number(valor.toFixed(decimal));
  }
  calTotal() {
    // tslint:disable-next-line:max-line-length
    this.receiptData.iva = ((this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges) * this.receiptData.taxId) / 100;
    // tslint:disable-next-line:max-line-length
    this.receiptData.totalPremium = this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges + this.receiptData.iva;
  }
  calCommissionPercentage() {
    const commission = (this.dataToSendReceipt.commissionAmount * 100) / this.dataToSendReceipt.netPremium;
    this.dataToSendReceipt.commission = this.invokeFunctionFormatValue(commission, 2);
  }
  calCommissionAmount() {
    const commissionAmount = (this.dataToSendReceipt.netPremium * this.dataToSendReceipt.commission) / 100;
    this.dataToSendReceipt.commissionAmount = this.invokeFunctionFormatValue(commissionAmount, 2);
  }
  calChargesPercentage() {
    const chargesPercentageTmp = (parseFloat(this.dataToSendReceipt.paymentCharges) * 100) / this.dataToSendReceipt.netPremium;
    this.chargesPercentage = chargesPercentageTmp;
    this.chargesPercentage = this.invokeFunctionFormatValue(this.chargesPercentage, 2);
  }
  calChargesAmount() {
    if (this.dataToSendReceipt.paymentCharges === 0) {
      const chargesAmount =  (this.dataToSendReceipt.netPremium * this.chargesPercentage) / 100;
      this.dataToSendReceipt.paymentCharges = this.invokeFunctionFormatValue(chargesAmount, 2);
      console.log(this.dataToSendReceipt.paymentCharges);
    }
  }
  splitRights(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, backdrop: 'static'});
  }
  sendSplitRights() {
    this.spinner.show('sp');
    const countReceipt = this.receiptList.length;
    const rightTmp = this.receiptList[0].rights;
    this.receiptList.forEach(item => {
      item.rights = rightTmp / countReceipt;
      item.iva = ((item.netPremium + item.rights + item.paymentCharges) * 16) / 100;
      item.iva = this.invokeFunctionFormatValue(item.iva,  2);
      item.totalPremium = item.netPremium + item.rights + item.paymentCharges + item.iva;
    });
    this.spinner.hide('sp');
    this.modalRef.hide();
  }
  calculateTotalPremium(receipt) {
    this.receiptList.forEach( item => {
      if (item.receiptId === receipt.receiptId) {
        item.iva = ((item.netPremium + item.rights + item.paymentCharges) * 16) / 100;
        item.iva = this.invokeFunctionFormatValue(item.iva,  2);
        item.totalPremium = item.netPremium + item.rights + item.paymentCharges + item.iva;
        item.totalPremium = this.invokeFunctionFormatValue(item.totalPremium, 2);
        return;
      }
    });
  }
  getCategoriesPolicy() {
    this.spinner.show('sp');
    this.receiptList = [];
    this.categoryList = [];
    const damagePolicy = {
      policyId: Number(localStorage.getItem('policy-id')),
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getCollectionsByPolicyDamages(damagePolicy).then((data: any) => {
      this.categoryList = data;
      this.spinner.hide('sp');
      // en caso de a ver cobranza hace la petición
      if (this.categoryList[0].receipt > 0) {
        switch (this.dataToSendGeneral.typePaymentPolicy) {
          case 1:
            this.loadReceiptPolicyCategory(0);
            this.categoryId = 0;
            // console.log('poliza: ', this.categoryId);
            break;
          case 2:
            this.loadReceiptPolicyCategory(this.categoryList[0].clientId);
            this.categoryId = this.categoryList[0].clientId;
            // console.log('cliente: ', this.categoryId);
            break;
          case 3:
            this.loadReceiptPolicyCategory(this.categoryList[0].categoryId);
            this.categoryId = this.categoryList[0].categoryId;
            // console.log('categoria: ', this.categoryId);
            break;
        }
      } else {
        switch (this.dataToSendGeneral.typePaymentPolicy) {
          case 1:
            this.categoryId = 0;
            // console.log('poliza: ', this.categoryId);
            break;
          case 2:
            this.categoryId = this.categoryList[0].clientId;
            // console.log('cliente: ', this.categoryId);
            break;
          case 3:
            this.categoryId = this.categoryList[0].categoryId;
            // console.log('categoria: ', this.categoryId);
            break;
        }
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  invokeServiceSaveReceipts() {
    this.spinner.show('sp');
    let validateData = true;
    let netPremiumTmp = 0;
    let rightsTmp = 0;
    let chargesTmp = 0;
    let ivaTmp = 0;
    let totalPremiumTmp = 0;
    // console.log('validacion de cobranza');
    this.receiptList.forEach(receipt => {
      if (receipt.paymentDate === null || receipt.paymentDeadline === null) {
        this.spinner.hide('sp');
        this.toastr.info('INGRESE UNA FECHA VALIDA', 'Notificación');
        validateData = false;
        return;
      }
      const paymentDate = Date.parse(receipt.paymentDate);
      receipt.paymentDate = this.datePipe.transform(paymentDate, 'yyyy-MM-dd');
      const deadLinePayment = Date.parse(receipt.paymentDeadline);
      receipt.paymentDeadline = this.datePipe.transform(deadLinePayment, 'yyyy-MM-dd');
      netPremiumTmp = netPremiumTmp + receipt.netPremium;
      rightsTmp = rightsTmp + receipt.rights;
      chargesTmp = chargesTmp + receipt.paymentCharges;
      ivaTmp = ivaTmp + receipt.iva;
      totalPremiumTmp = totalPremiumTmp + receipt.totalPremium;
      receipt.policyId = this.dataToSendGeneral.policyId;
    });
    if (!validateData) {
      return;
    }
    this.policyAmount = {
      netPremium: this.invokeFunctionFormatValue(netPremiumTmp, 2),
      rights: this.invokeFunctionFormatValue(rightsTmp, 2),
      charges: this.invokeFunctionFormatValue(chargesTmp, 2),
      iva: this.invokeFunctionFormatValue(ivaTmp, 2),
      totalPremium: this.invokeFunctionFormatValue(totalPremiumTmp, 2)
    };
    // console.log(this.receiptList);
    // console.log(this.policyAmount);
    if (this.receiptTotal.netPremium !== this.policyAmount.netPremium ||
       this.receiptTotal.rights !== this.policyAmount.rights ||
       this.receiptTotal.charges !== this.policyAmount.charges ||
       this.receiptTotal.iva !== this.policyAmount.iva ||
       this.receiptTotal.totalPremium !== this.policyAmount.totalPremium ) {
      this.spinner.hide('sp');
      this.toastr.warning('VERIFIQUE LOS MONTOS', 'NOTIFICACIÓN');
      return;
    }
    this.api.putReceiptDataDamages(this.receiptList)
      .then(
        (response: any) => {
          this.loadReceiptPolicyCategory(this.categoryId);
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACION');
          this.spinner.hide('sp');
        }, error => {
          console.error(error.status);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceGetConfigurationRightInsurance() {
    this.spinner.show('sp');
    this.api.getConfigurationRightsByInsuranceSubBranch(this.dataToSendGeneral.subBranchId, this.dataToSendGeneral.insuranceId)
      .then((data: any) => {
        if (data != null) {
          this.dataToSendReceipt.receiptId = data.configurationRightId;
          this.dataToSendReceiptLocation.receiptId = data.configurationRightId;
        } else {
          this.dataToSendReceipt.receiptId = 1;
          this.dataToSendReceiptLocation.receiptId = 1;
        }
        this.spinner.hide('sp');
      }, error => {
        this.toastr.error('Su solicitud no se ha realizado de forma exitosa, comuniquese con el Administrador', 'Notificación');
        this.spinner.hide('sp');
      });
  }
  /*--+---------------------------------------------------------------------------------+
|----------------------------| E M I T I R P O L I Z A |----------------------------|
+---------------------------------------------------------------------------------+--*/
  cancelPolicy() {
    this.spinner.show('sp');
    this.dataToSendCancelPolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: 0,
      number: ''
    };
    this.api.putChangeStatusPolicy(this.dataToSendCancelPolicy)
      .then(
        (data) => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }
  finishPolicy(id: number) {
    this.spinner.show('sp');
    this.dataToSendCancelPolicy = {
      policyId: localStorage.getItem('policy-id'),
      statusId: id,
      number: '',
      cancelationId: 0
    };
    this.api.putChangeStatusPolicy(this.dataToSendCancelPolicy)
      .then(
        (data) => {
          this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          this.spinner.hide('sp');
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }
  SendPolicy() {
    // console.log(this.dataToSendIssuePolicy);
    this.spinner.show('sp');
    this.api.putChangeStatusPolicy(this.dataToSendIssuePolicy)
      .then(
        (data) => {
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          this.spinner.hide('sp');
          this.toastr.success('LA PÓLIZA HA SIDO EMITIDA CORRECTAMENTE.', 'NOTIFICACIÓN');
          this.modalRef.hide();
          this.getPolicy();
          // @ts-ignore
          $('#smartwizard').smartWizard('goToStep', 0);
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }
  opeModalIssuePolicy(template: TemplateRef<any>) {
    if (this.dataToSendGeneral.subBranchId === 15) {
      this.dataToSendIssuePolicy = {
        policyId: localStorage.getItem('policy-id'),
        statusId: 1,
        number: ''
      };
    } else if (this.dataToSendGeneral.subBranchId === 1) {
      this.emmitHousingForm.reset();
      this.invokeServiceGetGender();
      this.initDataEmmitPolicyHousing();
    } else if (this.dataToSendGeneral.subBranchId === 18) {
      this.dataToSendIssuePolicy = {
        policyId: localStorage.getItem('policy-id'),
        statusId: 1,
        number: this.dataToSendGeneral.number
      };
      this.fileUpload = undefined;
    }
    this.validateIssuancePolicy();
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
  }
  validateIssuancePolicy() {
    this.spinner.show('sp');
    this.api.validationIssuancePolicyDamages(this.dataToSendGeneral.policyId).then((data: any) => {
      this.validationPolicy = data;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  initDataEmmitPolicyHousing() {
    this.dataEmmitHousing = {
      policyId: this.dataToSendGeneral.policyId,
      numberPolicy: this.dataToSendGeneral.number,
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      birthDate: '',
      gender: 0,
      statusId: 1,
      groupId: this.dataToSendGeneral.groupId,
      clientId: this.clientHousingId,
      insuranceCompanyId: this.dataToSendGeneral.insuranceId,
      insuranceCompany: '',
      address: '',
      rfc: ''
    };
    this.catInsuranceCompany.forEach(item => {
      if (item.insuranceId === this.dataToSendGeneral.insuranceId) {
        this.dataEmmitHousing.insuranceCompany = item.name;
      }
    });
    this.catClients.forEach(item => {
      if (item.clientId === this.clientHousingId) {
        this.dataEmmitHousing.rfc = item.rfc;
      }
    });
    if (this.locationList === undefined || this.locationList.length === 0) {
      this.initFiltersLocation();
      this.invokeServiceGetLocationsByPagination(1,  10);
    }
    setTimeout(() => {
      this.dataEmmitHousing.address = this.locationList[0].address;
    }, 500);
  }
  invokeServiceEmmitPolicyHousing() {
    this.dataEmmitHousing.birthDate = this.datePipe.transform(this.dataEmmitHousing.birthDate, 'yyyy-MM-dd', 'es-ES');
    this.spinner.show('sp');
    this.api.postEmmitPolicyHousing(this.dataEmmitHousing)
      .then(
        (data) => {
          this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
          this.spinner.hide('sp');
          this.toastr.success('LA PÓLIZA HA SIDO EMITIDA CORRECTAMENTE.', 'NOTIFICACIÓN');
          this.modalRef.hide();
          this.getPolicy();
          // @ts-ignore
          $('#smartwizard').smartWizard('goToStep', 0);
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }
  invokeServiceGetGender() {
    const data1 = {name: 'GENDERS'};
    this.api.getCatalogByBusiness(data1).then((data: any) => {
      this.catGenderList = data;
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  /*--+---------------------------------------------------------------------------------+
 |----------------------------| OBRA CIVIL |----------------------------|
 +---------------------------------------------------------------------------------+--*/
  invokeFunctionInitialValuesDetail() {
    this.dataDetailCivilWork = {
      locationId: 0,
      projectName: '',
      projectDescription: '',
      country: '',
      zipCode: '',
      stateCode: '',
      municipalityCode: '',
      neighborhoodCode: '',
      street: '',
      numExt: '',
      numInt: '',
      clientId: this.clientHousingId,
      policyId: this.dataToSendGeneral.policyId,
      streetNew: false
    };
  }
  invokeServiceGetDetailCivilWork() {
    const civilPolicy = {
      policyId: this.dataToSendGeneral.policyId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getDetailCivilWork(civilPolicy).then((data: any) => {
      this.dataDetailCivilWork = data;
      this.invokeServiceGetCoveragesAdditionalCivilWork();
      this.invokeFunctionLoadAddressCivilWork();
    }, error => {
      console.error(error.status);
    });
  }
  invokeServiceGetBasicCoverageCivilWork() {
    //  console.log('cobertura basica');
    const policyCivil = {
      policyId: this.dataToSendGeneral.policyId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getCoverageBasicsCivilWork(policyCivil).then((data: any) => {
      this.dataBasicCoverage = data;
    }, error => {
      console.error(error.status);
    });
  }
  invokeServiceSaveCoverageBasics() {
// proceso de validación de datos de nivel 2
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < this.dataBasicCoverage.coverageList.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
      for (let d = 0; d < this.dataBasicCoverage.coverageList[c].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
        // tslint:disable-next-line:prefer-for-of
        for (let va = 0; va < this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList.length; va ++) { // se recorre valores
          // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
          if (va === 0) {
            if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
              if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataBasicCoverage.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return;
              }
            } else {
              if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataBasicCoverage.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return;
              }
            }
          } else {
            if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
              if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataBasicCoverage.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            } else {
              if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].description === 'MINIMO'
                || this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].description === 'MAXIMO') {
                if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].value === 'NO APLICA') {
                  // console.log('ya no valida el monto y unidad');
                  va++;
                }
              } else if (this.dataBasicCoverage.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataBasicCoverage.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            }
          }

        }
      }
    }
    const coverageBasicsCivilWork = {
      locationId: this.dataDetailCivilWork.locationId,
      sectionId: this.dataBasicCoverage.id,
      json : JSON.stringify(this.dataBasicCoverage)
    };
    // console.log(coverageBasicsCivilWork);
    this.spinner.show('sp');
    this.api.postCoverageBasicsCivilWork(coverageBasicsCivilWork)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceGetCoveragesAdditionalCivilWork() {
    // console.log(this.dataDetailCivilWork.locationId + 'arbol de coberturas');
    const endorsementPolicy = {
      locationId: this.dataToSendGeneral.policyId,
      subBranchId: this.dataToSendGeneral.subBranchId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getSectionByGroups(endorsementPolicy).then((data: any) => {
      this.dataCoverageAdditional = data;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  invokeServiceSaveCoverageAdditionalCivilWork(coverageAdditional) {
    // console.log(coverageAdditional);
    this.spinner.show('sp');
    this.api.postCoverageAdditionalDamageCivilWork(coverageAdditional)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.modalRef.hide();
          this.invokeServiceGetCoveragesAdditionalCivilWork();
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  openSectionCivilWork(template: TemplateRef<any>, id: number) {
    this.luc = false;
    this.dataSectionSave = {
      locationId: this.dataDetailCivilWork.locationId,
      sectionId: id,
      json: ''
    };
    // console.log(id);
    if (id === 429) {
      // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dataCoverageAdditional[0].children.length; i ++) {
          if (this.dataCoverageAdditional[0].children[i].sectionId === 424
            || this.dataCoverageAdditional[0].children[i].sectionId === 425) {
            if (!this.dataCoverageAdditional[0].children[i].applySection) {
              this.toastr.warning('DEBE DE CAPTURAR PRIMERO LAS COBERTURAS B Y C', 'NOTIFICACIÓN');
              this.invokeServiceGetCoveragesAdditionalCivilWork();
              return;
            }
          }
        }

    }
    setTimeout(() => {
      this.spinner.show('sp');
      const damagePolicy = {
        sectionId: id,
        locationId: this.dataDetailCivilWork.locationId,
        policyId: this.dataToSendGeneral.policyId,
        endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
      };
      this.api.getCoverageAdditionalById(damagePolicy).then((data: any) => {
        this.dataSelection = data;
        // // console.log('load');
        this.spinner.hide('sp');
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, backdrop: 'static'});
      }, error => {
        this.spinner.hide('sp');
      });
    }, 500);
  }
  formatCurrency2CivilWork(valor, coverage, description, value) {
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    valor.value = valor.value.replace(/,/g, '');
    const r10 = valor.value * .10;
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[value].value = val;
  }
  changeSelectionOption2CivilWork(valor, coverage, description, value, typeVisible) {
    // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[value].medition = valor.medition;
    } else {
      this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[value].value = valor.value;
    }
    // console.log('entra: ', valor, coverage, description, value, typeVisible);
    const va = value + 1;
    // SE TIENE QUE DESACTIVAR CAMPOS
    switch (valor.description) {
      case 'MAXIMO':
        // deshabilitar campos
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va' + coverage + description + va).style.pointerEvents = 'none';
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
        break;
      case 'MINIMO':
        if (valor.medition === 'NO APLICA' || valor.value === 'NO APLICA' ) {
          // document.getElementById('va' + coverage + description + va).style.pointerEvents = 'none';
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].disabledField = true;
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].value = '';
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].medition = '';
        } else {
          // document.getElementById('va' + coverage + description + va).style.pointerEvents = 'auto';
          this.dataBasicCoverage.coverageList[coverage].descriptionList[description].valueList[va].disabledField = false;
        }
    }
  }
  invokeFunctionLoadAddressCivilWork() {
    this.invokeServiceGetCountries();
    this.invokeServiceGetStatesByCountry();
    this.loadInitialValuesData();
    let streetTmp = '';
    let neighborhoodCodeTmp = '';
    // setear dirección a objeto dataAddressFiscal
    this.dataAddressFiscal.locationId = this.dataDetailCivilWork.locationId;
    this.dataAddressFiscal.country = this.dataDetailCivilWork.country;
    this.dataAddressFiscal.zipCode = this.dataDetailCivilWork.zipCode;
    this.dataAddressFiscal.stateCode = this.dataDetailCivilWork.stateCode;
    this.dataAddressFiscal.municipalityCode = this.dataDetailCivilWork.municipalityCode;
    this.dataAddressFiscal.neighborhoodCode = this.dataDetailCivilWork.neighborhoodCode;
    this.dataAddressFiscal.street = this.dataDetailCivilWork.street;
    this.dataAddressFiscal.numExt = this.dataDetailCivilWork.numExt;
    this.dataAddressFiscal.numInt = this.dataDetailCivilWork.numInt;
    this.isNewStreetFiscal = true;
    streetTmp = this.dataAddressFiscal.street;
    neighborhoodCodeTmp = this.dataAddressFiscal.neighborhoodCode;
    // this.addressForm.get('addressTypeFiscal').patchValue(data.addressType);
    this.addressForm.get('countryFiscal').patchValue(this.dataDetailCivilWork.country);
    this.selectStateMunicipalityByZipCode(this._FISCAL);
    setTimeout(() => {
      this.dataAddressFiscal.street = streetTmp;
      this.dataAddressFiscal.neighborhoodCode = neighborhoodCodeTmp;
    }, 1000);
  }
  invokeServiceSaveDataGeneralCivilWork() {
    // setear dirección a objeto dataAddressFiscal
    this.dataDetailCivilWork.country = this.dataAddressFiscal.country;
    this.dataDetailCivilWork.zipCode = this.dataAddressFiscal.zipCode;
    this.dataDetailCivilWork.stateCode = this.dataAddressFiscal.stateCode;
    this.dataDetailCivilWork.municipalityCode = this.dataAddressFiscal.municipalityCode;
    this.dataDetailCivilWork.neighborhoodCode = this.dataAddressFiscal.neighborhoodCode;
    this.dataDetailCivilWork.street = this.dataAddressFiscal.street;
    this.dataDetailCivilWork.numExt = this.dataAddressFiscal.numExt;
    this.dataDetailCivilWork.numInt = this.dataAddressFiscal.numInt;
    this.dataDetailCivilWork.streetNew = this.isNewStreetFiscal;
    // console.log(this.dataDetailCivilWork);
    if (this.dataDetailCivilWork.locationId === 0) {
      this.invokeServiceSaveCivilWork(this.dataDetailCivilWork);
    } else {
      this.invokeServiceUpdateCivilWork(this.dataDetailCivilWork);
    }

  }
  invokeServiceSaveCivilWork(civilWork) {
    this.spinner.show('sp');
    this.api.postCivilWork(civilWork)
      .then(
        (response: any) => {
          this.dataDetailCivilWork.locationId = response.locationId;
          this.spinner.hide('sp');
          this.toastr.success('SE GUARDADO CORRECTAMENTE LA INFORMACIÓN', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceUpdateCivilWork(civilWork) {
    this.spinner.show('sp');
    this.api.updateCivilWork(civilWork)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SE GUARDADO CORRECTAMENTE LA INFORMACIÓN', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeFunctionLoadCoverageAdditional() {
    if (this.dataDetailCivilWork.locationId > 0) {
      this.invokeServiceGetCoveragesAdditionalCivilWork();
    }
  }
  invokeServiceEmmitPolicyCivilWork() {
    if (this.dataToSendIssuePolicy.number === '' || this.fileUpload === undefined) {
      this.toastr.warning('PARA EMITIR LA PÓLIZA ES NECESARIO EL NUMERO Y EL ARCHIVO DE LA PÓLIZA');
      return;
    }

    this.spinner.show('sp');
    this.api.putChangeStatusPolicy(this.dataToSendIssuePolicy)
      .then(
        (data) => {
          const formData = new FormData();
          formData.append('file', this.fileUpload);
          this.api.postFilePolicy(formData, this.dataToSendIssuePolicy.policyId)
            .subscribe(
              response => {
                this.shared.gPolicyId.next(Number(localStorage.getItem('policy-id')));
                this.spinner.hide('sp');
                this.toastr.success('LA PÓLIZA HA SIDO EMITIDA CORRECTAMENTE.', 'NOTIFICACIÓN');
                this.modalRef.hide();
                this.getPolicy();
                // @ts-ignore
                $('#smartwizard').smartWizard('goToStep', 0);
              }, error => {
                this.toastr.warning(error.error);
                // console.error();
                this.spinner.hide('sp');
              }
            );
        }, error => {
          this.spinner.hide('sp');
          this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
        }
      );
  }

  invokeFunctionLoadEndorsement() {
    this.endorsements = [];
    this.endorsementListSelected = [];
    this.key = 'id';
    this.display = 'name';
    // console.log('entrando a endosos');
    if (this.dataDetailCivilWork.locationId === 0) {
      this.endorsements = [];
      this.toastr.warning('PRIMERO DEBE DE GUARDAR LOS DATOS GENERALES', 'NOTIFICACIÓN');
      return;
    }
    this.spinner.show('sp');
    this.api.getEndorsementCivilWork(this.dataToSendGeneral.subBranchId).then((coverages: any) => {
      this.endorsements = coverages;
      // console.log(this.endorsements);
      this.invokeServiceGetEndorsementByLocation();
      this.spinner.hide('sp');
    }, error => {
      console.error(error.status);
      this.spinner.hide('sp');
    });
  }
  resetEndorsementCivil() {
    this.endorsementListSelected = [];
  }
  onDestinationChange(ev) {
    // console.log(this.tmpEndorsements);
    if (this.tmpEndorsements.length > ev.length) {
      // console.log('se elimino endoso');
      // saber que ids se eliminaron
      if (ev.length === 0) {
        this.tmpEndorsements.forEach(item => {
          this.invokeServiceDeleteEndorsement(this.dataDetailCivilWork.locationId, item.id);
        });
        return;
      }
      const finalEndorsements = ev.map(item => item.id);
      const initialEndorsements = this.tmpEndorsements.filter(item => !finalEndorsements.includes(item.id));
      // console.log('elementos a eliminar');
      // console.log(initialEndorsements);
      initialEndorsements.forEach(item => {
        this.invokeServiceDeleteEndorsement(this.dataDetailCivilWork.locationId, item.id);
      });
      setTimeout(() => {
        this.invokeServiceGetEndorsementByLocation();
      }, 2500);
    }
  }

  formatCurrencyEndorsement(valor, coverage, description, value, endorsement) {
    let moneyTmp = '';
    if (this.dataToSendGeneral.currencyId === 1) {
      moneyTmp = 'PESOS MEXICANOS';
    } else {
      moneyTmp = 'USD';
    }
    valor.value = valor.value.replace(/,/g, '');
    const r10 = valor.value * .10;
    const val = formatNumber(Number(valor.value), 'en-US', '1.2-2');
    this.endorsementListSelected[endorsement].coverageList[coverage].descriptionList[description].valueList[value].value = val;
    // aplicar modena de poliza a todas las sumas aseguradas
    if (valor.description === 'MONTO') {
      // tslint:disable-next-line:max-line-length
      this.endorsementListSelected[endorsement].coverageList[coverage].descriptionList[description].valueList[value].medition = moneyTmp;
    }
  }
  changeSelectionOptionEndorsement(valor, coverage, description, value, typeVisible, endorsement) {
    // console.log(valor, coverage, description, value, typeVisible);
    if (typeVisible === 1) {
      // tslint:disable-next-line:max-line-length
      this.endorsementListSelected[endorsement].coverageList[coverage].descriptionList[description].valueList[value].medition = valor.medition;
    } else {
      this.endorsementListSelected[endorsement].coverageList[coverage].descriptionList[description].valueList[value].value = valor.value;
    }
  }

  invokeServiceSaveEndorsementCivilWork(endorsement) {
    console.log(endorsement);
    this.dataSectionSave.json = JSON.stringify(endorsement);
    this.dataSectionSave.locationId = this.dataDetailCivilWork.locationId;
    this.dataSectionSave.sectionId = endorsement.id;
    // proceso de validación de datos de nivel 2
    // tslint:disable-next-line:prefer-for-of
    for (let c = 0; c < endorsement.coverageList.length; c ++) { // recorre coberturas
      // tslint:disable-next-line:prefer-for-of
      for (let d = 0; d < endorsement.coverageList[c].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
        // tslint:disable-next-line:prefer-for-of
        for (let va = 0; va < endorsement.coverageList[c].descriptionList[d].valueList.length; va ++) { // se recorre valores
          // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
          if (va === 0) {
            if (endorsement.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
              if (endorsement.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + endorsement.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return;
              }
            } else {
              if (endorsement.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + endorsement.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return;
              }
            }
          } else {
            if (endorsement.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
              if (endorsement.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + endorsement.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            } else {
              if (endorsement.coverageList[c].descriptionList[d].valueList[va].description === 'MINIMO'
                || endorsement.coverageList[c].descriptionList[d].valueList[va].description === 'MAXIMO') {
                if (endorsement.coverageList[c].descriptionList[d].valueList[va].value === 'NO APLICA') {
                  // console.log('ya no valida el monto y unidad');
                  va++;
                }
              } else if (endorsement.coverageList[c].descriptionList[d].valueList[va].value === '') {
                // console.log(c, d, va);
                // tslint:disable-next-line:max-line-length
                this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + endorsement.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                return; // se salta la validación
              }
            }
          }

        }
      }
    }
    //
    this.invokeServiceSaveEndorsementDamageByLocation(this.dataSectionSave);
    this.tmpEndorsements.push(endorsement);
    // console.log(this.tmpEndorsements);
    // console.log(this.endorsementListSelected);
  }

  invokeServiceSaveEndorsementDamageByLocation(dataSection: any) {
    this.spinner.show('sp');
    this.api.postCivilWorkEndorsement(dataSection)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          // this.invokeServiceGetEndorsementByLocation();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeServiceGetEndorsementByLocation() {
    this.spinner.show('sp');
    const civilPolicy = {
      locationId: this.dataDetailCivilWork.locationId,
      endorsementId: this.endorsementId === undefined ? 0 : this.endorsementId
    };
    this.api.getEndorsementCivilWorkByLocation(civilPolicy).then((data: any) => {
      this.endorsementListSelected = data;
      const objTmp = [];
      this.endorsementListSelected.forEach(item => {
        objTmp.push(item);
      });
      this.tmpEndorsements = objTmp;
      // console.log('se refresca endosos de ubicación');
      // console.log(this.tmpEndorsements);
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }

  invokeServiceDeleteEndorsement(locationId, id) {
    this.spinner.show('sp');
    this.api.deleteEndorsementLocationDamageById(locationId, id).subscribe( response => {
      this.toastr.success('OPERACIÓN REALIZADA CON ÉXITO', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
      this.spinner.hide('sp');
    });
  }

  closeCivilWork() {
    this.modalRef.hide();
    this.invokeServiceGetCoveragesAdditionalCivilWork();
  }

  openModalSaveEndorsement(template: TemplateRef<any>, optionSystem: number) {
    console.log('guardar coberturas secciones');
    this.operationSystem = optionSystem;
    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      // validaciones para la coberturas
      if (this.operationSystem === 5) {
        // proceso de validación de datos de nivel 2
        // tslint:disable-next-line:prefer-for-of
        for (let c = 0; c < this.dataSelection.coverageList.length; c ++) { // recorre coberturas
          // tslint:disable-next-line:prefer-for-of
          for (let d = 0; d < this.dataSelection.coverageList[c].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
            // tslint:disable-next-line:prefer-for-of
            for (let va = 0; va < this.dataSelection.coverageList[c].descriptionList[d].valueList.length; va ++) { // se recorre valores
              // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
              if (va === 0) {
                if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
                  if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                    if (this.dataToSendGeneral.subBranchId === 18
                      || this.dataToSendGeneral.subBranchId === 15
                      || this.dataToSendGeneral.subBranchId === 1) {
                        // tslint:disable-next-line:max-line-length
                        this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                        return;
                    }
                    break; // se salta la validación
                  }
                } else {
                  if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                    if (this.dataToSendGeneral.subBranchId === 18
                      || this.dataToSendGeneral.subBranchId === 15
                      || this.dataToSendGeneral.subBranchId === 1) {
                       // tslint:disable-next-line:max-line-length
                        this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                        return;
                    }
                    break; // se salta la validación
                  }
                }
              } else {
                if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].typeVisible === 1) {
                  if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                    // console.log(c, d, va);
                    // tslint:disable-next-line:max-line-length
                    this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                    return; // se salta la validación
                  }
                } else {
                  if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'MINIMO'
                    || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'MAXIMO'
                    || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'DESCRIPCIÓN'
                    || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].description === 'DESCRIPCION') {
                    if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === 'NO APLICA'
                      || this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                      // console.log('ya no valida el monto y unidad');
                      va++;
                    }
                  } else if (this.dataSelection.coverageList[c].descriptionList[d].valueList[va].value === '') {
                    // console.log(c, d, va);
                    // tslint:disable-next-line:max-line-length
                    this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                    return; // se salta la validación
                  }
                }
              }

            }
          }
        }
      } else if ( this.operationSystem === 6) {
        // proceso de validación de datos de nivel 3
        // tslint:disable-next-line:prefer-for-of
        for (let c = 0; c < this.dataSelection.subCoverageList.length; c ++) { // recorre coberturas
          // tslint:disable-next-line:prefer-for-of
          for (let d = 0; d < this.dataSelection.subCoverageList[c].coverageList.length; d ++) { // se recorre caracteristicas de coberturas
            // tslint:disable-next-line:prefer-for-of
            for (let de = 0; de < this.dataSelection.subCoverageList[c].coverageList[d].descriptionList.length; de ++) {
              // tslint:disable-next-line:prefer-for-of max-line-length
              for (let va = 0; va < this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList.length; va ++) { // se recorre valores
                // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
                // console.log(this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va]);
                if (de === 0 && va === 0) {
                  if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].typeVisible === 1) {
                    if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                      de = this.dataSelection.subCoverageList[c].coverageList[d].descriptionList.length;
                      break; // se salta la validación
                    }
                  } else {
                    if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                      de = this.dataSelection.subCoverageList[c].coverageList[d].descriptionList.length;
                      break; // se salta la validación
                    }
                  }
                } else {
                  if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].typeVisible === 1) {
                    if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                      // console.log(c, d, de, va);
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return; // se salta la validación
                    }
                  } else {
                    if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].description === 'MINIMO'
                      || this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].description === 'MAXIMO') {
                      if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === 'NO APLICA') {
                        // console.log('ya no valida el monto y unidad');
                        va++;
                      }
                    } else if (this.dataSelection.subCoverageList[c].coverageList[d].descriptionList[de].valueList[va].value === '') {
                      // console.log(c, d, de, va);
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return; // se salta la validación
                    }
                  }
                }
              }
            }
          }
        }
      } else if (this.operationSystem === 7) {
        // tslint:disable-next-line:prefer-for-of
        for (let c = 0; c < this.dataSelection.subCoverageList.length; c ++) { // recorre coberturas
          // tslint:disable-next-line:prefer-for-of
          for (let co = 0; co < this.dataSelection.subCoverageList[c].coverageList.length; co ++) {
            // tslint:disable-next-line:prefer-for-of max-line-length
            for (let d = 0; d < this.dataSelection.subCoverageList[c].coverageList[co].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
              // tslint:disable-next-line:prefer-for-of max-line-length
              for (let va = 0; va < this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList.length; va ++) { // se recorre valores
                // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
                if (va === 0) {
                  if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                    if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      break; // se salta la validación
                    }
                  } else {
                    if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      break; // se salta la validación
                    }
                  }
                } else {
                  if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                    if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      // console.log(c, co, d, va);
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return; // se salta la validación
                    }
                  } else {
                    if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].description === 'MINIMO'
                      || this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].description === 'MAXIMO') {
                      if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === 'NO APLICA') {
                        // console.log('ya no valida el monto y unidad');
                        va++;
                      }
                    } else if (this.dataSelection.subCoverageList[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      // console.log(c, co, d, va);
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return; // se salta la validación
                    }
                  }
                }
              }
            }
          }
        }
      } else if (this.operationSystem === 8) {
        // proceso de validación de datos de nivel 5
        // tslint:disable-next-line:prefer-for-of
        for (let c = 0; c < this.dataSelection.coverageLevels2.length; c ++) { // recorre coberturas
          // tslint:disable-next-line:prefer-for-of max-line-length
          for (let d = 0; d < this.dataSelection.coverageLevels2[c].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
            // tslint:disable-next-line:prefer-for-of
            for (let va = 0; va < this.dataSelection.coverageLevels2[c].descriptionList[d].valueList.length; va ++) { // se recorre valores
              // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
              if (va === 0) {
                if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].typeVisible === 1) {
                  if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                    break; // se salta la validación
                  }
                } else {
                  if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                    break; // se salta la validación
                  }
                }
              } else {
                if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].typeVisible === 1) {
                  if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                    // console.log(c, d, va);
                    // tslint:disable-next-line:max-line-length
                    this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageLevels2[c].name + ', PARA PODER GUARDAR', 'Notificación');
                    return; // se salta la validación
                  }
                } else {
                  if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].description === 'MINIMO'
                    || this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].description === 'MAXIMO') {
                    if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === 'NO APLICA') {
                      // console.log('ya no valida el monto y unidad');
                      va++;
                    }
                  } else if (this.dataSelection.coverageLevels2[c].descriptionList[d].valueList[va].value === '') {
                    // console.log(c, d, va);
                    // tslint:disable-next-line:max-line-length
                    this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverageLevels2[c].name + ', PARA PODER GUARDAR', 'Notificación');
                    return; // se salta la validación
                  }
                }
              }

            }
          }
        }
        // tslint:disable-next-line:prefer-for-of
        for (let c = 0; c < this.dataSelection.subCoverageList5.length; c ++) { // recorre coberturas
          // tslint:disable-next-line:prefer-for-of
          for (let co = 0; co < this.dataSelection.subCoverageList5[c].coverageList.length; co ++) {
            // tslint:disable-next-line:prefer-for-of max-line-length
            for (let d = 0; d < this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList.length; d ++) { // se recorre caracteristicas de coberturas
              // tslint:disable-next-line:prefer-for-of max-line-length
              for (let va = 0; va < this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList.length; va ++) { // se recorre valores
                // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
                if (va === 0) {
                  if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                    if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      break; // se salta la validación
                    }
                  } else {
                    if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      break; // se salta la validación
                    }
                  }
                } else {
                  if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].typeVisible === 1) {
                    if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      // console.log(c, co, d, va);
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList5[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return; // se salta la validación
                    }
                  } else {
                    if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].description === 'MINIMO'
                      // tslint:disable-next-line:max-line-length
                      || this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].description === 'MAXIMO') {
                      if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === 'NO APLICA') {
                        // console.log('ya no valida el monto y unidad');
                        va++;
                      }
                    } else if (this.dataSelection.subCoverageList5[c].coverageList[co].descriptionList[d].valueList[va].value === '') {
                      // console.log(c, co, d, va);
                      // tslint:disable-next-line:max-line-length
                      this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.subCoverageList5[c].name + ', PARA PODER GUARDAR', 'Notificación');
                      return; // se salta la validación
                    }
                  }
                }
              }
            }
          }
        }
      } else if (this.operationSystem === 9) {
        // validaciones para nivel 6
        // tslint:disable-next-line:prefer-for-of
        for (let c = 0; c < this.dataSelection.coverage5List.length; c ++) { // recorre coberturas
          // tslint:disable-next-line:prefer-for-of max-line-length
          for (let d = 0; d < this.dataSelection.coverage5List[c].descriptionCoverageList.length; d ++) { // se recorre caracteristicas de coberturas
            // tslint:disable-next-line:prefer-for-of max-line-length
            for (let va = 0; va < this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList.length; va ++) { // se recorre valores
              // verificamos que la primera caracteristica tenga valor si es así continuamos con la validación caso contrario se salta
              if (va === 0) {
                if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].typeVisible === 1) {
                  if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                    break; // se salta la validación
                  }
                } else {
                  if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                    break; // se salta la validación
                  }
                }
              } else {
                if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].typeVisible === 1) {
                  if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                    // console.log(c, d, va);
                    // tslint:disable-next-line:max-line-length
                    this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverage5List[c].name + ', PARA PODER GUARDAR', 'Notificación');
                    return; // se salta la validación
                  }
                } else {
                  if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].description === 'MINIMO'
                    || this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].description === 'MAXIMO') {
                    if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === 'NO APLICA') {
                      // console.log('ya no valida el monto y unidad');
                      va++;
                    }
                  } else if (this.dataSelection.coverage5List[c].descriptionCoverageList[d].valueList[va].value === '') {
                    // console.log(c, d, va);
                    // tslint:disable-next-line:max-line-length
                    this.toastr.warning('COMPLETE LA INFORMACIÓN DE ' + this.dataSelection.coverage5List[c].name + ', PARA PODER GUARDAR', 'Notificación');
                    return; // se salta la validación
                  }
                }
              }
            }
          }
        }
      }
      this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, animated: true});
    } else {
      this.saveData(true);
    }
  }

  openModalSaveEndorsementCivilWork(template: TemplateRef<any>, endorsement) {
    this.endorsementDataCivilWork = endorsement;
    this.operationSystem = 12;
    if (this.dataToSendGeneral.status === 1 || this.dataToSendGeneral.status === 4) {
      this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, animated: true});
    } else {
      this.saveData(true);
    }
  }
  saveData(save) {
    console.log('respuesta cierre endoso');
    switch (this.operationSystem) {
      case 1:
        this.saveAddress();
        break;
      case 2:
        this.updateAddress();
        break;
      case 3:
        this.confirmDelete();
        break;
      case 4:
        this.InvokeServiceDuplicateLocation();
        break;
      case 5:
        this.saveSectionLevel2();
        break;
      case 6:
        this.saveSectionLevel3();
        break;
      case 7:
        this.saveSectionLevel4();
        break;
      case 8:
        this.saveSectionLevel5();
        break;
      case 9:
        this.saveSectionLevel6();
        break;
      case 10:
        this.invokeServiceSaveDataGeneralCivilWork();
        break;
      case 11:
        this.invokeServiceSaveCoverageBasics();
        break;
      case 12:
        this.invokeServiceSaveEndorsementCivilWork(this.endorsementDataCivilWork);
        break;
      default:
        break;
    }
    this.invokeServiceGetEndorsementByPolicy(this.dataToSendGeneral.policyId);
  }
  closeModalEndorsement() {
    this.modalSaveEndorsement.hide();
    this.saveData(true);
  }
}
