import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {SharedService} from '../shared-service.service';
import {Event as NavigationEvent, NavigationEnd, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {NotificationMdlComponent} from '../notification-mdl/notification-mdl.component';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {WebsocketService} from '../websocket.service';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  constructor(private api: ApiService,
              public shared: SharedService,
              private router: Router,
              private modalService: BsModalService,
              private toastr: ToastrService,
              private wsRocket: WebsocketService,
              private validateAccess: ValidateAccessService) {

    this.getProfile();

    this.shared.fUpdateProfileObservable().subscribe(
      fProfile => {
        if (fProfile) {
          this.getProfile();
          this.shared.fUpdateProfile.next(false);
        }
      }
    );
    /**
     * Observable para monitorear los cambios en la barra de Búsqueda
     */
    this.dataSource = new Observable((observer: any) => {
      // Runs on every search
      observer.next(this.searchItem);
    }).pipe(
      mergeMap((token: any) => this.getStatesAsObservable(token))
    );
  }
  // -Variables para typeahead ->
  searchItem = {
    idFilter: 2,
    searchItemSelected: ''
  };
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  // -Variables para typeahead <-

  lclShowAdminAccess: boolean;
  lclShowUserAccess: boolean;
  lclShowDashboardMenu: boolean;

  user: any = {};
  incidentFilter: number;
  notificationList: any = [];
  bsModalRef: BsModalRef;
  notificationCount;
  item: any = {};
  lclCustomerList: any = [];
  customerSelected: any = {};
  insured: any = {};
  widthSize: number = window.innerWidth;
  urlAmmia: string;
  statisticsFilters: any = [];
  lclStatisticsFilterSelected: any = {};
  statisticId: number;
  optionOnBlur: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }

  ngOnInit() {
    this.user = {
      imageProfile: '../../assets/img/userprofile.png',
      name: '<i>Nombre de usuario</i>',
      position: '<i>Puesto de usuario</i>'
    };

    if (this.shared.gCurrentRoleId === 3) {
      this.invokeServiceForGetCustomerList();
    }

    this.lclShowAdminAccess = false;
    this.lclShowUserAccess = false;
    /*this.shared.gUserRoles.forEach((item, index) => {
      if (item.roleId === 3 && this.shared.gCurrentRoleId !== 3) {
        this.lclShowAdminAccess = true;
        this.lclShowUserAccess = false;
      } else if (item.roleId === 4 && this.shared.gCurrentRoleId === 3) {
        this.lclShowUserAccess = true;
        this.lclShowAdminAccess = false;
      }
      if (this.shared.gCurrentRoleId === 3) {
        this.lclShowDashboardMenu = true;
      }
    });*/
    this.incidentFilter = 0;
    /* rm-> this.shared.pgTitleObservable().subscribe(
      pgTitle => this.pgLclTitle = pgTitle
    );
    this.getNotificationUser();
    this.getcountNotification();
     */
    this.loadFilterStatics();
  }
  loadFilterStatics() {
    this.statisticId = 1;
    this.statisticsFilters = [
      {
        id: 1,
        description: 'Por poliza'
      },
      {
        id: 2,
        description: 'Por usuario'
      },
      /*{
        id: 5,
        description: 'Por correo'
      },*/
      {
        id: 3,
        description: 'Por incidente'
      },
      /*{
        id: 4,
        description: 'Por ammia lite'
      },*/
      {
        id: 6,
        description: 'Por poliza daños'
      }
    ];
    this.lclStatisticsFilterSelected = this.statisticsFilters[this.statisticId];
  }

  changeStatisticsFilter(statisticsFilter) {
    this.lclStatisticsFilterSelected = statisticsFilter;
    this.searchItem.idFilter = this.lclStatisticsFilterSelected.id;

    // Para ejecutar la consulta del search
    const element: HTMLElement = document.getElementById('search') as HTMLElement;
    const event = new Event('input', {
      bubbles: true,
      cancelable: true
    });
    element.dispatchEvent(event);
  }

  changeIncidentFilter(filterNumber) {
    this.incidentFilter = filterNumber;
    this.shared.fIncidentGeneral.next(filterNumber);
  }


  private getNotificationUser() {
    this.api.getNotificationUser().then((data) => {
        this.notificationList = data;
      }, error => {
        console.error(error);
      }
    );
  }

  private getcountNotification() {
    this.api.getcountNotification().then((data) => {
        if (data === null) {
          this.notificationCount = 0;
        } else {
          this.notificationCount = data;
        }
      }, error => {
        console.error(error);
        if (error.status === 204) {
          this.notificationCount = 0;
        }
      }
    );
  }

  modalnotification(notication) {
    const initialState = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component',
      notification: notication
    };
    this.bsModalRef = this.modalService.show(NotificationMdlComponent, {initialState});
    this.bsModalRef.setClass('modal-md');
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result === true) {
        this.getNotificationUser();
        this.getcountNotification();
      } else if (result === false) {
        console.info('Modal cancel');
      } else {
        console.info('Modal close');
      }
    });
  }

  deletenotification(notification) {
    this.api.deleteNotification(notification.id_notification).subscribe( response => {
      this.getNotificationUser();
    }, error => {
      this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
    });
  }

  /* Funciones para perfil */
  getProfile() {
    this.api.getUserProfile()
      .then(
        (data) => {
          this.user = data;
          this.shared.gProfileImg = this.user.imageProfile;
          this.shared.gProfileName = this.user.name;
          this.shared.gProfilePosition = this.user.position;
          // tslint:disable-next-line:max-line-length
          this.urlAmmia = '?userId=' + this.user.userId + '&zId=' + this.user.zulipId + '&email=' + this.user.email + '&name=' + this.user.name + '&firstName=' +
            this.user.primaryLastName + '&secondName=' + this.user.secondaryLastName;
        }, error => {
          console.log(error);
        }
      );
  }

  closeSession() {
    localStorage.clear();
    this.shared.fStreamRoomMessages.next(false);
    // this.wsRocket.disconnectToHost();
    // this.router.navigate(['/login']).then();
    window.open('/', '_self');
  }

  goToAdminProfile() {
    this.lclShowAdminAccess = false;
    this.lclShowUserAccess = true;
    localStorage.setItem('roleId', '3');
    this.router.navigate(['C']);
  }
  goToUserProfile() {
    this.lclShowAdminAccess = true;
    this.lclShowUserAccess = false;
    localStorage.setItem('roleId', '4');
    this.router.navigate(['/user/home']);
  }
  /*----------------------------------------------------------------------------------------------------------------------------------------
   -----------------------------------B U S Q U E D A    G E N E R A L----------------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  typeaheadOnSelect(e: TypeaheadMatch): void {
    const itemSelected: any = e.item;
    // this.searchItemSelected = `Póliza: ${itemSelected.title} - ${itemSelected.description}`;
    switch (itemSelected.type) {
      case 1:
        this.shared.fPolicyDataReadonly = true;
        if (this.shared.gPolicyId.getValue() !== itemSelected.id) {
          this.shared.gPolicyId.next(itemSelected.id);
        }
        this.router.navigate(['/admin/policies']);
        break;
      case 2:
        localStorage.setItem('incidentId', itemSelected.id);
        this.router.navigate(['/admin/incident_detail']);
        break;
      case 3:
        /*this.shared.gRequestId.next(itemSelected.id);
        this.shared.fOpenSidebar(6);
        break;*/
        if (this.shared.gRequestId.getValue() !== itemSelected.id) {
          this.shared.gRequestId.next(itemSelected.id);
        }
        this.router.navigate(['/admin/request']);
        break;
      case 4:
        this.insured = {
          userId: itemSelected.id,
          name: itemSelected.title,
          dependet: null,
          employeeNumber: null,
          created: null
        };

        if (this.shared.pginsuredId.getValue() !== itemSelected.id) {
          this.shared.pginsuredId.next(itemSelected.id);
        }
        this.shared.fInsuredDataReadonly = true;
        localStorage.setItem('insuredView', JSON.stringify(this.insured));
        // console.log(window.location);
        this.router.navigate(['/admin/insured']);
        break;
        // todo amia lite
      case 5:
        this.shared.fPolicyDataReadonly = true;
        this.shared.gPolicyIdLite.next(itemSelected.id);
        this.router.navigate(['/admin/ammia_lite']);
        break;
      // todo cobranza (SE CAMBIA A DAÑOS)
      case 6:
        this.shared.fPolicyDataReadonly = true;
        this.shared.gPolicyIdDamage.next(itemSelected.id);
        this.router.navigate(['/admin/damage-policy']);
        break;
    }
  }

  getStatesAsObservable(token: any): Observable<any> {
    switch (this.shared.gSearchId) {
      case 0:
        // console.log('busqueda general');
        const parameters = {
          search: token.searchItemSelected,
          optionSearch: token.idFilter
        };
        return this.api.getFinderResultPolicyByFilter(parameters).pipe();
        // return this.api.getFinderResultPolicy(token).pipe();
        break;
      case 1:
        // console.log('polizas');
        // return this.api.getFinderResultPolicy(token).pipe();
        const parameters2 = {
          search: token.searchItemSelected,
          optionSearch: token.idFilter
        };
        return this.api.getFinderResultPolicyByFilter(parameters2).pipe();
        break;
      case 2:
        // console.log('cobranza');
        // return this.api.getFinderResultPolicy(token).pipe();
        const parameters3 = {
          search: token.searchItemSelected,
          optionSearch: token.idFilter
        };
        return this.api.getFinderResultPolicyByFilter(parameters3).pipe();
        break;
    }
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
   -----------------------------------C A M B I A R   D E   D A S H B O A R D---------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/
  public changeDashboard(item) {
    this.shared.fAdminDashboard = item;
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
   -------------------------------------C A M B I A R   D E   C L I E N T E-----------------------------------------------------------------
   ---------------------------------------------------------------------------------------------------------------------------------------*/
  private invokeServiceForGetCustomerList() {
    this.api.getCustomerList().subscribe((response: any) => {
      if (response !== null && response !== undefined && response.length > 0) {
        this.lclCustomerList = response;
        this.customerSelected = response[0].customerId;
        this.shared.gCurrentCustomerId.next(response[0].customerId);
      }
    }, error => {
      console.error(error);
    });
  }

  public changeClientToConsult() {
    this.shared.gCurrentCustomerId.next(this.customerSelected);
  }

  openReportesDinamicos() {
    this.router.navigate(['/admin/dinamic-reports']);
    // this.router.navigate(['/admin/insurance']);
  }
  openCatalog() {
    this.router.navigate(['/admin/catalog']);
    // this.router.navigate(['/admin/insurance']);
  }
 openCliente() {
   this.validateAccess.validateAccessCore(11).then((response: any) => {
     console.log('valor que regresa', response.accessCore);
     if (response.accessCore === 0) {
       this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
       this.shared.gSearchId = 0;
       this.router.navigate(['/admin/executive']);
       this.shared.fUpdateHome.next(1);
     } else {
       this.router.navigate(['/admin/client']);
     }
   });
  }
  openReporte() {
    this.validateAccess.validateAccessCore(4).then((response: any) => {
      console.log('valor que regresa', response.accessCore);
      if (response.accessCore === 0) {
        this.toastr.warning('LO SENTIMOS NO TIENE ACCESO A ESTA FUNCIONALIDAD', 'RESTRICCIÓN DE ACCESO');
      } else {
        this.router.navigate(['/admin/report_core']);
      }
    });
  }
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
    this.shared.fUpdateHome.next(1);
  }
  openGestion() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
    this.shared.fUpdateHome.next(2);
  }

  openQuotation() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/quotation-list']);
    this.shared.fUpdateHome.next(3);
  }

  typeaheadOnBlur(event: TypeaheadMatch): void {
    this.optionOnBlur = event.item;
  }

  openQuote() {
    this.validateAccess.validateAccessCore(3).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        const tempLink = document.createElement('a');
        // tempLink.href = 'https://qa.tienda.ammia.io/inicio/login-multicotizador';
        // tempLink.href = 'https://tienda.ammia.io/inicio/login-multicotizador';
        tempLink.href = 'https://mb.tienda.bkuic.com/inicio/multi-quote';
        tempLink.setAttribute('target', '_blank');
        tempLink.click();
      }
    });
  }
}
