import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import {object} from "@amcharts/amcharts4/core";

@Component({
  selector: 'app-admin-incident',
  templateUrl: './admin-incident.component.html',
  styleUrls: ['./admin-incident.component.css']
})
export class AdminIncidentComponent implements OnInit {
// variables pantalla consulta siniestros
  incidentFilter: any = {};
  policyList: any = [];
  keywordPolicy = 'number';
  InsuredList: any = [];
  keyword = 'name';
  kinshipList: any = [];
  keywordKinship = 'name';
  clientList: any = [];
  groupList: any = [];
  statusList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  @ViewChild('policy', {static: false}) policy;
  @ViewChild('insured', {static: false}) insured;
  @ViewChild('kinship', {static: false}) kinship;
  // -Varibales para paginado de siniestros->
  incidentsList: any = {};
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  // variables para siniestro nuevo
  dataIncident: any = {};
  modalRef: BsModalRef;
  myFormIncident: FormGroup;
  groupSelected: number;
  clientSelected: number;
  insuredIncidentList: any = [];
  keywordIncident = 'name';
  policySelected: number;
  policyInsuredList: any = [];
  typeIncidentSelected: number;
  typeIncidentList: any = [];
  @ViewChild('user', {static: true}) user;
  kinshipIncidentList: any = [];
  kinshipSelected: number;
  typeRequestList: any = [];
  typeDocumentationSelected: number;
  isIncident: boolean;
  incidentSelected: any = {};
  incidentList: any = [];
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  flag: number;
  selectionIncident: boolean;
  phaseList: any [];
  phaseSelectedItems: any = [];
  dropdownSettingsPhase = {};
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {
    this.myFormIncident = this.fb.group({
      group: ['', [Validators.required]],
      subGroup: ['', [Validators.required]],
      insured: ['', [Validators.required]],
      incidentOld: [''],
      policy: ['', [Validators.required]],
      nameIncident: [''],
      typeIncident: ['', [Validators.required]],
      typeRequest: ['', [Validators.required]],
      numberContact: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
        Validators.maxLength(10)]],
      folioSrx: ['', {disabled: true}],
      folioInsurance: [''],
      description: ['', [Validators.required]],
      medicalCondition: ['', [Validators.required]],
      kinship: [''],
    });
  }
  ngOnInit() {
    this.incidentsList = [];
    this.selectionIncident = false;
    this.flag = 1;
    // this.kinshipSelected = 0;
    this.getPolicies();
    this.getInsured();
    this.getKinship();
    this.getGroups();
    this.getStatusIncidents();
    this.getPhaseIncidents();
    this.loadFilters();
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusName',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsPhase = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusName',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON FASES',
      searchPlaceholderText: 'BUSCAR'
    };
    // console.log(0);
    if (localStorage.getItem('filter') != undefined) {
      switch (localStorage.getItem('filter')) {
        case 'decline':
          this.phaseSelectedItems = [{ statusId: 6, statusName: 'RECHAZADO' }];
          this.incidentFilter.phase = [6];
          break;
        case 'active':
          this.statusSelectedItems = [{statusId : 1, statusName: 'ABIERTO'}];
          this.incidentFilter.status = [1];
          break;
        case 'documentation':
          this.phaseSelectedItems = [{statusId: 2, statusName: 'PENDIENTE DE DOCUMENTACIÓN'}];
          this.incidentFilter.phase = [2];
          break;
        case 'classification':
          this.phaseSelectedItems = [{statusId: 1, statusName: 'PENDIENTE POR CLASIFICAR'}];
          this.incidentFilter.phase = [1];
          break;
        case 'opinion':
          this.phaseSelectedItems = [{statusId: 3, statusName: 'EN DICTAMEN'}];
          this.incidentFilter.phase = [3];
          break;
        case 'group':
          const data = JSON.parse(localStorage.getItem('group'));
          this.groupsSelectedItems = [{groupId: data.groupId, name: data.name}];
          this.incidentFilter.groups = [data.groupId];
          const multiGroup = {
            idsGroups:  this.incidentFilter.groups
          };
          this.getSubGroups(multiGroup);
          break;
        case 'client':
          const dataGroup = JSON.parse(localStorage.getItem('group'));
          this.groupsSelectedItems = [{groupId: dataGroup.groupId, name: dataGroup.name}];
          this.incidentFilter.groups = [dataGroup.groupId];
          const multiGroup1 = {
            idsGroups:  this.incidentFilter.groups
          };
          this.getSubGroups(multiGroup1);
          const dataClient = JSON.parse(localStorage.getItem('client'));
          this.clientsSelectedItems = [ { clientId: dataClient.clientId, name: dataClient.name }];
          this.incidentFilter.subGroups = [dataClient.clientId];
          break;
      }
    }
    this.getByPagination(1, 10);
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  /* -----------------------------------------------------------------------------------------------------
    ------------------------P A N T A L L A B U S Q U E D A-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  selectPolicy(item) {
    this.incidentFilter.policyId = item.policyId;
    this.loadFiltersTable();
  }
  inputClearedPolicy() {
    this.incidentFilter.policyId = 0;
    this.policy.close();
    this.loadFiltersTable();
  }
  getPolicies() {
    this.api.getPoliciesIncident().then((data: any) => {
      this.policyList = data;
    }, error => {
    });
  }
  selectInsured(item) {
    this.incidentFilter.insuredId = item.id;
    this.loadFiltersTable();
  }
  inputClearedInsured() {
    this.incidentFilter.insuredId = 0;
    this.insured.close();
    this.loadFiltersTable();
  }
  getInsured() {
    this.api.getInsuredIncident().then((data: any) => {
      this.InsuredList = data;
    }, error => {
    });
  }
  selectKinship(item) {
    this.incidentFilter.kinshipId = item.id;
    this.loadFiltersTable();
  }
  inputClearedKinship() {
    this.incidentFilter.kinshipId = 0;
    this.kinship.close();
    this.loadFiltersTable();
  }
  getKinship() {
    this.api.getKinshipIncident().then((data: any) => {
      this.kinshipList = data;
    }, error => {
    });
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
    });
  }
  changeStatusGroups() {
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          group.push(item.groupId);
        });
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.incidentFilter.groups = group;
        this.clientsSelectedItems = [];
        this.loadFiltersTable();
      } catch (e) { }
    }, 1000);
  }
  changeStatusSubGroups() {
    const subGroups = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.clientId);
        });
        this.incidentFilter.subGroups = subGroups;
        this.loadFiltersTable();
      } catch (e) { }
    }, 500);
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }
  changeStatus() {
    const status = [];
    setTimeout(() => {
      try {
        this.statusSelectedItems.forEach(item => {
          // cambiar a nombre de variable del id des estatus
          status.push(item.statusId);
        });
        this.incidentFilter.status = status;
        this.loadFiltersTable();
      } catch (e) { }
    }, 500);
  }
  changePhase() {
    const phase = [];
    setTimeout(() => {
      try {
        this.phaseSelectedItems.forEach(item => {
          // cambiar a nombre de variable del id des estatus
          phase.push(item.statusId);
        });
        this.incidentFilter.phase = phase;
        this.loadFiltersTable();
      } catch (e) { }
    }, 500);
  }
  getStatusIncidents() {
    this.api.getStatusIncident().then((data: any) => {
      this.statusList = data;
      console.log(this.statusList);
    }, error => {
    });
  }
  getPhaseIncidents() {
    this.api.getPhaseIncident().then((data: any) => {
      this.phaseList = data;
    }, error => {
    });
  }
  loadFilters() {
    this.incidentFilter = {
      policyId: 0,
      folioSurexs: '',
      folioInsurance: '',
      insuredId: 0,
      kinshipId: 0,
      groups: [],
      subGroups: [],
      status: [],
      phase: [],
      claim: false,
      page: 1,
      size: 10
    };
  }
  clearFiltersTable() {
    this.loadFilters();
    this.statusSelectedItems = [];
    this.groupsSelectedItems = [];
    this.clientsSelectedItems = [];
    this.phaseSelectedItems = [];
    this.insured.clear();
    this.insured.close();
    this.policy.clear();
    this.policy.close();
    this.kinship.clear();
    this.kinship.close();
    this.loadFiltersTable();
  }
  loadFiltersTable() {
    // console.log('entra');
    if (this.incidentFilter.claim && this.incidentFilter.folioInsurance == "") {
      this.toastr.info('INGRESE EL NUMERO DE SINIESTRO, POR FAVOR', 'NOTIFICACIÓN');
      return;
    }
    this.getByPagination(1, 10);
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.incidentFilter.page = page;
    this.incidentFilter.size = size;
    // // console.loglog(this.filters);
    this.api.getIncidentPagination(this.incidentFilter)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.incidentsList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.incidentsList = this.paginationResponse.incidents ;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // // console.logerror(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.incidentsList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  openIncidentDetail(incident) {
    this.validateAccess.validateAccessCore(90).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        localStorage.setItem('incidentId', incident.incidentId);
        this.router.navigate(['/admin/incident_detail']);
      }
    });
  }

  /* -----------------------------------------------------------------------------------------------------
    ------------------------N U E V O S I N I E S T R O-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  closeModal() {
    this.modalRef.hide();
    this.selectionIncident = false;
  }
  changeGroupToConsult(event: any) {
    /*for (const item of this.groupList) {
      if (this.groupSelected == item.groupId) {
        this.dataIncident.groupId = item.groupId;
        const group = [];
        group.push(item.groupId);
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
      }
    }*/
    if (event !== undefined) {
      this.dataIncident.groupId = event.groupId;
      const group = [];
      group.push(event.groupId);
      let multiGroup = {};
      multiGroup = {
        idsGroups: group
      };
      this.getSubGroups(multiGroup);
    }
  }
  changeClientToConsult(event: any) {
    /*for (const item of this.clientList) {
      if (this.clientSelected == item.clientId) {
          this.dataIncident.clientId = item.clientId;
          this.invokeServiceGetUserByGroupClient(this.dataIncident.groupId, this.dataIncident.clientId);
      }
    }*/
    if (event !== undefined) {
      this.dataIncident.clientId = event.clientId;
      this.invokeServiceGetUserByGroupClient(this.dataIncident.groupId, this.dataIncident.clientId);
    }
  }
  selectInsuredIncident(item) {
    console.log(item);
    this.dataIncident.userId = item.userId;
    this.dataIncident.insured = item.name;
    this.invokeServiceGetPoliciesByUser(item.userId);
    this.invokeServiceGenerateZulipProfile(item.userId);
    if (!this.isIncident) {
      this.invokeServiceGetIncidentByUserId();
    }
  }
  inputClearedInsuredIncident() {
    this.dataIncident.userId = 0;
    this.user.close();
  }
  changePolicyToConsult(event: any) {
    if (event !== undefined) {
      console.log(event);
      this.dataIncident.policyId = event.policyId;
      this.dataIncident.policy = event.policy;
      this.invokeServiceGetTypeIncidentsByPolicy(event.policyId);
      this.invokeServiceGetKinshipByUserPolicy();
    }
  }
  invokeServiceSaveIncident() {
    // console.log(this.dataIncident);
    this.spinner.show('sp');
    this.api.saveIncident(this.dataIncident)
      .then(
        (response: any) => {
          this.invokeServiceSendMessageChat(response.incidentId, response.messageChat);
          this.toastr.success('EL SINIESTRO SE REGISTRO CORRECTAMENTE', 'NOTIFICACIÓN');
          localStorage.setItem('incidentId',  response.incidentId);
          this.router.navigate(['/admin/incident_detail']);
          this.selectionIncident = false;
          this.modalRef.hide();
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
          this.selectionIncident = false;
          this.modalRef.hide();
        }
      );
  }

  invokeServiceSendMessageChat(id, messageChat) {
    const ammia = {
      message: messageChat,
      incidentId: id,
      base64: '',
      extencion: '',
      keyDocumentType: ''
    };
    this.api.sendMessageFromCoreIncident(ammia).then((data: any) => {
      if (data == null) {
        console.log(data);
      }
      this.incidentList = data;
    }, error => {
    });
  }
  changeTypeIncidentToConsult(event: any) {
    /*for (const item of this.typeIncidentList) {
      if (this.typeIncidentSelected == item.typeIncidentId) {
          this.dataIncident.typeIncidentId = item.typeIncidentId;
      }
    }*/
    if (event !== undefined) {
      this.dataIncident.typeIncidentId = event.typeIncidentId;
    } else {
      this.dataIncident.typeIncidentId = 0;
    }
  }
  changeKinshipToConsult(event: any) {
    /*for (const item of this.kinshipIncidentList) {
      if (this.kinshipSelected == item.userId) {
        this.dataIncident.kinshipId = item.userId;
        // console.log(this.dataIncident);
      }
    }*/
    console.log(event);
    if (event !== undefined) {
      this.dataIncident.kinshipId = event.userId;
    }
  }
  changeDocumentationToConsult(event: any) {
    /*for (const item of this.typeRequestList) {
      if (this.typeDocumentationSelected == item.requestTypeIncidentId) {
        this.dataIncident.typeRequestIncidentId = item.requestTypeIncidentId;
      }
    }*/
    if (event !== undefined) {
      this.dataIncident.typeRequestIncidentId = event.requestTypeIncidentId;
    }
  }
  openIncident(template: TemplateRef<any>, isIncident) {
    this.validateAccess.validateAccessCore(89).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
// this.isIncident = isIncident;
        this.loadDataIncident();
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
        this.myFormIncident.reset();
      }
    });
  }
  loadDataIncident() {
    this.dataIncident = {
      incidentId: 0,
      name: '',
      policyId : 0,
      policy: '',
      userId: 0,
      insured: '',
      typeIncidentId: 0,
      contact: '',
      folioSurexs: '',
      folioInsurance: '',
      kinshipId: 0,
      kinship: '',
      groupId: 0,
      clientId: 0,
      description: '',
      medicalCondition: '',
      classification: 1,
      typeRequestIncidentId: 0,
      incidentIdOld: 0
    };
    // this.groupSelected = 0;
    // this.clientSelected = 0;
    // this.policySelected = 0;
    // this.typeDocumentationSelected = 0;
    // this.incidentSelected = 0;
    // this.insuredIncident.close();
  }
  invokeServiceGetPoliciesByUser(userId) {
    this.api.getPoliciesByUserId(userId).then((data: any) => {
      if (data == null) {
        this.policyInsuredList = [];
        return;
      }
      this.policyInsuredList = data;
    }, error => {
    });
  }
  invokeServiceGenerateZulipProfile(id) {
    const zulip = {
      userId: id
    };
    this.api.updateChatZulipByIncident(zulip).then((data: any) => {
    }, error => {
    });
  }
  invokeServiceGetUserByGroupClient(groupId, clientId) {
    this.api.getUserByGroupClient(groupId, clientId).then((data: any) => {
      if (data == null) {
        this.insuredIncidentList = [];
        return;
      }
      this.insuredIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetKinshipByUserPolicy() {
    this.api.getKinshipByUserPolicy(this.dataIncident.policyId, this.dataIncident.userId).then((data: any) => {
      if (data == null) {
        this.kinshipIncidentList = [];
        return;
      }
      this.kinshipIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetTypeIncidentsByPolicy(policyId) {
    this.api.getTypeIncidentByPolicy(policyId).then((data: any) => {
      if (data == null) {
        this.typeIncidentList = [];
        return;
      }
      this.typeIncidentList = data;
    }, error => {
    });
  }
  invokeServiceGetTypeDocumentation(type: number) {
    this.api.getTypesRequestIncidentHigh(type).then((data: any) => {
      this.typeRequestList = data;
    }, error => {
    });
  }
  changeTypeIncident() {
    for (const item of this.incidentList) {
      if (this.incidentSelected == item.incidentId) {
        this.dataIncident.incidentIdOld = item.incidentId;
        this.dataIncident.medicalCondition = item.medicalCondition;
        this.dataIncident.folioInsurance = item.folioInsurance;
      }
    }
  }
  invokeServiceGetIncidentByUserId() {
    this.api.getIncidentsByUserId(this.dataIncident.userId).then((data: any) => {
      if (data == null) {
        this.toastr.info('EL USUARIO NO CUENTA CON SINIESTROS PREVIOS');
        this.incidentList = [];
        return;
      }
      this.incidentList = data;
    }, error => {
    });
  }
  loadCatalog(value) {
    this.flag = value.catalogs;
  }

  selectReclamation() {
    this.invokeServiceGetTypeDocumentation(this.flag);
    switch (this.flag) {
      case 1:
        this.isIncident = true;
        break;
      case 2:
        this.isIncident = false;
        break;
    }
    if (this.isIncident) {
      this.dataIncident.classification = 1;
    } else {
      this.dataIncident.classification = 2;
    }
    this.selectionIncident = true;
  }

  selectCheckClaim(select: boolean) {
    if (select) {
      const folio = this.incidentFilter.folioSurexs;
      const isClaim = this.incidentFilter.claim;
      this.loadFilters();
      this.statusSelectedItems = [];
      this.groupsSelectedItems = [];
      this.clientsSelectedItems = [];
      this.insured.clear();
      this.insured.close();
      this.policy.clear();
      this.policy.close();
      this.incidentFilter.folioInsurance = folio;
      this.incidentFilter.claim = isClaim;
      // console.log(this.incidentFilter);
      if (this.dataIncident.folioInsurance != undefined) {
        this.loadFiltersTable();
      }
    }
  }
}
