import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {Documents} from '../upload-file/documents';
import * as events from 'events';
import {ValidateAccessService} from "../validate-access.service";
import {timeout} from "rxjs/operators";

@Component({
  selector: 'app-admin-request',
  templateUrl: './admin-request.component.html',
  styleUrls: ['./admin-request.component.css']
})
export class AdminRequestComponent implements OnInit {
  requestList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  typesSelectedItems: any = [];
  dropdownSettingsTypes = {};
  filterRangeDate: Date[];
  bsConfig: Partial<BsDatepickerConfig>;
  filterDate: any = {};
  clientList: any = [];
  groupList: any = [];
  statusList: any = [];
  typesList: any = [];
  filters: any = {};
  InsuredList: any = [];
  folioList: any = [];
  keyword = 'name';
  policyList: any = [];
  keywordPolicy = 'number';
  // -Varibales para paginado de recibos->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  modalRef: BsModalRef;
  lclRequestDetail: any = [];
  comments: string;
  uploadedFilesToLoad: Array<Documents> = []; // certificados
  certificates: Documents[] = [];
  polizas: any;
  idRequest: number;
  lclRequest: any = [];
  isRequesCar = false;
  modalRefCertificate: BsModalRef;
  modalOptionRequest: BsModalRef;
  endorsement: string;
  isPolicyGMM = false;
  operationType = 0;
  idsRequestValidate: any = [];
  allIsValide = false;
  statusRequest: any;
  idStatus: number;
  isCollapsable = true;
  selectedType = 0;
  @ViewChild('insured', {static: false}) insured;
  @ViewChild('policy', {static: false}) policy;
  @ViewChild('folio', {static: false}) folio;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {

    this.statusSelectedItems.push({statusRequestId: 2, description: 'PENDIENTE'});

    this.shared.gRequestIdGeneralObservable().subscribe(
      gRequestGeneralId => {
        this.filters = {
          groups: [],
          clients: [],
          requestTypes: [],
          insuredId: 0,
          requestId: gRequestGeneralId,
          startDate: '',
          endDate: '',
          requestStatus: [2],
          searchName: '',
          searchFolio: '',
          policyId: 0,
          page: 1,
          size: 10
        };
        // console.log(gRequestGeneralId);
        if (gRequestGeneralId > 0) {
          this.getByPagination(1, 10);
        }
      }
    );

    this.shared.gRequestIdObservable().subscribe(
      gRequestId => {
        this.filters = {
          groups: [],
          clients: [],
          requestTypes: [],
          insuredId: 0,
          requestId: gRequestId,
          startDate: '',
          endDate: '',
          requestStatus: [2],
          searchName: '',
          searchFolio: '',
          policyId: 0,
          page: 1,
          size: 10
        };
        // console.log(gRequestId);
        if (gRequestId > 0) {
          this.getByPagination(1, 10);
        }
      }
    );
  }

  ngOnInit() {
    this.loadFilters();
    this.getGroups();
    this.getTypesRequest();
    this.getStatusRequest();
    this.getInsured();
    this.getFolio();
    this.getPolicies();
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'statusRequestId',
      textField: 'description',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsTypes = {
      singleSelection: false,
      idField: 'typeRequestId',
      textField: 'description',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON TIPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.getByPagination(1, 10);
  }

  /**
   * función para regresar a dashboard principal
   */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  /**
   * cargar tabla solicitudes
   */
  loadFiltersTable() {
    this.getByPagination(1, 10);
  }

  /**
   * obtiene los grupos
   */
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }

  /**
   * obtiene los status de las solicitudes
   */
  getStatusRequest() {
    this.api.getStatusRequest().then((data: any) => {
      this.statusList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }

  /**
   * obtiene los tipos de solicitudes
   */
  getTypesRequest() {
    this.api.getTypesRequest().then((data: any) => {
      this.typesList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }

  /**
   * obtiene los asegurados
   */
  getInsured() {
    this.api.getInsured().then((data: any) => {
      this.InsuredList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }

  /**
   * obtiene los asegurados
   */
  getFolio() {
    this.api.getFolios().then((data: any) => {
      this.folioList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }
  /**
   * obtiene las polizas
   */
  getPolicies() {
    this.api.getPoliciesRequest().then((data: any) => {
      this.policyList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }
  loadDatesData(filterRangeDate: Date[]) {
    if (filterRangeDate != null) {
      if (filterRangeDate[0] > filterRangeDate[1]) {
        this.toastr.info('POR FAVOR INGRESE UNA FECHA VALIDA', 'NOTIFICACION');
        setTimeout(() => {
          this.filterRangeDate = undefined;
          this.filters.startDate = '';
          this.filters.endDate = '';
          return;
        }, 500);
      }
      this.filters.startDate = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
      this.filters.endDate = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
      // // console.loglog(this.filters);
    } else {
      this.filters.startDate = '';
      this.filters.endDate = '';
    }
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
      // // console.logerror(error.status);
      // // console.logerror(error.statusText);
      // // console.logerror(error.message);
    });
  }
  changeStatusGroups() {
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          group.push(item.groupId);
        });
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.filters.groups = group;
        this.clientsSelectedItems = [];
      } catch (e) { }
    }, 1000);
  }
  changeStatusSubGroups() {
    const subGroups = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.clientId);
        });
        this.filters.clients = subGroups;
        // // console.loglog(this.filters);
      } catch (e) { }
    }, 500);
  }
  changeStatusRequest() {
    const status = [];
    setTimeout(() => {
      try {
        this.statusSelectedItems.forEach(item => {
          // cambiar a nombre de variable del id des estatus
          status.push(item.statusRequestId);
        });
        this.filters.requestStatus = status;
        // // console.loglog(this.filters);
      } catch (e) { }
    }, 500);
  }
  changeStatusTypes() {
    const types = [];
    setTimeout(() => {
      try {
        this.typesSelectedItems.forEach(item => {
          // cambiar a nombre de variable del id des estatus
          types.push(item.typeRequestId);
        });
        this.filters.requestTypes = types;
        // // console.loglog(this.filters);
      } catch (e) { }
    }, 500);
  }
  selectInsured(item) {
    this.filters.insuredId = item.userId;
  }
  selectFolio(item) {
    this.filters.searchFolio = item;
  }
  inputClearedInsured() {
    this.filters.insuredId = 0;
    this.insured.close();
  }
  inputClearedFolio() {
    this.filters.searchFolio = '';
    this.folio.close();
  }
  inputClearedPolicy() {
    this.filters.policyId = 0;
    this.policy.close();
  }
  selectPolicy(item) {
    this.filters.policyId = item.policyId;
  }
  loadFilters() {
    this.filters = {
      groups: [],
      clients: [],
      requestTypes: [],
      insuredId: 0,
      requestId: 0,
      startDate: '',
      endDate: '',
      requestStatus: [2],
      searchName: '',
      searchFolio: '',
      policyId: 0,
      page: 1,
      size: 10
    };
  }

  pageChanged(event: PageChangedEvent): void {
    // // // console.loglog(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    // // console.loglog(this.filters);
    this.api.getRequestPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.requestList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.requestList = this.paginationResponse.requestLists ;
        }, error => {

          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.requestList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  requestGeneralModal(r) {
    localStorage.setItem('request-id', r.requestId);
  }
  clearFiltersTable() {
    this.filterRangeDate = undefined;
    this.loadFilters();
    this.statusSelectedItems = [];
    this.groupsSelectedItems = [];
    this.clientsSelectedItems = [];
    this.typesSelectedItems = [];
    this.insured.clear();
    this.insured.close();
    this.policy.clear();
    this.policy.close();
    this.loadFiltersTable();
  }

  processRequest(isBaja: boolean, idRequest: number) {
    let data;
    let certificateSkiped = false;

    if (!isBaja) {
      if (this.polizas.length === 0 || this.polizas.length === undefined) {
        this.toastr.warning('Es necesario agregar al menos un certificado', 'Notificación');
      }

     /* this.lclRequestDetail.forEach((item, index) => {
        if (item.typeKinshipId === 10 && this.polizas.length[index] === undefined || this.polizas[index] === '') {
          certificateSkiped = true;
          return;
        }
      });*/
    }

    /*if (certificateSkiped && !isBaja) {
      this.toastr.warning('Es necesario agregar los certificados solicitados', 'Notificación');
    } else */
    if (this.uploadedFilesToLoad.length === 0 && isBaja && this.isPolicyGMM && this.operationType != 4) {
      this.toastr.warning('Es necesario agregar la carta de antiguedad', 'Notificación');
    } else if (this.endorsement === undefined || this.endorsement === '') {
      this.toastr.warning('Es necesario agregar el endoso del movimiento', 'Notificación');
    } else {
      if (isBaja) {
        data = {
          requestId: this.idRequest,
          base: this.uploadedFilesToLoad.length === 0 ? '' : this.uploadedFilesToLoad[0].base64,
          nameFile: this.uploadedFilesToLoad.length === 0 ? '' : this.uploadedFilesToLoad[0].name,
          endorsement: this.endorsement
        };

      } else {

        const filtredPolicies = this.polizas.filter(item => item !== null);
        data = {
          requestId: this.idRequest,
          endorsement: this.endorsement,
          polizas: filtredPolicies
        };
      }

      this.spinner.show('sp');
      this.api.postRequestData(data)
        .then(
          (response: any) => {
            if (response.sagaSucces === true) {
              this.toastr.success('Operación realizada con éxito', 'Notificación');
              this.getByPagination(1, 10);
              this.spinner.hide('sp');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
              this.spinner.hide('sp');
            }
            this.modalRefCertificate.hide();
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
            this.spinner.hide('sp');
            this.modalRefCertificate.hide();
          }
        );
      }
    }

  openModalRequestDetail(template: TemplateRef<any>, requestId, status, idStatus) {
    this.validateAccess.validateAccessCore(86).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.getRequestDetail(requestId);
        this.statusRequest = status;
        this.idStatus = idStatus;
        if (this.idStatus == 4) {
          this.isCollapsable = false;
        } else {
          this.isCollapsable = true;
        }
        this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
      }
    });
  }
  async getRequestDetail(requestId) {
    const data = {
      requestId
    };
    this.api.getRequest(data)
      .then(
        (response: any) => {
          if (response.requestProcessUser !== undefined && response.requestProcessUser.length !== 0) {
            this.isRequesCar = false;
            this.lclRequestDetail = response.requestProcessUser;
            let countTitular = 0;

            if (response.requestProcessUser[0].operationType === 2) {
              this.lclRequestDetail = [... new Map (response.requestProcessUser.map(item =>
              [item.policyId, item])).values()];
              countTitular = this.lclRequestDetail.length;

            } else {
              response.requestProcessUser.forEach(value => {
                if (value.typeKinshipId === 10) {
                  countTitular++;
                }
              });
            }
            this.polizas = new Array(countTitular);
            this.comments = this.lclRequestDetail[0].comments;
            this.operationType = this.lclRequestDetail[0].operationType;
          } else {
            this.isRequesCar = true;
            this.lclRequestDetail = response.requestProcessCar;
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }
  closeModal() {
    this.modalRef.hide();
    this.comments = '';
  }

  getEventInfoRequest() {
    this.api.getInfoRequest()
      .then(
        (response: any) => {
          this.lclRequest = response;

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );

  }

  onLoadDocuments(event, poliza, id, policyId) {
    if (poliza !== '') {
      const certificates = {
        endorsement: this.endorsement,
        number: poliza,
        idPolicy: policyId,
        nameFile: event[event.length - 1].name,
        base: event[event.length - 1].base64
      };
      this.polizas[id] = certificates;
    } else {
      this.uploadedFilesToLoad = event;
    }
  }
  async openModalEditCertificate(template: TemplateRef<any>, element) {
    let policies = [];
    this.uploadedFilesToLoad = [];
    this.endorsement = '';
    await this.getRequestDetail(element);
    this.api.getPoliciesByRequest(element)
      .then(
        (response: any) => {
          policies = response;
          policies.some(x => {
            if (x === 2 || x === 3 || x === 5) {
              this.isPolicyGMM = true;
              return;
            }
          });
          this.modalRefCertificate = this.modalService.show(template);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
    this.idRequest = element;
  }

  setFinishedRequest(idRequest: number) {
    this.api.setFinishedRequest(idRequest)
      .then(
        (response: any) => {
          this.getByPagination(1, 10);
          this.toastr.success('La solicitud' + idRequest + ' fue finalizada.', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  validarSolicitud() {
    this.lclRequestDetail[0].comments = '';
    this.lclRequestDetail[0].statusId = 1;
    this.lclRequestDetail[0].validateInformation = true;

    this.invokeServiceForValidateRequest(this.lclRequestDetail);
  }

  activateComments() {
    this.isCollapsable = false;
  }

  rechazarSolicitud() {

    this.lclRequestDetail[0].comments = this.comments;
    this.lclRequestDetail[0].statusId = 4;
    this.lclRequestDetail[0].validateInformation = true;

    this.invokeServiceForValidateRequest(this.lclRequestDetail);
  }

  invokeServiceForValidateRequest(data) {
    this.spinner.show('sp');
    this.api.validateRequest(data)
      .then(
        (response: any) => {
          if (response.validate === true) {
            this.toastr.success('Operación realizada con éxito', 'Notificación');
          }
          this.spinner.hide('sp');
          this.closeModal();
          this.getByPagination(1, 10);
        }, error => {
          this.spinner.show('sp');
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.getByPagination(1, 10);
          this.closeModal();
        }
      );
  }

  /**
   * Mandar pantalla nueva solicitud
   */
  newRequest(idRequest, subject) {
    localStorage.setItem('selectedType', String(this.selectedType));

    if (idRequest > 0) {
      localStorage.removeItem('selectedType');

      if (subject.includes('ALTA')) {
        localStorage.setItem('selectedType', String(0));
      } else {
        localStorage.setItem('selectedType', String(1));
      }

      if (subject.includes('ASEGURADO')) {
        localStorage.setItem('selectedTypeSave', String(0));
      } else {
        localStorage.setItem('selectedTypeSave', String(1));
      }
    } else {
      this.closeModalOpionRequest();
    }



    localStorage.setItem('idRequest', String(idRequest));
    this.router.navigate(['/admin/new-request']);
  }

  closeModalOpionRequest() {
    this.modalOptionRequest.hide();
  }

  openModalOpionRequest(template: TemplateRef<any>) {
    this.validateAccess.validateAccessCore(84).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        this.modalOptionRequest = this.modalService.show(template, {class: 'modal-sm', keyboard: false, ignoreBackdropClick: true, backdrop: false});
      }
    });

  }

  downloadLayout() {
    this.spinner.show('sp');
    this.api.getLayoutMassiveUsers(2, this.filters)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_ASEGURADOS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }
}
