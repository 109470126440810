import {Component, OnInit} from '@angular/core';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-placement-quotation-list',
  templateUrl: './admin-placement-quotation-list.component.html',
  styleUrls: ['./admin-placement-quotation-list.component.css']
})
export class AdminPlacementQuotationListComponent implements OnInit {

  quotationList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.getQuotation();
  }

  /**
   * Carga los datos según la página seleccionada
   */
  pageChangedSpecial(event: PageChangedEvent): void {
    console.log(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }

  changeColorPagination() {
  }

  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
  }

  getQuotation() {


    this.quotationList.push(
      {
        id: 1,
        company: 'PATITO DE HULE',
        version: '1',
        date: '2023-11-01',
        status: 'VERSIÓN BASE'
      },
      {
        id: 2,
        company: 'PATITO DE HULE',
        version: '1.1',
        date: '2023-12-05',
        status: 'VERSIÓN COPIA'
      },
      {
        id: 3,
        company: 'CABALLITO DE MADERA',
        version: '2',
        date: '2023-12-08',
        status: 'VERSIÓN BORRADOR'
      });

  }

  openQuotationDetail() {
    this.router.navigate(['/admin/quotation-detail']);
  }

}
