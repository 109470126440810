import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {mergeMap} from 'rxjs/operators';
import {CarType} from './carType';
import {Use} from './use';
import {CoverageModel} from './coverage-model-autos';
import {DualListComponent} from 'angular-dual-listbox';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {regex} from '../../common/regex';
import {string} from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-coverage-car-policy',
  templateUrl: './coverage-car-policy.component.html',
  styleUrls: ['./coverage-car-policy.component.css']
})
export class CoverageCarPolicyComponent implements OnInit {

  coverageList: Array<CoverageModel>;
  categoriesCarForm: FormGroup; // Formulario
  private _subBranchId: number;
  private _insuranceId: number;
  private _endorsementId: number;
  dataToSendGeneral: any = {};
  @Output() public isSaved = new EventEmitter<any>();

  // Variables para typeahead (búsqueda de asegurados) ->
  searchMarcaSelected: any = {};
  searchVehicleTypeSelected: string;
  searchModeloSelected: any = {};
  searchVersionSelected: string;
  searchPackageSelected: string;
  dataSourceMarca: any;
  dataSourceModelo: any;
  dataSourceVersion: any;
  dataSourceCarType: any[];
  dataSourceUse: Array<Use>;
  dataSourcePackege: any[];
  policyDataReadonly;
  dataSourcesCoverages: Array<CoverageModel>;
  dataSourcesCoveragesMandatory: Array<CoverageModel>;
  dataSourcesCoveragesCopy: Array<CoverageModel> = new Array<CoverageModel>();
  dataSourcesCoveragesMandatoryBackup: Array<CoverageModel>;
  totals: number;
  catClientsPolicy: any = [];
  categoryId = 0;
  clientId: number;
  clientName: string;
  client: any;
  rfcClient: string;
  newValor: any;
  modalRef: BsModalRef;
  modalSaveEndorsement: BsModalRef;
  coverage: any = {};
  label: any;
  format = {
    add: 'ASIGNAR COBERTURA',
    remove: 'QUITAR COBERTURA',
    all: 'SELECCIONAR TODOS',
    none: 'DESELECCIONAR',
    direction: DualListComponent.LTR,
    draggable: true
  };
  key: any;
  display: any;
  disbleUse: boolean;
  disableTypeVehicle: boolean;
  isEdition = false;
  packageCoverage: any = {};
  isManualVersion: boolean; // Bid para saber si van a crear un sugrupo o van a tomarlo de uno existente
  isMotorCycle: boolean; // Bid para saber si van a crear un sugrupo o van a tomarlo de uno existente
  optionsTemplate: {};
  isText: boolean;
  constructor(private modalService: BsModalService,
              private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
    // this.policyDataReadonly = this.shared.fPolicyDataReadonly;
    this.coverageList = new Array<CoverageModel>();
    this.categoriesCarForm = new FormGroup(
      {
        policyId: new FormControl({value: localStorage.getItem('policy-id'), disabled: false}),
        categoryId: new FormControl(0),
        mark: new FormControl(null, [
          Validators.required
        ]),
        model: new FormControl(null, [
          Validators.required
        ]),
        version: new FormControl(null, [
          Validators.required
        ]),
        vehicleId: new FormControl(null, [
          Validators.required
        ]),
        clientId: new FormControl(null, [
          Validators.required
        ]),
        clientName: new FormControl(null),
        useVehicleId: new FormControl(null, [
          Validators.required
        ]),
        packageId: new FormControl(null, [
          Validators.required
        ]),
        plate: new FormControl(),
        series: new FormControl(null, [
          Validators.required,
          Validators.maxLength(17),
          Validators.pattern(regex.vin)
        ]),
        coverages: this.formBuilder.array([])
      }
    );

    this.shared.gPolicyIdCategoryObservable().subscribe(
      gPolicyIdCategory => {
        this.disbleUse = false;
        this.disableTypeVehicle = false;

        if (Number(localStorage.getItem('policy-id')) > 0) {
          this.getPolicy();
          this.typeaheadOnSelectPackage('TODO');
          this.key = 'id';
          this.display = 'description';
        }
      }
    );
    this.isManualVersion = false;
    this.isMotorCycle = false;
  }

  ngOnInit() {
    this.isSaved.emit(false);
    /*this.disbleUse = false;
    this.disableTypeVehicle = false;

    if (Number(localStorage.getItem('policy-id')) > 0 ) {
      if (this.shared.fPolicyDataReadonly) {
        this.getPolicy();
      }
      this.getClientsPolicy();
    }

    this.shared.gPolicyIdCategoryObservable().subscribe(
      gPolicyIdCategory => {
        this.disbleUse = false;
        this.disableTypeVehicle = false;

        if (Number(localStorage.getItem('policy-id')) > 0) {
          if (this.shared.fPolicyDataReadonly) {
            this.getPolicy();
          }

          if (this.dataToSendGeneral.branchId === 3) {
            this.getClientsPolicy();
            this.invokeCoverageCategories();

            this.invokeMakesCatalogList();
            this.invokeModelYearCatalogList();
            this.invokeServiceGetVehicleList();
            this.invokeServiceGetUseVehicleList();

            this.typeaheadOnSelectPackage('TODO');
            this.key = 'id';
            this.display = 'description';
            this.createFormNew();
          }
        }
      }
    );*/

    /*
    this.invokeMakesCatalogList();
    this.invokeModelYearCatalogList();
    this.invokeServiceGetVehicleList();
    this.invokeServiceGetUseVehicleList();

    this.typeaheadOnSelectPackage('TODO');
    this.key = 'id';
    this.display = 'description';
    */
    // this.createFormNew();
  }

  test(e) {
    console.log(e);
  }

  createFormNew() {
    // Se inicializa el formulario
    this.categoriesCarForm = new FormGroup(
      {
        policyId: new FormControl({value: localStorage.getItem('policy-id'), disabled: false}),
        categoryId: new FormControl(0),
        mark: new FormControl(null, [
          Validators.required
        ]),
        model: new FormControl(null, [
          Validators.required
        ]),
        version: new FormControl(null, [
          Validators.required
        ]),
        vehicleId: new FormControl(null, [
          Validators.required
        ]),
        clientId: new FormControl(this.clientId, [
          Validators.required
        ]),
        clientName: new FormControl(null),
        useVehicleId: new FormControl(null, [
          Validators.required
        ]),
        packageId: new FormControl(null, [
          Validators.required
        ]),
        plate: new FormControl(),
        series: new FormControl(null, [
          Validators.required,
          Validators.maxLength(17),
          Validators.pattern(regex.vin)
        ]),
        coverages: this.formBuilder.array([])
      }
    );
  }

  getPolicy() {
    this.api.getPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.dataToSendGeneral = data;
      this._subBranchId = this.dataToSendGeneral.subBranchId;
      this._insuranceId = this.dataToSendGeneral.insuranceId;
      this.invokePackage();
      this.validateVehicleType();
      this.invokeCoverageCategories();
      this.getClientsPolicy();
      this.invokeMakesCatalogList();
      this.invokeModelYearCatalogList();
      this.invokeServiceGetVehicleList();
      this.invokeServiceGetUseVehicleList();

      this.createFormNew();
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  validateVehicleType() {
    if (this._subBranchId === 20) {
      this.categoriesCarForm.controls.useVehicleId.setValue(6); // Transporte público
      this.disbleUse = true;
    }
    if (this._subBranchId === 22) {
      this.categoriesCarForm.controls.useVehicleId.setValue(7); // Transporte público
      this.disbleUse = true;
    }
  }

  validateUseVehicle(idTypeVehicle) {

    if (idTypeVehicle !== undefined && idTypeVehicle.id === 8) {
      this.categoriesCarForm.controls.useVehicleId.setValue(8); // Transporte público
      this.disbleUse = true;
    } else {
      this.categoriesCarForm.controls.useVehicleId.setValue(null); // Transporte público
      this.disbleUse = false;
    }

    if (idTypeVehicle !== undefined && idTypeVehicle !== null) {
      const textToCopy = String(idTypeVehicle.id + '::' + idTypeVehicle.description);
      this.copyText(textToCopy);
    }

  }

  changeUso(event) {
    if (event !== undefined && event !== null) {
      const textToCopy = String(event.id + '::' + event.description);
      this.copyText(textToCopy);
    }
  }

  getClientsPolicy() {
    this.api.getClientsPolicy(localStorage.getItem('policy-id')).then((data: any) => {
      this.catClientsPolicy = data;
      this.clientId = this.catClientsPolicy[0].clientId;
      this.clientName = this.catClientsPolicy[0].name;
      this.api.getClient(this.clientId).then((data: any) => {
        this.client = data;
        this.rfcClient = this.client[0].rfc;
      }, error => {

      });
    }, error => {
      this.catClientsPolicy = [];
    });
  }

  invokeMakesCatalogList() {
    const data = {
      isMotorbike: this.isMotorCycle
    };
    this.api.getMakeCatalogist(data).then(
      (response: any) => {
        this.dataSourceMarca = response;

      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeCoverageCategories() {
    if (this.dataToSendGeneral.groupId === 1 || this.dataToSendGeneral.financialClient) {
      this.api.getCategoryCoveragesCar(localStorage.getItem('policy-id')).then(
        (response: any) => {
          if (response !== undefined && response !== null) {
            this.packageCoverage = response;
            this.fillForm();
            this.categoriesCarForm.disable();
            this.categoryId = response.categoryId;
            this.isSaved.emit(true);
          }
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    } else {
      this.api.getCategoryCoverages(localStorage.getItem('policy-id'), this.endorsementId).then(
        (response: any) => {
          if (response !== undefined && response !== null) {
            this.packageCoverage = response;
            this.fillForm();
            this.categoryId = response.categoryId;

            const data = {
              idPackage: this.packageCoverage.packageId
            };
            this.isSaved.emit(true);
            this.invokeCoverageFromPackage(data, true);
          } else {
            this.isSaved.emit(false);
          }
        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    }
  }

  fillForm() {
    this.coverages.clear();
    this.categoriesCarForm.controls.mark.setValue(this.packageCoverage.mark);
    this.categoriesCarForm.controls.model.setValue(this.packageCoverage.model);
    this.categoriesCarForm.controls.version.setValue(this.packageCoverage.version);
    this.categoriesCarForm.controls.vehicleId.setValue(this.packageCoverage.vehicleId);
    this.categoriesCarForm.controls.useVehicleId.setValue(this.packageCoverage.useVehicleId);
    this.categoriesCarForm.controls.packageId.setValue(this.packageCoverage.packageId);
    this.categoriesCarForm.controls.categoryId.setValue(this.packageCoverage.categoryId);
    this.categoriesCarForm.controls.clientId.setValue(this.clientId);
    this.categoriesCarForm.controls.plate.setValue(this.packageCoverage.plate);
    this.categoriesCarForm.controls.series.setValue(this.packageCoverage.series);
    this.packageCoverage.coverages.map(coverage => {
      this.coverages.push(this.addCoverage(coverage));
    });

    this.dataSourcesCoveragesMandatory = this.packageCoverage.coverages.filter(x => x);
    this.totals = this.dataSourcesCoveragesMandatory.length;
    this.dataSourcesCoveragesMandatoryBackup = new Array<CoverageModel>();
    this.dataSourcesCoveragesCopy = new Array<CoverageModel>();
    this.dataSourcesCoverages = new Array<CoverageModel>();

    this.dataSourcesCoveragesMandatory.map(coverages => {
      if (coverages.visible !== null && coverages.visible) {
        this.dataSourcesCoveragesMandatoryBackup.push(coverages);
      }
      this.dataSourcesCoveragesCopy.push(coverages);
      this.dataSourcesCoverages.push(coverages);
    });
  }

  invokeModelYearCatalogList() {
    const data = {
      isMotorbike: this.isMotorCycle
    };
    this.api.getModelYearCatalogist(data).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceModelo = response;
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  typeaheadOnSelectMarca(marca): void {
    this.searchMarcaSelected = marca;
    if (this.categoriesCarForm.get('mark').value !== null && this.categoriesCarForm.get('model').value !== null) {
      const searchVersion = {
        shortMake: this.searchMarcaSelected.id,
        longMake: this.searchMarcaSelected.name,
        modelYear: this.searchModeloSelected.id
      };

      this.invokeCarVersion(searchVersion);
    }

    if (marca !== undefined && marca !== null) {
      const textToCopy = String(marca);
      this.copyText(textToCopy);
    }
  }

  typeaheadOnSelectModelo(modelo): void {
    this.searchModeloSelected = modelo;
    if (this.categoriesCarForm.get('mark').value !== null && this.categoriesCarForm.get('model').value !== null) {
      const searchVersion = {
        shortMake: this.searchMarcaSelected.id,
        longMake: this.searchMarcaSelected.name,
        modelYear: this.searchModeloSelected.id
      };

      this.invokeCarVersion(searchVersion);
    }

    if (modelo !== undefined && modelo !== null) {
      const textToCopy = String(modelo);
      this.copyText(textToCopy);
    }
  }

  changeVersion(event) {
    if (event !== undefined && event !== null) {
      const textToCopy = String(event);
      this.copyText(textToCopy);
    }
  }

  invokeCarVersion(dataSearch) {
    this.api.getCarVersionCatalogist(dataSearch).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceVersion = response;
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeServiceGetVehicleList() {
    const data = {name: 'VEHICLE'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.dataSourceCarType = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  invokeServiceGetUseVehicleList() {
    const data = {name: 'USE_VEHICLE'};
    this.api.getCatalogByBusiness(data).then((data: any) => {
      if (data != null) {
        this.dataSourceUse = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  invokePackage() {
    const data = {
      idInsurance: this._insuranceId,
      idSubranch: this._subBranchId
    };
    this.api.getPacakge(data).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourcePackege = response;
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  getOptionsAmp(): any[] {
    return ['AMPARADO', 'NO AMPARADO'];
  }

  typeaheadOnSelectPackage(e) {

    if (e !== undefined && e.idPackage !== null && e.idPackage !== undefined) {
      const data = {
        idPackage: e.idPackage
      };

      this.invokeCoverageFromPackage(data, false);
    }
  }

  invokeCoverageFromPackageSimple(data, isEdit) {

    this.api.getCoverageFromPacakge(data).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourcesCoverages = response;

      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeCoverageFromPackage(data, isEdit) {

    this.api.getCoverageFromPacakge(data).then(
      (response: any) => {

        if (isEdit) {
          const datasource = response;
          this.dataSourcesCoverages = this.dataSourcesCoveragesMandatory.filter(x => x);

          let founded = false;
          datasource.map(source => {
            this.dataSourcesCoveragesMandatory.map(mandatory => {
              if (mandatory.id === source.id) {
                founded = true;
              }
            });

            if (!founded) {
              this.dataSourcesCoverages.push(source);
            }
            founded = false;
          });
        } else  {
          this.dataSourcesCoverages =  new Array<CoverageModel>();
          this.dataSourcesCoveragesMandatory = new Array<CoverageModel>();

          this.dataSourcesCoverages = response;
          this.dataSourcesCoveragesMandatory = this.dataSourcesCoverages.filter(x => x.visible);
          this.totals = this.dataSourcesCoveragesMandatory.length;
          this.dataSourcesCoveragesMandatoryBackup = new Array<CoverageModel>();
          this.dataSourcesCoveragesCopy = new Array<CoverageModel>();

          this.coverages.clear();
          this.dataSourcesCoveragesMandatory.map(coverages => {
            this.addOption(coverages);
            this.dataSourcesCoveragesMandatoryBackup.push(coverages);
            this.dataSourcesCoveragesCopy.push(coverages);
          });
        }
      }, error => {
        this.spinner.hide('sp');
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  get coverages(): FormArray {
    return this.categoriesCarForm.get('coverages') as FormArray;
  }

  addOption(coverage) {
    this.coverages.push(this.newCoverage(coverage));
  }

  newCoverage(coverage): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(coverage.id),
      description: new FormControl(coverage.description),
      idCoverageEmission: new FormControl(coverage.idCoverageEmission),
      value: new FormControl(null, Validators.required),
      visible: new FormControl(coverage.visible),
      typeInput: new FormControl(coverage.typeInput),
      medition: new FormControl(coverage.medition, Validators.required),
      options: coverage.options === null ? this.formBuilder.array([]) : this.formBuilder.array(
        coverage.options.map(option => option)
      )
    });
  }

  addCoverage(coverage): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(coverage.id),
      description: new FormControl(coverage.description),
      value: new FormControl(coverage.value),
      visible: new FormControl(coverage.visible),
      typeInput: new FormControl(coverage.typeInput),
      medition: new FormControl(coverage.medition),
      options: (coverage.options === null || coverage.options === undefined) ? this.formBuilder.array([]) : this.formBuilder.array(
        coverage.options.map(option => option)
      )
    });
  }

  options(coverageForm): FormArray {
    return coverageForm.controls.options as FormArray;
  }

  typeaheadOnSelectCoverage(template: TemplateRef<any>, e, coverageForm) {
    this.coverage = coverageForm;
    if (e.label.split('::')[1] === '-1') {
      this.label = e.label.split('::')[0] === '' ? '' : e.label.split('::')[0] + '-';
      this.onChangeMedition(e.meditionType);
      this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false, focus: false});
    } else {
      if (this.coverage.controls.description.value === 'CADE') {
        const currentYear = new Date().getFullYear();
        const model = this.categoriesCarForm.controls.model.value;
        if ((currentYear - model) > 15) {
          this.toastr.warning('Los años máximos permitidos para CADE son 15', 'Notificación');
        } else {
          this.coverage.controls.medition.setValue(e.value);
          this.coverage.controls.value.setValue(e.label.split('::')[1]);
        }
      } else {
        this.coverage.controls.medition.setValue(e.value);
        this.coverage.controls.value.setValue(e.label.split('::')[1]);
      }
    }

  }

  closeModal() {
    this.modalRef.hide();
  }

  addNewValue() {
    const option = document.getElementById('valueCoverage') as HTMLInputElement | null;
    this.coverage.controls.medition.setValue(this.newValor);
    this.coverage.controls.value.setValue(this.label + '' + option.value);
    this.modalRef.hide();
    this.newValor = undefined;
    this.label = '';
  }

  groupingHelper(item) {
    return item.label.split('::')[0];
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }


  onDestinationChange(event) {
    // console.log(event);
    const validate = this.dataSourcesCoveragesMandatory.length - this.dataSourcesCoveragesCopy.length;

    if (validate > 1 || validate < -1) {

      this.dataSourcesCoveragesMandatory = [];
      this.dataSourcesCoveragesCopy.map(coverages => {
        this.dataSourcesCoveragesMandatory.push(coverages);
      });

      // this.copyList(this.dataSourcesCoveragesCopy, this.dataSourcesCoveragesMandatory);
      this.toastr.warning('Favor de seleccionar una cobertura a la vez', 'Notificación');
    } else {
      if (this.dataSourcesCoveragesMandatory.length > this.totals) {
        const difference = this.dataSourcesCoveragesMandatory.length - this.totals;
        for (let i = 1; i <= difference; i++ ) {
          this.addOption(this.dataSourcesCoveragesMandatory[this.dataSourcesCoveragesMandatory.length - i]);
        }
        this.totals = this.dataSourcesCoveragesMandatory.length;

        this.dataSourcesCoveragesCopy = [];
        this.dataSourcesCoveragesMandatory.map(coverages => {
          this.dataSourcesCoveragesCopy.push(coverages);
        });

        // this.copyList(this.dataSourcesCoveragesMandatory, this.dataSourcesCoveragesCopy);
      } else {

        let allMandatoryIncluding = true;
        this.dataSourcesCoveragesMandatoryBackup.map(covBackup => {
          if (!this.dataSourcesCoveragesMandatory.includes(covBackup)) {
            this.dataSourcesCoveragesMandatory.push(covBackup);
            this.toastr.warning('No se puede quitar coberturas obligatorias', 'Notificación');
            allMandatoryIncluding = false;
          }
        });

        if (allMandatoryIncluding) {
          const indexFounded = [];
          let founded;
          this.totals = this.dataSourcesCoveragesMandatory.length;
          for (const coveragesKey in this.coverages.value) {
            founded = false;
            this.dataSourcesCoveragesMandatory.map(coverageToDelete => {
              if (coverageToDelete.id === this.coverages.value[coveragesKey].id) {
                founded = true;
              }
            });
            if (!founded) {
              indexFounded.push(Number(coveragesKey));
              // this.coverages.removeAt(Number(coveragesKey));
            }
          }
          if (indexFounded.length > 0) {
            indexFounded.map(index => this.coverages.removeAt(index));

            this.dataSourcesCoveragesCopy = [];
            this.dataSourcesCoveragesMandatory.map(coverages => {
              this.dataSourcesCoveragesCopy.push(coverages);
            });
            // this.copyList(this.dataSourcesCoveragesMandatory, this.dataSourcesCoveragesCopy);
          }
        }
      }
    }
  }

  copyList(listSource, listDestination) {
    listDestination = [];
    listSource.map(coverages => {
      listDestination.push(coverages);
    });
  }

  saveCoverages() {
    this.spinner.show('sp');

    if (this.categoriesCarForm.valid) {
      this.categoriesCarForm.controls.policyId.setValue(localStorage.getItem('policy-id'));
      this.api.postCategoryCoveragePolicyCar(this.categoriesCarForm.value)
        .then(
          (response: any) => {
            this.categoryId = response.categoryId;
            this.categoriesCarForm.controls.categoryId.setValue(this.categoryId);
            this.isSaved.emit(true);
            this.spinner.hide('sp');
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          }, error => {
            this.isSaved.emit(false);
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {
      this.categoriesCarForm.markAllAsTouched();
      this.coverages.markAllAsTouched();
      this.isSaved.emit(false);
      this.spinner.hide('sp');
    }

  }
  closeModalEndorsement() {
    this.modalSaveEndorsement.hide();
  }

  editCoverages() {
    this.isEdition = true;
    const data = {
      idPackage: this.categoriesCarForm.controls.packageId.value
    };
    // this.invokeCoverageFromPackage(data);
    this.categoryId = 0;
    this.isSaved.emit(false);
  }
  onChangeNuevoSubGroup(event) {
    this.isManualVersion = event;
  }

  onChangeToMotorCycle(event) {
    this.isMotorCycle = event;
    this.invokeMakesCatalogList();
  }

  onChangeMedition(e) {
    // console.log(e);
      switch (e) {
        case 'PORCENTAJES': // 'PORCENTAJES' :
          this.isText = false;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' %',
            thousands: '',
            nullable: true
          };
          break;

        case 'UMAM': // 'UMAM' :
          this.isText = false;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' UMAM',
            thousands: '',
            nullable: true
          };
          break;

        case 'PESOS MEXICANOS': // 'PESOS MEXICANOS' :
          this.isText = false;
          this.optionsTemplate = {};
          break;

        case 'USD': // 'USD' :
          this.isText = false;
          this.optionsTemplate = {
            suffix: ' USD'
          };
          break;

        case 'TEXTO': // 'TEXTO' :
          this.isText = true;
          this.optionsTemplate = {
            allowNegative: true,
            allowZero: true,
            decimal: '',
            precision: 0,
            prefix: '',
            suffix: ' UMAM',
            thousands: '',
            nullable: true
          };
          break;

        default:
          break;
      }
  }

  validateSerialNumber(event) {
    let policy;
    if (event !== undefined) {
      this.api.validateSerieNumber(event.target.value).then(
        (response: any) => {
          policy = response;
          if (policy !== undefined &&
              policy.idPolicy !== null &&
              policy.policyId > 0 &&
              this.dataToSendGeneral.policyNumberOld !== policy.number) { // En idPolicy se encuentra el total de pólizas
            this.toastr.warning('El numero de serie ya se encuentra registrado en la póliza: ' + policy.number);
            this.categoriesCarForm.controls.series.setValue(null);
          }

        }, error => {
          this.spinner.hide('sp');
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.error(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
    }
  }

  copyText(text: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (text));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  openModalSaveEndorsement(template: TemplateRef<any>) {
      if (this.dataToSendGeneral.status !== 1 && this.dataToSendGeneral.status !== 4) {
          this.saveCoverageCar({saveData: true});
      } else {
        if (this.categoriesCarForm.valid) {
          this.modalSaveEndorsement = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        } else {
            this.categoriesCarForm.markAllAsTouched();
            this.coverages.markAllAsTouched();
            this.isSaved.emit(false);
        }
      }
  }

  saveCoverageCar(endorsement) {
    if (endorsement.saveData) {
      this.saveCoverages();
    }
  }

  get endorsementId(): number {
    return this._endorsementId;
  }
  @Input()
  set endorsementId(value: number) {
    this._endorsementId = value;
  }
}
